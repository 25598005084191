import React, { useEffect, useState } from 'react';
import MarqueCta from 'components/marque/MarqueCta';
import PictureMappings from 'components/picture-mappings/PictureMappings';
import { PAGES } from 'constants/content-library/ContentLibraryConstants';

const MarqueImage = (props) => {
  const [isActive, updateIsActive] = useState(false);
  const { marque_data = {}, marque_cta_active, marque_cta, onMarqueCtaClick, contentType, active } = props;
  const { marque_image, marque_image_alt } = marque_data;

  useEffect(() => {
    if (props.active) {
      updateIsActive(true);
    }
  }, [props.active]);

  if (!active && !isActive) {
    return '';
  } else {
    return (
      <section className="marque-area marque-area--image">
        <div className="marque-area__image container">
          <PictureMappings src={marque_image} imageAlt={marque_image_alt} />
        </div>
        {marque_cta_active == 'true' ? <MarqueCta {...marque_cta} onMarqueCtaClick={onMarqueCtaClick} /> : ''}
      </section>
    );
  }
};

export default MarqueImage;

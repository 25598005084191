//magic things
import React from 'react';

const NavMyGoalsIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.32 59.24" className="nav-my-goals-icon">
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <path
          d="M46 7.81c-.15.08-.3.24-.55.18v-2a1.84 1.84 0 00-1.2-2.05H11a2 2 0 00-1.17 2.2v1.93a7.15 7.15 0 00-3.23-.9 6.5 6.5 0 00-6.41 4.91c0 .2 0 .44-.19.6v7.44c0 .06.1.12.11.17a6.68 6.68 0 003.14 4.5c1.69 1.09 3.32 2.21 5 3.32l2.35 1.51a14.39 14.39 0 001.54 2.9 14.47 14.47 0 005.64 4.65A8.28 8.28 0 0122.59 43h-1.33a4.83 4.83 0 00-4.77 3.68 11.54 11.54 0 00-.16 3 2.16 2.16 0 01-.31.19 4.78 4.78 0 00-2.89 3.88 32.15 32.15 0 00-.06 3.67 1.63 1.63 0 001.77 1.79h25.73a1.56 1.56 0 001.67-1.64v-3.41a4.21 4.21 0 00-.17-1.11A5.08 5.08 0 0039 49.71v-1.83a4.83 4.83 0 00-3.5-4.65A8.49 8.49 0 0033 43c-.27 0-.37 0-.31-.36a7.76 7.76 0 013.81-5.36c1.15-.67 2.27-1.38 3.35-2.16A13.9 13.9 0 0044.41 30a.74.74 0 01.29-.37c2.53-1.68 5.08-3.35 7.6-5a6.43 6.43 0 003-5.44v-5.66A6.48 6.48 0 0046 7.81zM4.3 21.49A3 3 0 013.24 19v-5.23a3.24 3.24 0 116.47-.18v.37a6.43 6.43 0 01.08.74v10.54C8 24 6.06 22.86 4.3 21.49zm47.71-2a3 3 0 01-1.48 2.38l-4.8 3.2c-.06 0-.12.06-.26.13V14.37a3.85 3.85 0 011.15-3.14 3.13 3.13 0 013.38-.6 2.94 2.94 0 012 2.57 53.3 53.3 0 010 6.32zm-19.85-5.38a12.24 12.24 0 01-1.44 4.19l-.46.91L30 19a4 4 0 00-2.26-.7 3.94 3.94 0 00-2.22.68l-.3.23-.52-1a11.82 11.82 0 01-1.38-4.06 4.42 4.42 0 118.84 0zM25.52 19l-.3.23a3.92 3.92 0 00-1.43 3.05 4.07 4.07 0 00.12 1c0 .14.08.27.13.4-.44.08-.88.14-1.32.2a11.61 11.61 0 01-4.28.06 4.42 4.42 0 012.73-8.41 12.35 12.35 0 013.53 2.67zm2.21 7.23c-.06.43-.13.86-.21 1.28a11.87 11.87 0 01-1.27 4.1 4.42 4.42 0 11-7.15-5.2 12.31 12.31 0 013.62-2.54c.39-.2.79-.41 1.19-.6 0 .14.08.27.13.4a4 4 0 003.27 2.54zM35 32.71a4.44 4.44 0 01-6.18-1 12.32 12.32 0 01-1.29-4.24c-.08-.43-.15-.87-.21-1.3h.42a4 4 0 003.61-2.32 2.92 2.92 0 00.13-.34c.33.16.66.33 1 .51A11.77 11.77 0 0136 26.53a4.44 4.44 0 01-1 6.18zm1.9-8.57a12.53 12.53 0 01-4.43-.08l-1.11-.17a2.92 2.92 0 00.13-.34 4 4 0 00.22-1.29 3.93 3.93 0 00-1.44-3.05L30 19l.72-.7a11.66 11.66 0 013.43-2.57 4.42 4.42 0 112.74 8.41z"
          className="cls-1"
        />
        <path
          d="M46 3.88c-.15.08-.3.25-.55.18v-2a1.85 1.85 0 00-1.2-2H11a2 2 0 00-1.17 2.15v1.93a7.15 7.15 0 00-3.23-.9A6.53 6.53 0 00.19 8.15c0 .21 0 .44-.19.6v7.45c0 .05.1.11.11.17a6.66 6.66 0 003.14 4.49c1.69 1.09 3.32 2.21 5 3.32l2.35 1.52a14 14 0 001.54 2.89 14.58 14.58 0 005.64 4.66 8.27 8.27 0 014.82 5.83h-1.33a4.84 4.84 0 00-4.77 3.68 11.61 11.61 0 00-.16 3c-.11.06-.21.14-.31.19a4.75 4.75 0 00-2.89 3.88 32.07 32.07 0 00-.06 3.67 1.62 1.62 0 001.77 1.78h25.72a1.56 1.56 0 001.67-1.64v-3.41a4.21 4.21 0 00-.17-1.11A5 5 0 0039 45.79V44a4.84 4.84 0 00-3.5-4.66 8.84 8.84 0 00-2.5-.23c-.27 0-.37 0-.31-.36a7.76 7.76 0 013.81-5.36c1.15-.67 2.27-1.38 3.35-2.15a14 14 0 004.58-5.15.74.74 0 01.29-.37c2.53-1.67 5.08-3.34 7.6-5a6.41 6.41 0 003-5.44V9.62A6.48 6.48 0 0046 3.88zM4.3 17.57a3 3 0 01-1.06-2.51V9.84a3.24 3.24 0 116.47-.18v.37a6 6 0 01.08.74V21.3C8 20.11 6.06 18.93 4.3 17.57zm47.71-2A3 3 0 0150.53 18c-1.59 1.08-3.21 2.14-4.8 3.21l-.26.12V10.51a3.84 3.84 0 011.15-3.14A3.11 3.11 0 0150 6.71a2.92 2.92 0 012 2.57 53.13 53.13 0 010 6.31zm-19.85-5.39a12.17 12.17 0 01-1.44 4.19c-.15.31-.3.61-.46.91l-.26-.2a3.91 3.91 0 00-2.26-.71 3.87 3.87 0 00-2.22.69l-.3.22c-.18-.34-.35-.69-.52-1a11.93 11.93 0 01-1.38-4.07 4.42 4.42 0 118.84 0zm-6.64 4.88l-.3.22a3.94 3.94 0 00-1.43 3 4 4 0 00.12 1c0 .14.08.27.13.41l-1.32.2a11.84 11.84 0 01-4.28.06 4.42 4.42 0 012.73-8.41 12 12 0 013.53 2.67c.3.3.55.57.82.85zm2.21 7.23c-.06.42-.13.85-.21 1.28a11.8 11.8 0 01-1.27 4.09 4.42 4.42 0 11-7.15-5.2 12.31 12.31 0 013.62-2.53c.39-.21.79-.41 1.19-.61 0 .14.08.27.13.41a4 4 0 003.27 2.53 3 3 0 00.42.03zM35 28.78a4.43 4.43 0 01-6.18-1 12.25 12.25 0 01-1.29-4.23c-.08-.44-.15-.87-.21-1.31a3 3 0 00.42 0A4 4 0 0031.35 20a2 2 0 00.13-.34l1 .51A12 12 0 0136 22.6a4.44 4.44 0 01-1 6.18zm1.9-8.57a12.3 12.3 0 01-4.43-.08L31.35 20a2 2 0 00.13-.34 3.93 3.93 0 00-1.22-4.34l-.26-.2.72-.71a11.84 11.84 0 013.43-2.57 4.42 4.42 0 112.74 8.41z"
          className="cls-2"
        />
      </g>
    </g>
  </svg>
);

export default NavMyGoalsIcon;

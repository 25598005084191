import { INTERACTION, TRACKING_ARRAY } from 'constants/ensighten-tracking/EnsightenTrackingConstants';
import { activeTracking } from 'store/selectors/EnsightenTracking.selectors';
import { getPerformanceCookiesEnabled, getOneTrustActive } from 'store/selectors/OneTrust.selectors';
import { getContentItem, getIsProgressiveWebAppMode, getSitePerviousPageEnglishName } from 'store/selectors/App.selectors';
import { getInteractionTemplate, getPageViewTemplate, getTaggedContentTemplate, getVideoTemplate } from 'store/actions/ensightenTracking/DataTemplates';
import { PAGE } from 'constants/app/AppConstants';
import { CONTENT } from 'constants/content-library/ContentLibraryConstants';

import { getSiteActivePageEnglishName, getSiteActiveSubPage } from 'store/selectors/App.selectors';

export const ENSIGHTEN_TRACKING_STATUS = '[ENSIGHTEN] ENSIGHTEN_TRACKING_STATUS';

const _pushTrackingData = ({ type, subtype, data }) => {
  console.info('+++++++++ pre tracking data push:', type, data);
  const toPush = { type };
  subtype && (toPush.subtype = subtype);
  toPush.data = data;

  return TRACKING_ARRAY.push(toPush);
};

const trackingIsEnabled = (state) => {
  const oneTrustActive = getOneTrustActive(state);
  const performanceCookiesEnabled = getPerformanceCookiesEnabled(state);
  let isEnabled = true; // default to tracking enabled

  // if (oneTrustActive) {
  //   isEnabled = performanceCookiesEnabled; // if oneTrustActive tracking enabled if performanceCookiesEnabled = true
  // }
  return isEnabled;
};

export const triggerPageView = (opts) => {
  return (dispatch, getState) => {
    const state = getState();
    console.log('triggerPageView', { opts });
    // if (!trackingIsEnabled(state)) {
    //   return console.warn('ensighten tracking disabled');
    // }
    opts.isPWA = getIsProgressiveWebAppMode(state);

    const data = getPageViewTemplate(opts);
    data.previous_page = getSitePerviousPageEnglishName(state);

    return _pushTrackingData({ type: 'pageview', data });
  };
};

export const triggerInteraction = (opts) => {
  return (dispatch, getState) => {
    const state = getState();
    // if (!trackingIsEnabled(state)) {
    //   return console.log("ensighten tracking isn't active");
    // }
    const { contentType, id, contentItem = null } = opts;

    opts.isPWA = getIsProgressiveWebAppMode(state);
    if (contentItem && contentItem === null) {
      console.log('contentItem', contentItem);
      const contentItem = id ? getContentItem(state, contentType, id) : {};
      opts.contentItem = contentItem || {};
    }

    const currentPage = getSiteActivePageEnglishName(state);
    opts.page = currentPage;
    opts.subpage = getSiteActiveSubPage(state);

    const data = getInteractionTemplate(opts);
    data.previous_page = getSitePerviousPageEnglishName(state);
    return _pushTrackingData({ type: 'interaction', data });
  };
};

export const triggerVideoReady = (opts) => {
  return (dispatch, getState) => {
    const state = getState();
    // if (!trackingIsEnabled(state)) {
    //   return console.log("ensighten tracking isn't active");
    // }
    const { contentType, id } = opts;
    opts.isPWA = getIsProgressiveWebAppMode(state);
    const contentItem = id ? getContentItem(state, contentType, id) : {};
    opts.contentItem = contentItem || {};
    return _pushTrackingData({ type: 'video', subtype: 'Player_Ready', data: getVideoTemplate(opts) });
  };
};

export const triggerContentClick = ({ path, contentType, id, isTaggedContent = false }) => {
  // According to user stories we are only tracking game, video, quiz thumbnail click
  console.log('triggerContentClick', contentType, id, isTaggedContent);

  if (contentType === CONTENT.GAME || contentType === CONTENT.VIDEO || contentType === CONTENT.QUIZ || contentType === CONTENT.CHALLENGE || contentType === CONTENT.REWARD_UNLOCKED) {
    const { pathname = '' } = path;
    return triggerInteraction({ interactionType: INTERACTION.CLICK_CONTENT, pageType: PAGE.SUB, contentType, id, pathname: decodeURIComponent(pathname) });
  } else {
    return (dispatch, getState) => {};
  }
};

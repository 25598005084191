import { BOOTSTRAP_COMING_SOON_PAGE_CONTENT } from 'store/actions/Bootstrap.actions';

const initState = {
  data: {},
  status: '',
};

const ComingPagepage = (state = initState, action) => {
  let nextState = {};
  switch (action.type) {
    case BOOTSTRAP_COMING_SOON_PAGE_CONTENT: {
      const { data = {}, status = '' } = action.payload.comingSoonPage;
      nextState.status = status;
      nextState.data = data;
      return { ...state, ...nextState };
    }
  }
  return state;
};

export default ComingPagepage;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSiteTextDataByGroup } from 'store/selectors/App.selectors';
import { getLegalPopupById } from 'store/selectors/LegalPopups.selectors';
import { activeExitSiteModal } from 'store/actions/App.actions';
import LEGAL_POPUP_OPTIONS from 'constants/app/AppConstants';

class LegelInfoModal extends Component {
  constructor(props) {
    super(props);

    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.state = {
      activeModal: false,
    };
  }

  closeModal() {
    this.setState({ activeModal: false });
  }

  componentWillUnmount() {
    this.closeModal();
  }

  showModal() {
    this.setState({ activeModal: true });
    window.scrollTo(0, 0);
  }

  render() {
    const { clazz = '', legal_target, legal_link_text, info_target = '', popup_target = '' } = this.props;

    const ModalLayout = () => {
      const { popup_target, legalData } = this.props;
      const { title, description } = legalData;

      return (
        <section id="scrollTo" className="modal--legal-info modal__container flex flex-wrap justify-content-center align-items-start">
          <div className="modal">
            <div className="modal__close-button">
              <button onClick={this.closeModal} aria-label={'close'}>
                {'X'}
              </button>
            </div>
            <section className="modal__scrollarea">
              <div className="modal__header">
                <div className="modal__header__title">{title}</div>
              </div>
              <div className="modal__description" dangerouslySetInnerHTML={{ __html: description }}></div>
            </section>
          </div>
        </section>
      );
    };

    return (
      <React.Fragment>
        <button className={`${clazz} link`} onClick={this.showModal} aria-label="show overlay">
          {legal_link_text}
        </button>
        {this.state.activeModal && <ModalLayout />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { popup_target } = ownProps;

  return {
    legalData: getLegalPopupById(state, popup_target),
  };
};

export default connect(mapStateToProps, null)(LegelInfoModal);

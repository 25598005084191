import React from 'react';
import { connect } from 'react-redux';
import { getSiteTextDataByGroup } from 'store/selectors/App.selectors';

const SuperChallengeIcon = (props) => {
  const { text } = props;
  const { schools_super_challenge_badge_line_1 = 'super', schools_super_challenge_badge_line_2 = 'challenge' } = text;

  return (
    <div className="super-challenge-icon">
      <div className="super-challenge-icon__line-1">{schools_super_challenge_badge_line_1}</div>
      <div className="super-challenge-icon__line-2">{schools_super_challenge_badge_line_2}</div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    text: getSiteTextDataByGroup(state, 'schools'),
  };
};

export default connect(mapStateToProps, null)(SuperChallengeIcon);

import React, { useState } from 'react';
import { getUrlStaticImages } from 'constants/app/AppUrls';

// import constants
import { GARDENS } from 'constants/my-goals/MyGoalsConstants';

// import components
import CheckMark from 'components/inline-svgs/content-items/CheckMark';

const MyGoalsChoose = ({ nextStep, stepCallback, gardenCallback, text }) => {
  const [choice, setChoice] = useState(null);

  const choiceHandler = (key) => {
    stepCallback(nextStep);
    gardenCallback(key);
  };

  const renderGardens = () => {
    const { confirm_button } = text;

    const data = [];

    for (const [key, value] of Object.entries(GARDENS)) {
      const { img } = value;
      data.push(
        <div key={key} onClick={() => choiceHandler(key)} className={`mygoals_gardenchoice ${choice === key ? 'active' : ''}`}>
          <img src={img} alt={`Garden choice ${key + 1}`} />
        </div>
      );
    }

    return data;
  };

  const { choose_garden_title } = text;

  return (
    <div className="mygoals_choice">
      <img src={getUrlStaticImages('my-goals/introbg_small.jpg')} className="mygoals_intro__bg" alt="Intro Background Image" />
      <section className="mygoals_intro__body mygoals_intro__body--choose-garden">
        <div className="mygoals_choiceheader">{choose_garden_title}</div>
        <div className="mygoals_choicegrid">{renderGardens()}</div>
      </section>
    </div>
  );
};

export default MyGoalsChoose;

import { PAGES } from 'constants/content-library/ContentLibraryConstants';
import { INTERACTION } from 'constants/ensighten-tracking/EnsightenTrackingConstants';
import { PAGE } from 'constants/app/AppConstants';
import getHomePageData from 'store/actions/ensightenTracking/pageview/homepage';
import getOtherPageData from 'store/actions/ensightenTracking/pageview/other';
import getVideoPageData from 'store/actions/ensightenTracking/pageview/video';
import getRewardPageData from 'store/actions/ensightenTracking/pageview/reward';
import getSubPageData from 'store/actions/ensightenTracking/pageview/subpage';
import getNotFoundPageData from 'store/actions/ensightenTracking/pageview/notfound';
import {
  getRewardData,
  getCharacterClickAudioData,
  getCharacterData,
  getContentData,
  getDownloadData,
  getRewardDownloadData,
  getEmojiData,
  getFeaturedContentData,
  getOmniData,
  getTaggedContentData,
  getRewardLevelCompleteData,
  getTopTipsImpressionData,
  getTopTipsClickData,
  getChallengeLikedData,
  getChallengeShareData,
  getChallengeTakedData,
  getSignUpData,
  getLoginData,
  getMapMarkerData,
  getRewardUnlockedData,
  getChallengeUncompleteData,
  getRewardLevelUpData,
  getSchoolsLinkClickData,
  getUnlikeEmojiData,
  getChallengeUnlikedData,
} from 'store/actions/ensightenTracking/interaction/interaction';
import getVideoReadyData from 'store/actions/ensightenTracking/video/videoReady';

const getMainTemplate = (opts) => {
  const { contentType } = opts;

  console.log({ opts }, PAGES.VIDEOS);
  switch (contentType) {
    case PAGES.HOME:
      return getHomePageData(opts);
    case PAGES.VIDEOS:
      return getVideoPageData(opts);
    case PAGES.NOTFOUND:
      return getNotFoundPageData(opts);
    default:
      return getOtherPageData(opts);
  }
};

const getSubPageTemplate = (opts) => {
  return getSubPageData(opts);
};

export const getPageViewTemplate = (opts) => {
  const { pageType } = opts;
  if (pageType === PAGE.LANDING) {
    return getMainTemplate(opts);
  }
  return getSubPageTemplate(opts);
};

export const getInteractionTemplate = (opts) => {
  const { interactionType } = opts;
  console.log('interactionType', interactionType);

  switch (interactionType) {
    case INTERACTION.FEATURE_GAME:
    case INTERACTION.FEATURE_VIDEO:
      return getFeaturedContentData(opts);
    case INTERACTION.CLICK_CONTENT:
      return getContentData(opts);
    case INTERACTION.CLICK_OMNI:
      return getOmniData(opts);
    case INTERACTION.CLICK_EMOJI:
      return getEmojiData(opts);
    case INTERACTION.CLICK_UNLIKE_EMOJI:
      return getUnlikeEmojiData(opts);
    case INTERACTION.CLICK_DOWNLOAD:
      return getDownloadData(opts);
    case INTERACTION.CLICK_REWARD_DOWNLOAD:
      return getRewardDownloadData(opts);
    case INTERACTION.CLICK_CHARACTER:
      return getCharacterData(opts);
    case INTERACTION.CLICK_CHARACTER_AUDIOCLIP:
      return getCharacterClickAudioData(opts);
    case INTERACTION.REWARD_LEVEL_COMPLETE:
      return getRewardLevelCompleteData(opts);
    case INTERACTION.IMPRESSION_TOP_TIPS:
      return getTopTipsImpressionData(opts);
    case INTERACTION.CLICK_TOP_TIPS:
      return getTopTipsClickData(opts);
    case INTERACTION.CLICK_CHALLENGE_SHARE:
      return getChallengeShareData(opts);
    case INTERACTION.CLICK_CHALLENGE_COMPLETE:
      return getChallengeTakedData(opts);
    case INTERACTION.CLICK_CHALLENGE_LIKE:
      return getChallengeLikedData(opts);
    case INTERACTION.CLICK_CHALLENGE_UNLIKE:
      return getChallengeUnlikedData(opts);
    case INTERACTION.SUCCESSFUL_SIGN_UP:
      return getSignUpData(opts);
    case INTERACTION.SUCCESSFUL_LOGIN:
      return getLoginData(opts);
    case INTERACTION.CLICK_MAP_MARKER:
      return getMapMarkerData(opts);
    case INTERACTION.REWARD_UNLOCKED:
      return getRewardUnlockedData(opts);
    case INTERACTION.LEVEL_UP:
      return getRewardLevelUpData(opts);
    case INTERACTION.CLICK_CHALLENGE_UNCOMPLETE:
      return getChallengeUncompleteData(opts);
    case INTERACTION.CLICK_SCHOOLS_LINK:
      return getSchoolsLinkClickData(opts);
  }
};

export const getTaggedContentTemplate = (opts) => {
  return getTaggedContentData(opts);
};

export const getVideoTemplate = (opts) => {
  return getVideoReadyData(opts);
};

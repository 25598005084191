import * as Constants from 'constants/app/AppConstants';
import { getUrlDynamic } from 'constants/app/AppUrls';
import { SITE_STATUS, TRACKING_ACTIVE } from 'constants/app/AppConstants';

const getPublicData = () => {
  return Constants.APP_CONFIG.site_data;
};

export const getNormalisedPublicAdsData = (JsonData = null) => {
  if (JsonData !== null) {
    const { cms_ads = {} } = JsonData;
    return { ...cms_ads };
  }
  const { ads = {} } = getPublicData().config;
  return { ...ads };
};

export const getNormalisePublicContentData = (JsonData = null) => {
  // If JSON data is there
  if (JsonData !== null) {
    // TODO: Remove unneeded pages - appPage, interactiveStory, customPages, legalPopups
    // How best to do? remove altoghter or set empty obejcts??

    const {
      cms_home = {},
      cms_videos = {},
      cms_quizzes = {},
      challenges = {},
      cms_manifesto,
      video_playlists,
      editorials = {},
      cms_blank_1 = {},
      cms_blank_2 = {},
      super_challenges = [],
      cms_schools_manifesto = {},
      resources,
    } = JsonData;

    return {
      homePage: cms_home,
      videoPage: cms_videos,
      quizPage: cms_quizzes,
      schoolsPage: {
        super_challenges,
        cms_schools_manifesto,
        resources,
      },
      customPages: [cms_blank_1, cms_blank_2],
      legalPopups: {},
      challenges,
      manifesto: cms_manifesto,
      videoPlaylist: video_playlists,
      editorials,
    };
  }
};

export const getNormaliseComingSoonContentData = (JsonData = null) => {
  if (JsonData !== null) {
    const { cms_site_status = {}, cms_site_text = {} } = JsonData;
    const { status } = cms_site_status;
    const { coming_soon = {} } = cms_site_text;

    return {
      comingSoonPage: { status, data: coming_soon },
    };
  }

  const publicData = { ...getPublicData().content };

  return {
    comingSoonPage: publicData.coming_soon,
  };
};

export const getNormaliseShowcaseMapContentData = (JsonData = null) => {
  if (JsonData !== null) {
    const { areas = [], regions = [], countries = [], editorials = {} } = JsonData;
    return { areas, regions, countries, editorials };
  }

  const publicData = { ...getPublicData().content };

  return {
    comingSoonPage: publicData.coming_soon,
  };
};

export const getNormalisePublicConfigData = (JsonData = null) => {
  if (JsonData !== null) {
    // TODO: Remove unneeded pages - experience, levels, app_store
    // How best to do? remove altoghter or set empty obejcts??
    const {
      cms_header_footer = {},
      cms_cookie_bar_onetrust = {},
      cms_bitmovin = {},
      cms_ensighten_tracking = {},
      cms_metadata = {},
      cms_emojis = {},
      cms_story = {},
      cms_navigation = {},
      cms_image_alt_tags = {},
      cms_site_text = {},
      cms_site_urls = {},
      cms_boredom_buster = {},
      app_store = {},
      config_emoji_tracking_names = {},
      config_my_goals_tracking_names = [],
      config_centrium = {},
      cms_dynamic_cta = {},
      registered_users = 0,
      rotation_challenges = [],
    } = JsonData;

    const { header_content = {}, footer_content = {} } = cms_header_footer;

    if (app_store) {
      for (const id of Object.keys(app_store)) {
        app_store[id].image = getUrlDynamic(app_store[id].image);
      }
    }

    const { setup: emojis = [] } = cms_emojis;

    if (emojis) {
      for (const id of Object.keys(emojis)) {
        const emojiID = emojis[id].id;
        emojis[id].english_name = config_emoji_tracking_names[emojiID] || 'nvs';
      }
    }

    // parse stats CTA data
    // rewrite page urls to be correct
    if (cms_dynamic_cta && cms_dynamic_cta.setup && cms_dynamic_cta.setup.length > 0) {
      for (const [key, value] of Object.entries(cms_dynamic_cta.setup)) {
        if (value.page) {
          cms_dynamic_cta.setup[key].url = cms_site_urls[value.page];
        }
      }
    }

    const myGoalsLevelNames =
      config_my_goals_tracking_names.length === 0
        ? {
            level_1: 'aloe vera',
            level_2: 'amaryllis',
            level_3: 'cactus',
            level_4: 'cordyline fruticosa',
            level_5: 'daisy',
            level_6: 'fern',
            level_7: 'flamingo flower',
            level_8: 'hyacinth',
            level_9: 'jasmine',
            level_10: 'lavender',
            level_11: 'orchid',
            level_12: 'sunflower',
          }
        : config_my_goals_tracking_names;

    const { cookie_bar_description = '' } = cms_cookie_bar_onetrust;

    return {
      header: header_content,
      footer: footer_content,
      navigation: cms_navigation,
      metaData: cms_metadata,
      siteText: cms_site_text,
      imageAlt: cms_image_alt_tags,
      emojis: emojis,
      experience: {},
      siteUrls: cms_site_urls,
      tracking: TRACKING_ACTIVE,
      oneTrust: cms_cookie_bar_onetrust,
      bitmovin: cms_bitmovin,
      statsCta: { content: { ...cms_dynamic_cta.setup }, registered_users },
      rotation_challenges,
      myGoalsLevelNames,
      cookieBarDescription: cookie_bar_description,
    };
  }

  const publicData = { ...getPublicData().config };
  // prepend dynamic url to store images
  const { app_store } = publicData;

  if (app_store !== null) {
    for (const id of Object.keys(app_store)) {
      app_store[id].image = getUrlDynamic(app_store[id].image);
    }
  }

  const { emojis = null, emoji_tracking_names } = publicData;

  if (emojis !== null) {
    for (const id of Object.keys(emojis)) {
      const emojiID = emojis[id].id;
      emojis[id].english_name = emoji_tracking_names[emojiID] || 'nvs';
    }
  }

  return {
    header: publicData.header,
    footer: publicData.footer,
    navigation: publicData.navigation || {},
    metaData: publicData.meta_data,
    heroSwitch: publicData.centrium || {},
    siteText: publicData.site_text,
    imageAlt: publicData.image_alt_tags,
    emojis: publicData.emojis,
    experience: publicData.experience_points || null,
    levels: publicData.levels,
    characters: publicData.characters || {},
    story: publicData.story,
    tracking: publicData.tracking,
    appStores: publicData.app_store,
    oneTrust: publicData.cookie_bar_onetrust,
    bitmovin: publicData.bitmovin,
    siteUrls: publicData.cms_site_urls,
  };
};

/**
 * CONVERT TO WEBP
 */
export const convert_to_webp = (image) => {
  const splitImage = image.split('?');
  if (splitImage.length > 1) {
    const convertImage = splitImage[0].replace(new RegExp('.(' + ['jpg', 'png', 'svg', 'gif'].join('|') + ')$', 'i'), '.webp');
    return `${convertImage}?${splitImage[1]}`;
  }
  return image.replace(new RegExp('.(' + ['jpg', 'png', 'svg', 'gif'].join('|') + ')$', 'i'), '.webp');
};

/**
 * Ensighten Video event
 * Trigger fired on page load, forcing page to re-render the ads
 */
const ENSIGHTEN_PAGE_VIEW_TRIGGER = 'CN Show page change';
const ENSIGHTEN = {
  available: false,
  trigger: false,
};
export const getEnsightenTrigger = () => {
  if (!ENSIGHTEN.trigger) {
    if (window.ensBootstraps && window.ensBootstraps.tdi && window.ensBootstraps.tdi.ensEvent) {
      // console.info('Ensighten - Event trigger available');
      ENSIGHTEN.available = true;
      ENSIGHTEN.trigger = window.ensBootstraps.tdi.ensEvent.trigger;
    } else {
      console.info('Ensighten - Event trigger not available');
      ENSIGHTEN.available = false;
    }
    // } else {
    // console.log('ENSIGHTEN', ENSIGHTEN);
  }
  return ENSIGHTEN;
};

export const dispatchEnsightenPageLoad = (adTrigger) => {
  // console.log('<><><><><> dispatchEnsightenPageLoad', adTrigger);
  const ensighten = getEnsightenTrigger();
  if (ensighten.available) {
    ensighten.trigger(adTrigger);
    // ensighten.trigger(ENSIGHTEN_PAGE_VIEW_TRIGGER);
  }
};

import React, { Component } from 'react';
import { connect } from 'react-redux';

// import actions
import { displayMoreChallenges, displayResetChallenges } from 'store/actions/Challenges.actions';

// import selectors
import { getDisplayedChallenges, getDailyChallenges, getDailyChallengesCount, getChallengeItemsByIDs, getDefaultChallenges, getRotationChallenges } from 'store/selectors/Challenges.selectors';
import { getSiteTextDataByGroupedKey, getRotatonChallenges } from 'store/selectors/App.selectors';

// import Components
import ChallengeItem from './ChallengeItem';
import ChallengeItemCompactedView from './ChallengeItemCompactedView';
import Ad from 'components/ads/Ad';

/**
 * Challenges Component.
 * Places Challenges on the page
 *
 * @param {string} type (compact / mobile): type of challenge generated
 * Defaults to full view type
 */

class Challenges extends Component {
  constructor(props) {
    super(props);

    this.showMore = this.showMore.bind(this);
  }

  componentWillUnount() {
    this.props.displayResetChallenges();
  }

  showMore() {
    this.props.displayMoreChallenges();
  }

  renderChallenges() {
    const { challenges, daily, isOnline = true, toggleOfflineMessage } = this.props;
    const { content = [], showMore = false } = challenges;
    const renderableContent = daily || !isOnline ? content.slice(0, 3) : content;

    return renderableContent.map((item, index) => {
      return (
        <li key={index}>
          <ChallengeItem data={item} toggleOfflineMessage={toggleOfflineMessage} isOnline={isOnline} />
        </li>
      );
    });
  }

  render() {
    const { type = 'full', compactedView = false, daily = false, challenges, isOnline = true, moreButtontext } = this.props;
    const { content = [], showMore = false } = challenges;

    if (compactedView) {
      return content.slice(0, 1).map((item, index) => {
        return <ChallengeItemCompactedView key={index} data={item} />;
      });
    }

    return (
      <>
        <ul className={`challenges-grid challenges-grid--${type}`}>{this.renderChallenges()}</ul>
        {!daily && showMore && isOnline && (
          <button aria-label="Show More" className="challenges-grid__more-button" onClick={this.showMore}>
            {moreButtontext}
          </button>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { daily = false, compactedView = false } = ownProps;

  if (daily || compactedView) {
    if (getDailyChallengesCount(state) !== 0) {
      return {
        challenges: getDailyChallenges(state),
        moreButtontext: getSiteTextDataByGroupedKey(state, 'challenges', 'more_button'),
      };
    }
    const rotation = getRotatonChallenges(state);

    if (rotation.length > 0) {
      return {
        challenges: getRotationChallenges(state, rotation),
        moreButtontext: getSiteTextDataByGroupedKey(state, 'challenges', 'more_button'),
      };
    }

    return {
      challenges: getDefaultChallenges(state),
      moreButtontext: getSiteTextDataByGroupedKey(state, 'challenges', 'more_button'),
    };
  }

  return {
    challenges: getDisplayedChallenges(state),
    moreButtontext: getSiteTextDataByGroupedKey(state, 'challenges', 'more_button'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    displayMoreChallenges: () => {
      dispatch(displayMoreChallenges());
    },
    displayResetChallenges: () => {
      dispatch(displayResetChallenges());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Challenges);

import React from 'react';

const ChallengesEnergyIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.21 53.43" className="challenge-energy-icon">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="challenge_01">
          <path
            className="cls-1"
            d="M18.66 18.27l7-2.24a2.11 2.11 0 10-1.29-4l-7 2.23a2.11 2.11 0 001.28 4zM15 23.61A2.1 2.1 0 0017.6 25l14.22-4.54a2.11 2.11 0 10-1.29-4L16.32 21A2.11 2.11 0 0015 23.61zM30.7 22.63l-14.21 4.54a2.09 2.09 0 00-1.37 2.65.69.69 0 00.05.13 2.48 2.48 0 001 1.71l3.37 2.51.12 3.51a2.11 2.11 0 004.22-.27l-.15-4.32a2.12 2.12 0 00-.57-1.3 2.66 2.66 0 00-.66-.68l-1.35-1L32 26.65a2.11 2.11 0 10-1.28-4z"
          />
          <path className="cls-1" d="M30.87 29.18l-4.15 1.2A2.1 2.1 0 0025.35 33a1.42 1.42 0 00.08.18l.15 4.31a2.11 2.11 0 104.21-.26l-.11-3.34 2.48-.72a2.11 2.11 0 10-1.29-4z" />
          <rect className="cls-2" x="19.41" y="47.36" width="11.18" height="2.15" rx="1.07" transform="rotate(-1.53 24.8233 48.2709)" />
          <path
            className="cls-2"
            d="M26.56 53.35l-3 .08a.15.15 0 01-.13-.07l-1.29-1.75a.16.16 0 01.12-.26l5.65-.15a.16.16 0 01.14.26l-1.36 1.83a.2.2 0 01-.13.06zM23.07 46.07a5.7 5.7 0 01-5.73-5.58L17.25 37l14.89-.39.09 3.47A5.75 5.75 0 0126.65 46z"
          />
          <path
            d="M31.94 36.82L32 40.1a5.54 5.54 0 01-5.38 5.68l-3.43.09h-.15a5.53 5.53 0 01-5.53-5.39l-.09-3.27 14.49-.39m.39-.41h-.4l-14.49.39H17v.4l.09 3.27a5.91 5.91 0 005.93 5.78h.16l3.43-.09a5.94 5.94 0 005.77-6.09l-.09-3.28v-.4z"
            className="cls-3"
          />
          <path className="cls-4" d="M47.66 29.7l-10.76.29M23.76 8.38l-.2-7.83M31.86 11.05l5.61-6.13M35.72 20.38l9.94-3.93M.55 30.95l10.57-.27M15.15 11.49L9.03 5.81M11.79 21.02L.95 17.35" />
        </g>
      </g>
    </g>
  </svg>
);

export default ChallengesEnergyIcon;

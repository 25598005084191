import React from 'react';

const ChallengesFoodIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120.76 152.44" className="challenge-food-icon">
    <g id="Layer_2" data-name="Layer 2">
      <g id="updated">
        <g id="category_v3">
          <g id="apple">
            <g id="food_selected">
              <path
                d="M24.54 0a51.2 51.2 0 0017.55 42.09L60 57.53l.22-18.16C58.45 3.39 40.15 10.22 24.54 0zm35 48.6a.38.38 0 01-.45-.3l-.19-.67c-.13-.43-.29-1.07-.54-1.87s-.53-1.78-.9-2.87-.83-2.32-1.34-3.63A66.7 66.7 0 0052 30.7a59.89 59.89 0 00-6.1-8.64 72.54 72.54 0 00-7.22-7.4c-2.44-2.19-4.81-4.11-6.84-5.77a37.44 37.44 0 01-4.71-4.53 19.21 19.21 0 01-1.58-2 .39.39 0 010-.5.26.26 0 01.39 0s.59.66 1.71 1.72a43.54 43.54 0 004.87 3.92c2.13 1.5 4.61 3.31 7.16 5.5a63.71 63.71 0 017.55 7.45c1.19 1.41 2.32 2.89 3.38 4.41a49.52 49.52 0 012.87 4.66 50 50 0 012.28 4.67c.67 1.52 1.22 3 1.69 4.4s.84 2.68 1.14 3.84.54 2.17.72 3 .28 1.51.37 2l.13.7a.46.46 0 01-.3.47z"
                className="cls-1"
              />
              <path className="cls-2" d="M58.9 50.67C57.79 36.83 59.65 18.3 74 7.75A13.46 13.46 0 0181.43 14S62.9 22.33 61.12 51.65z" />
              <path
                className="cls-2"
                d="M115.64 55.2c-13.47-32.8-50.29-16.42-56.35-5.76C52.92 39 16.67 24.25 4 57.52c-9.78 25.76-.14 51.83 11.57 69.16s25.64 26 28.59 25.75c5.88-.51 7.92-11.87 7.92-11.87s2.38 11.66 8.52 11.53 8.49-11.89 8.49-11.89 2.37 11.27 8.27 11.54c3 .13 17.46-9.35 28.72-27.24s21.01-41.43 9.56-69.3z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ChallengesFoodIcon;

import React, { useState, useEffect } from 'react';

// import components
import RotateScreen from 'components/rotate-screen/RotateScreen';

const MyGoalsFrame = ({ children, text, isMobile }) => {
  const [rotated, setRotated] = useState(false);

  const checkRotate = () => {
    if (window.innerWidth < window.innerHeight) {
      setRotated(true);
    } else {
      setRotated(false);
    }
  };

  useEffect(() => {
    if (isMobile) {
      checkRotate();

      window.addEventListener('resize', checkRotate);
      window.addEventListener('onorientationchange', checkRotate);

      return () => {
        window.removeEventListener('resize', checkRotate);
        window.removeEventListener('onorientationchange', checkRotate);
      };
    }
  });

  if (!isMobile) {
    return (
      <div className="mygoals_frame">
        <div className="mygoals_inner">{children}</div>
      </div>
    );
  }

  if (rotated) {
    return <RotateScreen />;
  } else {
    return children;
  }
};

export default MyGoalsFrame;

//magic things
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { IMAGE_SRC_SIZES, WEBPSUPPORT, NO_IMAGE_FALLBACK, PAGE } from 'constants/app/AppConstants';
import { convert_to_webp } from 'constants/app/AppServices';
import { triggerInteraction } from 'store/actions/EnsightenTracking.actions';

import { CONTENT } from 'constants/content-library/ContentLibraryConstants';
import { INTERACTION } from 'constants/ensighten-tracking/EnsightenTrackingConstants';

import PictureMappings from 'components/picture-mappings/PictureMappings';
import PictureMappingsNoImage from 'components/picture-mappings/PictureMappingsNoImage';
// Import Content SVGs
import { getContentLink, getUrlDynamic, getUrlStaticImages } from 'constants/app/AppUrls';
import { getSiteTextDataByGroupedKey, getSiteUrlByName } from 'store/selectors/App.selectors';
import ContentIcon from 'components/generic/ContentIcon';
import { triggerContentClick } from 'store/actions/EnsightenTracking.actions';
import PictureMappingsSingleImage from 'components/picture-mappings/PictureMappingsSingleImage';

class ContentItemDefault extends Component {
  constructor(props) {
    super(props);
    this.handleContentClick = this.onContentClick.bind(this);
  }

  onContentClick(e) {
    // const { isTaggedContent = false, id, content_type } = this.props;
    // if (content_type === CONTENT.STORY) {
    //   this.props.trackInteraction({ interactionType: INTERACTION.CLICK_CHARACTER, pageType: PAGE.SUB, contentType: CONTENT.STORY, id: id, path: this.props.location });
    // } else {
    //   this.props.triggerContentClick({ path: this.props.location, contentType: content_type, id, isTaggedContent });
    // }
  }

  render() {
    // Strip out the data we need to populate the content item
    // prettier-ignore
    const {
      friendly_url = '',
      thumb = {}, title,
      content_type,
      contentTypeText,
      thumb_alt = '',
      thumb_title = '',
      thumb_type = '',
      thumb_video,
      thumb_video_image = '',
      playlistReferal = ''
    } = this.props;

    const { match, location, id, override_default_xp, override_xp } = this.props;

    // Create vars to push through picture mappings
    let sizes = this.props.itemSize == 'large' || this.props.itemSize == 'size-mix' ? IMAGE_SRC_SIZES.LARGE : this.props.itemSize == 'small' ? IMAGE_SRC_SIZES.DEFAULT : IMAGE_SRC_SIZES.DEFAULT;

    let imageAlt = thumb_alt != '' ? thumb_alt : `${title} ${content_type}`;
    let imageTitle = thumb_title != '' ? thumb_title : title;

    const videoPoster =
      thumb_video_image != ''
        ? getUrlDynamic(WEBPSUPPORT != false ? convert_to_webp(thumb_video_image) : thumb_video_image)
        : getUrlStaticImages(WEBPSUPPORT != false ? convert_to_webp(NO_IMAGE_FALLBACK) : NO_IMAGE_FALLBACK);

    if (content_type === 'challenge') {
      if (!this.props.content) return '';
      const { content } = this.props;
      const { has_download, download_content, image } = content;
      return (
        <div className="content-item__wrapper">
          <section className={'content-item content-item--' + this.props.itemSize + ' content-item--' + content_type}>
            <a href={download_content.download_location} target="_blank" rel="noreferrer">
              <figure className="content-item__image">
                <PictureMappings src={image} sizes={IMAGE_SRC_SIZES.DEFAULT} imageAlt={title} imageTitle={title} />
                <figcaption className="content-item__details">
                  <div className="content-item__title">{this.props.itemSize === 'hero' ? <h1>{title}</h1> : <h2>{title}</h2>}</div>
                  <div className="content-item__icon">
                    <span className="icon">
                      <ContentIcon type={content_type} />
                    </span>
                  </div>
                </figcaption>
              </figure>
            </a>
          </section>
        </div>
      );
    }

    if (friendly_url === '') {
      return (
        <div className="content-item__wrapper">
          <section className={'content-item content-item--' + this.props.itemSize + ' content-item--no-item'}>
            <figure className="content-item__image">
              <PictureMappings />
            </figure>
          </section>
        </div>
      );
    }

    const { contentBaseUrl } = this.props;

    let link = `/${contentBaseUrl}/${friendly_url}`;

    if (content_type === 'video') {
      let id = null;
      if (playlistReferal !== '') {
        id = playlistReferal;
      } else if (location.state) {
        id = location.state.playlist;
      }

      if (id) {
        link = {
          pathname: link,
          state: {
            playlist: id,
          },
        };
      }
    }

    return (
      <div className="content-item__wrapper">
        <section className={'content-item content-item--' + this.props.itemSize + ' content-item--' + content_type} onClick={this.handleContentClick}>
          <Link to={link}>
            {thumb_type != '' && thumb_type === 'video' ? (
              <div className="content-item__video">
                <video autoPlay playsInline loop muted poster={videoPoster}>
                  <source src={getUrlDynamic(thumb_video)} type="video/mp4" />
                </video>
                <div className="content-item__details">
                  <div className="content-item__title">{this.props.itemSize === 'hero' ? <h1>{title}</h1> : <h2>{title}</h2>}</div>
                  <div className="content-item__icon">
                    <span className="icon">
                      <ContentIcon type={content_type} />
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <figure className="content-item__image">
                <PictureMappings src={thumb} sizes={sizes} imageAlt={imageAlt} imageTitle={imageTitle} />
                <figcaption className="content-item__details">
                  <div className="content-item__title">{this.props.itemSize === 'hero' ? <h1>{title}</h1> : <h2>{title}</h2>}</div>
                  <div className="content-item__icon">
                    <span className="icon">
                      <ContentIcon type={content_type} />
                    </span>
                  </div>
                </figcaption>
              </figure>
            )}
          </Link>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    triggerContentClick: (opts) => {
      dispatch(triggerContentClick(opts));
    },
    trackInteraction: (opts) => {
      dispatch(triggerInteraction(opts));
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  const { content_type } = ownProps;
  return {
    contentTypeText: getSiteTextDataByGroupedKey(state, 'content_types', content_type),
    contentBaseUrl: getSiteUrlByName(state, content_type),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentItemDefault));

import React from 'react';

const ChallengesRecycleIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.23 44.45" className="challenge-recycle-icon">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="challenge_01">
          <path className="cls-1" d="M26.28 4.87L23.77 1h-3.55a4.68 4.68 0 00-4 2.15L13.91 6.8l8.33 5.08z" />
          <path className="cls-2" d="M25.21 15.83l4.69-2.69L22.92.5h9.93a4.79 4.79 0 014.22 2.43l2.67 4.39L44.56 5l-6.21 12.26z" />
          <path
            className="cls-3"
            d="M32.86 1a4.3 4.3 0 013.77 2.18L39.55 8l3.9-1.89-5.39 10.62-11.27-1.23 3.78-2.17L23.77 1h9.09m0-1H22.07l.82 1.48L29.22 13l-2.93 1.69-2.66 1.52 3.05.34L38 17.72l.69.08.31-.62 5.34-10.65 1.34-2.65L43 5.18l-3.07 1.49-2.44-4A5.27 5.27 0 0032.86 0z"
          />
          <path className="cls-1" d="M44.2 16.23l-9.08 5.07 3.78 6.53H45l1.58-2.45a4.26 4.26 0 00.11-4.47z" />
          <path className="cls-2" d="M21.58 32.15L30.32 23v4.38h15.53l-5.76 9.46A4.63 4.63 0 0136 39.21h-5.68V43z" />
          <path
            className="cls-3"
            d="M29.82 24.2v3.63H45l-5.3 8.7a4.14 4.14 0 01-3.7 2.18h-6.18v2.9l-7.58-9.43 7.58-8m1-2.5l-1.73 1.81-7.57 8-.6.63.54.69L29 42.23l1.78 2.22v-4.74H36A5.11 5.11 0 0040.53 37l5.29-8.67.92-1.52H30.82V21.7z"
          />
          <path className="cls-1" d="M10.88 28.55L9 32.62l2.31 3.83a4.85 4.85 0 004.08 2.26h3V28.55z" />
          <path className="cls-2" d="M4.4 25.91a4.83 4.83 0 010-4.86l2.59-5-5.33-3.13 14.07-.84L21 22.93l-4.5-1.67L9 33.59z" />
          <path
            className="cls-3"
            d="M15.43 12.6L20 22l-3.73-1.38L10.62 30 9 32.62l-4.21-7a4.29 4.29 0 010-4.35l2.81-5.4-4.29-2.54 12.12-.73m.61-1h-.67l-12.12.73-3.25.19 2.8 1.67 3.52 2.08-2.38 4.57a5.35 5.35 0 000 5.31l4.22 7L9 34.56l.86-1.42 1.57-2.62 5.2-8.64 3 1.09 2.32.85-1.08-2.23-4.54-9.42-.33-.61z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ChallengesRecycleIcon;

import { getMicrositeName, BASE_TEMPLATE } from 'constants/ensighten-tracking/EnsightenTrackingConstants';

const getNotFoundPageData = ({ contentType, isPWA }) => {
  let { page_name, content_type, ...data } = BASE_TEMPLATE;
  // data.micrositename = getMicrositeName(isPWA);
  data.section = page_name + `/${contentType}`;
  data.subsection = page_name + `/${contentType}`;
  data.pagetype = 'errorPage'; // xl doc has this as well 'errorPage' !!!;
  data.siteerrors = 'pagenotfound';
  data.pageerrorevent = 1;
  data.pageview_event = 1;
  return data;
};

export default getNotFoundPageData;

import React, { Component } from 'react';

// Import components
import ContentItemExternal from 'components/content-grid/content-items/external-items/ContentItemExternal';
import ContentItemDefault from 'components/content-grid/content-items/existing-items/ContentItemDefault';

class GridRowFirst extends Component {
  renderContentItems() {
    return this.props.data.map((item, index) => {
      if (item) {
        switch (item.content_type) {
          case 'external':
          case 'internal':
            return <ContentItemExternal key={index} itemSize={index === 0 ? 'large' : 'small'} {...item} />;
          default:
            return <ContentItemDefault key={index} itemSize={index === 0 ? 'large' : 'small'} {...item} />;
            break;
        }
      }
    });
  }

  renderGridCols() {
    let dataLength = this.props.data.length;
    let gridItems = this.renderContentItems();

    let gridCols = [];

    gridCols.push(
      <div key="col-1" className="grid-col-1 col-sm-8 col-xxs-12">
        {gridItems[0]}
      </div>
    );
    gridCols.push(
      <div key="col-2" className="grid-col-2 col-sm-4 col-xxs-12">
        {gridItems[1]}
        {gridItems[2]}
      </div>
    );

    return gridCols;
  }

  render() {
    return <section className="grid-row grid-row__1x2 row flex-wrap justify-content-between">{this.renderGridCols()}</section>;
  }
}

export default GridRowFirst;

//magic things
import React from 'react';

const SchoolsContactIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" className="schools-contact-icon">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <circle className="cls-1" cx="45" cy="45" r="45" />
        <circle cx="45" cy="45" r="39.6" fill="#ed0080" />
        <path
          className="cls-1"
          d="M21.87 59V37.23l.21.09 19.38 12.9a7.23 7.23 0 008.54-.17q8.52-5.7 17.07-11.37l2.25-1.45v21.84a4.39 4.39 0 01-4.75 3.7H26.44a4.55 4.55 0 01-3.21-1.21A4.92 4.92 0 0121.87 59z"
        />
        <path
          className="cls-1"
          d="M69.35 31v1.21a4.7 4.7 0 01-1.94 2.92C61 39.42 54.53 43.72 48.09 48a4.22 4.22 0 01-5 0q-9.66-6.41-19.31-12.85A4.11 4.11 0 0122 32.44a.76.76 0 00-.08-.21V31c.08-.26.14-.53.23-.79a4.4 4.4 0 014.39-3h38.29a4.39 4.39 0 014.29 3c.09.28.15.55.23.79z"
        />
      </g>
    </g>
  </svg>
);

export default SchoolsContactIcon;

//magic things
import React, { Component } from 'react';

// import constants
import { IMAGE_SRC_SIZES, WEBPSUPPORT, NO_IMAGE_FALLBACK, PAGE } from 'constants/app/AppConstants';
import { getUrlStaticImages } from 'constants/app/AppUrls';

// import component
import PictureMappings from 'components/picture-mappings/PictureMappings';
import ChallengeDownloadIcon from 'components/inline-svgs/challenges/ChallengeDownloadIcon';
import PictureMappingsSingleImage from 'components/picture-mappings/PictureMappingsSingleImage';
import SuperChallengeIcon from 'components/generic/SuperChallengeIcon';

/**
 * Challenge Item Body Component.
 */

class ChallengeBody extends Component {
  constructor(props) {
    super(props);
    this.showMoreDescription = this.showMoreDescription.bind(this);

    this.state = {
      showMoreDescription: false,
    };
  }

  showMoreDescription() {
    this.setState({ showMoreDescription: !this.state.showMoreDescription });
  }

  render() {
    const { text, hasDownload, downloadFile, image, supervision, challengeType, downloadType, downloadLocation, superChallenge } = this.props;
    const { supervision_required, description, download, read_less, read_more } = text;

    const displayShowMore = description !== '' && description.length > 255;

    const challengeItemImage =
      image === '' ? (
        <div className="challenge-item__image">
          <PictureMappingsSingleImage src={getUrlStaticImages(`challenges/${challengeType}.png`)} imageAlt={''} />
        </div>
      ) : (
        <figure className="challenge-item__image">
          <PictureMappings src={image} sizes={IMAGE_SRC_SIZES.DEFAULT} imageAlt={''} imageTitle={''} />
        </figure>
      );

    return (
      <div className="challenge-item__body">
        {supervision && (
          <div className="challenge-item__supervision">
            <span>{supervision_required}</span>
          </div>
        )}
        {superChallenge && (
          <div className="challenge-item__super-challenge">
            <SuperChallengeIcon />
          </div>
        )}
        {challengeItemImage}
        <div className="challenge-item__details">
          {displayShowMore ? (
            <React.Fragment>
              <div className={'description description--' + (this.state.showMoreDescription ? 'show' : 'hide')}>{description}</div>
              <button className="read-more" onClick={this.showMoreDescription} aria-label="Read more">
                {this.state.showMoreDescription ? read_less : read_more}
              </button>
            </React.Fragment>
          ) : (
            <div className="description">{description}</div>
          )}
          {hasDownload && (
            <a
              href={downloadLocation}
              className={'download-button download-button--' + (this.state.showMoreDescription ? 'show' : 'hide')}
              target="_blank"
              rel="noreferrer"
              aria-label="download file"
              onClick={(e) => {
                if (!this.props.checkOnlineStatus()) {
                  e.preventDefault();
                }
              }}
            >
              {download}
              <ChallengeDownloadIcon />
            </a>
          )}
        </div>
      </div>
    );
  }
}

export default ChallengeBody;

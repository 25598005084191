/**
 * Ad data :  {div: "ad_bnr_atf_01", trigger: ""}
 */

import { AD_FORMAT } from 'store/reducers/Ads';

export const getAdsSlug = (state) => {
  return state.ads.adsSlug;
};

export const getAdsEnabled = (state) => {
  return state.ads.adsEnabled;
};

export const getAdProvider = (state) => {
  return state.ads.adProvider;
};

export const getAdMPUByIDLength = (state) => {
  return state.ads.mpuAllIDs.length;
};

export const getAdLeaderBoardByIDLength = (state) => {
  return state.ads.leaderboardAllIDs.length;
};

const getAdMPUByID = (state, id) => {
  return state.ads.mpuAllIDs[id] || false;
};

const getAdLeaderBoardByID = (state, id) => {
  // console.log('id', id);
  return state.ads.leaderboardAllIDs[id] || false;
};

const getAdMPU = (state) => {
  return state.ads.ad_slots.mpu || false;
};

const getAdLeaderBoard = (state) => {
  return state.ads.ad_slots.leaderboard || false;
};

// const getDesktopAds = (state) => {
//   return state.ads.ads_desktop || {};
// };
//
// const getMobileAds = (state) => {
//   return state.ads.ads_mobile || {};
// };

// const getHomePageAd = (state, desktop = true) => {
//   const ads = desktop ? getDesktopAds(state) : getMobileAds(state);
//   return ads.header_home || false;
// };
//
// const getMainPageAd = (state, desktop = true) => {
//   const ads = desktop ? getDesktopAds(state) : getMobileAds(state);
//   return ads.header_main || false;
// };
//
// const getSubPageAd = (state, desktop = true) => {
//   const ads = desktop ? getDesktopAds(state) : getMobileAds(state);
//   return ads.header_sub || false;
// };

// const getLeaderBoardAd = (state, page, desktop) => {
//   switch (page) {
//     case AD_PAGE.HOMEPAGE:
//       return getHomePageAd(state, desktop);
//     case AD_PAGE.MAIN_PAGE:
//       return getMainPageAd(state, desktop);
//     case AD_PAGE.SUB_PAGE:
//       return getSubPageAd(state, desktop);
//   }
// };

export const getAdTrigger = (state, type = 'default') => {
  const trigger = state.ads.ad_triggers[type] || {};
  return trigger.trigger_id || '';
};

export const getAdData = (state, format, page, id, desktop = true) => {
  const provider = getAdProvider(state); // ensighten etc
  const enabled = getAdsEnabled(state);
  if (!enabled) {
    return null;
  }
  let data = false;
  switch (format) {
    case AD_FORMAT.MPU:
      data = getAdMPUByID(state, id);
      break;
    case AD_FORMAT.LEADERBOARD:
      data = getAdLeaderBoardByID(state, id);
      break;
  }
  return data;
};

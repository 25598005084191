// import helper functions
import { generateMembershipCard } from 'utils/membershipCard';

// import actions
import * as UserActions from 'store/actions/User.actions';

// import selectors
import { getSiteTextDataByGroup, getHeaderData } from 'store/selectors/App.selectors';

const userFlow =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case UserActions.USER_GENERATE_MEMBERSHIP_CARD:
        {
          const { name, passcode } = action.payload;
          const siteText = getSiteTextDataByGroup(getState(), 'membership_card');
          const siteLogo = getHeaderData(getState());

          generateMembershipCard({ name, passcode, siteText, logo: siteLogo.header_logo }, (data) => {
            dispatch(UserActions.setMembershipCard(data));
          });
        }
        break;
    }
  };

export default [userFlow];

import React, { Component } from 'react';
import { connect } from 'react-redux';

// Import selectors
import { getSiteNavigationData } from 'store/selectors/Navigation.selectors';
import { getMobileNavState, getSiteActivePage, getOnlineStatus } from 'store/selectors/App.selectors';
import { activeMobileNav } from 'store/actions/App.actions';

// Import Components
import NavigationLinks from 'components/navigation/NavigationLinks';
import AppAuthLink from 'containers/base/AppAuthLink';

class AppNav extends Component {
  handleBurgerClick = () => {
    if (window.innerWidth < 1024) {
      this.props.activeMobileNav();
    }
  };
  bindedHandleBurgerClick = this.handleBurgerClick.bind(this);

  render() {
    const { links, activePage } = this.props;

    return (
      <nav className="navigation flex-grow-2">
        <div className={'navigation__wrapper' + (this.props.mobileNavState ? ' navigation__wrapper--active' : '')}>
          <ul
            id="nav-links"
            className={'navigation__items-group navigation__items-group--' + (links.length + 1) + ' flex flex-wrap' + (this.props.mobileNavState ? ' navigation__items-group--active' : '')}
          >
            <NavigationLinks navigation={links} clickHandler={this.bindedHandleBurgerClick} activePage={activePage} isOnline={this.props.isOnline} />
          </ul>
        </div>
        <div className="navigation__toggle flex align-items-center justify-content-end">
          <div className="navigation__menu-burger__wrapper flex justify-content-center align-items-center" onClick={this.handleBurgerClick}>
            <div className={'navigation__menu-burger flex justify-content-center align-items-center' + (this.props.mobileNavState ? ' navigation__menu-burger--active' : '')}>
              <div className="navigation__menu-burger-line" />
            </div>
          </div>
        </div>
        {this.props.isOnline && (
          <div className="navigation__mobile-login">
            <AppAuthLink />
          </div>
        )}
      </nav>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    activeMobileNav: () => {
      dispatch(activeMobileNav());
    },
  };
}
const mapStateToProps = (state) => {
  return {
    links: getSiteNavigationData(state),
    mobileNavState: getMobileNavState(state),
    activePage: getSiteActivePage(state),
    isOnline: getOnlineStatus(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppNav);

import { getContentItem } from 'store/selectors/App.selectors';

export const getHomePageExternalContent = (state, id) => {
  return state.homepage.externalContent.find((item) => {
    return item.content_id === id;
  });
};

export const getHomePageInternalContent = (state, id) => {
  // console.log(state.homepage);
  return state.homepage.internalContent.find((item) => {
    return item.content_id === id;
  });
};

export const getHomePageHeroItem = (state) => {
  const id = state.homepage.heroID;
  let item = {};
  if (id) {
    const { type, content_type } = state.homepage.byID[id];
    item = getContentItem(state, content_type, id);
  }
  return item;
};

export const getHomeContent = (state) => {
  const allIds = [...state.homepage.allIDs]; // first item is hero
  return allIds.map((id, index) => {
    const { type, content_type } = state.homepage.byID[id];
    const item = getContentItem(state, content_type, id);
    return item;
  });
};

export const getHomeMarqueData = (state) => {
  return state.homepage.marqueData;
};

// // Not sure if this is needed but leave in for now
// export const getHomePageMediaDataFromUrl = ({ state, videoId }) => {
//   let homeItem = state.homepage.homePageData.hero_items.find((item) => {
//     if (item.hero_promo_cta_url_type === 'video_link') {
//       return videoId === item.hero_promo_cta_youtube_id;
//     }
//     return false;
//   });
//   if (typeof homeItem === 'undefined') return 'url_invalid';
//
//   return {
//     type: CONTENT.VIDEO,
//     youtube_id: homeItem.hero_promo_cta_youtube_id,
//     description: homeItem.hero_promo_copy,
//   };
// };

import { BOOTSTRAP_SHOWCASE_MAP_CONTENT } from 'store/actions/Bootstrap.actions';
import {
  MAP_DATA_INVALIDATE,
  MAP_DATA_REQUEST,
  MAP_DATA_RECEIVE,
  GEO_DATA_INVALIDATE,
  GEO_DATA_REQUEST,
  GEO_DATA_RECEIVE,
  GEO_DATA_UPDATE_LOCATION,
  MAP_ADD_TYPE_FILTER,
  MAP_REMOVE_TYPE_FILTER,
  MAP_ALL_TYPE_FILTER,
} from 'store/actions/ShowcaseMap.actions';
import { CHALLENGE_TYPES } from 'constants/challenges/ChallengeTypes';

const initState = {
  areas: {},
  regions: {},
  countries: {},
  editorials: {},
  map_data: {
    isFetching: false,
    didInvalidate: false,
    fetched: false,
    allIDs: [],
    byID: {},
  },
  geo_data: {
    isFetching: false,
    didInvalidate: false,
    fetched: false,
    location: '',
  },
  currentZoomLevel: 1,
  activeTypeFilters: [],
  challenge_types: {
    type1: CHALLENGE_TYPES['type1'].name,
    type2: CHALLENGE_TYPES['type2'].name,
    type3: CHALLENGE_TYPES['type3'].name,
    type4: CHALLENGE_TYPES['type4'].name,
    type5: CHALLENGE_TYPES['type5'].name,
    type6: CHALLENGE_TYPES['type6'].name,
    type7: CHALLENGE_TYPES['type7'].name,
    type8: CHALLENGE_TYPES['type8'].name,
    type9: CHALLENGE_TYPES['type9'].name,
  },
  challenges_completed: {},
  total_of_completed_challenges: 0,
};

const ShowcaseMap = (state = initState, action) => {
  let nextState = {};
  switch (action.type) {
    case BOOTSTRAP_SHOWCASE_MAP_CONTENT: {
      const { areas, regions, countries } = action.payload;
      const { regionData, areaData, countryData } = sortMapContent({ areas, regions, countries });

      nextState.areas = areaData;
      nextState.regions = regionData;
      nextState.countries = countryData;
      return { ...state, ...nextState };
    }
    case MAP_DATA_INVALIDATE: {
      nextState.map_data = { ...state.map_data };
      nextState.map_data.isFetching = false;
      nextState.map_data.didInvalidate = true;
      return { ...state, ...nextState };
    }
    case MAP_DATA_REQUEST: {
      nextState.map_data = { ...state.map_data };
      nextState.map_data.isFetching = true;
      nextState.map_data.didInvalidate = false;
      return { ...state, ...nextState };
    }
    case MAP_DATA_RECEIVE: {
      const { data = {} } = action.payload;
      const { allIDs, byID, completedChallengesTotal, overallCompletedChallenges } = parseData(data);

      nextState.challenges_completed = completedChallengesTotal;
      nextState.total_of_completed_challenges = overallCompletedChallenges;
      nextState.map_data = { ...state.map_data };
      nextState.map_data.allIDs = allIDs;
      nextState.map_data.byID = byID;
      nextState.map_data.isFetching = true;
      nextState.map_data.fetched = true;
      nextState.map_data.didInvalidate = false;
      return { ...state, ...nextState };
    }
    case GEO_DATA_INVALIDATE: {
      nextState.geo_data = { ...state.geo_data };
      nextState.geo_data.isFetching = false;
      nextState.geo_data.didInvalidate = true;
      return { ...state, ...nextState };
    }

    case GEO_DATA_REQUEST: {
      nextState.geo_data = { ...state.geo_data };
      nextState.geo_data.isFetching = true;
      nextState.geo_data.didInvalidate = false;
      return { ...state, ...nextState };
    }
    case GEO_DATA_RECEIVE: {
      // if country is 'null' could be you are on localhost
      // So default to en_GB
      const { country = 'GB' } = action.payload;

      nextState.geo_data = { ...state.geo_data };
      nextState.geo_data.location = country === null ? 'GB' : country;
      nextState.geo_data.isFetching = true;
      nextState.geo_data.fetched = true;
      nextState.geo_data.didInvalidate = false;
      return { ...state, ...nextState };
    }
    case GEO_DATA_UPDATE_LOCATION: {
      nextState.geo_data = { ...state.geo_data };
      nextState.geo_data.location = action.payload;

      return { ...state, ...nextState };
    }
    case MAP_ADD_TYPE_FILTER: {
      let newState = [action.payload];

      // If the length is 8, then all filters have been clicked on
      // so reset the array
      if (newState.length === 8) {
        newState = [];
      }
      nextState.activeTypeFilters = newState;
      return { ...state, ...nextState };
    }
    case MAP_REMOVE_TYPE_FILTER: {
      const newArray = [];
      for (let i = 0; i < state.activeTypeFilters.length; i++) {
        if (state.activeTypeFilters[i] !== action.payload) {
          newArray.push(state.activeTypeFilters[i]);
        }
      }
      nextState.activeTypeFilters = newArray;

      return { ...state, ...nextState };
    }
    case MAP_ALL_TYPE_FILTER: {
      nextState.activeTypeFilters = [];

      return { ...state, ...nextState };
    }
  }
  return state;
};

export default ShowcaseMap;

const parseData = (data) => {
  const allIDs = [];
  const byID = {};
  const completedChallengesTotal = {
    type1: 0,
    type2: 0,
    type3: 0,
    type4: 0,
    type5: 0,
    type6: 0,
    type7: 0,
    type8: 0,
    type9: 0,
  };
  let overallCompletedChallenges = 0;

  for (const item of data) {
    const { last_completed = '', challenge_type_summary = {} } = item;

    if (challenge_type_summary) {
      for (const [index, value] of Object.entries(challenge_type_summary)) {
        completedChallengesTotal[index] = completedChallengesTotal[index] + parseInt(value);
      }
    }

    // overallCompletedChallenges = overallCompletedChallenges + completedChallengesTotal[index];

    if (item && last_completed !== null && last_completed !== '') {
      const { country } = item;
      allIDs.push(country);
      byID[country] = { ...item };
    }
  }

  // Get the grand total
  for (const [index, value] of Object.entries(completedChallengesTotal)) {
    if (!isNaN(value)) {
      overallCompletedChallenges = overallCompletedChallenges + value;
    }
  }

  return { allIDs, byID, completedChallengesTotal, overallCompletedChallenges };
};

const sortMapContent = ({ areas, regions, countries }) => {
  let regionData = {};
  let areaData = {};
  let countryData = {};

  regions.map((region) => {
    const { id, name, data } = region;
    const { coord } = data;
    regionData[id] = { name, coord: JSON.parse(coord) };
    regionData[id].areaIDs = [];
  });

  areas.map((area) => {
    const { id, region, name, data } = area;
    const { coord } = data;
    areaData[id] = { region, name, coord: JSON.parse(coord) };
    areaData[id].countryIDs = [];

    if (regionData[region.id]) {
      regionData[region.id].areaIDs.push(parseInt(id));
    }
  });

  countries
    .sort(function (a, b) {
      return a.area.id - b.area.id;
    })
    .map((country) => {
      const { id, area, data, code } = country;
      const { id: areaID } = area;
      const { coord } = data;
      countryData[code] = { areaID, area, coord: JSON.parse(coord), code };

      if (areaData[area.id]) {
        areaData[area.id].countryIDs.push(code);
      }
    });

  return { regionData, areaData, countryData };
};

//magic things
import React from 'react';

const GameIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 105" className="game-icon">
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <g className="cls-1">
          <circle cx="50" cy="55" r="50" />
        </g>
        <circle className="cls-2" cx="50" cy="50" r="50" />
        <path d="M74.27,37.22C66.46,24.77,54.7,33.43,54.7,33.43a6.18,6.18,0,0,1-3.12,1H48.43a6.08,6.08,0,0,1-3.13-1s-11.76-8.67-19.57,3.78-4.66,26-4.66,26c.55,3.32,2.37,5.53,5.8,5.26S37.7,59.54,37.7,59.54a4.32,4.32,0,0,1,2.85-1.3H59.44a4.26,4.26,0,0,1,2.85,1.3s7.42,8.66,10.84,8.93,5.25-1.94,5.8-5.25c0,0,3.15-13.55-4.66-26ZM42.9,47.35h-4v3.79a3.78,3.78,0,0,1-2.18.62,2.69,2.69,0,0,1-1.93-.69V47.35H30.92a2.9,2.9,0,0,1-.61-1.72,3.91,3.91,0,0,1,.53-2.26h4.05V39.44a4.43,4.43,0,0,1,2-.4,5.4,5.4,0,0,1,2.08.47v3.86h3.82a3.58,3.58,0,0,1,.72,1.83A4.48,4.48,0,0,1,42.9,47.35Zm18.58,4.32a3.11,3.11,0,1,1,3.21-3.11,3.15,3.15,0,0,1-3.21,3.11Zm0-8.56A3.11,3.11,0,1,1,64.69,40a3.15,3.15,0,0,1-3.21,3.11Zm8,4.6a3.12,3.12,0,1,1,3.21-3.11A3.15,3.15,0,0,1,69.46,47.71Z" />
      </g>
    </g>
  </svg>
);

export default GameIcon;

//magic things
import React, { Component } from 'react';

// import utils
import { numberWithFormatting } from 'utils/general';

class Counter extends Component {
  constructor(props) {
    super(props);

    this.countRef = React.createRef();
    this.state = {
      hasAnimated: false,
    };
    this.timer = null;
    this.animationID = null;
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextProps.active && !nextState.hasAnimated && this.props.count !== 0) {
      const { count } = this.props;
      this.timer = setTimeout(() => {
        const passedCount = count.toString();
        this.animateValue(this.countRef.current, 0, passedCount, 1000);
      }, 750);
    }
  }

  componentWillUnmount() {
    if (this.timer !== null) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    if (this.animationID !== null) {
      window.cancelAnimationFrame(this.animationID);
      this.animationID = null;
    }
  }

  animateValue(obj, start, end, duration) {
    let startTimestamp = null;

    if (obj === null) {
      return;
    }

    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      const nextValue = Math.floor(progress * (end - start) + start);
      obj.innerHTML = numberWithFormatting(nextValue);

      if (progress < 1) {
        this.animationID = window.requestAnimationFrame(step);
      } else if (this.animationID && this.countRef.current !== null) {
        this.setState({ hasAnimated: true });
      }
    };
    this.animationID = window.requestAnimationFrame(step);
  }

  render() {
    const { animateCount = false, active, data, count } = this.props;

    const passedCount = count.toString();
    return (
      <section className="challenges-counter">
        <div className="challenges-counter__text">{data.label}</div>
        <div className="challenges-counter__count" ref={this.countRef}>
          {this.state.hasAnimated ? numberWithFormatting(passedCount) : 0}
        </div>
      </section>
    );
  }
}

export default Counter;

// Video Page Based Selectors

export const getVideoPageItemByID = (state, id) => {
  return state.videopage.byID[id] || false;
};

export const getVideoPageContent = (state) => {
  const allIds = state.videopage.allIDs;
  return allIds.map((id, index) => {
    return state.videopage.byID[id];
  });
};

export const getVideoPageContentSlice = (state, total) => {
  return getVideoPageContent(state).slice(0, total);
};

export const getVideoMarqueData = (state) => {
  return state.videopage.marqueData;
};

export const getVideoPromoData = (state) => {
  return state.videopage.promoData;
};

export const getVideoIDByFriendlyURL = (state, url = '') => {
  const content = getVideoPageContent(state);
  return content.reduce((arr, item, index) => {
    return url === content[index].friendly_url ? content[index].id : arr;
  }, null);
};

export const getVideoPageItemXP = (state, id) => {
  const item = getVideoPageItemByID(state, id);
  let { override_xp = 0, title } = item;
  return { title, xp: override_xp !== 0 ? Math.trunc(override_xp) : override_xp };
};

export const getAvsData = (state) => {
  return { avsPlayURLpattern: state.videopage.avsPlayURLpattern, avsShowName: state.videopage.avsShowName };
};

export const getVideoData = (state, avs_id) => {
  if (state.videopage.videoData && state.videopage.videoData[avs_id]) {
    const { id = 0, src, title } = state.videopage.videoData[avs_id];

    const videoDataCMS = state.videopage.byID[id] || false;
    const adEnabled = videoDataCMS ? videoDataCMS.bitmovin_ad_enabled === '1' : false;
    return { id, src, adEnabled, title };
  } else {
    return false;
  }
};

import { ALL_CONTENT_TYPES, CONTENT } from 'constants/content-library/ContentLibraryConstants';
import { TAGGED_CONTENT_MAX_LIMIT } from 'constants/content-tagged/ContentTaggedConstants';
// import { getGamePageContent, getGamePageItemXP } from 'store/selectors/Gamepage.selectors';
import { getQuizPageContent, getQuizPageItemXP } from 'store/selectors/Quizpage.selectors';
// import { getDownloadsPageContent, getDownloadsPageItemXP } from 'store/selectors/Downloadspage.selectors';
import { getVideoPageContent, getVideoPageItemXP } from 'store/selectors/Videopage.selectors';
import { getAppPageContent, getAppPageItemXP } from 'store/selectors/Apppage.selectors';
import { getStoryPageItemXP } from 'store/selectors/Characters.selectors';

// Function to return all content for a certain content type
const getContentByType = (state, type) => {
  switch (type) {
    case CONTENT.QUIZ:
      return getQuizPageContent(state);
    case CONTENT.VIDEO:
      return getVideoPageContent(state);
    default:
  }
};

/**
 * Ash - For future and if time permitting - maybe we can invert this somewhat
 * rather than getting content and appluying rules and sorting on tags etc perhaps we
 * write selectors in each content store selector to return appropriate content?
 */

// Tagged Content Functions
// ******
// Order of getting tagged content
// This will be fun to code...

// 1. Get all specific type content
// 2. Has most char + genre tags (additionial filter by date created)
// 3. Has character tags + most genre tags (additionial filter by date created)
// 4. has at least one char + genre tags (additionial filter by date created)
// 6. has char tags on other content types (additionial filter by date created)
// 7. has genre tags on other content types (additionial filter by date created)
// 8. any other same content type (additionial filter by date created)

export const getTaggedContent = ({ state, type, genre, characterTags, genreTags, id, noContent }) => {
  if (noContent) {
    return getContentByType(state, type);
  }

  let taggedContentArr = [];
  // 1. Get all specific type content And rip out current content
  let baseContent = getContentByType(state, type).filter((item) => {
    return item.id != id;
  });

  // 2, 3, 4. 5. Get content containing both lot of tags
  let contentByAllTags = getContentByAllTags(baseContent, characterTags, genreTags);

  if (maxTaggedLimitCheck(contentByAllTags)) {
    return maxTaggedLimitCheck(contentByAllTags);
  }

  // 6. Search other content types for character tags
  let otherContent = getAllOtherContentTypes(state, type);
  let otherContentByAllTags = getContentByAllTags(otherContent, characterTags, genreTags);

  taggedContentArr = [...contentByAllTags, ...otherContentByAllTags];

  if (maxTaggedLimitCheck(taggedContentArr)) {
    return maxTaggedLimitCheck(taggedContentArr);
  }

  taggedContentArr = Array.from(new Set([...taggedContentArr, ...baseContent])).slice(0, TAGGED_CONTENT_MAX_LIMIT);

  return taggedContentArr;
};

// ------
// Tagged Content Helper Functions
// ------

/**
 * This could be a pretty expensive when there is lots of data
 * may need to think about memoisation on the selectors
 */
const getAllOtherContentTypes = (state, excludedType) => {
  let types = ALL_CONTENT_TYPES;

  types = ALL_CONTENT_TYPES.filter((item) => {
    return item != excludedType;
  });

  return types.reduce((arr, value, index) => {
    const content = getContentByType(state, value);
    if (typeof content != 'undefined' && content.length > 0) {
      arr.push(...content);
      return arr;
    }
    return arr;
  }, []);
};

const getContentByCharacterTags = (content, characterTags) => {
  return content.filter((item, index) => {
    if (typeof item.character_tags !== 'undefined') {
      return item.character_tags.some((tag) => characterTags.includes(tag));
    }
  });
};

const getContentByGenreTags = (content, genreTags) => {
  return content.filter((item, index) => {
    if (typeof item.genre_tags !== 'undefined') {
      return item.genre_tags.some((tag) => genreTags.includes(tag));
    }
  });
};

const maxTaggedLimitCheck = (arr) => {
  if (arr.length >= TAGGED_CONTENT_MAX_LIMIT) {
    return arr.slice(0, TAGGED_CONTENT_MAX_LIMIT);
  }
  return false;
};

const sortArrByTags = (arr) => {
  return arr.sort((a, b) => {
    if (b.character_tags && a.character_tags) {
      // First sort by the length of character tags
      if (b.character_tags.length > a.character_tags.length) return 1;
      if (b.character_tags.length < a.character_tags.length) return -1;
    }

    if (b.genre_tags && a.genre_tags) {
      // then sort by the length of genre tags
      if (b.genre_tags.length > a.genre_tags.length) return 1;
      if (b.genre_tags.length < a.genre_tags.length) return -1;
    }

    // then sort by date created
    if (b.date_created > a.date_created) return 1;
    if (b.date_created < a.date_created) return -1;
  });
};

const getContentByAllTags = (content, characterTags, genreTags) => {
  // Character tags are the primary filter
  let taggedCharacterContent = getContentByCharacterTags(content, characterTags);

  // Genre tags are the sceondary filter
  let taggedGenreContent = getContentByGenreTags(content, genreTags);

  // We need to match there arent duplicates
  let uniqueContent = Array.from(new Set([...taggedCharacterContent, ...taggedGenreContent]));

  // Return a sorted list
  return sortArrByTags(uniqueContent);
};

/**
 * Returns item title and xp override for content type
 */
export const getContentItemTitleAndXpByType = (state, contentType, id) => {
  switch (contentType) {
    case CONTENT.GAME:
      return getGamePageItemXP(state, id);
    case CONTENT.APP:
      return getAppPageItemXP(state, id);
    case CONTENT.DOWNLOADS:
      return getDownloadsPageItemXP(state, id);
    case CONTENT.QUIZ:
      return getQuizPageItemXP(state, id);
    case CONTENT.VIDEO:
      return getVideoPageItemXP(state, id);
    case CONTENT.STORY:
      return getStoryPageItemXP(state, id);
    default:
  }
};

export const getUserToken = (state) => {
  return state.userAuth.token;
};

export const getUserExternalID = (state) => {
  return state.userAuth.external_id;
};

export const getUserName = (state) => {
  return state.userAuth.name;
};

export const getUserCode = (state) => {
  return state.userAuth.code;
};

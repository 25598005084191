import * as Constants from 'constants/app/AppConstants';
import { CONTENT_SUBPAGES } from 'constants/content-library/ContentLibraryConstants';

// ****
// Base site URLS
// ****
export const URL_BASE = Constants.APP_CONFIG.base_url;
export const URL_DYNAMIC = Constants.APP_CONFIG.dynamic_url;
export const URL_STATIC = Constants.APP_CONFIG.static_url;
export const URL_FRONTEND = Constants.APP_CONFIG.frontend_url;

// *****
// App URLS
// *****
export const URL_GAME_PAGE = '/game/';
export const URL_REWARDS_PAGE = '/rewards';
export const URL_MAP_PAGE = 'map';
export const URL_GLOBAL_GOALS_PAGE = 'global-goals';
export const URL_CHALLENGES_PAGE = 'challenges';
export const URL_MY_GOALS_PAGE = 'my-goals';
export const URL_PAGE_NOT_FOUND = '/page-not-found';

// ******
// Set navigation links
// ******
export const SET_NAVIGATION_LINKS = [
  {
    id: 'challenges',
    name: 'Challenges',
    page: URL_CHALLENGES_PAGE,
    icon: 'challenges',
    friendlyURL: URL_CHALLENGES_PAGE,
  },
  {
    id: 'my_goals',
    name: 'My Goals',
    page: URL_MY_GOALS_PAGE,
    icon: 'my-goals',
    friendlyURL: URL_MY_GOALS_PAGE,
  },
  {
    id: 'global_goals',
    name: 'Global Goals',
    page: URL_GLOBAL_GOALS_PAGE,
    icon: 'global-goals',
    friendlyURL: URL_GLOBAL_GOALS_PAGE,
  },
];

// ******
// url image "stitching" methods
// ******
export const getUrlDynamic = (suffix = '') => {
  return URL_DYNAMIC + suffix;
};

export const getUrlStatic = (suffix = '') => {
  return URL_STATIC + suffix + Constants.CACHEBUST;
};

export const getUrlFrontend = (suffix = '') => {
  return URL_FRONTEND + suffix;
};

export const getUrlStaticImages = (suffix = '') => {
  return URL_STATIC + 'frontend/images/' + suffix + Constants.CACHEBUST;
};

export const getUrlFrontendImage = (suffix = '') => {
  return getUrlFrontend('images/' + suffix);
};

// ******
// Dynamic content linking method
// ******
export const getContentLink = (contenttype, friendlyurl) => {
  // <Link to={`${match.url}/${friendly_url}`}>
  const sub = CONTENT_SUBPAGES[contenttype];
  return '/' + sub + '/' + friendlyurl;
};

// *************
// API URLS
// *************

// ------
// Base api url
const URL_API = URL_BASE + 'api/';

// ------
// emoji tracking
export const getUrlTrackLike = () => {
  return URL_API + 'track_like/' + Constants.LANGUAGE_CODE;
};
export const getUrlTrackUnLike = () => {
  return URL_API + 'track_unlike/' + Constants.LANGUAGE_CODE;
};
export const getUrlGetTrackedData = () => {
  return URL_API + 'get_tracked_data/' + Constants.LANGUAGE_CODE;
};

// ------
// Challenge data
export const getUrlTrackChallengeLike = () => {
  return URL_API + 'track_challenge_like/' + Constants.LANGUAGE_CODE;
};
export const getUrlTrackChallengeUnLike = () => {
  return URL_API + 'track_challenge_unlike/' + Constants.LANGUAGE_CODE;
};
export const getUrlChallegeCompleted = () => {
  return URL_API + 'track_challenge_complete/' + Constants.LANGUAGE_CODE;
};
export const getUrlChallegeUncompleted = () => {
  return URL_API + 'track_challenge_uncomplete/' + Constants.LANGUAGE_CODE;
};
export const getUrlChallegeCancelled = () => {
  return URL_API + 'track_challenge_cancelled/' + Constants.LANGUAGE_CODE;
};
export const getUrlChallegeUncancelled = () => {
  return URL_API + 'track_challenge_uncancelled/' + Constants.LANGUAGE_CODE;
};

// ------
// site data
export const getUrlSiteData = () => {
  return URL_API + 'get_site_data/' + Constants.LANGUAGE_CODE;
};

// ------
// map / geo locale data
export const getUrlMapData = () => {
  return URL_API + 'get_map_data/';
};
export const getUrlGeoPosition = () => {
  return URL_API + 'get_geoip_data/';
};

// ------
// user api
export const getUrlCreateNewPlayer = () => {
  return URL_API + 'create_new_player/';
};
export const getUrlGetPlayer = () => {
  return URL_API + 'get_player/';
};
export const getUrlLoginPlayer = () => {
  return URL_API + 'login_player/';
};
export const getUrlSetPlayerName = () => {
  return URL_API + 'set_player_name/';
};
export const getUrlSetPlayerData = () => {
  return URL_API + 'set_player_data/';
};

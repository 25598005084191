import React from 'react';

// import selectors

// import Components
import Grid1x2Row from 'components/content-grid/grid-rows/Grid1x2Row';

const PromoSection = (props) => {
  const { data } = props;

  return (
    <div className="content-grid">
      <Grid1x2Row data={data} />
    </div>
  );
};

export default PromoSection;

//magic things
import React, { Component } from 'react';

// import component
import ChallengeIcon from 'components/challenges/ChallengeIcon';
import ChallengesArrowDown from 'components/inline-svgs/challenges/ChallengesArrowDown';

/**
 * Challenge Item Header Component.
 */

class ChallengeHeader extends Component {
  render() {
    const { title, type } = this.props;

    return (
      <div className="challenge-item__header" onClick={this.props.callback}>
        <div className="challenge-item__icon">
          <span className="icon">
            <ChallengeIcon type={type} />
          </span>
        </div>
        <div className="challenge-item__title">
          <h2>{title}</h2>
        </div>
        <div className="challenge-item__mobile-arrow">
          <ChallengesArrowDown />
        </div>
      </div>
    );
  }
}

export default ChallengeHeader;

//magic things
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import constants
import { getUrlStaticImages } from 'constants/app/AppUrls';
import { LANGUAGE_CODE } from 'constants/app/AppConstants';

// import components
import SchoolsContactIcon from 'components/inline-svgs/schools-page/SchoolsContactIcon';
import SchoolsQuizIcon from 'components/inline-svgs/schools-page/SchoolsQuizIcon';
import SchoolsTeacherIcon from 'components/inline-svgs/schools-page/SchoolsTeacherIcon';
import ChallengeItem from 'components/challenges/ChallengeItem';

class AreYouTeacher extends Component {
  render() {
    const { pageText, trackClick } = this.props;

    const {
      schools_are_you_teacher_title = '',
      schools_are_you_teacher = '',
      schools_climate_action_curriculum_url = '',
      schools_climate_action_curriculum_button = '',
      schools_take_the_quiz_url = '',
      schools_take_the_quiz_button = '',
    } = pageText;

    return (
      <div className="are-you-teacher">
        <div className="container">
          <h2 className="are-you-teacher__title">{schools_are_you_teacher_title}</h2>
          <div className="are-you-teacher__grid">
            <div className=" are-you-teacher__buttons">
              <button type="button" className="are-you-teacher__button are-you-teacher__button--curriculum" aria-label="Curriculum">
                <a
                  href={schools_climate_action_curriculum_url}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => {
                    trackClick({ title: 'lesson plan' });
                  }}
                >
                  <SchoolsTeacherIcon />
                  {schools_climate_action_curriculum_button}
                </a>
              </button>
              <button type="button" className="are-you-teacher__button are-you-teacher__button--quiz" arai-label="quiz">
                <a
                  href={schools_take_the_quiz_url}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => {
                    trackClick({ title: 'school quiz' });
                  }}
                >
                  <SchoolsQuizIcon />
                  {schools_take_the_quiz_button}
                </a>
              </button>
            </div>
            <div className="are-you-teacher__logos">
              <a
                href={schools_climate_action_curriculum_url}
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  trackClick({ title: 'lesson plan' });
                }}
              >
                <img src={getUrlStaticImages('schools-page/climate-action-project.png')} alt="Climate Action Project" />
              </a>
              {LANGUAGE_CODE !== 'ar-ae' && (
                <a
                  href={schools_take_the_quiz_url}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => {
                    trackClick({ title: 'school quiz' });
                  }}
                >
                  <img src={getUrlStaticImages('schools-page/kahoot.png')} alt="Kahoot" />
                </a>
              )}
            </div>
          </div>
          <p className="are-you-teacher__body" dangerouslySetInnerHTML={{ __html: schools_are_you_teacher }} />
        </div>
      </div>
    );
  }
}

export default AreYouTeacher;

import { BOOTSTRAP_VIDEO_PLAYLIST_DATA } from 'store/actions/Bootstrap.actions';
import { VIDEO_PLAYLIST } from 'store/actions/VideoPlaylist.actions';

const initState = {
  allIDs: [],
  byID: {},
};

const VideoPlaylist = (state = initState, action) => {
  let nextState = {};
  switch (action.type) {
    case BOOTSTRAP_VIDEO_PLAYLIST_DATA: {
      const { allIDs, byID } = action.payload;
      nextState.allIDs = allIDs;
      nextState.byID = byID;
      return { ...state, ...nextState };
    }
  }
  return state;
};

export default VideoPlaylist;

import React, { Component } from 'react';

// Import Components
import Grid1x2Row from 'components/content-grid/grid-rows/Grid1x2Row';
import Grid2x1Row from 'components/content-grid/grid-rows/Grid2x1Row';
import Grid3xRow from 'components/content-grid/grid-rows/Grid3xRow';
import GridRelatedTitleRow from 'components/content-grid/grid-rows/GridRelatedTitleRow';

class RenderInitialGrid extends Component {
  // We setup components like this to
  // access them below
  components = {
    Grid1x2Row,
    Grid2x1Row,
    Grid3xRow,
    GridRelatedTitleRow,
  };

  renderGrid() {
    let currentCount = 0;
    let previousCount = 0;
    let itemsPerRow = 3;

    return this.props.layout.map((layout, index) => {
      let TagName = this.components[layout];

      switch (layout) {
        case 'RelatedTitle':
          return <GridRelatedTitleRow key="relate-title" moreTitle={this.props.moreTitle} />;
        case 'Grid1x2Row':
        case 'Grid2x1Row':
        case 'Grid3xRow':
          previousCount = currentCount;
          currentCount = currentCount + itemsPerRow;
          if (this.props.data.slice(previousCount, currentCount).length > 0) {
            return <TagName key={index} data={this.props.data.slice(previousCount, currentCount)} />;
          }
          return null;
        default:
          break;
      }
    });
  }

  render() {
    const { onlyOneItem = false } = this.props;
    return <section className={`content-grid content-grid--${this.props.extraClass} ${onlyOneItem ? 'content-grid--one-item' : ''}`}>{this.props.data && this.renderGrid()}</section>;
  }
}

export default RenderInitialGrid;

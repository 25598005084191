import { URL_DYNAMIC } from 'constants/app/AppUrls';
import { isEmptyObj } from 'utils/general';

// import Parsers
import { parseCustom } from 'store/actions/parsers/CustomDataParser';
import { parseNavigation } from 'store/actions/parsers/NavigationDataParser';
import { parseMetaData } from 'store/actions/parsers/MetaDataParser';
import { parseChallenges } from 'store/actions/parsers/ChallengesDataParser';
import { parseEditorials } from 'store/actions/parsers/EditorialsDataParser';
import { parseContentData } from 'store/actions/parsers/ContentDataParser';
import { parseHomePageData } from 'store/actions/parsers/HomePageDataParser';
import { parseCustomPagesData } from 'store/actions/parsers/CustomPagesDataParser';
import { parsePageData } from 'store/actions/parsers/PageDataParser';
import { parseVideoPageData } from 'store/actions/parsers/VideoDataParser';
import { parseVideoPlaylist } from 'store/actions/parsers/VideoPlaylistDataParser';
import { siteTextDataParser } from 'store/actions/parsers/SiteTextDataParser';

// Bootstrap Constants
export const BOOTSTRAP_PAGE_CONTENT = 'BOOTSTRAP_PAGE_CONTENT';
export const BOOTSTRAP_COMING_SOON_PAGE_CONTENT = 'BOOTSTRAP_COMING_SOON_PAGE_CONTENT';
export const BOOTSTRAP_SHOWCASE_MAP_CONTENT = 'BOOTSTRAP_SHOWCASE_MAP_CONTENT';
export const BOOTSTRAP_BATTLE_BUILDER_CONTENT = 'BOOTSTRAP_BATTLE_BUILDER_CONTENT';
export const BOOTSTRAP_APP_DATA = 'BOOTSTRAP_APP_DATA';
export const BOOTSTRAP_MANIFESTO_DATA = 'BOOTSTRAP_MANIFESTO_DATA';
export const BOOTSTRAP_NAVIGATION_DATA = 'BOOTSTRAP_NAVIGATION_DATA';
export const BOOTSTRAP_EXPERIENCE_DATA = 'BOOTSTRAP_EXPERIENCE_DATA';
export const BOOTSTRAP_ADS_DATA = 'BOOTSTRAP_ADS_DATA';
export const BOOTSTRAP_IDLE_PROMPT_DATA = 'BOOTSTRAP_IDLE_PROMPT_DATA';
export const BOOTSTRAP_VIDEO_PLAYLIST_DATA = 'BOOTSTRAP_VIDEO_PLAYLIST_DATA';

// *******
// Config data
// *******
export const dispatchConfigData = (dispatch, configData) => {
  const { experience, navigation = {}, metaData = {}, levels = {}, siteText, ...rest } = configData;
  if (experience !== null && !isEmptyObj(experience)) {
    const { enable_experience = '0', xp_bar_badge, ...totals } = experience;
    const experienceEnabled = enable_experience === '1';
    const parsedLevels = parseExperienceLevelsData(levels.content);
    dispatch({ type: BOOTSTRAP_EXPERIENCE_DATA, payload: { experienceEnabled, levels: parsedLevels, totals, xpBarBadge: URL_DYNAMIC + xp_bar_badge } });
    rest.metaData = parseMetaData(metaData);
  }

  rest.metaData = parseMetaData(metaData);

  rest.siteText = siteTextDataParser(siteText);

  // dump the rest of the data in stores
  dispatch({ type: BOOTSTRAP_APP_DATA, payload: rest });
};

// *******
// Content data
// *******
export const dispatchContentData = (dispatch, contentData) => {
  const pageData = {
    homepage: parseHomePageData(contentData, 'homePage'),
    videoPage: parseVideoPageData(contentData, 'videoPage'),
    quizPage: parsePageData(contentData, 'quizPage'),
    schoolsPage: contentData.schoolsPage,
    customPages: parseCustomPagesData(contentData, 'customPages'),
    legalPopups: contentData.legalPopups,
    challenges: parseChallenges(contentData, 'challenges'),
    editorials: parseEditorials(contentData, 'editorials'),
  };
  dispatch({ type: BOOTSTRAP_MANIFESTO_DATA, payload: contentData.manifesto });
  dispatch({ type: BOOTSTRAP_PAGE_CONTENT, payload: pageData });
};

export const dispatchComingSoonData = (dispatch, contentData) => {
  dispatch({ type: BOOTSTRAP_COMING_SOON_PAGE_CONTENT, payload: contentData });
};

export const dispatchShowcaseMapData = (dispatch, contentData) => {
  dispatch({ type: BOOTSTRAP_SHOWCASE_MAP_CONTENT, payload: contentData });
};

export const dispatchVideoPlaylistData = (dispatch, contentData) => {
  const { videoPlaylist } = contentData;
  const data = parseVideoPlaylist(contentData, 'videoPlaylist');

  dispatch({ type: BOOTSTRAP_VIDEO_PLAYLIST_DATA, payload: data });
};

// *******
// Idle prompt data
// *******
export const dispatchIdlePromptData = (dispatch, configData, contentData) => {
  const { idlePrompt } = configData;
  const data = parseIdlePromptData(contentData, 'idlePrompt');

  dispatch({ type: BOOTSTRAP_IDLE_PROMPT_DATA, payload: { data, config: idlePrompt } });
};

// *******
// Navigation data
// *******
export const dispatchNavigationData = (dispatch, configData, contentData) => {
  const { navigation, siteUrls, siteText } = configData;
  const { customPages } = contentData;
  dispatch({ type: BOOTSTRAP_NAVIGATION_DATA, payload: parseNavigation(navigation, siteUrls, customPages, siteText) });
};

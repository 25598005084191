// grab some styling :)
import '../scss/main.scss';
import '../scss/main.critical.us.scss';
// grab libs
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import App from './App';
import configureStore from './store/configureStore';
import { Provider } from 'react-redux';
import { DeviceDetection } from './modules/DeviceDetection';
// actions
import { startBootstrap } from 'store/actions/App.actions';
import { requestMapData, requestGeoData } from 'store/actions/ShowcaseMap.actions';
import { initialiseSiteMedia, startOneTrustSetup, handleOneTrustInit, handleDomLoad, attachOneTrustListeners } from 'store/actions/OneTrust.actions';
import { getSiteData, getMapData } from 'services/api';
import { IS_BASE_URL, LANGUAGE_CODE, LOCAL_STORAGE_OPTIONS } from 'constants/app/AppConstants';
import { check_lang_redirect } from 'constants/app/AppServices';
import { addSaveStorage } from 'store/configureStore';
import 'intersection-observer'; // polyfill for react-lazy-images
import { triggerSW } from './utils/service-worker';

const store = configureStore(); // this populates store from localstorage if it exists
// localStorage.setItem(LOCAL_STORAGE_OPTIONS.namespace, JSON.stringify({})); // zero localstorage (this will be reinstated if app receives onetrust_init event)
// document.addEventListener('onetrust_init', () => {
//   // addSaveStorage({ store });
//   store.dispatch(handleOneTrustInit());
// });

addSaveStorage({ store });

// listen for touch event
const handleTouchStart = () => {
  document.body.className = document.body.className.replace('no-touchevents', 'touch-device');
  window.removeEventListener('touchstart', handleTouchStart);
};
window.addEventListener('touchstart', handleTouchStart);

const doRender = (Component = App) => {
  render(
    <Provider store={store}>
      <Router basename={IS_BASE_URL ? (__WATCH__ && window.location.host.includes('localhost') ? `/cc/build/${LANGUAGE_CODE}` : `/${LANGUAGE_CODE}`) : '/'}>
        <Route component={App} store={store} />
      </Router>
    </Provider>,
    document.getElementById('app')
  );
};

/**
 * kickstart the bootstrap
 */
window.DeviceDetection = new DeviceDetection();
// start the ball rolling with api site data
getSiteData()
  .then((response) => {
    if (response.result === 'SUCCESS') {
      const data = response.data;
      store.dispatch(startBootstrap(data));
    } else {
      return { result: 'FAILURE', reason: 'was not succesful' };
    }
  })
  .catch((error) => {
    return { result: 'FAILURE', reason: error };
  });

store.dispatch(requestMapData());
// store.dispatch(requestGeoData());

export const startApp = (initialiseMedia = false) => {
  // if (initialiseMedia) {
  //   store.dispatch(initialiseSiteMedia());
  // }

  /**
   * Forcing a promise polyfill : for IE11
   * We know this is going to be an issue for Bitmovin in Firefox
   */
  // const x = new Promise((resolve, reject) => {});
  doRender(App);

  if (process.env.NODE_ENV !== 'production') {
    module.hot.accept('./App', () => {
      const NextRootContainer = require('./App').default;
      doRender(NextRootContainer);
    });
  }

  triggerSW();

  // We need to delay the listeners
  // store.dispatch(attachOneTrustListeners({ debug: false }));
};

window.addEventListener('load', function () {
  // store.dispatch(handleDomLoad());
  startApp();
});

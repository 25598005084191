import React, { Component } from 'react';
import { connect } from 'react-redux';

// import Selectors
import { getAllVideoPlaylists } from 'store/selectors/VideoPlaylist.selectors';
import { getSiteTextDataByGroup } from 'store/selectors/App.selectors';

// import components
import VideoPlaylist from 'components/video-playlist/VideoPlaylist';

class VideoPlaylists extends Component {
  renderLists() {
    const { playlists, text } = this.props;
    const { less = '', more = '' } = text;

    return playlists.map((item, index) => {
      if (item.content.length > 0) {
        return <VideoPlaylist key={index} playlist={item} less={less} more={more} />;
      }
    });
  }

  render() {
    return <React.Fragment>{this.renderLists()}</React.Fragment>;
  }
}

const mapStateToProps = (state) => {
  return {
    playlists: getAllVideoPlaylists(state),
    text: getSiteTextDataByGroup(state, 'videopage'),
  };
};

export default connect(mapStateToProps, null)(VideoPlaylists);

import React from 'react';
import PlusSign from 'components/inline-svgs/content-items/PlusSign';
import NavLoginIcon from 'components/inline-svgs/navigation/NavLoginIcon';

const Welcome = ({ setModalType, text, recaptchaLoaded }) => {
  const { welcome_text = '', login_button = '', help_text = '', go_to_register_button = '' } = text;

  return (
    <>
      <div className="authModal__header authModal__center">{welcome_text}</div>

      <div className="authModal__buttons authModal__center">
        <button className="authModal__button authModal__button_welcome authModal_button__login" aria-label="Login" onClick={() => setModalType('login')}>
          <NavLoginIcon /> {login_button}
        </button>
        {recaptchaLoaded ? (
          <button className="authModal__button authModal__button_welcome authModal_button__register" aria-label="Register" onClick={() => setModalType('register')}>
            <PlusSign /> {go_to_register_button}
          </button>
        ) : (
          <button className="authModal__button authModal_button__register" aria-label="Loading Register">
            sign up loading...
          </button>
        )}
      </div>
      <p className="authModal__center">{help_text}</p>
    </>
  );
};

export default Welcome;

import { IS_DESKTOP } from 'constants/app/AppConstants';
import { getLevelUpID, getTotalXP, getCompletedLevels, getCurrentLevelID } from 'store/selectors/UserExperience.selectors';

export const getXPEnabled = (state) => {
  if (state.experience) {
    return state.experience.experienceEnabled;
  }
  return false;
};

export const getLevelByID = (state, id) => {
  return state.experience.levelsByID[id];
};

export const getContentTypeDefaultExperience = (state, contentType) => {
  if (state.experience) {
    return (state.experience.totals[contentType] && state.experience.totals[contentType]) || false;
  }
  return false;
};

export const getContentTypeDefaultExperienceTotal = (state, contentType) => {
  if (state.experience) {
    return (state.experience.totals[contentType] && state.experience.totals[contentType].xp_total) || false;
  }
  return false;
};

export const getExperienceLevels = (state) => {
  return state.experience.levelIDs.map((id, index) => {
    return state.experience.levelsByID[id];
  });
};

export const getUsersXpTotalPerLevel = (state) => {
  let currentXpTotal = getTotalXP(state);
  const completedLevels = getCompletedLevels(state);

  if (completedLevels.length === 0) {
    return currentXpTotal;
  }

  let perviousLevelXpTotal = parseInt(getPerviousLevelByID(state, getCurrentLevelID(state)).points);

  return currentXpTotal - perviousLevelXpTotal;
};

export const getXpTotalPerLevel = (state) => {
  const completedLevels = getCompletedLevels(state);

  if (completedLevels.length === 0) {
    const levelByID = getLevelByID(state, getCurrentLevelID(state)) || false;

    if (levelByID) {
      return (currentlevelXpTotal = parseInt(getLevelByID(state, getCurrentLevelID(state)).points));
    }
    return false;
  }

  let perviousLevelXpTotal = parseInt(getPerviousLevelByID(state, getCurrentLevelID(state)).points);
  let currentlevelXpTotal = parseInt(getLevelByID(state, getCurrentLevelID(state)).points);

  return currentlevelXpTotal - perviousLevelXpTotal;
};

export const getRewardByLevelID = (state, id) => {
  return state.experience.rewardsByLevelID[id] || false;
};

export const getMobileRewardByLevelID = (state, id) => {
  return state.experience.mobileRewardsByLevelID[id] || false;
};

export const getRewardByURL = (state, url) => {
  let rewardID;
  let mobileReward = false;

  Object.keys(state.experience.levelsByID).map((id) => {
    if (state.experience.levelsByID[id].friendly_url === url) {
      rewardID = id;
      mobileReward = state.experience.levelsByID[id].is_mobile_reward && state.experience.levelsByID[id].is_mobile_reward === 'true' ? true : false;
    }
  });

  if (!IS_DESKTOP && mobileReward) {
    const reward = getMobileRewardByLevelID(state, rewardID);
    return {
      ...reward,
      id: rewardID,
    };
  }
  const reward = getRewardByLevelID(state, rewardID);
  return (
    {
      ...reward,
      id: rewardID,
    } || false
  );
};

const getMaxPoints = (state) => {
  const ind = state.experience.levelIDs.length - 1;
  const levelID = ind >= 0 ? state.experience.levelIDs[ind] : false;
  if (levelID) {
    const { max = 0 } = state.experience.experienceByID[levelID];
    return max;
  }
  return 0;
};
const getFirstLevel = (state) => {
  return state.experience.levelsByID[state.experience.levelIDs[0]];
};
const getHighestLevel = (state) => {
  return state.experience.levelsByID[state.experience.levelIDs[state.experience.levelIDs.length - 1]];
};

const getUserLevelIDByXP = (state, totalXP) => {
  return state.experience.levelIDs.reduce((res, levelID, index) => {
    const { min, max } = state.experience.experienceByID[levelID];
    res = totalXP >= min && totalXP < max ? levelID : res;
    return res;
  }, false);
};
/*
 * Get current user level (used in xp-bar)
 * Calculated from current totalXP
 */
export const getUserLevel = (state) => {
  const totalXP = getTotalXP(state);
  const levelID = getUserLevelIDByXP(state, totalXP);
  const totalXPForLevel = getUsersXpTotalPerLevel(state);
  const xpTotalPerLevel = getXpTotalPerLevel(state);
  if (levelID) {
    const { min, max } = state.experience.experienceByID[levelID];
    const data = { ...state.experience.levelsByID[levelID] };
    data.percentComplete = (totalXPForLevel / xpTotalPerLevel) * 100;
    return data;
  } else if (totalXP >= getMaxPoints(state)) {
    // console.log('getHighestLevel(state)', getHighestLevel(state));
    const data = { ...getHighestLevel(state) };
    data.percentComplete = 100;
    data.allLevelsCompleted = true;
    return data;
  }

  // console.log('getFirstLevel(state)', getFirstLevel(state));
  return { ...getFirstLevel(state) };
};

/*
 * Get all current user level progress (used in badges)
 */
export const getUserLevels = (state) => {
  const totalXP = getTotalXP(state);
  const levelUpID = getLevelUpID(state);
  const levels = state.experience.levelIDs.map((id, index) => {
    const { min, max } = state.experience.experienceByID[id];
    const level = { ...state.experience.levelsByID[id] };
    level.levelledUp = levelUpID === id; // Mark if the level has levelled up
    if (totalXP >= max) {
      level.completed = true;
      level.current = false;
      level.percentComplete = 100;
    } else if (totalXP >= min && totalXP < max) {
      level.completed = false;
      level.current = true;
      level.percentComplete = ((totalXP - min) / (max - min)) * 100;
    } else {
      level.completed = false;
      level.current = false;
    }
    return level;
  });

  return levels;
};

export const getPerviousLevelByID = (state, id) => {
  // get the index of the current level by iD
  const currentLevelIndex = state.experience.levelIDs.indexOf(id);
  // get pervious level ID
  const nextLevelID = state.experience.levelIDs[currentLevelIndex - 1];

  // return the pervious level data
  return state.experience.levelsByID[nextLevelID];
};

export const getNextLevelByID = (state, id) => {
  // get the index of the current level by iD
  const currentLevelIndex = state.experience.levelIDs.indexOf(id);
  // get next level ID
  const nextLevelID = state.experience.levelIDs[currentLevelIndex + 1];

  // return the next level data
  return state.experience.levelsByID[nextLevelID];
};

export const getXPBarBadge = (state) => {
  return state.experience.xpBarBadge;
};

const hasNotification = (state) => {
  return state.experience.notifications.length > 0;
};

export const getXPReward = (state) => {
  const notification = hasNotification(state) ? state.experience.notifications[0] : false;
  const { xpReward = false } = notification;
  return xpReward;
};

//magic things
import React from 'react';

const ExternalIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 497.5 481.98">
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <g style={{ mixBlendMode: 'multiply', opacity: '.5' }}>
          <path d="M248.75 25.81l71.05 143.97 158.89 23.09-114.97 112.08 27.14 158.23-142.11-74.7-142.11 74.7 27.14-158.23L18.81 192.87l158.89-23.09 71.05-143.97z" />
          <path
            stroke="#000"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="37.62"
            d="M248.75 25.81l71.05 143.97 158.89 23.09-114.97 112.08 27.14 158.23-142.11-74.7-142.11 74.7 27.14-158.23L18.81 192.87l158.89-23.09 71.05-143.97z"
          />
        </g>
        <path fill="#ffffff" d="M248.75 18.81l71.05 143.97 158.89 23.09-114.97 112.08 27.14 158.23-142.11-74.7-142.11 74.7 27.14-158.23L18.81 185.87l158.89-23.09 71.05-143.97z" />
        <path
          stroke="#ffffff"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="37.62"
          d="M248.75 18.81l71.05 143.97 158.89 23.09-114.97 112.08 27.14 158.23-142.11-74.7-142.11 74.7 27.14-158.23L18.81 185.87l158.89-23.09 71.05-143.97z"
        />
      </g>
    </g>
  </svg>
);

export default ExternalIcon;

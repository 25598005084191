import React from 'react';

const ChallengesSchoolsIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.27 37.78" className="challenge-schools-icon">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="challenge_01">
          <g id="title">
            <g id="grad_icon">
              <path
                className="cls-1"
                d="M47.27 12.81A3.1 3.1 0 0146 15.44l-8 4.7-8.81 5.14a10.55 10.55 0 01-3.7 1.95 2.7 2.7 0 01-.49 0 6.49 6.49 0 01-3.12-1.11l-11.76-6-3.3-1.56S3.53 17 3.61 16.9c-1.71-.44-1.86-2.53-1.86-2.53-.29-1.66 2.15-3 2.15-3L21.34 1.21A6.8 6.8 0 0123.68 0a2.42 2.42 0 01.45 0 2.94 2.94 0 011.5.43l19.3 9.65s2.34.92 2.34 2.73z"
              />
              <path className="cls-1" d="M21.83 26.16l-11.76-6v-.06H38l-8.81 5.14a10.55 10.55 0 01-3.7 1.95 2.7 2.7 0 01-.49 0 6.49 6.49 0 01-3.17-1.03z" />
              <path
                className="cls-1"
                d="M38.92 21.93v5.95c-.13.2-.26.4-.4.59-2.84 4-8.06 6.59-14 6.59s-11.18-2.64-14-6.59c-.14-.19-.28-.39-.41-.59v-5.4l10.69 5.44A8.32 8.32 0 0025 29.3a4.61 4.61 0 00.84-.07A11.54 11.54 0 0030.29 27zM5.93 29.22c1.09 2.85 1.34 7.93-2.47 8.5-4.17.62-4.17-3.84-2.12-5.63a3.84 3.84 0 001.24-2.69 2.63 2.63 0 01.51-4.53V13.64h2.1v11.23a2.64 2.64 0 01.74 4.35z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ChallengesSchoolsIcon;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

// import constants
import { PAGES } from 'constants/content-library/ContentLibraryConstants';

// import selectors
import { getSiteMetaData, getSiteTextDataByGroup, getSiteUrlByName } from 'store/selectors/App.selectors';

// import components
import SiteMetaData from 'components/generic/SiteMetaData';
import GenericNoWifi from 'components/inline-svgs/generic/GenericNoWifi';

class PageOfflineMessage extends Component {
  render() {
    const { offline_message = '' } = this.props.site_text;
    const { title = '', description_1 = '', link = '' } = offline_message;
    return (
      <React.Fragment>
        <SiteMetaData {...this.props.metadata} />
        <div className="page-not-found__background container">
          <section className="page-not-found__container flex flex-wrap justify-content-center">
            <div className="page-not-found">
              <div className="page-not-found__icon">
                <GenericNoWifi />
              </div>
              <h1 className="page-not-found__title">{title}</h1>
              <p className="page-not-found__description">{description_1}</p>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    metadata: getSiteMetaData(state, PAGES.HOME),
    site_text: getSiteTextDataByGroup(state, 'pwa'),
  };
};

export default connect(mapStateToProps, null)(PageOfflineMessage);

import React, { useRef, useState } from 'react';

// Check passcode input
const regexNumbersOnly = new RegExp('^[0-9]{1,4}$');

const Login = ({ setModalType, callback, text, errorMessage, errorText }) => {
  const inputName = useRef();
  const inputPass = useRef();

  // error messages
  const [numbersOnly, updateNumbersOnly] = useState(false);
  const [notEnoughNumbers, updateNotEnoughNumbers] = useState(false);

  const [enterUsername, updateEnterUsername] = useState(false);
  const [enterPasscode, updateEnterPasscode] = useState(false);

  const clickHandler = (e) => {
    e.preventDefault();

    if (inputName.current.value === '') {
      updateEnterUsername(true);
    } else {
      updateEnterUsername(false);
    }

    if (inputPass.current.value === '') {
      updateEnterPasscode(true);
    } else {
      updateEnterPasscode(false);
    }

    if (inputName.current.value !== '' && inputPass.current.value !== '') {
      if (inputPass.current.value.length === 4) {
        updateNotEnoughNumbers(false);
        callback({ name: inputName.current.value, password: inputPass.current.value });
      } else {
        updateNotEnoughNumbers(true);
      }
    }
  };

  const checkInput = () => {
    if (inputPass.current.value !== '') {
      updateEnterPasscode(false);
      if (regexNumbersOnly.test(inputPass.current.value)) {
        updateNumbersOnly(false);
      } else {
        updateNumbersOnly(true);
      }
    } else {
      updateNumbersOnly(false);
    }
  };

  const { login_title = '', enter_pin = '', login_submit_button = '', no_account = '', register_button = '', enter_username = '' } = text;

  const { enter_username_error, enter_passcode_error, enter_only_numbers_error, enter_four_numbers_error } = errorText;

  return (
    <>
      <div className="authModal__header">{login_title}</div>
      <form className="authModal__loginForm flex justify-content-center align-items-center">
        {enterUsername && <p className="authModal__error-message">{enter_username_error}</p>}
        <input className="authModal_input" maxLength="10" placeholder={enter_username} ref={inputName} />
        <p>{enter_pin}</p>
        {enterPasscode && <p className="authModal__error-message">{enter_passcode_error}</p>}
        {numbersOnly && <p className="authModal__error-message">{enter_only_numbers_error}</p>}
        {notEnoughNumbers && <p className="authModal__error-message">{enter_four_numbers_error}</p>}
        <input className="authModal_input" maxLength="4" type="number" ref={inputPass} onChange={checkInput} />
        <button className="authModal__button authModal_button__login" aria-label="Login" onClick={clickHandler}>
          {login_submit_button}
        </button>
      </form>
      <div className="authModal__register-prompt flex justify-content-center align-items-center">
        <div>{no_account}</div>
        <button className="authModal__button authModal_button__register" aria-label="Register" onClick={() => setModalType('register')}>
          {register_button}
        </button>
      </div>
      {errorMessage !== '' && <p className="authModal__error-message">{errorMessage}</p>}
    </>
  );
};

export default Login;

// Content Grid Constants
// Type of grid rows
//
// Grid1x2Row
// Grid3xRow
// Grid1xMpuRow
// Grid2x1Row

// Pages Grid Layout
export const HOME_PAGE_GRID_HERO = true;
export const HOME_PAGE_GRID = ['Grid1x2Row', 'Grid2x1Row', 'Grid1x2Row', 'Grid2x1Row'];

export const GAMES_PAGE_GRID_HERO = false;
export const GAMES_PAGE_GRID = ['Grid1x2Row', 'Grid2x1Row', 'Grid1x2Row', 'Grid2x1Row'];

export const VIDEOS_PAGE_GRID_HERO = false;
export const VIDEOS_PAGE_GRID = ['Grid1x2Row', 'Grid2x1Row', 'Grid1x2Row', 'Grid2x1Row'];

export const QUIZZES_PAGE_GRID_HERO = false;
export const QUIZZES_PAGE_GRID = ['Grid1x2Row', 'Grid2x1Row', 'Grid1x2Row', 'Grid2x1Row'];

export const DOWNLOADS_PAGE_GRID_HERO = false;
export const DOWNLOADS_PAGE_GRID = ['Grid1x2Row', 'Grid2x1Row', 'Grid1x2Row', 'Grid2x1Row'];

// Sub Pages Layout
export const SUB_GAMES_PAGE_GRID_HERO = false;
export const SUB_GAMES_PAGE_GRID = ['RelatedTitle', 'Grid1x2Row', 'Grid2x1Row'];

export const SUB_VIDEOS_PAGE_GRID_HERO = false;
export const SUB_VIDEOS_PAGE_GRID = ['RelatedTitle', 'Grid1x2Row', 'Grid2x1Row'];

export const SUB_QUIZZES_PAGE_GRID_HERO = false;
export const SUB_QUIZZES_PAGE_GRID = ['RelatedTitle', 'Grid1x2Row', 'Grid2x1Row'];

export const SUB_DOWNLOADS_PAGE_GRID_HERO = false;
export const SUB_DOWNLOADS_PAGE_GRID = ['RelatedTitle', 'Grid1x2Row', 'Grid2x1Row'];

export const SUB_APPS_PAGE_GRID_HERO = false;
export const SUB_APPS_PAGE_GRID = ['RelatedTitle', 'Grid1x2Row', 'Grid2x1Row'];

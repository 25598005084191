import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSiteTextDataByGroupedKey } from 'store/selectors/App.selectors';
import { getPageIdFromFriendlyURL } from 'store/selectors/Navigation.selectors';
import { getCustomPageById } from 'store/selectors/CustomPages.selectors';
import IframeResizer from 'iframe-resizer-react';
import { CUSTOM_CONTENT_TYPE, PAGE } from 'constants/app/AppConstants';
import { getUrlDynamic } from 'constants/app/AppUrls';
import { CONTENT_PAGES, PAGES } from 'constants/content-library/ContentLibraryConstants';
import { triggerPageView } from 'store/actions/EnsightenTracking.actions';

class PageCustom extends Component {
  componentDidMount() {
    const { id } = this.props;
    const contentType = CONTENT_PAGES[id];
    this.props.trackPageView({ contentType, pageType: PAGE.LANDING });
  }

  render() {
    const { contentItem, text } = this.props;
    const { title, externalContentType, externalContent } = contentItem;
    const { src = '', width = 1, height = 1, zip = '' } = externalContent;

    const parsedSrc = externalContentType === CUSTOM_CONTENT_TYPE.IFRAME ? src : getUrlDynamic(zip) + '/index.html';
    const content = <IframeResizer src={parsedSrc} style={{ width: `${width}px`, minWidth: '100%', height: `${height}px`, minHeight: '100%' }} />;

    return <>{content}</>;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const { path } = match;
  const id = getPageIdFromFriendlyURL(state, path);
  return {
    id,
    contentItem: getCustomPageById(state, id),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    trackPageView: (opts) => {
      dispatch(triggerPageView(opts));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PageCustom);

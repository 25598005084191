import { createSelector } from 'reselect';

// import actions
import { displayUpdateCountChallenges } from 'store/actions/Challenges.actions';

// import constants
import { CHALLENGE_TYPES } from 'constants/challenges/ChallengeTypes';

// import selectors
import { hasUserLikedChallenge, completedChallenges, cancelledChallenges } from 'store/selectors/User.selectors';
import { getUserToken, getUserExternalID } from 'store/selectors/UserAuth.selectors';

// declare some states
const challengesItemByIDs = (state) => state.challenges.byID;
const challengesItemAllIDs = (state) => state.challenges.allIDs;
const typeFilters = (state) => state.challenges.activeTypeFilters;
const activeDropdownFilter = (state) => state.challenges.activeDropdownFilter;
const currentDisplayCount = (state) => state.challenges.numberToDisplay;
const usersCompletedChallenges = (state) => completedChallenges(state);
const usersCancelledChallenges = (state) => cancelledChallenges(state);
const dailyChallengeIDs = (state) => state.challenges.dailyByIDs;
const defaultChallengeIDs = (state) => state.challenges.defaultByIDs;

export const getChallengesDropdownFilters = (state) => state.challenges.dropdownFilters;

export const getChallengesDisplayCount = createSelector([currentDisplayCount], (count) => count);

export const getChallengesItemByIDs = createSelector([challengesItemByIDs], (challenges) => challenges);

export const getChallengesItemAllIDs = createSelector([challengesItemAllIDs], (ids) => ids);

export const getChallengesTypeFilters = createSelector([typeFilters], (filters) => filters);

export const getChallengesActiveDropdownFilter = createSelector([activeDropdownFilter], (filter) => filter);

export const getChallengesContent = createSelector(
  [challengesItemByIDs, typeFilters, challengesItemAllIDs, activeDropdownFilter, usersCompletedChallenges, usersCancelledChallenges],
  (challenges, filterTypes, allIDs, dropdownFilter, completed, cancelled) => {
    const returnableData = allIDs.reduce((arr, id, index) => {
      const challenge = challenges[id];
      let shouldReturn = false;

      if (filterTypes.length > 0) {
        const { type } = challenge;
        const { name } = CHALLENGE_TYPES[type];

        if (filterTypes.indexOf(name) !== -1) {
          shouldReturn = true;
        }
      } else {
        shouldReturn = true;
      }

      if (shouldReturn && dropdownFilter !== 'all' && dropdownFilter !== 'popular') {
        if (dropdownFilter === 'daily' && challenge.daily) {
          arr.push(challenge);
        }
        if (dropdownFilter === 'complete' && completed.indexOf(id) !== -1) {
          arr.push(challenge);
        }
        if (dropdownFilter === 'todo' && completed.indexOf(id) === -1 && cancelled.indexOf(id) === -1) {
          arr.push(challenge);
        }
        if (dropdownFilter === 'cant_do' && cancelled.indexOf(id) !== -1) {
          arr.push(challenge);
        }
      } else if (shouldReturn) {
        arr.push(challenge);
      }

      return arr;
    }, []);

    // Apply additional filter/sort by
    if (dropdownFilter !== 'all' && dropdownFilter === 'popular') {
      returnableData.sort(function (a, b) {
        return b.likes - a.likes;
      });
    }

    return returnableData;
  }
);

export const getDisplayedChallenges = (state) => {
  const challenges = getChallengesContent(state);
  const count = currentDisplayCount(state);

  return { content: challenges.slice(0, count), showMore: count < challenges.length };
};

export const getDailyChallengesCount = (state) => {
  const challengeIDs = dailyChallengeIDs(state);

  return challengeIDs.length;
};

export const getDailyChallenges = (state) => {
  const challengeIDs = dailyChallengeIDs(state);

  const returnableData = challengeIDs.map((id) => {
    return getChallengesItemByID(state, id);
  });

  return { content: returnableData, showMore: false };
};

export const getDefaultChallenges = (state) => {
  const challengeIDs = defaultChallengeIDs(state);

  const returnableData = challengeIDs.map((id) => {
    return getChallengesItemByID(state, id);
  });

  return { content: returnableData, showMore: false };
};

export const getRotationChallenges = (state, rotation) => {
  const challengeIDs = defaultChallengeIDs(state);
  let rotationCount = 0;
  const returnableData = [];

  rotation.map((id) => {
    if (getChallengesItemByID(state, id)) {
      returnableData.push(getChallengesItemByID(state, id));
      rotationCount = rotationCount + 1;
    }
  });

  if (rotationCount > 0 && rotationCount < 3) {
    const remainingCount = 3 - rotationCount;
    let i = 0;

    for (i; i < remainingCount; ++i) {
      const id = challengeIDs[i];
      returnableData.push(getChallengesItemByID(state, id));
    }
  }

  return { content: returnableData, showMore: false };
};

export const getChallengesItemByID = (state, id) => {
  const challenges = getChallengesItemByIDs(state);
  return challenges[id] || false;
};

export const getChallengeItemsByIDs = (state, ids) => {
  const challenges = getChallengesItemByIDs(state);
  return ids.map((id) => {
    return challenges[id];
  });
};

export const getRandomChallengeByType = (state, type) => {
  const typeArray = state.challenges.byTypeIDs[type] || [];

  if (typeArray.length > 0) {
    const randomID = typeArray[Math.floor(Math.random() * typeArray.length)];
    return getChallengesItemByID(state, randomID);
  }

  return false;
};

export const shouldDisplayMoreButton = (state) => {
  const currentDisplayCount = state.challenges.numberToDisplay;
  const totalChallenges = state.challenges.allIDs.length;

  return currentDisplayCount < totalChallenges;
};

export const challengeCountByTypeID = (state, typeID) => {
  return state.challenges.byTypeIDs[typeID].length ?? 0;
};

export const orderedChallengesByTypeList = (state, type) => {
  const types = state.challenges.byTypeIDs;
  const returnableData = [];

  for (const key in types) {
    if (key) {
      types[key].map((id) => {
        returnableData.push(getChallengesItemByID(state, id));
      });
    }
  }
  return returnableData;
};

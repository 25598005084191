import React from 'react';

const ChallengesFloraIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.77 49.06" className="challenge-flora-icon">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M41.76 13.28a.9.9 0 00-.81-1l-3.78-.4a14.23 14.23 0 00-15.64 12.67l-.44 4.19a23.51 23.51 0 012.66-2.62L25 25l.62-.56c.22-.18.46-.33.68-.5L29 22a24.53 24.53 0 012.88-1.71q1.47-.81 2.94-1.56a64.85 64.85 0 00-10.19 8.35 33.23 33.23 0 00-2.73 3l3.72.39a14.23 14.23 0 0015.67-12.72zM15 27.15l-.14-.89-.23-.87c-.15-.59-.28-1.18-.44-1.76s-.41-1.14-.6-1.71l-.3-.87-.29-.83-.73-1.68c-.54-1.11-3.17-4.94-3.7-6.09.83.93 3.66 4.65 4.4 5.67l1 1.61.5.82.4.86a22 22 0 012.11 7.35c0 .39.07.78.08 1.17a1 1 0 00.26-.38l1.89-4.76A17.57 17.57 0 009.36 2L4.6.08a1.11 1.11 0 00-1.45.63l-1.9 4.75a17.57 17.57 0 009.82 22.82L15.36 30l-.08-1.06c-.02-.61-.17-1.2-.28-1.79z"
        />
        <path
          className="cls-2"
          d="M16.14 35.1a10.33 10.33 0 01.6-.87 20.64 20.64 0 00.28-4.3c0-.39 0-.78-.08-1.17a22 22 0 00-2.11-7.35l-.4-.86-.5-.82-1-1.61c-.74-1-3.57-4.74-4.4-5.67.53 1.15 3.16 5 3.7 6.09l.77 1.68.38.83.3.87c.19.57.39 1.15.6 1.71s.29 1.17.44 1.76l.23.87.14.89c.07.59.22 1.18.24 1.78l.03 1.07.05.73v1.8A25.47 25.47 0 0115 36.1c-.09.53-.21 1.06-.33 1.59.2-.38.41-.76.63-1.13z"
        />
        <path
          className="cls-2"
          d="M24.66 27.06a64.85 64.85 0 0110.19-8.35q-1.47.75-2.94 1.56A24.53 24.53 0 0029 22l-2.7 1.93c-.22.17-.46.32-.68.5L25 25l-1.25 1.13a23.51 23.51 0 00-2.66 2.62c-.67.74-1.33 1.5-2 2.25s-1.32 1.82-2 2.73l-.36.51a10.33 10.33 0 00-.6.87l-.86 1.46c-.22.37-.43.75-.63 1.13a46.82 46.82 0 00-4.4 11l1.27.35a55.61 55.61 0 015.2-11.65l.82-1.4c.29-.47.62-.9.92-1.35.63-.89 1.22-1.81 1.89-2.67L21.93 30a33.23 33.23 0 012.73-2.94z"
        />
      </g>
    </g>
  </svg>
);

export default ChallengesFloraIcon;

import React from 'react';

const PlusSign = (props) => (
  <svg version="1.1" x="0px" y="0px" xmlns="http://www.w3.org/2000/svg" className="plus-sign" viewBox="0 0 56.69 56.69">
    <g>
      <g>
        <circle className="cls-1" cx="28.34" cy="28.34" r="28.34" />
        <circle className="cls-2" cx="28.34" cy="28.34" r="24.94" />
        <line className="cls-3" x1="28.34" y1="16.72" x2="28.34" y2="39.97" />
        <line className="cls-3" x1="39.97" y1="28.34" x2="16.72" y2="28.34" />
      </g>
    </g>
  </svg>
);
export default PlusSign;

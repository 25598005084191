import React, { Component } from 'react';
import ManifestoCarousel from './ManifestoCarousel';

/**
 * Manifesto Component.
 * Places Manifesto carousel on the page
 *
 * @param {string} type (large / small): type of carousel to generate
 *  defaults to small
 */

class Manifesto extends Component {
  componentDidMount() {}

  render() {
    const { type = 'small', pageType = 'home' } = this.props;
    return (
      <section className="manifesto__container">
        <div className="container-fluid">
          <ManifestoCarousel type={type} pageType={pageType} />
        </div>
      </section>
    );
  }
}

export default Manifesto;

import React from 'react';

const GenericArrowDown = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.24 28" className="generic-arrow-down">
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <path className="cls-1" data-name="Path 989" d="M3.88 0h43.48a3.88 3.88 0 012.74 6.63L31 25.73a7.77 7.77 0 01-11 0L1.12 6.62A3.88 3.88 0 013.88 0z" />
      </g>
    </g>
  </svg>
);

export default GenericArrowDown;

export const USER_LEVELS_SAVE = 'USER_LEVELS_SAVE';
export const USER_LEVELS_LEVEL_UP = 'USER_LEVELS_LEVEL_UP';
export const USER_LEVELS_UP_POPUP_SHOW = 'USER_LEVELS_UP_POPUP_SHOW';
export const USER_LEVELS_UP_POPUP_HIDE = 'USER_LEVELS_UP_POPUP_HIDE';
export const USER_LEVELS_UPDATE = 'USER_LEVELS_UPDATE';
export const USER_LEVELS_ALL_LEVELS_COMPLETED = 'USER_LEVELS_ALL_LEVELS_COMPLETED';
export const USER_LEVELS_SEEN_LEVEL = 'USER_LEVELS_SEEN_LEVEL';

export const USER_LEVELS_CHALLENGES_INCREMENT = 'USER_LEVELS_CHALLENGES_INCREMENT';
export const USER_LEVELS_CHALLENGES_DECREMENT = 'USER_LEVELS_CHALLENGES_DECREMENT';

// import constants
import { CHALLENGES_PER_LEVEL, TOTAL_NUMBER_OF_LEVELS, UNLOCK_LEVELS_CHALLENGE_THRESHOLD, LAST_LEVEL_ID } from 'constants/my-goals/MyGoalsConstants';

// import parser
import { parseUserLevelsData } from 'store/actions/parsers/UserLevelsDataParser';

// import selectors
import { getAllLevelsCompleted, totalChallengesCompleted, getTotalLevelsCompleted, getCurrentLevelID } from 'store/selectors/UserLevels.selectors';

export const userHasSeenLevelUp = () => ({
  type: USER_LEVELS_SEEN_LEVEL,
});

export const incrementUserChallengeProgress = () => ({
  type: USER_LEVELS_CHALLENGES_INCREMENT,
});

export const decrementUserChallengeProgress = () => ({
  type: USER_LEVELS_CHALLENGES_DECREMENT,
});

export const updateUserLevelUp = (opts) => ({
  type: USER_LEVELS_LEVEL_UP,
  payload: opts,
});

export const updateUserCompletedAllLevels = (type) => ({
  type: USER_LEVELS_ALL_LEVELS_COMPLETED,
  payload: type,
});

const showLevelUpPopup = () => ({
  type: USER_LEVELS_UP_POPUP_SHOW,
});

export const hideLevelUpPopup = () => ({
  type: USER_LEVELS_UP_POPUP_HIDE,
});

const checkLeveledUpStatus = (state, type) => {
  const currentLevel = getCurrentLevelID(state);
  const currentCompletedChallengesByLevel = totalChallengesCompleted(state);
  const checkCount = currentCompletedChallengesByLevel === 0 ? 0 : type === USER_LEVELS_CHALLENGES_DECREMENT ? currentCompletedChallengesByLevel - 1 : currentCompletedChallengesByLevel + 1;

  let level = 'level_1';
  let prevLevel = 'level_1';

  for (const [key, value] of Object.entries(UNLOCK_LEVELS_CHALLENGE_THRESHOLD)) {
    if (value <= checkCount) {
      prevLevel = level;
      level = key;
      continue;
    }

    break;
  }

  return { leveledUp: currentLevel !== level, levelID: level, prevLevelID: prevLevel };
};

export const setUserLevelsData = ({ completed_challenges }) => {
  const parsedData = parseUserLevelsData(completed_challenges);

  return (dispatch, getState) => {
    const { allLevelsCompleted } = parsedData;
    dispatch(updateUserCompletedAllLevels(allLevelsCompleted));

    dispatch({ type: USER_LEVELS_SAVE, payload: parsedData });
  };
};

export const onChallengeUpdate = (type) => {
  return (dispatch, getState) => {
    const state = getState();

    if (type === USER_LEVELS_CHALLENGES_DECREMENT) {
      dispatch(decrementUserChallengeProgress());

      const hasLeveledUp = checkLeveledUpStatus(state, USER_LEVELS_CHALLENGES_DECREMENT);

      if (hasLeveledUp.leveledUp) {
        dispatch(updateUserLevelUp({ id: hasLeveledUp.levelID, prevId: hasLeveledUp.prevLevelID, type: USER_LEVELS_CHALLENGES_DECREMENT }));

        const isCompleted = getAllLevelsCompleted(state);
        if (isCompleted) {
          dispatch(updateUserCompletedAllLevels(false));
        }
      }
    } else {
      dispatch(incrementUserChallengeProgress());

      const isCompleted = getAllLevelsCompleted(state);

      // has the user finished all the levels
      if (!isCompleted) {
        // has the user leveled up?
        const hasLeveledUp = checkLeveledUpStatus(state, USER_LEVELS_CHALLENGES_INCREMENT);

        if (hasLeveledUp.leveledUp) {
          dispatch(showLevelUpPopup());
          dispatch(updateUserLevelUp({ id: hasLeveledUp.levelID, prevId: hasLeveledUp.prevLevelID, type: USER_LEVELS_CHALLENGES_INCREMENT }));

          dispatch(updateUserCompletedAllLevels(hasLeveledUp.levelID === LAST_LEVEL_ID));
        }
      }
    }
  };
};

import { requestAvsDataObject } from 'services/api';
import { getAvsData, getVideoPageItemByID } from 'store/selectors/Videopage.selectors';

export const VIDEO_RESET = 'video.RESET';
export const VIDEO_INVALIDATE = 'video.INVALIDATE';
export const VIDEO_REQUEST = 'video.REQUEST';
export const VIDEO_RECEIVE = 'video.RECEIVE';

export const resetVideo = () => ({
  type: VIDEO_RESET,
});

const retrieveAvsDataObject = (state, url, id, title) => {
  console.log('** retrieveAvsDataObject **', { url: url, id: id });

  return (dispatch) => {
    // dispatch({ type: VIDEO_REQUEST });
    requestAvsDataObject(url).then((response) => {
      console.log('** retrieveAvsDataObject **', response);
      if (response.resultCode === 'OK') {
        // console.log('** retrieveAvsDataObject **', response);
        response.resultObj.id = id;
        response.resultObj.title = title;
        dispatch({ type: VIDEO_RECEIVE, payload: response.resultObj });
      } else {
        console.error('retrieveAvsDataObject', response.reason);
        dispatch({ type: VIDEO_INVALIDATE });
      }
    });
  };
};

export const loadVideo = ({ id, interactive = false, title = '' }) => {
  return (dispatch, getState) => {
    if (interactive) {
      const state = getState();
      const avsData = getAvsData(state);
      const { avsPlayURLpattern, avsShowName } = avsData;
      const url = avsPlayURLpattern.replace('{format}', 'PCTV').replace('{id}', id);
      return dispatch(retrieveAvsDataObject(getState(), url, id, title));
    }
    const state = getState();
    const { avs_id } = getVideoPageItemByID(state, id);
    const avsData = getAvsData(state);
    const { avsPlayURLpattern, avsShowName } = avsData;
    const url = avsPlayURLpattern.replace('{format}', 'PCTV').replace('{id}', avs_id);
    return dispatch(retrieveAvsDataObject(getState(), url, id, title));
  };
};

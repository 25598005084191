export const getBitmovinWithTracking = (state) => {
  return state.onetrust.bitmovinWithTracking;
};

export const getBitmovinWithAd = (state) => {
  return state.onetrust.bitmovinWithAd;
};

export const getBitmovinStatus = (state) => {
  return state.onetrust.bitmovinStatus;
};

export const getPerformanceCookiesEnabled = (state) => {
  return state.onetrust.performanceCookiesEnabled;
};

export const getFunctionalCookiesEnabled = (state) => {
  return state.onetrust.functionalCookiesEnabled;
};

export const getAdvertisingCookiesEnabled = (state) => {
  return state.onetrust.advertisingCookiesEnabled;
};

export const getOneTrustActive = (state) => {
  return state.onetrust.oneTrustActive;
};

import { USER_ERRORS } from 'constants/user-errors/UserErrors';

import { SET_LOGIN_STATE, SET_LOGIN_ERROR, SET_LOGIN_TYPE, LOGIN_REQUESTED, LOGOUT_REQUESTED, STATE_IDLE, STATE_PENDING, STATE_ERROR } from 'store/actions/Login.actions';

const initState = {
  loginState: STATE_IDLE,
  loginError: false,
  loginErrorMessageID: '',
  loginRedirect: false,
  loginPending: false,
  loginType: 'login',
};

const Login = (state = initState, action) => {
  let nextState = {};

  switch (action.type) {
    case LOGOUT_REQUESTED:
      nextState.loginState = action.payload;
      return { ...state, ...nextState };
    case SET_LOGIN_STATE:
      nextState.loginState = action.payload;
      nextState.loginError = false;
      nextState.loginErrorMessageID = '';
      return { ...state, ...nextState };
    case SET_LOGIN_ERROR:
      nextState.loginError = action.payload;
      nextState.loginErrorMessageID = USER_ERRORS[action.payload];
      return { ...state, ...nextState };
    case SET_LOGIN_TYPE:
      nextState.loginType = action.payload;
      return { ...state, ...nextState };
  }

  return state;
};

export default Login;

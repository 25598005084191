import { BOOTSTRAP_PAGE_CONTENT } from 'store/actions/Bootstrap.actions';

const initState = {
  popupData: {},
};

const reducer = (state = initState, action) => {
  let nextState = {};
  switch (action.type) {
    case BOOTSTRAP_PAGE_CONTENT: {
      const { legalPopups = {} } = action.payload;
      nextState.popupData = legalPopups;
      return { ...state, ...nextState };
    }
  }
  return state;
};

export default reducer;

export const SET_LOGIN_STATE = 'SET_LOGIN_STATE';
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR';
export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED';
export const ANON_LOGIN_REQUESTED = 'ANON_LOGIN_REQUESTED';
export const ANON_LOGOUT_REQUESTED = 'ANON_LOGOUT_REQUESTED';
export const STATE_LOGGED_IN = 'LOGIN_STATE_LOGGED_IN';
export const STATE_IDLE = 'LOGIN_STATE_IDLE';
export const STATE_PENDING = 'LOGIN_STATE_PENDING';
export const STATE_ERROR = 'LOGIN_STATE_ERROR';
export const SIGN_UP_REQUESTED = 'SIGN_UP_REQUESTED';
export const SETUP_NAME_REQUESTED = 'SETUP_NAME_REQUESTED';
export const ANON_SIGN_UP_REQUESTED = 'ANON_SIGN_UP_REQUESTED';
export const STATE_ANON_LOGGED_IN = 'STATE_ANON_LOGGED_IN';
export const SET_LOGIN_TYPE = 'SET_LOGIN_TYPE';

import { logoutUser } from 'store/actions/User.actions';
import { removeAuth } from 'store/actions/UserAuth.actions';

import { getUserToken, getUserExternalID } from 'store/selectors/UserAuth.selectors';

export const requestAnonLogin = ({ external_id, token }) => ({
  type: ANON_LOGIN_REQUESTED,
  payload: { external_id, token },
});

export const setLoginType = (type) => {
  return {
    type: SET_LOGIN_TYPE,
    payload: type,
  };
};

export const requestAnonSignUp = (callback) => ({
  type: ANON_SIGN_UP_REQUESTED,
  payload: callback,
});

export const requestLogin = ({ name, password }) => ({
  type: LOGIN_REQUESTED,
  payload: { name, password },
});

export const setLoginStatus = ({ status }) => ({
  type: SET_LOGIN_STATE,
  payload: status,
});

export const setLoginError = ({ error }) => ({
  type: SET_LOGIN_ERROR,
  payload: error,
});

export const requestSignUp = (name) => {
  return (dispatch, getState) => {
    const token = getUserToken(getState());
    const external_id = getUserExternalID(getState());

    if (token === '' && external_id === '') {
      dispatch({ type: SIGN_UP_REQUESTED, payload: name });
    } else {
      dispatch({ type: SETUP_NAME_REQUESTED, payload: { external_id, token, name } });
    }
  };
};

export const requestLogout = () => {
  return (dispatch, getState) => {
    dispatch({ type: LOGOUT_REQUESTED, payload: STATE_IDLE });
    dispatch(logoutUser());
    dispatch(removeAuth());
    dispatch(setLoginType('login'));
  };
};

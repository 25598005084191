export const getFriendsAndFamilyCharacters = (state) => {
  return {
    title: state.characters.friends.title,
    characters: state.characters.friends.characters.map((data) => {
      return { content_type: 'story', ...data };
    }),
  };
};

export const getAliensCharacters = (state) => {
  if (state.characters.aliens === false) {
    return false;
  }

  return {
    title: state.characters.aliens.title,
    characters: state.characters.aliens.characters.map((data) => {
      return { friendly_url: data.friendly_url || data.id, ...data };
    }),
  };
};

export const getVilainsCharacters = (state) => {
  return state.characters.villains;
};

export const getOtherCharacters = (state) => {
  return state.characters.other;
};

export const getCharacterByFriendlyUrl = (state, friendly_url) => {
  return state.characters.all.find((character) => {
    return character.friendly_url === friendly_url;
  });
};

export const getPrevNextCharactersByFriendlyUrl = (state, friendly_url) => {
  const currIndex = state.characters.all.findIndex((character) => {
    return character.friendly_url === friendly_url;
  });

  let next = currIndex + 1;
  let prev = currIndex - 1;

  if (next >= state.characters.all.length) {
    next = 0;
  }
  if (prev < 0) {
    prev = state.characters.all.length - 1;
  }
  // console.log(currIndex, next, prev, state.characters.all[22]);
  return { next: state.characters.all[next], prev: state.characters.all[prev] };
};

export const getCharactersMarque = (state) => {
  return state.characters.marque;
};

export const getCharacterByID = (state, id) => {
  return state.characters.all.find((character) => {
    return character.id === id;
  });
};

export const getStoryPageItemXP = (state, id) => {
  const character = getCharacterByID(state, id);
  const { override_xp = 0, title } = character;
  return { title, xp: override_xp !== 0 ? Math.trunc(override_xp) : override_xp };
};

import { parseContentData } from 'store/actions/parsers/ContentDataParser';

export const parsePageData = (contentData, key) => {
  const data = contentData[key] || {};
  const { content = [], ...other } = data; // strips content from data, copying other keys into other

  // convert array into ByID lookup
  const allIDs = [];
  const byID = {};
  for (const item of content) {
    const { id } = item;
    const parsed = parseContentData(item);
    parsed.home_content_type = 'existing'; // all page data is existing

    if (key === 'downloadsPage') {
      // lagacy code has set everything downloads item
      // with "0" as default - update this to 'downloads' for consistany
      if (item.downloads_category === '0') {
        item.downloads_category = 'downloads';
      }
    }
    allIDs.push(id);
    byID[id] = { ...parsed };
  }
  // constructs "coming soon" object, initially for games page
  const comingSoon = {
    sectionActive: other.section_active || null,
    comingSoonBody: other.coming_soon_body || null,
    comingSoonHeader: other.coming_soon_header_1 || null,
    comingSoonSubHeader: other.coming_soon_header_2 || null,
  };

  // const marqueData = parseMarqueData(data);

  const promoData = {
    type: other.promo_type,
    content: other.promo_videos,
  };

  if (key === 'downloadsPage') {
    return { data: { allIDs, byID }, promoData, filter: other.filtering_option };
  }
  if (comingSoon.sectionActive) {
    return { data: { allIDs, byID }, promoData, comingSoon };
  }
  return { data: { allIDs, byID }, promoData };
};

import {
  BOOTSTRAP_COMPLETE,
  ACTIVE_MOBILE_NAV,
  ACTIVE_EXIT_SITE_MODAL,
  ACTIVE_AUTH_MODAL,
  UDPATE_ACTIVE_PAGE,
  SET_ONLINE_STATUS,
  ACTIVE_OFFLINE_MODAL,
  HIDE_COOKIE_BAR,
} from 'store/actions/App.actions';
import { COOKIE_ACCEPT_POLICY } from 'constants/app/AppConstants';
import { PAGES } from 'constants/content-library/ContentLibraryConstants';
import { BOOTSTRAP_APP_DATA, BOOTSTRAP_MANIFESTO_DATA } from 'store/actions/Bootstrap.actions';
import { APP_MODES, PWAASSETSUFFIX } from 'constants/app/AppConstants';
import { getAppMode } from 'utils/general';

const initState = {
  bootstrapComplete: false,
  statsCtaData: {},
  headerData: {},
  footerData: {},
  siteTextData: {},
  imageAltData: {},
  siteMetaData: {},
  appStores: {},
  oneTrust: {},
  bitmovin: {},
  manifesto: {},
  activeMobileNav: false,
  exitSiteModalActive: false,
  offlineModalActive: false,
  exitSiteModalLink: '',
  siteUrls: {},
  activePage: 'home',
  activeSubPage: false,
  activePageEnglishName: 'home',
  perviousPageEnglishName: '',
  isProgressiveWebAppMode: false, // we need to set this at some point :: https://stackoverflow.com/questions/41742390/javascript-to-check-if-pwa-or-mobile-web
  onlineStatus: window.navigator.onLine || true,
  rotation_challenges: [],
  myGoalsLevelNames: {},
  cookieBarDescription: '',
  hideCookieBar: true,
};

const App = (state = initState, action) => {
  let nextState = {};

  switch (action.type) {
    case BOOTSTRAP_APP_DATA: {
      const {
        header = {},
        footer = {},
        siteText = {},
        metaData = {},
        appStores = {},
        imageAlt = {},
        oneTrust = {},
        idlePrompt = {},
        bitmovin = {},
        statsCta = {},
        siteUrls = {},
        rotation_challenges,
        myGoalsLevelNames = {},
        cookieBarDescription = '',
      } = action.payload;
      nextState.headerData = parseHeaderData(header);
      nextState.footerData = parseFooterData(footer);
      nextState.statsCtaData = statsCta;
      nextState.siteTextData = siteText;
      nextState.imageAltData = imageAlt;
      nextState.siteMetaData = metaData;
      nextState.appStores = appStores;
      nextState.oneTrust = oneTrust;
      nextState.bitmovin = bitmovin;
      nextState.siteUrls = parseURLS(siteUrls);
      nextState.isProgressiveWebAppMode = checkAppMode();
      nextState.rotation_challenges = rotation_challenges;
      nextState.myGoalsLevelNames = myGoalsLevelNames;
      nextState.cookieBarDescription = cookieBarDescription;
      return { ...state, ...nextState };
    }
    case BOOTSTRAP_MANIFESTO_DATA:
      nextState.manifesto = action.payload;
      return { ...state, ...nextState };
    case BOOTSTRAP_COMPLETE:
      document.getElementById('preloader').classList.add('preloader-overlay--hidden');
      nextState.bootstrapComplete = true;
      return { ...state, ...nextState };
    case ACTIVE_MOBILE_NAV:
      nextState.activeMobileNav = !state.activeMobileNav;
      return { ...state, ...nextState };
    case ACTIVE_EXIT_SITE_MODAL:
      nextState.exitSiteModalActive = !state.exitSiteModalActive;
      nextState.exitSiteModalLink = action.payload;
      return { ...state, ...nextState };
    case ACTIVE_OFFLINE_MODAL:
      nextState.offlineModalActive = !state.offlineModalActive;
      return { ...state, ...nextState };
    case HIDE_COOKIE_BAR:
      nextState.hideCookieBar = action.payload;
      return { ...state, ...nextState };
    case ACTIVE_AUTH_MODAL:
      nextState.authModalActive = !state.authModalActive;
      return { ...state, ...nextState };
    case UDPATE_ACTIVE_PAGE:
      const { perviousPage, perviousPageSubpage = false, page, subpage = false } = action.payload;
      nextState.activePage = page !== '' ? page : 'home';
      nextState.activeSubPage = subpage;
      nextState.activePageEnglishName = parsePageState(page, subpage, state.siteUrls);
      nextState.perviousPageEnglishName = parsePageState(perviousPage, perviousPageSubpage, state.siteUrls);
      return { ...state, ...nextState };
    case SET_ONLINE_STATUS:
      nextState.onlineStatus = action.payload === 'online';
      return { ...state, ...nextState };
  }
  return state;
};

export default App;

const checkAppMode = () => {
  return getAppMode().mode === APP_MODES.APP;
};

const parseHeaderData = (data) => {
  let returnableData = { ...data };
  returnableData.header_logo = `${data.header_logo}${PWAASSETSUFFIX}`;
  return returnableData;
};

const parseFooterData = (data) => {
  let returnableData = { ...data };

  returnableData.footer_cn_logo = `${returnableData.footer_cn_logo}${PWAASSETSUFFIX}`;

  if (returnableData.logo_setup && returnableData.logo_setup.length > 0) {
    returnableData.logo_setup.map((img, index) => {
      returnableData.logo_setup[index].logo_image = `${img.logo_image}${PWAASSETSUFFIX}`;
    });
  }

  return returnableData;
};

const parseURLS = (urls) => {
  if (typeof urls === 'object') {
    const { challenges = PAGES.CHALLENGES, map = PAGES.MAP, my_goals = PAGES.MY_GOALS, quizzes = PAGES.QUIZZES, videos = PAGES.VIDEOS, faq = PAGES.FAQ, schools = PAGES.SCHOOLS, ...rest } = urls;
    const challengeTypeUrls = {};
    for (const [key, value] of Object.entries(rest)) {
      challengeTypeUrls[key.replace('challenge_', '')] = value;
    }
    return { challenges, map, my_goals, quizzes, videos, faq, schools, challengeTypeUrls };
  } else {
    return { challenges: PAGES.CHALLENGES, map: PAGES.MAP, my_goals: PAGES.MY_GOALS, quizzes: PAGES.QUIZZES, videos: PAGES.VIDEOS, faq: PAGES.FAQ, schools: PAGES.SCHOOLS, challengeTypeUrls: {} };
  }
};

const parsePageState = (page, subpage, urls) => {
  let englishPage = 'home';

  for (const [key, value] of Object.entries(urls)) {
    if (page === value) {
      englishPage = key;
      break;
    }

    if (key === 'challengeTypeUrls') {
      for (const [key, value] of Object.entries(value)) {
        if (page === value) {
          englishPage = `challenges/${key}`;
          break;
        }
      }
    }
  }
  if (subpage === 'sub-page') {
    if (englishPage === 'challenges') {
      englishPage = 'challenge';
    }
    if (englishPage === 'videos') {
      englishPage = 'video';
    }
  }

  return englishPage;
};

import { BASE_TEMPLATE, getMicrositeName, SECTION_LOOKUP, SUBSECTION_LOOKUP, SUBSECTION_LOOKUP_ENGLISH } from 'constants/ensighten-tracking/EnsightenTrackingConstants';
import { PAGES, CONTENT } from 'constants/content-library/ContentLibraryConstants';
import { PAGE } from 'constants/app/AppConstants';
import { parseData } from 'constants/ensighten-tracking/EnsightenTrackingServices';

/**
 * Emoji click
 * Omnitrix Click
 * Character Click
 * Character Audio Clip Click
 */
/**
 * Detail = true
 * videos|video detail
 * games|games detail
 */

export const getSectionDataOEQ = ({ pageType, contentType, detail = false }) => {
  let { pageviewevent, ...data } = BASE_TEMPLATE;
  if (pageType === PAGE.SUB) {
    const type = contentType === 'quizzes' ? 'quiz' : contentType === 'videos' ? 'video' : contentType;
    const { section, subsection } = SUBSECTION_LOOKUP[type];
    data.section = section;
    data.subsection = detail ? `${section}|${subsection} detail` : `${section}|main`;
    // data.pagetype = contentType;
    data.content_type = contentType;
  } else {
    const section = SECTION_LOOKUP[contentType];
    data.section = section;
    data.subsection = `${section}|main`;
    // data.pagetype = contentType === PAGES.HOME ? 'main' : 'category';
    data.content_type = contentType === PAGES.HOME ? 'home page' : 'section front';
  }
  return data;
};

const addGenericInteractionData = ({ base, contentItem = {}, isPWA }) => {
  const { title = 'nvs', id = 'nvs', english_tracking_name = 'nvs' } = contentItem;
  // base.micrositename = getMicrositeName(isPWA);
  base.page_name = base.page_name + decodeURIComponent(location.pathname);
  // base.show = 'cn climate champions';

  base.content_title = parseData(title);
  base.content_id = id;
  // base.englishname = parseData(english_tracking_name);
  base.franchise = 'cn climate champions'; // todo : is this cn climate champions

  base.interaction_event = 1;
  // base.contentclickevent = 1;
  return base;
};

export const getSectionData = (opts) => {
  const { pageType, contentType, detail = false } = opts;
  opts.base = getSectionDataOEQ({ pageType, contentType, detail });
  return addGenericInteractionData(opts);
};

export const getSectionDataDetail = (opts) => {
  const { pageType, contentType } = opts;
  opts.base = getSectionDataOEQ({ pageType, contentType, detail: true });
  return addGenericInteractionData(opts);
};

export const getSectionDataFeatured = (opts) => {
  const { pageType, pageContentType } = opts;
  opts.base = getSectionDataOEQ({ pageType, contentType: pageContentType });
  return addGenericInteractionData(opts);
};

export const getDataByBaseClick = (opts) => {
  const { pathname } = opts;
  let pageType = PAGE.LANDING;
  let contentType = '';

  if (pathname === '/') {
    contentType = 'home';
  } else {
    const split = pathname.split('/').filter(function (i) {
      return i;
    });

    if (split && split.length > 0) {
      contentType = split[0];
    }

    if (split.length > 1) {
      pageType = PAGE.SUB;

      switch (split[0]) {
        case PAGES.GAMES:
          contentType = CONTENT.GAME;
          break;
        case PAGES.DOWNLOAD:
          contentType = CONTENT.DOWNLOADS;
          break;
        case PAGES.VIDEOS:
          contentType = CONTENT.VIDEO;
          break;
        case PAGES.STORY:
          contentType = CONTENT.STORY;
          break;
        case PAGES.QUIZZES:
          contentType = CONTENT.QUIZ;
          break;
        default:
      }
    }
  }

  // based on the pathname
  // setup contentType and pageType
  return { pageType, contentType };
};

/**
 * section
 * home: "home"
 * quiz section: "quiz"
 *
 * subsection
 * home: "home|main"
 * quiz section: "quiz|main"
 *
 * pagetype
 * home: "main"
 * quiz section: "category"
 * quiz detail: "quiz"
 *
 * content_type
 * home: "home page"
 * quiz section: "section front"
 * quiz detail: "quiz"
 */

//magic things
import React, { Component } from 'react';
import { IMAGE_SRC_SIZES, WEBPSUPPORT, NO_IMAGE_FALLBACK } from 'constants/app/AppConstants';
import { NavLink, withRouter } from 'react-router-dom';
import AppExitSiteLink from 'containers/base/AppExitSiteLink';

// Import things
import { getUrlDynamic } from 'constants/app/AppUrls';

// Import Components
import PictureMappings from 'components/picture-mappings/PictureMappings';
import PictureMappingsNoImage from 'components/picture-mappings/PictureMappingsNoImage';

// Import SVG icon
import ExternalIcon from 'components/inline-svgs/content-items/ExternalIcon';

class ContentItemExternal extends Component {
  renderContentItem() {
    const { friendly_url, thumb = {}, title = '', thumb_alt = '', thumb_title = '', content_type, show_leaving_message = true } = this.props;

    // Create vars to push through picture mappings
    let sizes =
      (thumb && this.props.itemSize == 'large') || (thumb && this.props.itemSize == 'size-mix')
        ? IMAGE_SRC_SIZES.LARGE
        : thumb && this.props.itemSize == 'small'
        ? IMAGE_SRC_SIZES.DEFAULT
        : IMAGE_SRC_SIZES.DEFAULT;

    let imageAlt = thumb_alt != '' ? thumb_alt : title;
    let imageTitle = thumb_title != '' ? thumb_title : title;

    if (content_type === 'external' && show_leaving_message !== 'false') {
      return (
        <AppExitSiteLink link={friendly_url}>
          <figure>
            <PictureMappings src={thumb} sizes={sizes} imageAlt={imageAlt} imageTitle={imageTitle} />
            <figcaption className="content-item__details">
              <div className="content-item__title">{this.props.itemSize === 'hero' ? <h1>{title}</h1> : <h2>{title}</h2>}</div>
              <div className="content-item__icon">
                <span className="icon">
                  <ExternalIcon />
                </span>
              </div>
            </figcaption>
          </figure>
        </AppExitSiteLink>
      );
    }

    if (content_type === 'external') {
      return (
        <a href={friendly_url} target="_blank" rel="noreferrer">
          <figure>
            <PictureMappings src={thumb} sizes={sizes} imageAlt={imageAlt} imageTitle={imageTitle} />
            <figcaption className="content-item__details">
              <div className="content-item__title">{this.props.itemSize === 'hero' ? <h1>{title}</h1> : <h2>{title}</h2>}</div>
              <div className="content-item__icon">
                <span className="icon">
                  <ExternalIcon />
                </span>
              </div>
            </figcaption>
          </figure>
        </a>
      );
    }

    return (
      <NavLink to={friendly_url}>
        <figure>
          <PictureMappings src={thumb} sizes={sizes} imageAlt={imageAlt} imageTitle={imageTitle} />
          <figcaption className="content-item__details">
            <div className="content-item__title">{this.props.itemSize === 'hero' ? <h1>{title}</h1> : <h2>{title}</h2>}</div>
            <div className="content-item__icon">
              <span className="icon">
                <ExternalIcon />
              </span>
            </div>
          </figcaption>
        </figure>
      </NavLink>
    );
  }

  render() {
    const { content_type } = this.props;

    return (
      <div className="content-item__wrapper">
        <section className={'content-item content-item--' + this.props.itemSize + ' content-item--' + content_type}>{this.renderContentItem()}</section>
      </div>
    );
  }
}

export default withRouter(ContentItemExternal);

import { CONTENT } from 'constants/content-library/ContentLibraryConstants';
// import { getGameIDByFriendlyURL, getGamePageItemByID } from 'store/selectors/Gamepage.selectors';
import { getVideoIDByFriendlyURL, getVideoPageItemByID } from 'store/selectors/Videopage.selectors';
import { getQuizIDByFriendlyURL, getQuizPageItemByID } from 'store/selectors/Quizpage.selectors';
// import { getAppIDByFriendlyURL, getAppPageItemByID } from 'store/selectors/Apppage.selectors';
// import { getDownloadsIDByFriendlyURL, getDownloadsPageItemByID } from 'store/selectors/Downloadspage.selectors';
import { getHomePageExternalContent, getHomePageInternalContent } from 'store/selectors/Homepage.selectors';
import { getCharacterByID } from 'store/selectors/Characters.selectors';

export const getContentItem = (state, content_type, id) => {
  switch (content_type) {
    case CONTENT.VIDEO:
      return getVideoPageItemByID(state, id);
    case CONTENT.QUIZ:
      return getQuizPageItemByID(state, id);
    case CONTENT.EXTERNAL:
      return getHomePageExternalContent(state, id);
    case CONTENT.INTERNAL:
      return getHomePageInternalContent(state, id);
    default:
      return null;
  }
};

export const getContentIDByFriendlyURL = (state, content_type, url) => {
  switch (content_type) {
    case CONTENT.VIDEO:
      return getVideoIDByFriendlyURL(state, url);
    case CONTENT.QUIZ:
      return getQuizIDByFriendlyURL(state, url);
    default:
      return null;
  }
};

export const getIsProgressiveWebAppMode = (state) => {
  return state.app.isProgressiveWebAppMode === true;
};

export const getLevelEnglishName = (state, level) => {
  return state.app.myGoalsLevelNames[level] || '';
};

export const getRotatonChallenges = (state) => {
  return state.app.rotation_challenges;
};

export const getBootstrapComplete = (state) => {
  return state.app.bootstrapComplete;
};

export const getSiteTextData = (state) => {
  return state.app.siteTextData;
};

export const getSiteActivePage = (state) => {
  return state.app.activePage;
};

export const getSiteActiveSubPage = (state) => {
  return state.app.activeSubPage;
};

export const getSiteActivePageEnglishName = (state) => {
  return state.app.activePageEnglishName;
};
export const getSitePerviousPageEnglishName = (state) => {
  return state.app.perviousPageEnglishName;
};

export const getCookieBarDescription = (state) => {
  return state.app.cookieBarDescription;
};

export const getHideCookieBar = (state) => {
  return state.app.hideCookieBar;
};

export const getStatsCtaData = (state) => {
  return state.app.statsCtaData;
};

export const getManifestoData = (state) => {
  return state.app.manifesto;
};

export const getImageAltData = (state) => {
  return state.app.imageAltData;
};

export const getAppStoreImages = (state) => {
  return state.app.appStores;
};

export const getMobileNavState = (state) => {
  return state.app.activeMobileNav;
};

export const getExitSiteModalState = (state) => {
  return state.app.exitSiteModalActive;
};

export const getOfflineModalState = (state) => {
  return state.app.offlineModalActive;
};

export const getOnlineStatus = (state) => {
  return state.app.onlineStatus;
};

export const getAuthModalState = (state) => {
  return state.app.authModalActive;
};

export const getExitSiteModalLink = (state) => {
  return state.app.exitSiteModalLink;
};

export const getSiteTextDataByGroup = (state, group) => {
  return state.app.siteTextData[group] || {};
};

export const getSiteTextDataByGroupedKey = (state, group, key) => {
  return getSiteTextDataByGroup(state, group)[key] || '';
};

export const getImageAltDataByGroup = (state, group) => {
  return state.app.imageAltData[group] || {};
};

export const getImageAltDataByGroupedKey = (state, group, key) => {
  return getImageAltDataByGroup(state, group)[key] || '';
};

export const getHeaderData = (state) => {
  return state.app.headerData;
};

export const getFooterData = (state) => {
  const f = state.app.footerData;
  const h = state.app.headerData;
  const ot = state.app.oneTrust;
  const legalLinks = Array.isArray(f.legal_setup) ? [...f.legal_setup] : [];
  if (ot.enable_onetrust === '1') {
    legalLinks.unshift({
      clazz: 'optanon-toggle-display',
      legal_link: '#',
      legal_link_text: state.app.oneTrust.onetrust_footer_link_label || '',
      oneTrustLink: true,
    });
  }
  return {
    legalLinks,
    cnText: f.cn_text,
    logos: f.logo_setup,
    legalText: f.legal_text,
    cnAlt: getImageAltDataByGroupedKey(state, 'logos_alt', 'cn_alt'),
    cnLink: f.footer_cn_link,
    cnLinkText: f.back_to_homepage_link_text,
    wwfText: f.wwf_legal_text,
  };
};

export const getBitmovinData = (state) => {
  return state.app.bitmovin;
};

export const getEmojisData = (state) => {
  return state.app.emojisData;
};

export const getSiteUrls = (state) => {
  return state.app.siteUrls;
};

export const getSiteUrlByName = (state, name) => {
  const urls = state.app.siteUrls;
  let returnableData = '';
  for (const [key, value] of Object.entries(urls)) {
    if (key.includes(name)) {
      returnableData = value;
    }
  }
  return returnableData;
};

export const getChallengesTypeByUrl = (state, url) => {
  const challengeUrl = state.app.siteUrls.challengeTypeUrls;
  let returnableData = '';
  for (const [key, value] of Object.entries(challengeUrl)) {
    if (value === url) {
      returnableData = key;
    }
  }
  return returnableData;
};

export const getChallengesUrlByType = (state, type) => {
  const challengeUrl = state.app.siteUrls.challengeTypeUrls;
  let returnableData = '';
  for (const [key, value] of Object.entries(challengeUrl)) {
    if (key.includes(type)) {
      returnableData = value;
    }
  }
  return returnableData;
};

export const getSiteMetaData = (state, id) => {
  if (state.app.siteMetaData.byID) {
    return state.app.siteMetaData.byID[id] || state.app.siteMetaData.byID.home;
  }
  return state.app.siteMetaData.byID.home;
};

import React from 'react';
import { CONTENT } from 'constants/content-library/ContentLibraryConstants';
import GameIcon from 'components/inline-svgs/content-items/GameIcon';
import QuizIcon from 'components/inline-svgs/content-items/QuizIcon';
import VideoIcon from 'components/inline-svgs/content-items/VideoIcon';
import AppIcon from 'components/inline-svgs/content-items/AppIcon';
import DownloadIcon from 'components/inline-svgs/content-items/DownloadIcon';
import ResourceIcon from 'components/inline-svgs/content-items/ResourceIcon';

const ContentIcon = (props) => {
  const getContentIcon = (type) => {
    switch (type) {
      case CONTENT.GAME:
        return <GameIcon />;
      case CONTENT.QUIZ:
        return <QuizIcon />;
      case CONTENT.VIDEO:
        return <VideoIcon />;
      case CONTENT.APP:
        return <AppIcon />;
      case CONTENT.DOWNLOAD:
        return <DownloadIcon />;
      case CONTENT.CHALLENGE:
        return <ResourceIcon />;
      default:
        return null;
    }
  };
  const { type } = props;
  return getContentIcon(type);
};

export default ContentIcon;

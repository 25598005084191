import React, { useEffect, useRef, useState } from 'react';
import Swiper from 'react-id-swiper';
import { connect } from 'react-redux';

// Import Components
import Counter from 'components/stats-cta/Counter';
import Text from 'components/stats-cta/Text';

// Import Selectors
import { getStatsCtaData } from 'store/selectors/App.selectors';
import { getTotalOfCompletedChallenges } from 'store/selectors/ShowcaseMap.selectors';

const StatsCTA = (props) => {
  const [swiper, setSwiper] = useState(null);
  const [activeSlide, updateActiveSlide] = useState(0);
  const [eventAttached, updateEventAttached] = useState(false);

  let swiperRef = useRef();

  const updateSlide = () => {
    updateActiveSlide(swiper.activeIndex);
  };

  let params = {
    slidesPerView: 1,
    loop: false,
    autoplay: {
      delay: 5000,
    },
    spaceBetween: 20,
  };

  useEffect(() => {
    if (swiper !== null && !eventAttached) {
      swiper.on('transitionEnd', updateSlide);
      updateEventAttached(true);
    }

    return () => {
      if (swiper !== null && eventAttached) {
        swiper.off('transitionEnd', updateSlide);
        updateEventAttached(false);
      }
    };
  }, [swiper, activeSlide]);

  const renderSlides = () => {
    const { totalCompletedChallenges = 0 } = props;
    const { content = {}, registered_users } = props.data;
    const slides = [];
    for (const index in content) {
      if (content.hasOwnProperty(index)) {
        const indexValue = parseInt(index);
        switch (content[index].cta_item_type) {
          case 'text':
            // Need to prevent the slide from showing if page link
            // matches the currentPage
            if (props.currentPage !== content[index].page) {
              slides.push(
                <div key={indexValue} className="swiper-slide">
                  <Text data={content[index]} />
                </div>
              );
            }
            break;
          default:
            slides.push(
              <div key={indexValue} className="swiper-slide">
                <Counter data={content[index]} active={indexValue === activeSlide} count={content[index].cta_item_type === 'total_registered_users' ? registered_users : totalCompletedChallenges} />
              </div>
            );
        }
      }
    }
    return slides;
  };

  const { totalCompletedChallenges = 0 } = props;
  if (totalCompletedChallenges !== 0) {
    return (
      <section className={'stats-cta' + (totalCompletedChallenges === 0 ? ' stats-cta--hidden' : '')}>
        <Swiper {...params} getSwiper={setSwiper}>
          {renderSlides()}
        </Swiper>
      </section>
    );
  } else {
    return '';
  }
};

const mapStateToProps = (state) => {
  return {
    data: getStatsCtaData(state),
    totalCompletedChallenges: getTotalOfCompletedChallenges(state),
  };
};

export default connect(mapStateToProps, null)(StatsCTA);

import React from 'react';
import { connect } from 'react-redux';

// import constants
import { getUrlFrontendImage } from 'constants/app/AppUrls';

// import actions
import { activeAuthModal } from 'store/actions/App.actions';

// import selectors
import { getUsername, getUserAvatar } from 'store/selectors/User.selectors';
import { getLoggedInStatus } from 'store/selectors/Login.selectors';
import { getSiteTextDataByGroupedKey, getOnlineStatus } from 'store/selectors/App.selectors';

// import components
import NavLoginIcon from 'components/inline-svgs/navigation/NavLoginIcon';

const AppAuthLink = (props) => {
  const { username, isLoggedIn, loginText = '', activeAvatar } = props;
  return (
    <div className="login-button" onClick={() => props.activeAuthModal()}>
      <div className="navigation__link__icon">{isLoggedIn ? <img className="user-login-img" src={getUrlFrontendImage(`logged-in/avatars/avatar-${activeAvatar}.png`)} /> : <NavLoginIcon />}</div>
      <div className="navigation__link__title">{isLoggedIn && username !== '' ? username : loginText}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    username: getUsername(state),
    isLoggedIn: getLoggedInStatus(state),
    loginText: getSiteTextDataByGroupedKey(state, 'login', 'login_title'),
    isOnline: getOnlineStatus(state),
    activeAvatar: getUserAvatar(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    activeAuthModal: () => {
      dispatch(activeAuthModal());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppAuthLink);

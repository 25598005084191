import { BOOTSTRAP_PAGE_CONTENT } from 'store/actions/Bootstrap.actions';

const initState = {
  allIDs: [],
  byID: {},
};

const reducer = (state = initState, action) => {
  let nextState = {};
  switch (action.type) {
    case BOOTSTRAP_PAGE_CONTENT: {
      const { data } = action.payload.customPages;
      const { allIDs, byID } = data;
      nextState.allIDs = allIDs;
      nextState.byID = byID;
      return { ...state, ...nextState };
    }
  }
  return state;
};

export default reducer;

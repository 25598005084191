//magic things
import React from 'react';
// Import selectors
import FigureImage from 'components/generic/FigureImage';
import AppExitSiteLink from 'containers/base/AppExitSiteLink';

const FooterCNContent = ({ cnLogo, cnLogoLink, cnAlt, cnText }) => (
  <div className="cn-content">
    <div className="cn-content__blurb">{cnText}</div>
  </div>
);

export default FooterCNContent;

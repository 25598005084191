import React, { useState } from 'react';
import * as DeviceDetection from 'utils/deviceDetection';

// import constants
import { getUrlFrontendImage } from 'constants/app/AppUrls';
import { USER_AVATARS } from 'constants/user/UserConstants';

const AvatarSelection = ({ avatarCallback, avatarConfirm, avatarTitle, activeAvatar }) => {
  const [currentAvatar, updateCurrentAvatar] = useState(activeAvatar);

  const handleAvatarClick = (avatarIndex) => {
    updateCurrentAvatar(avatarIndex);
  };

  const renderAvatars = () => {
    return USER_AVATARS.map((avatar, index) => {
      const avatarIndex = index + 1;
      return (
        <li key={index}>
          <img className={avatarIndex === currentAvatar ? 'active' : ''} src={getUrlFrontendImage(`logged-in/avatars/${avatar}.png`)} onClick={() => handleAvatarClick(avatarIndex)} alt={avatar} />
        </li>
      );
    });
  };

  return (
    <div className="authModal--avatar">
      <div className=" authModal__header">{avatarTitle}</div>
      <div className="authModal__body"></div>
      <ul className="authModal__list">{renderAvatars()}</ul>
      <button
        className="authModal__button"
        aria-label="choose avatar"
        onClick={() => {
          avatarCallback(currentAvatar);
        }}
      >
        {avatarConfirm}
      </button>
    </div>
  );
};

export default AvatarSelection;

import React, { Component } from 'react';
// import { getEnsightenTrigger } from 'constants/app/AppServices';

class EnsightenAd extends Component {
  constructor(props) {
    super(props);
  }

  /**
   * The ad refresh is acrtually handled by dispatchEnsightenPageLoad
   */
  // componentDidMount() {
  //   const ensighten = getEnsightenTrigger();
  //   const { trigger } = this.props;
  //   if (ensighten.available) {
  //     ensighten.trigger(trigger);
  //   }
  // }

  render() {
    // const { div_id, type, trigger } = this.props;
    return <div id={this.props.div_id}></div>;
  }
}
export default EnsightenAd;

import { isEmptyObj } from 'utils/general';

import {
  USER_SET_USERNAME,
  USER_SET_DATA,
  USER_SET_PASSCODE,
  USER_REMOVE_USERNAME,
  USER_SET_MEMBERSHIP_CARD,
  USER_SET_LIKE_CHALLENGE,
  USER_REMOVE_LIKE_CHALLENGE,
  USER_SET_COMPLETED_CHALLENGE,
  USER_REMOVE_COMPLETED_CHALLENGE,
  USER_MY_GOALS_SET_GARDEN_ID,
  USER_MY_GOALS_SET_PLANT_PLACEMENT,
  USER_LOGOUT,
  USER_SET_ANON_DETAILS,
  USER_SET_CANCELLED_CHALLENGE,
  USER_REMOVE_CANCELLED_CHALLENGE,
  USER_UPDATE_AVATAR,
  USER_FIRST_TIME_COMPLETED_CHALLENGE,
  USER_UPDATE_PLANNER,
  USER_REMOVE_PLANNER,
  UPDATE_COMPLETED_CHALLENGE_LIST,
} from 'store/actions/User.actions';

import { DEFAULT_PLANNER_DATA } from 'constants/app/AppConstants';

const initState = {
  username: '',
  avatar: '',
  likes: [],
  completed_challenges: [],
  cancelled_challenges: [],
  country: 'GB',
  passcode: '',
  membershipCard: '',
  token: '',
  external_id: '',
  my_goals: {
    garden_id: '',
    plant_placements: {},
  },
  completed_player_challenges: [],
  first_time_completed_challenges: [],
  planner: DEFAULT_PLANNER_DATA,
};

const User = (state = initState, action) => {
  let nextState = {};

  switch (action.type) {
    case USER_SET_ANON_DETAILS: {
      const { token, external_id } = action.payload;

      nextState.token = token;
      nextState.external_id = external_id;
      return { ...state, ...nextState };
    }

    case USER_LOGOUT:
      nextState.username = '';
      nextState.avatar = '';
      nextState.likes = [];
      nextState.completed_challenges = [];
      nextState.first_time_completed_challenges = [];
      nextState.passcode = '';
      nextState.membershipCard = '';
      nextState.token = '';
      nextState.external_id = '';
      nextState.my_goals = { garden_id: '', plant_placements: {} };
      nextState.completed_player_challenges = [];
      nextState.planner = DEFAULT_PLANNER_DATA;
      return { ...state, ...nextState };

    case USER_UPDATE_AVATAR:
      nextState.avatar = action.payload;
      return { ...state, ...nextState };

    case USER_UPDATE_PLANNER:
      nextState.planner = action.payload;
      return { ...state, ...nextState };

    case USER_REMOVE_PLANNER:
      // nextState.planner = action.payload;
      return { ...state, ...nextState };

    case USER_SET_USERNAME:
      nextState.username = action.payload;
      return { ...state, ...nextState };

    case USER_REMOVE_USERNAME:
      nextState.username = '';
      return { ...state, ...nextState };

    case UPDATE_COMPLETED_CHALLENGE_LIST:
      nextState.completed_player_challenges = action.payload;
      return { ...state, ...nextState };

    case USER_SET_PASSCODE:
      nextState.passcode = action.payload;
      return { ...state, ...nextState };

    case USER_SET_MEMBERSHIP_CARD:
      nextState.membershipCard = action.payload;
      return { ...state, ...nextState };

    case USER_SET_DATA: {
      const { token, external_id } = action.payload;
      const { name = '', country = '', data = {}, likes = [], completed_challenges = [], cancelled_challenges = [] } = action.payload.player;
      const { avatar: defaultAvatar = 1, stored_data = {} } = data;
      const {
        avatar = defaultAvatar,
        first_time_completed_challenges = [],
        planner = state.planner,
        completed_challenges: completed_player_challenges = [],
        garden_id = '',
        plant_placements = {},
      } = stored_data;

      const currentAvatar = avatar == '' || avatar > 8 ? 1 : avatar;
      nextState.username = name;
      nextState.avatar = currentAvatar;
      nextState.likes = likes;
      nextState.completed_challenges = completed_challenges;
      nextState.cancelled_challenges = cancelled_challenges;
      nextState.country = country;
      nextState.token = token;
      nextState.external_id = external_id;
      nextState.my_goals = { garden_id, plant_placements };
      nextState.first_time_completed_challenges = first_time_completed_challenges;
      nextState.completed_player_challenges = completed_player_challenges;
      nextState.planner = planner;
      return { ...state, ...nextState };
    }

    case USER_SET_LIKE_CHALLENGE: {
      nextState.likes = [...state.likes, action.payload];
      return { ...state, ...nextState };
    }

    case USER_REMOVE_LIKE_CHALLENGE: {
      const newArray = [];
      for (let i = 0; i < state.likes.length; i++) {
        if (state.likes[i] !== action.payload) {
          newArray.push(state.likes[i]);
        }
      }
      nextState.likes = newArray;

      return { ...state, ...nextState };
    }

    case USER_SET_COMPLETED_CHALLENGE: {
      nextState.completed_challenges = [...state.completed_challenges, action.payload];
      return { ...state, ...nextState };
    }

    case USER_REMOVE_COMPLETED_CHALLENGE: {
      const newArray = [];
      for (let i = 0; i < state.completed_challenges.length; i++) {
        if (state.completed_challenges[i] !== action.payload) {
          newArray.push(state.completed_challenges[i]);
        }
      }
      nextState.completed_challenges = newArray;

      return { ...state, ...nextState };
    }

    case USER_SET_CANCELLED_CHALLENGE: {
      nextState.cancelled_challenges = [...state.cancelled_challenges, action.payload];
      return { ...state, ...nextState };
    }

    case USER_REMOVE_CANCELLED_CHALLENGE: {
      const newArray = [];
      for (let i = 0; i < state.cancelled_challenges.length; i++) {
        if (state.cancelled_challenges[i] !== action.payload) {
          newArray.push(state.cancelled_challenges[i]);
        }
      }
      nextState.cancelled_challenges = newArray;

      return { ...state, ...nextState };
    }

    case USER_MY_GOALS_SET_GARDEN_ID:
    case USER_MY_GOALS_SET_PLANT_PLACEMENT: {
      nextState.my_goals = action.payload;
      return { ...state, ...nextState };
    }
    case USER_FIRST_TIME_COMPLETED_CHALLENGE: {
      const newArray = [...state.first_time_completed_challenges];
      newArray.push(action.payload);
      nextState.first_time_completed_challenges = newArray;
      return { ...state, ...nextState };
    }
  }

  return state;
};

export default User;

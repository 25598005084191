//magic things
import React, { Component } from 'react';
import { connect } from 'react-redux';

// import constants
import { getUrlStaticImages } from 'constants/app/AppUrls';

// Import selectors
import { getSiteTextDataByGroupedKey } from 'store/selectors/App.selectors';
import PictureMappingsSingleImage from 'components/picture-mappings/PictureMappingsSingleImage';

const RotateScreen = (props) => {
  const { text = '' } = props;
  return (
    <div className="rotate-screen__container">
      <div className="rotate-screen">
        <div className="rotate-screen__image">
          <PictureMappingsSingleImage src={getUrlStaticImages(`rotate-screen/pls_rotate.png`)} imageAlt={text} />
        </div>
        <div className="rotate-screen__text">{text}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    text: getSiteTextDataByGroupedKey(state, 'my_goals', 'rotate_device_screen'),
  };
};

export default connect(mapStateToProps)(RotateScreen);

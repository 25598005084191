import React, { Component } from 'react';
import Draggable from 'react-draggable';
import { isEmptyObj } from 'utils/general';

// import constants
import { getUrlStaticImages } from 'constants/app/AppUrls';

class MyGoalsPlant extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeDrags: 0,
      deltaPosition: {
        x: 0,
        y: 0,
      },
      placements: {},
      placement: {
        width: 98,
        height: 25,
      },
      controlledPosition: {
        x: 0,
        y: 0,
      },
      zIndex: 1,
    };
    this.imgRef = React.createRef();
    this.placePlant = this.placePlant.bind(this);
  }

  placePlant() {
    const { plant, plantPlacement, updatePlanePlacement, placements } = this.props;
    this.setState({
      placements,
    });

    updatePlanePlacement({ plant_id: plant, placement_id: plantPlacement });
    const { x, y, zIndex } = placements[plantPlacement];

    const placementPos = {
      left: x,
      right: x + this.state.placement.width,
      top: y,
      bottom: y + this.state.placement.height,
    };
    const img = this.imgRef.current;
    const xCenterPoint = placementPos.left + this.state.placement.width / 2 - img.offsetWidth / 2;

    this.setState({
      controlledPosition: { x: xCenterPoint, y: placementPos.top + (this.state.placement.height - 5) - img.offsetHeight },
    });

    this.setState({ zIndex });

    if (__WATCH__) {
      this.setState({
        deltaPosition: { x: xCenterPoint, y: placementPos.top + (this.state.placement.height - 5) - img.offsetHeight },
      });
    }
  }

  onStartDrag = (id) => {
    this.props.callback(id, true);
  };

  onStopDrag = () => {
    this.props.callback(false);
  };

  handleDrag = (e, position) => {
    if (!this.props.hasSeenLevelUp) {
      this.props.userHasSeenLevelUp();
    }

    if (!this.props.isOnboardingCompleted && this.props.onboardingCurrentStep === 2) {
      this.props.onboardingClicked(2);
    }

    const { x, y, node } = position;
    const plantPos = { left: x, right: x + node.offsetWidth, top: y, bottom: y + node.offsetHeight };

    const isWithinBounds = this.checkPlacementBounds({ position, node, plantPos });

    this.onStartDrag(isWithinBounds ? isWithinBounds.id : false);

    if (__WATCH__) {
      const { x, y } = this.state.deltaPosition;

      this.setState({
        deltaPosition: {
          x: x + position.deltaX,
          y: y + position.deltaY,
        },
      });
    }
  };

  checkPlacementBounds({ position, node, plantPos }) {
    for (const [key, value] of Object.entries(this.state.placements)) {
      const placementPos = {
        left: value.x,
        right: value.x + this.state.placement.width,
        top: value.y,
        bottom: value.y + this.state.placement.height,
      };

      const bottom = plantPos.bottom - 10;

      const withinYBounds = bottom >= placementPos.top && bottom <= placementPos.bottom;
      const withinXBounds = position.x + node.offsetWidth / 2 >= placementPos.left && position.x + node.offsetWidth / 2 <= placementPos.right;

      if (withinYBounds && withinXBounds) {
        const xCenterPoint = placementPos.left + this.state.placement.width / 2 - node.offsetWidth / 2;

        return { id: key, x: xCenterPoint, y: placementPos.top + this.state.placement.height - node.offsetHeight };
      }
    }

    return false;
  }

  onControlledDragStop = (e, position) => {
    this.onStopDrag();

    const { x, y, node } = position;
    const plantPos = { left: x, right: x + node.offsetWidth, top: y, bottom: y + node.offsetHeight };

    const isWithinBounds = this.checkPlacementBounds({ position, node, plantPos });

    if (isWithinBounds) {
      this.setState({
        controlledPosition: { x: isWithinBounds.x, y: isWithinBounds.y },
      });

      const { zIndex } = this.state.placements[isWithinBounds.id];

      this.setState({ zIndex });

      this.props.updatePlanePlacement({ plant_id: this.props.plant, placement_id: isWithinBounds.id });
      this.props.setPlantPlacement({ id: this.props.plant, placement_id: isWithinBounds.id });

      if (__WATCH__) {
        this.setState({
          deltaPosition: { x: isWithinBounds.x, y: isWithinBounds.y },
        });
      }
    }
  };

  render() {
    const { plant, plantFrame, currentPlant, hasSeenLevelUp } = this.props;

    return (
      <Draggable position={this.state.controlledPosition} onDrag={this.handleDrag} onStop={this.onControlledDragStop} scale={this.props.scale}>
        <div className={`draggable-plant index-${this.state.zIndex}`} style={__WATCH__ ? { border: '1px red solid' } : {}}>
          {__WATCH__ && debug(this.state, plantFrame)}
          <img
            ref={this.imgRef}
            src={getUrlStaticImages(`my-goals/plants/${plant}/0${plantFrame}.png`)}
            className={currentPlant && !hasSeenLevelUp ? 'pulse' : ''}
            onDragStart={(e) => {
              e.preventDefault();
              return false;
            }}
            onLoad={this.placePlant}
          />
        </div>
      </Draggable>
    );
  }
}

export default MyGoalsPlant;

/*
  -----
  USED FOR DEBUGGING THE PLANT POSITION
  -----
*/
const debug = (state, frame) => {
  const { deltaPosition } = state;

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        textAlign: 'center',
        width: '100%',
        padding: '5px 0',
        backgroundColor: 'rgba(255, 253, 253, 0.6)',
        color: '#000000',
      }}
    >{`x: ${deltaPosition.x}, y: ${deltaPosition.y}, frame: 0${frame}`}</div>
  );
};

import { RECAPTCHA_KEY } from 'constants/app/AppConstants';

export const recaptchaActive = () => {
  return RECAPTCHA_KEY !== '';
};

export const recaptchaAvaiable = () => {
  return window.grecaptcha || false;
};

export const attachRecaptcha = (cb = () => {}) => {
  const recaptchaScript = document.createElement('script');
  recaptchaScript.setAttribute('src', `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`);
  recaptchaScript.id = 'google-recaptcha';
  recaptchaScript.onload = () => {
    cb();
  };
  document.head.appendChild(recaptchaScript);
};

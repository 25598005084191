import React from 'react';

const ChallengeBoxUnticked = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66.77 62.72" className="challenge-box-ticked">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="complete">
          <g id="done">
            <g className="cls-1">
              <path
                className="cls-2"
                d="M51.5 25.09v24.13a12 12 0 01-12 12h-26a12 12 0 01-12-12v-26a12 12 0 0112-12h26a12 12 0 019.14 4.25 4.91 4.91 0 00-3 1.42L25.53 37l-8.18-8.18a5 5 0 00-7 7L22 47.55a5 5 0 007 0z"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
              />
              <path d="M25.77 52a6.07 6.07 0 01-4.26-1.77l-11.9-12a6 6 0 118.51-8.46l7.61 7.67L55.47 6.82a6 6 0 018.61 8.36l-34 35A6.06 6.06 0 0125.8 52z" />
              <path d="M59.77 6a5 5 0 013.59 8.48l-34 35A5 5 0 0125.8 51a5 5 0 01-3.55-1.48l-11.9-12A5 5 0 0113.87 29a4.94 4.94 0 013.54 1.48l8.32 8.38L56.19 7.52A5 5 0 0159.77 6m0-2a6.93 6.93 0 00-5 2.12L25.72 36l-6.89-6.94a7 7 0 00-9.93 9.87l11.9 12a7 7 0 0010 0l34-35A7 7 0 0059.77 4z" />
              <path d="M25.77 50a6.07 6.07 0 01-4.26-1.77l-11.9-12a6 6 0 118.51-8.46l7.61 7.67L55.47 4.82a6 6 0 018.61 8.36l-34 35A6.06 6.06 0 0125.8 50z" />
              <path d="M59.77 4a5 5 0 013.59 8.48l-34 35A5 5 0 0125.8 49a5 5 0 01-3.55-1.48l-11.9-12A5 5 0 0113.87 27a4.94 4.94 0 013.54 1.48l8.32 8.38L56.19 5.52A5 5 0 0159.77 4m0-2a6.93 6.93 0 00-5 2.12L25.72 34l-6.89-6.94a7 7 0 00-9.93 9.87l11.9 12a7 7 0 0010 0l34-35A7 7 0 0059.77 2z" />
            </g>
            <path
              className="cls-3"
              d="M51.5 23.09v24.13a12 12 0 01-12 12h-26a12 12 0 01-12-12v-26a12 12 0 0112-12h26a12 12 0 019.14 4.25 4.91 4.91 0 00-3 1.42L25.53 35l-8.18-8.18a5 5 0 10-7 7L22 45.55a5 5 0 007 0z"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
            />
            <g className="cls-1">
              <path d="M25.77 50a6.07 6.07 0 01-4.26-1.77l-11.9-12a6 6 0 118.51-8.46l7.61 7.67L55.47 4.82a6 6 0 018.61 8.36l-34 35A6.06 6.06 0 0125.8 50z" />
              <path d="M59.77 4a5 5 0 013.59 8.48l-34 35A5 5 0 0125.8 49a5 5 0 01-3.55-1.48l-11.9-12A5 5 0 0113.87 27a4.94 4.94 0 013.54 1.48l8.32 8.38L56.19 5.52A5 5 0 0159.77 4m0-2a6.93 6.93 0 00-5 2.12L25.72 34l-6.89-6.94a7 7 0 00-9.93 9.87l11.9 12a7 7 0 0010 0l34-35A7 7 0 0059.77 2z" />
            </g>
            <path className="cls-4" d="M25.77 48a6.07 6.07 0 01-4.26-1.77l-11.9-12a6 6 0 118.51-8.46l7.61 7.67L55.47 2.82a6 6 0 018.61 8.36l-34 35A6.06 6.06 0 0125.8 48z" />
            <path
              className="cls-5"
              d="M59.77 2a5 5 0 013.59 8.48l-34 35A5 5 0 0125.8 47a5 5 0 01-3.55-1.48l-11.9-12A5 5 0 0113.87 25a4.94 4.94 0 013.54 1.48l8.32 8.38L56.19 3.52A5 5 0 0159.77 2m0-2a6.93 6.93 0 00-5 2.12L25.72 32l-6.89-6.94a7 7 0 00-9.93 9.87l11.9 12a7 7 0 0010 0l34-35A7 7 0 0064.65 2a7 7 0 00-4.88-2z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ChallengeBoxUnticked;

export const getOnboardingMap = (state) => {
  return state.onboarding.map;
};

export const getOnboardingMyGoals = (state) => {
  return state.onboarding.my_goals;
};

export const getOnboardingMyGoalsIntro = (state) => {
  return state.onboarding.my_goals_intro;
};

import { getCurrentActiveState, isRandom, randomlySelectContent, selectNextOrderedContent } from 'store/selectors/HeroSwitch.selectors';
import { triggerInteraction } from 'store/actions/EnsightenTracking.actions';
import { INTERACTION } from 'constants/ensighten-tracking/EnsightenTrackingConstants';
import { PAGE } from 'constants/app/AppConstants';
import { saveHeroSwitchexperience } from 'store/actions/Experience.actions';

export const UPDATE_HERO_SWITCH_ACTIVE_STATE = '[ACTIVE_OMINTRIX] UPDATE_HERO_SWITCH_ACTIVE_STATE';
export const HERO_SWITCH_DEFAULT_ACTIVE_STATE = 'ELLIOTT_HOME';
export const HERO_SWITCH_FIRST_BACKGROUND = 'background_header_id1';

const _updateActiveState = (state, data) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_HERO_SWITCH_ACTIVE_STATE, payload: data });
  };
};

export const clickedHeroSwitch = ({ contentType }) => {
  return (dispatch, getState) => {
    let newData;
    dispatch(triggerInteraction({ interactionType: INTERACTION.CLICK_OMNI, pageType: PAGE.LANDING, contentType }));
    let random = isRandom(getState());
    newData = random === 'true' ? randomlySelectContent(getState()) : selectNextOrderedContent(getState());
    dispatch({ type: UPDATE_HERO_SWITCH_ACTIVE_STATE, payload: newData });
    dispatch(saveHeroSwitchexperience({ contentType }));
  };
};

import React from 'react';

const ChallengesTravelIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.57 36.27" className="challenge-travel-icon">
    <defs>
      <radialGradient id="radial-gradient" cx="-99.67" cy="-246.13" r="5.17" gradientTransform="rotate(-.08 193748.3912 -85865.731)" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#f195be" />
        <stop offset=".52" stopColor="#f194bc" />
        <stop offset=".71" stopColor="#f08eb5" />
        <stop offset=".84" stopColor="#ef85aa" />
        <stop offset=".95" stopColor="#ee7899" />
        <stop offset="1" stopColor="#ed6f8d" />
      </radialGradient>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="challenge_01">
          <path
            className="cls-1"
            d="M12.14 12a12.16 12.16 0 1012.18 12.09A12.17 12.17 0 0012.14 12zm9.13 8.16a.61.61 0 01-.38.28l-.53.16c-1.42.43-5.18 1.56-6.53 2a.67.67 0 01-.58 0 .54.54 0 01-.17-.44v-7.38a.82.82 0 01.18-.63.41.41 0 01.27-.08 1.18 1.18 0 01.28 0 10.18 10.18 0 017.34 5.38.86.86 0 01.12.66zm-10.84-6h.06a.7.7 0 01.55.05.8.8 0 01.18.62v7.27a.53.53 0 01-.17.44.64.64 0 01-.57 0c-1.37-.44-5.14-1.57-6.56-2l-.51-.16a.51.51 0 01-.41-.25.74.74 0 01.08-.6 10.17 10.17 0 017.35-5.41zm-8.3 8.54a.73.73 0 01.24-.46.48.48 0 01.24-.06.82.82 0 01.21 0L8.94 24l1 .31a.53.53 0 01.38.3.64.64 0 01-.12.54c-.9 1.33-3.9 5.64-4.21 6a.6.6 0 01-.47.26.63.63 0 01-.47-.13 10.13 10.13 0 01-3-7.23 10.25 10.25 0 01.08-1.39zm5.26 10.09a.58.58 0 01.1-.47c.32-.43 3.52-5.05 4.16-6 .21-.31.39-.35.51-.35s.3 0 .51.35c1.16 1.66 3.88 5.56 4.18 6a.57.57 0 01.1.47.72.72 0 01-.34.44 10.15 10.15 0 01-4.44 1 10 10 0 01-4.44-1 .72.72 0 01-.34-.44zm11.89-1.46a.66.66 0 01-.48.13.63.63 0 01-.47-.26c-.3-.41-3.32-4.71-4.22-6a.61.61 0 01-.12-.53.53.53 0 01.38-.3l1.05-.37 6.07-1.86h.21a.4.4 0 01.24.07.71.71 0 01.25.45 10.27 10.27 0 01.1 1.47 10 10 0 01-3 7.23z"
          />
          <path
            className="cls-1"
            d="M12.14 12.63a11.48 11.48 0 1011.5 11.46 11.49 11.49 0 00-11.5-11.46zm.63 2.15a1 1 0 01.29-.86.92.92 0 01.79-.11h.06a10.46 10.46 0 017.55 5.54 1.11 1.11 0 01.09.86.87.87 0 01-.57.46l-.53.16c-1.42.43-5.17 1.56-6.53 2a1.64 1.64 0 01-.41.08.72.72 0 01-.44-.14.78.78 0 01-.29-.68zm-9.92 4.61a10.47 10.47 0 017.53-5.57.94.94 0 01.8.11 1.06 1.06 0 01.29.86v7.31a.71.71 0 01-.73.82 1.31 1.31 0 01-.41-.07c-1.36-.44-5.13-1.57-6.54-2l-.52-.15a.83.83 0 01-.57-.46 1 1 0 01.15-.85zm2.71 12.4h-.08a.93.93 0 01-.63-.23 10.3 10.3 0 01-3.13-7.43 10.74 10.74 0 01.1-1.51 1 1 0 01.39-.62.78.78 0 01.69-.07l6.1 1.8 1 .27a.78.78 0 01.57.49.88.88 0 01-.15.81c-.9 1.33-3.9 5.64-4.21 6.06a.94.94 0 01-.65.43zm11.68 1.06a1 1 0 01-.49.64 10.45 10.45 0 01-9.15 0 1 1 0 01-.5-.64.86.86 0 01.15-.72c.32-.43 3.51-5 4.15-6a.85.85 0 011.52 0c.9 1.3 3.87 5.55 4.17 6a.82.82 0 01.15.72zm2.25-1.31a.93.93 0 01-.63.23h-.08a.94.94 0 01-.69-.39c-.31-.41-3.33-4.72-4.23-6.05a.9.9 0 01-.16-.8.85.85 0 01.58-.5l1.05-.32c2.1-.64 5.62-1.73 6.08-1.86a.81.81 0 01.69.07 1 1 0 01.39.67 10.73 10.73 0 01.11 1.51 10.36 10.36 0 01-3.11 7.44z"
          />
          <circle className="cls-1" cx="12.16" cy="24.11" r="1.04" transform="rotate(-89.31 12.158 24.1133)" />
          <path
            className="cls-1"
            d="M50.39 11.9A12.16 12.16 0 1062.57 24a12.17 12.17 0 00-12.18-12.1zm9.13 8.15a.56.56 0 01-.38.29l-.53.16c-1.41.42-5.17 1.56-6.53 2a.67.67 0 01-.58 0 .51.51 0 01-.16-.43v-7.35a.77.77 0 01.18-.62.38.38 0 01.26-.08 2.19 2.19 0 01.28 0h.06a10.17 10.17 0 017.33 5.39.78.78 0 01.07.64zm-10.83-6h.05A1.35 1.35 0 0149 14a.41.41 0 01.27.08.82.82 0 01.18.63v7.32a.56.56 0 01-.16.44.67.67 0 01-.58 0c-1.36-.43-5.13-1.56-6.55-2l-.52-.16a.55.55 0 01-.38-.28.82.82 0 01.08-.61 10.17 10.17 0 017.35-5.35zm-8.31 8.54a.66.66 0 01.25-.46.54.54 0 01.44 0L47.2 24l1 .31a.53.53 0 01.38.3.64.64 0 01-.12.54c-.89 1.32-3.9 5.63-4.2 6.05a.67.67 0 01-.47.26.72.72 0 01-.48-.14 10.2 10.2 0 01-2.93-8.68zm5.27 10.12a.52.52 0 01.1-.46c.32-.43 3.51-5.05 4.15-6 .22-.31.4-.34.51-.34s.3 0 .52.34c.64.92 3.85 5.53 4.17 6a.52.52 0 01.1.46.71.71 0 01-.33.44 10 10 0 01-4.44 1 10.17 10.17 0 01-4.45-1 .77.77 0 01-.33-.42zm11.41-1.32a.6.6 0 01-.47-.26c-.31-.41-3.32-4.71-4.23-6a.67.67 0 01-.12-.54.55.55 0 01.38-.3l1.05-.29 6.07-1.86h.21a.48.48 0 01.24.06.66.66 0 01.25.46 10.1 10.1 0 01-2.91 8.69.63.63 0 01-.47.06z"
          />
          <circle className="cls-2" cx="12.16" cy="24.11" r="1.88" />
          <path className="cls-2" d="M12.16 26.15a2 2 0 112-2 2 2 0 01-2 2zm0-3.77a1.73 1.73 0 101.73 1.73 1.72 1.72 0 00-1.73-1.73z" />
          <path d="M26.92 24.28l-13.45-.74-.58.93-.07.1 14.5.79a.85.85 0 01-.13-.25z" fill="url(#radial-gradient)" />
          <path className="cls-2" d="M21.91 4.38l-.59-1.88-.96.31.6 1.87.95-.3z" />
          <path
            className="cls-4"
            d="M51.18 23.75S47.53 9.58 47.41 9.61l-1.95-7.45 4.69.46a.81.81 0 00.6-.19.87.87 0 00.3-.57.9.9 0 00-.2-.61.9.9 0 00-.57-.25L44.44.4a.84.84 0 00-.87 1l.84 3.35.19.72H22.67l-1.11-3.2a.15.15 0 00-.19-.1l-1.25.39a.14.14 0 00-.09.08.2.2 0 000 .11s.85 2.91.94 2.9l.6 1.89-10.08 16a.85.85 0 00.26 1.15.9.9 0 00.45.13h.13l15.62.85a.88.88 0 00.57-.23l17-16.19 4 14.89a.83.83 0 00.8.63 1.66 1.66 0 00.22 0 .84.84 0 00.64-1.02zm-37.52-.5l8.57-13.64L26.82 24zm14.71.1L23.2 7.2 45 7.17l.07.27z"
          />
          <path
            className="cls-1"
            d="M27.89 29.18h-.41c-2.38-.18-14.25-2.36-15.89-2.86a2.35 2.35 0 01-1.71-2.21 2.23 2.23 0 011.92-2.25c.84-.11 5.6-.48 9.42-.78h.2c2.74-.22 5.3-.42 6-.49H28.51a4.34 4.34 0 013.77 4.82 4.38 4.38 0 01-4.38 3.79z"
          />
          <path
            className="cls-2"
            d="M28 20.73a3.55 3.55 0 01.46 0A4.12 4.12 0 0127.9 29h-.41c-2.37-.22-14.21-2.33-15.84-2.83a2.17 2.17 0 01-1.58-2 2 2 0 011.76-2.06c.81-.1 5.38-.46 9.41-.78 2.82-.22 5.48-.43 6.2-.5a5 5 0 01.58 0m0-.38a5.72 5.72 0 00-.62 0c-.72.07-3.5.29-6.19.5-4.05.32-8.61.68-9.43.79a2.43 2.43 0 00-2.1 2.44 2.55 2.55 0 001.86 2.39c1.64.51 13.54 2.64 15.92 2.85h.43a4.51 4.51 0 00.64-9 4.36 4.36 0 00-.51 0z"
          />
          <path
            className="cls-1"
            d="M25.45 1.59a15 15 0 00-4.8-.51 9.58 9.58 0 01-4.2-.61c-.72-.26-1.06-.09-1.22.1s-.2.82.11 1.39a3 3 0 002.85 1.74 9.55 9.55 0 001.51-.16l.44-.06a27.43 27.43 0 013.76-.15h1.41c.87 0 1.07-.36 1.11-.57.08-.4-.33-.89-.97-1.17z"
          />
          <path
            d="M25.48 1.52A13.71 13.71 0 0021.32 1H19.87a8.7 8.7 0 01-3.39-.61 2 2 0 00-.68-.13h0a.78.78 0 00-.63.26 1.33 1.33 0 00.1 1.48 3.08 3.08 0 002.92 1.78 9.68 9.68 0 001.52-.16l.44-.06a22.27 22.27 0 012.94-.15h2.22c.93 0 1.15-.4 1.19-.63.09-.45-.33-.97-1.02-1.26z"
            className="cls-5"
            strokeMiterlimit="10"
            strokeWidth=".51"
            fill="#fff"
          />
          <path
            className="cls-6"
            d="M27.12 25a.53.53 0 00-.33-.37l-.31-.13-.79-.35a.77.77 0 00-.71 0 .65.65 0 00-.28.54 3.42 3.42 0 00.31 1.6.63.63 0 00.5.41h.09a.76.76 0 00.56-.28l.08-.09.76-.83a.59.59 0 00.12-.5zM26 23.59c.43.19.81.36 1.05.48a.88.88 0 00.32.08.45.45 0 00.23-.06.52.52 0 00.25-.43l.15-1.15a.77.77 0 00-.22-.72.81.81 0 00-.8-.05 3.45 3.45 0 00-1.22.73.69.69 0 00-.2.61.65.65 0 00.44.51zM28.93 24.34h.17l1.14-.21a.69.69 0 00.56-.39.7.7 0 00-.06-.64 3.32 3.32 0 00-1-1 .81.81 0 00-.78-.14.76.76 0 00-.39.64l-.14 1.16a.53.53 0 00.14.47.55.55 0 00.36.11zM30.39 24.7l-1.18.21a.52.52 0 00-.42.28.62.62 0 000 .53l.56 1.07a.8.8 0 00.58.43H30a.67.67 0 00.53-.29 3.29 3.29 0 00.68-1.48.6.6 0 00-.15-.59.73.73 0 00-.67-.16zM28.45 26.31l-.11-.23a.6.6 0 00-.46-.36.58.58 0 00-.53.24l-.17.2c-.15.17-.34.39-.56.62a.79.79 0 00-.22.72.66.66 0 00.48.46 3.62 3.62 0 00.71.17h.72a.66.66 0 00.58-.34.78.78 0 000-.75z"
          />
          <path
            className="cls-1"
            d="M28.44 21.11a3.62 3.62 0 00-.46 0 3.77 3.77 0 00-3 6.1 3.77 3.77 0 006.72-1.87 3.77 3.77 0 00-3.26-4.23zm0 2.63l.14-1.16a.76.76 0 01.39-.64.81.81 0 01.78.14 3.32 3.32 0 011 1 .7.7 0 01.06.64.69.69 0 01-.56.39l-1.14.21h-.17a.55.55 0 01-.37-.13.53.53 0 01-.15-.45zm-2.69-1.27a3.45 3.45 0 011.25-.73.81.81 0 01.8.05.77.77 0 01.22.72l-.14 1.15a.52.52 0 01-.25.43.45.45 0 01-.23.06.88.88 0 01-.32-.08c-.24-.12-.62-.29-1.05-.48a.65.65 0 01-.45-.51.69.69 0 01.15-.61zm.43 3.93a.76.76 0 01-.56.28h-.09a.63.63 0 01-.5-.41 3.42 3.42 0 01-.31-1.6.65.65 0 01.28-.54.77.77 0 01.71 0l.79.35.31.13a.53.53 0 01.33.37.59.59 0 01-.16.52l-.72.81zm2.73 1.4a.66.66 0 01-.58.34h-.71a3.62 3.62 0 01-.71-.17.66.66 0 01-.48-.46.79.79 0 01.22-.72c.22-.23.41-.45.56-.62l.17-.2a.58.58 0 01.53-.24.6.6 0 01.46.36l.11.23.4.74a.78.78 0 01.01.74zm2.33-2.35a3.29 3.29 0 01-.68 1.48.67.67 0 01-.53.29H30a.8.8 0 01-.58-.43l-.56-1.07a.62.62 0 010-.53.52.52 0 01.42-.28l1.18-.21a.73.73 0 01.68.16.6.6 0 01.08.59z"
          />
          <rect className="cls-4" x="41.63" y=".53" width="9.5" height="1.84" rx=".72" transform="matrix(1 .08 -.08 1 .27 -3.73)" />
        </g>
      </g>
    </g>
  </svg>
);

export default ChallengesTravelIcon;

//magic things
import React from 'react';

const NavQuizIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.93 48" className="nav-quiz-icon">
    <g data-name="Layer 2">
      <g data-name="Layer 3">
        <path
          d="M44.89 46.37l-9.25-2.6a22.61 22.61 0 01-4.93 2.68A22.2 22.2 0 0122.5 48h-.88A22.5 22.5 0 1145 25.5a23 23 0 01-.25 3.34 21.79 21.79 0 01-1.67 5.75L47.63 43a2.37 2.37 0 01-2.74 3.37zM19.14 18a3.31 3.31 0 013-1.85 2.75 2.75 0 013 2.88c0 1.27-.66 2-1.68 2.92l-1.36 1.27c-2 1.89-3.2 3.33-3.2 5.05a3.08 3.08 0 003.2 3.21c1.07 0 2.55-.37 3.29-2.67a4.91 4.91 0 012.09-2.76c2.84-2.17 4.6-3.86 4.6-7.47 0-5.71-4.8-8.22-9.69-8.22-3.82 0-7.11 1.56-8.91 4.52a4 4 0 00-.62 2.18 3 3 0 003.16 3.12c1.32 0 2.26-.53 3.12-2.18zm7 18.73a4 4 0 10-4 3.91 3.94 3.94 0 003.99-3.91z"
          className="cls-1"
        />
        <path
          d="M44.89 43.37l-9.25-2.6a22.61 22.61 0 01-4.93 2.68A22.2 22.2 0 0122.5 45h-.88A22.5 22.5 0 1145 22.5a23 23 0 01-.25 3.34 21.79 21.79 0 01-1.67 5.75L47.63 40a2.37 2.37 0 01-2.74 3.37zM19.14 15a3.31 3.31 0 013-1.85 2.75 2.75 0 013 2.88c0 1.27-.66 2-1.68 2.92l-1.36 1.27c-2 1.89-3.2 3.33-3.2 5.05a3.08 3.08 0 003.2 3.21c1.07 0 2.55-.37 3.29-2.67a4.91 4.91 0 012.09-2.76c2.84-2.17 4.6-3.86 4.6-7.47 0-5.71-4.8-8.22-9.69-8.22-3.82 0-7.11 1.56-8.91 4.52a4 4 0 00-.62 2.18 3 3 0 003.16 3.12c1.32 0 2.26-.53 3.12-2.18zm7 18.73a4 4 0 10-4 3.91 3.94 3.94 0 003.99-3.91z"
          className="cls-2"
        />
      </g>
    </g>
  </svg>
);

export default NavQuizIcon;

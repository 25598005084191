import React, { Component } from 'react';
import { connect } from 'react-redux';

// import selectors
import { getSiteTextDataByGroupedKey } from 'store/selectors/App.selectors';

/**
 * Challenges Offline Message Component.
 * Displays Offline Message for Challenges on the page
 *
 */

class ChallengesOfflineMessage extends Component {
  render() {
    const { title = '', description_1 = '', description_2 = '' } = this.props.siteText;
    return (
      <div className="challenges__offline-message">
        <div className="challenges__offline-message__body">
          <div className="title">{title}</div>
          <div className="description_1">{description_1}</div>
          <div className="description_2">{description_2}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    siteText: getSiteTextDataByGroupedKey(state, 'pwa', 'challenges_offline_message'),
  };
};

export default connect(mapStateToProps, null)(ChallengesOfflineMessage);

import { BOOTSTRAP_PAGE_CONTENT } from 'store/actions/Bootstrap.actions';
import {
  CHALLENGE_UPDATE_DROPDOWN_FILTER,
  CHALLENGE_ADD_TYPE_FILTER,
  CHALLENGE_REMOVE_TYPE_FILTER,
  CHALLENGE_REMOVE_ALL_FILTERS,
  CHALLENGE_DISPLAY_MORE,
  CHALLENGE_DISPLAY_RESET,
  CHALLENGE_DISPLAY_UPDATE_COUNT,
} from 'store/actions/Challenges.actions';

import { BOOTSTRAP_APP_DATA } from 'store/actions/Bootstrap.actions';

// Constants
import { CHALLENGE_DISPLAY_LIMIT } from 'constants/challenges/ChallengeItems';

const initState = {
  allIDs: [],
  byID: {},
  byTypeIDs: {},
  dailyByIDs: [],
  defaultByIDs: [],
  activeDropdownFilter: 'all',
  activeTypeFilters: [],
  dropdownFilters: {
    all: '',
    cant_do: '',
    complete: '',
    daily: '',
    popular: '',
    todo: '',
  },
  numberToDisplay: CHALLENGE_DISPLAY_LIMIT,
};

const Challenges = (state = initState, action) => {
  let nextState = {};
  switch (action.type) {
    case BOOTSTRAP_APP_DATA: {
      const { challenges } = action.payload.siteText;
      const filters = { ...state.dropdownFilters };

      if (typeof challenges === 'object') {
        for (const [key, value] of Object.entries(filters)) {
          const filter = challenges[`filter_${key}`];
          if (filter !== '') {
            filters[key] = filter;
          } else {
            delete filters[key];
          }
        }
      }

      nextState.dropdownFilters = filters;
      return { ...state, ...nextState };
    }
    case BOOTSTRAP_PAGE_CONTENT: {
      const { data } = action.payload.challenges;
      const { allIDs, byID, byTypeIDs, dailyByIDs, defaultByIDs } = data;
      nextState.allIDs = allIDs;
      nextState.byID = byID;
      nextState.byTypeIDs = byTypeIDs;
      nextState.dailyByIDs = dailyByIDs;
      nextState.defaultByIDs = defaultByIDs;

      return { ...state, ...nextState };
    }
    case CHALLENGE_UPDATE_DROPDOWN_FILTER: {
      nextState.activeDropdownFilter = action.payload;
      return { ...state, ...nextState };
    }
    case CHALLENGE_ADD_TYPE_FILTER: {
      let newState = [action.payload];

      // If the length is 8, then all filters have been clicked on
      // so reset the array
      if (newState.length === 8) {
        newState = [];
      }
      nextState.activeTypeFilters = newState;
      return { ...state, ...nextState };
    }
    case CHALLENGE_REMOVE_TYPE_FILTER: {
      const newArray = [];
      for (let i = 0; i < state.activeTypeFilters.length; i++) {
        if (state.activeTypeFilters[i] !== action.payload) {
          newArray.push(state.activeTypeFilters[i]);
        }
      }
      nextState.activeTypeFilters = newArray;

      return { ...state, ...nextState };
    }
    case CHALLENGE_REMOVE_ALL_FILTERS: {
      nextState.activeTypeFilters = [];
      nextState.activeDropdownFilter = 'all';
      return { ...state, ...nextState };
    }
    case CHALLENGE_DISPLAY_MORE: {
      const nextCount = state.numberToDisplay + CHALLENGE_DISPLAY_LIMIT;
      nextState.numberToDisplay = Math.min(state.allIDs.length, nextCount);

      return { ...state, ...nextState };
    }
    case CHALLENGE_DISPLAY_RESET: {
      nextState.numberToDisplay = CHALLENGE_DISPLAY_LIMIT;

      return { ...state, ...nextState };
    }
  }
  return state;
};

export default Challenges;

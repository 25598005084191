import { MEMBERSHIP_CARD_WIDTH, MEMBERSHIP_CARD_HEIGHT, MEMBERSHIP_CARD_BG } from 'constants/membership-card/MembershipCardConstants';
import { getUrlStaticImages, getUrlDynamic } from 'constants/app/AppUrls';

/*
  Function to generate the membership card of the logged in user
*/
export const generateMembershipCard = ({ name, passcode, siteText, logo }, callback) => {
  // get the ball rolling
  const imageSources = {
    logo: getUrlDynamic(logo),
    bg: MEMBERSHIP_CARD_BG,
  };

  let images = {};
  let loadedImages = 0;
  let numImages = Object.keys(imageSources).length - 1;
  // get num of sources
  for (const [key, value] of Object.entries(imageSources)) {
    if (value) {
      images[key] = new Image();
      images[key].crossOrigin = 'anonymous';
      images[key].src = value + '?not-cache';
      images[key].onload = () => {
        if (loadedImages >= numImages) {
          generateCard({ name, passcode, siteText }, images, callback);
        } else {
          loadedImages = loadedImages + 1;
        }
      };
    }
  }
};

export const generateCard = ({ name, passcode, siteText }, images, callback) => {
  const { membership = '', card = '', pin = '' } = siteText;
  /*
      Setup the canvas element
    */
  const canvas = document.createElement('canvas');
  const dpr = window.devicePixelRatio || 1;
  // Get the size of the canvas in CSS pixels.
  // const rect = canvas.getBoundingClientRect();
  // Give the canvas pixel dimensions of their CSS
  // size * the device pixel ratio.
  canvas.width = MEMBERSHIP_CARD_WIDTH * dpr;
  canvas.height = MEMBERSHIP_CARD_HEIGHT * dpr;
  const context = canvas.getContext('2d');
  // Scale all drawing operations by the dpr, so you
  // don't have to worry about the difference.
  context.scale(dpr, dpr);

  // Add canvas to the DOM to draw on
  canvas.display = 'none';
  document.body.appendChild(canvas);

  /*
      Draw elements
    */
  // add image
  context.drawImage(images.bg, 0, 0);

  // add logo
  context.drawImage(images.logo, 0, 20);

  const rtl = document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl';
  const lang = document.getElementsByTagName('html')[0].getAttribute('lang') === 'en';
  const fr = document.getElementsByTagName('html')[0].getAttribute('lang') === 'fr';
  const pl = document.getElementsByTagName('html')[0].getAttribute('lang') === 'pl';
  const ru = document.getElementsByTagName('html')[0].getAttribute('lang') === 'ru';

  const defaultX = 220;

  // add text
  context.font = '28px Lubalin Graph ITC Turner Latin';
  context.fillStyle = 'white';
  context.fillText(membership, rtl ? 330 : defaultX, 40);

  if (pl || ru) {
    context.font = '28px Lubalin Graph ITC Turner Latin';
    context.fillText(card, rtl ? 330 : defaultX, 75);
  } else {
    context.font = '38px Lubalin Graph ITC Turner Latin';
    context.fillText(card, rtl ? 330 : defaultX, 75);
  }

  context.font = 'bold 20px avenir-rounded';
  context.fillStyle = 'black';
  context.fillText(name, rtl ? 330 : defaultX, 127);

  if (lang) {
    context.font = 'bold 18px avenir-rounded';
    context.fillText(pin, rtl ? 330 : defaultX, 180);
  } else if (fr) {
    context.font = '14px Lubalin Graph ITC Turner Latin';
    context.fillText(pin, rtl ? 330 : defaultX, 180);
  } else {
    context.font = '18px Lubalin Graph ITC Turner Latin';
    context.fillText(pin, rtl ? 330 : defaultX, 180);
  }

  context.font = '38px Lubalin Graph ITC Turner Latin';
  context.fillText(passcode, rtl ? 330 : defaultX, 210);

  /*
      trigger callback to set base64 to state
    */
  if (callback) {
    callback(canvas.toDataURL());
  }

  /*
      Remove canvas when done 
    */
  if (canvas.parentNode) {
    canvas.parentNode.removeChild(canvas);
  }
};

import React from 'react';
import { connect } from 'react-redux';
import Swiper from 'react-id-swiper';
import { isRTL } from 'utils/general';

// import Selectors
import { getVideoPageItemByID } from 'store/selectors/Videopage.selectors';

// Import Components
import ContentItemDefault from 'components/content-grid/content-items/existing-items/ContentItemDefault';

const VideoPlaylistCarousel = (props) => {
  let params = {
    slidesPerView: 3,
    spaceBetween: 25,
    loop: false,
    lazy: false,
    breakpoints: {
      768: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },
    navigation: {
      nextEl: '.video-playlist__carousel__button.button-next',
      prevEl: '.video-playlist__carousel__button.button-prev',
    },
  };

  if (isRTL()) {
    params.rtl = true;
  }

  const { videos } = props;

  return (
    <div className="video-playlist__carousel">
      <Swiper {...params}>
        {videos.map((video, index) => {
          return (
            <div key={index} className="swiper-slide">
              <ContentItemDefault itemSize={'small'} playlistReferal={props.playlistID} {...video} />
            </div>
          );
        })}
      </Swiper>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const { data } = props;

  const videos = props.data.map((id) => {
    return getVideoPageItemByID(state, id);
  });

  return {
    videos: videos,
  };
};

export default connect(mapStateToProps)(VideoPlaylistCarousel);

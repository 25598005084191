import { BOOTSTRAP_PAGE_CONTENT } from 'store/actions/Bootstrap.actions';
import { VIDEO_INVALIDATE, VIDEO_RECEIVE, VIDEO_REQUEST, VIDEO_RESET } from 'store/actions/Video.actions';

const initState = {
  isFetching: false,
  didInvalidate: false,
  allIDs: [],
  byID: {},
  marqueData: {},
  avsPlayURLpattern: '',
  avsShowName: '',
  videoData: false,
};

const Videopage = (state = initState, action) => {
  let nextState = {};
  switch (action.type) {
    case BOOTSTRAP_PAGE_CONTENT: {
      const { data, promoData, avsPlayURLpattern = '', avsShowName = '' } = action.payload.videoPage;
      const { allIDs, byID } = data;
      nextState.allIDs = allIDs;
      nextState.byID = byID;
      nextState.promoData = promoData;
      nextState.avsShowName = avsShowName;
      nextState.avsPlayURLpattern = avsPlayURLpattern;
      return { ...state, ...nextState };
    }

    case VIDEO_RESET:
      nextState.isFetching = false;
      nextState.didInvalidate = true;
      nextState.videoData = false;
      return { ...state, ...nextState };

    case VIDEO_INVALIDATE:
      nextState.isFetching = false;
      nextState.didInvalidate = true;
      nextState.videoData = { id: '', src: '' };
      return { ...state, ...nextState };

    case VIDEO_REQUEST:
      nextState.isFetching = true;
      nextState.didInvalidate = false;
      nextState.videoData = false; // reset this when retrieving a video
      return { ...state, ...nextState };

    case VIDEO_RECEIVE: {
      const { id, src, width, height, title } = action.payload;
      nextState.isFetching = false;
      nextState.didInvalidate = false;
      if (state.videoData) {
        const copy = { ...state.videoData };
        copy[id];
        copy[id] = { src, title };
        nextState.videoData = copy;
      } else {
        const copy = {};
        copy[id];
        copy[id] = { src, title };
        nextState.videoData = copy;
      }

      return { ...state, ...nextState };
    }
  }
  return state;
};

export default Videopage;

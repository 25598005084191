import { LIKES_INVALIDATE, LIKES_RECEIVE, LIKES_REQUEST, UNLIKE_INVALIDATE, UNLIKE_RECEIVE, UNLIKE_REQUEST } from 'store/actions/Likes.actions';

const initState = {
  isFetching: false,
  didInvalidate: false,
  likeCount: 0,
  likeCountByCategory: { 1: false, 2: false, 3: false, 4: false, 5: false, 6: false },
  byID: {},
  allIds: [],
};

const reducer = (state = initState, action) => {
  let nextState = {};
  switch (action.type) {
    case LIKES_INVALIDATE:
      nextState.isFetching = false;
      nextState.didInvalidate = true;
      return { ...state, ...nextState };

    case LIKES_REQUEST:
      nextState.isFetching = true;
      nextState.didInvalidate = false;
      return { ...state, ...nextState };

    case LIKES_RECEIVE: {
      const { entryID, emojiID, uuid } = action.payload;
      nextState.isFetching = false;
      nextState.didInvalidate = false;
      nextState.byID = { ...state.byID };
      nextState.allIds = [...state.allIds];
      if (nextState.allIds.indexOf(entryID) === -1) {
        nextState.allIds.push(entryID);
        nextState.byID[entryID] = {};
        nextState.byID[entryID][emojiID] = uuid;
        nextState.likeCount = state.likeCount + 1;
        nextState.likeCountByCategory = { ...state.likeCountByCategory };
        nextState.likeCountByCategory[emojiID] = true;
      } else {
        nextState.byID[entryID] = { ...state.byID[entryID] };
        nextState.byID[entryID][emojiID] = uuid;
        nextState.likeCount = emojiID in state.byID[entryID] ? state.likeCount : state.likeCount + 1;
        nextState.likeCountByCategory = { ...state.likeCountByCategory };
        nextState.likeCountByCategory[emojiID] = true;
      }
      return { ...state, ...nextState };
    }
    case UNLIKE_INVALIDATE:
      nextState.isFetching = false;
      nextState.didInvalidate = true;
      return { ...state, ...nextState };

    case UNLIKE_REQUEST:
      nextState.isFetching = true;
      nextState.didInvalidate = false;
      return { ...state, ...nextState };

    case UNLIKE_RECEIVE: {
      const { entryID, emojiID } = action.payload;
      nextState.isFetching = false;
      nextState.didInvalidate = false;
      nextState.byID = { ...state.byID };
      nextState.allIds = [...state.allIds];
      nextState.byID[entryID] = { ...state.byID[entryID] };
      nextState.byID[entryID][emojiID] = false;
      return { ...state, ...nextState };
    }
  }
  return state;
};

export default reducer;

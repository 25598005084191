import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmptyObj } from 'utils/general';

// import Constants
import { getUrlStaticImages } from 'constants/app/AppUrls';
import { STATE_INTRO, STATE_CHOOSE, STATE_GARDEN, CHANGE_GARDEN } from 'constants/my-goals/MyGoalsConstants';

// Import Actions
import { triggerContentClick, triggerInteraction } from 'store/actions/EnsightenTracking.actions';
import { setUserGardenID, setUserPlantPlacement } from 'store/actions/User.actions';
import { userHasSeenLevelUp } from 'store/actions/UserLevels.actions';
import { setOnboardingMyGoals, setOnboardingMyGoalsIntro } from 'store/actions/Onboarding.actions';

// import selectors
import { getSiteTextDataByGroup } from 'store/selectors/App.selectors';
import { getTotalLevelsCompleted, getHasSeenLevelUp } from 'store/selectors/UserLevels.selectors';
import { getMyGoalsData, getNumberOfCompletedChallenges } from 'store/selectors/User.selectors';
import { getOnboardingMyGoals, getOnboardingMyGoalsIntro } from 'store/selectors/Onboarding.selectors';

// import components
import MyGoalsFrame from 'components/my-goals/MyGoalsFrame';
import MyGoalsIntro from 'components/my-goals/MyGoalsIntro';
import MyGoalsChoose from 'components/my-goals/MyGoalsChoose';
import MyGoalsGarden from 'components/my-goals/MyGoalsGarden';
import RotateScreenContainer from 'components/rotate-screen/RotateScreenContainer';

class MyGoals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      myGoalsStep: STATE_INTRO,
      selectedGarden: 'garden_1',
      sizing: {
        height: '100%',
        width: '100%',
      },
    };
    this.updateCurrentStep = this.updateCurrentStep.bind(this);
    this.updateCurrentGarden = this.updateCurrentGarden.bind(this);
    this.setPlantPlacement = this.setPlantPlacement.bind(this);
    this.setOnboarding = this.setOnboarding.bind(this);
    this.setOnboardingIntro = this.setOnboardingIntro.bind(this);
  }

  componentDidMount() {
    const { isMobile, myGoalsData } = this.props;

    if (!isEmptyObj(myGoalsData) && myGoalsData.garden_id !== '') {
      this.setState({ selectedGarden: myGoalsData.garden_id });
    }

    if (isMobile) {
      if (window.screen.width > window.screen.height) {
        // Landscape
        this.setState({ sizing: { height: `${window.screen.height}px`, width: `100%` } });
      } else {
        // Portrait
        this.setState({ sizing: { height: `${window.screen.width}px`, width: `100%` } });
      }
    }
  }

  setOnboarding(step) {
    this.props.setOnboardingMyGoals({ step });
  }

  setOnboardingIntro(step) {
    this.props.setOnboardingMyGoalsIntro({ step });
  }

  setPlantPlacement({ id, placement_id }) {
    this.props.setUserPlantPlacement({ id, placement_id });
  }

  updateCurrentStep(step) {
    this.setState({ myGoalsStep: step });
  }

  updateCurrentGarden(id) {
    this.setState({ selectedGarden: id });
    this.props.setUserGardenID({ id });
  }

  renderStep() {
    const { localisedText, totalLevelsCompleted, myGoalsData = {}, challengeCompletedCount = 0, hasSeenLevelUp, userHasSeenLevelUp, onboarding, onboardingIntro } = this.props;
    const { top_tip_title = '', tip_1_description = '', tip_2_description = '' } = localisedText;
    const { confirm_button = '', choose_garden_title = '' } = localisedText;
    const { selectedGarden } = this.state;

    switch (this.state.myGoalsStep) {
      case CHANGE_GARDEN:
        return <MyGoalsChoose nextStep={STATE_GARDEN} stepCallback={this.updateCurrentStep} gardenCallback={this.updateCurrentGarden} text={{ confirm_button, choose_garden_title }} />;
      case STATE_GARDEN:
        return (
          <MyGoalsGarden
            selectedGarden={selectedGarden}
            totalLevelsCompleted={totalLevelsCompleted}
            perviousStep={STATE_CHOOSE}
            stepCallback={this.updateCurrentStep}
            setPlantPlacement={this.setPlantPlacement}
            userData={myGoalsData}
            onboardingText={{ top_tip_title, tip_1_description, tip_2_description }}
            challengeCompletedCount={challengeCompletedCount}
            hasSeenLevelUp={hasSeenLevelUp}
            userHasSeenLevelUp={userHasSeenLevelUp}
            onboarding={onboarding}
            setOnboarding={this.setOnboarding}
          />
        );

      case STATE_CHOOSE: {
        if (!isEmptyObj(myGoalsData) && myGoalsData.garden_id !== '') {
          return (
            <MyGoalsGarden
              selectedGarden={selectedGarden}
              totalLevelsCompleted={totalLevelsCompleted}
              perviousStep={STATE_CHOOSE}
              stepCallback={this.updateCurrentStep}
              setPlantPlacement={this.setPlantPlacement}
              userData={myGoalsData}
              onboardingText={{ top_tip_title, tip_1_description, tip_2_description }}
              challengeCompletedCount={challengeCompletedCount}
              hasSeenLevelUp={hasSeenLevelUp}
              userHasSeenLevelUp={userHasSeenLevelUp}
              onboarding={onboarding}
              setOnboarding={this.setOnboarding}
            />
          );
        }

        return <MyGoalsChoose nextStep={STATE_GARDEN} stepCallback={this.updateCurrentStep} gardenCallback={this.updateCurrentGarden} text={{ confirm_button, choose_garden_title }} />;
      }

      default:
        const {
          welcome_text = '',
          goals_title = '',
          step_1_text = '',
          step_1_title = '',
          step_2_text = '',
          step_2_title = '',
          step_3_text = '',
          step_3_title = '',
          lets_go_button = '',
        } = localisedText;
        return (
          <MyGoalsIntro
            nextStep={STATE_CHOOSE}
            callback={this.updateCurrentStep}
            text={{ welcome_text, goals_title, step_1_text, step_1_title, step_2_text, step_2_title, step_3_text, step_3_title, lets_go_button }}
            onboardingIntro={onboardingIntro}
            setOnboarding={this.setOnboardingIntro}
          />
        );
    }
  }

  render() {
    const { localisedText, isMobile, isFullscreen, toggleFullscreenClass } = this.props;
    const { rotate_device_screen = '' } = localisedText;

    if (isMobile) {
      return (
        <RotateScreenContainer isMobile={isMobile}>
          <div style={{ height: this.state.sizing.height, width: this.state.sizing.width }}>{this.renderStep()}</div>
        </RotateScreenContainer>
      );
    } else {
      return (
        <MyGoalsFrame text={rotate_device_screen} isMobile={isMobile}>
          {this.renderStep()}
        </MyGoalsFrame>
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    triggerContentClick: (opts) => {
      dispatch(triggerContentClick(opts));
    },
    trackInteraction: (opts) => {
      dispatch(triggerInteraction(opts));
    },
    setUserGardenID: ({ id }) => {
      dispatch(setUserGardenID({ id }));
    },
    setUserPlantPlacement: ({ id, placement_id }) => {
      dispatch(setUserPlantPlacement({ id, placement_id }));
    },
    userHasSeenLevelUp: () => {
      dispatch(userHasSeenLevelUp());
    },
    setOnboardingMyGoals: ({ step }) => {
      dispatch(setOnboardingMyGoals({ step }));
    },
    setOnboardingMyGoalsIntro: ({ step }) => {
      dispatch(setOnboardingMyGoalsIntro({ step }));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    localisedText: getSiteTextDataByGroup(state, 'my_goals'),
    totalLevelsCompleted: getTotalLevelsCompleted(state),
    hasSeenLevelUp: getHasSeenLevelUp(state),
    myGoalsData: getMyGoalsData(state),
    challengeCompletedCount: getNumberOfCompletedChallenges(state),
    onboarding: getOnboardingMyGoals(state),
    onboardingIntro: getOnboardingMyGoalsIntro(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyGoals);

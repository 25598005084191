import React from 'react';

const ChallengesWaterIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.71 23.55" className="challenge-water-icon">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="challenge_01">
          <path
            className="cls-1"
            d="M.85.85c2.75 0 2.75 2.49 5.5 2.49S9.1.85 11.85.85s2.75 2.49 5.5 2.49S20.1.85 22.85.85s2.75 2.49 5.5 2.49S31.1.85 33.86.85s2.75 2.49 5.5 2.49S42.11.85 44.86.85M.85 7.31c2.75 0 2.75 2.48 5.5 2.48s2.75-2.48 5.5-2.48 2.75 2.48 5.5 2.48 2.75-2.48 5.5-2.48 2.75 2.48 5.5 2.48 2.75-2.48 5.51-2.48 2.75 2.48 5.5 2.48 2.75-2.48 5.5-2.48M.85 13.76c2.75 0 2.75 2.49 5.5 2.49s2.75-2.49 5.5-2.49 2.75 2.49 5.5 2.49 2.75-2.49 5.5-2.49 2.75 2.49 5.5 2.49 2.75-2.49 5.51-2.49 2.75 2.49 5.5 2.49 2.75-2.49 5.5-2.49M.85 20.22c2.75 0 2.75 2.48 5.5 2.48s2.75-2.48 5.5-2.48 2.75 2.48 5.5 2.48 2.75-2.48 5.5-2.48 2.75 2.48 5.5 2.48 2.75-2.48 5.51-2.48 2.75 2.48 5.5 2.48 2.75-2.48 5.5-2.48"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ChallengesWaterIcon;

import React, { useState } from 'react';

const Register = ({ setModalType, callback, text, errorMessage, errorText }) => {
  const maxLength = 10;
  const [count, setCount] = useState(maxLength);
  const [name, updateName] = useState('');
  const [enterUsername, updateEnterUsername] = useState(false);

  const handleOnChange = (e) => {
    if (e.target.value.length > maxLength) {
      e.target.value = e.target.value.slice(0, maxLength);
      return;
    }

    setCount(maxLength - e.target.value.length);
    updateName(e.target.value);

    if (enterUsername && e.target.value.length > 0) {
      updateEnterUsername(false);
    }
  };

  const checkName = (e) => {
    if (name !== '') {
      updateEnterUsername(false);
      callback(name);
    } else {
      updateEnterUsername(true);
    }
  };

  const { chars_remaining = '', register_submit_button = '', register_title = '', register_username = '' } = text;

  return (
    <>
      <div className="authModal__header">{register_title}</div>
      <div className="authModal__register-prompt flex justify-content-center align-items-start">
        <div>
          <input className="authModal_input" placeholder={register_username} onChange={handleOnChange} />
          <p className="authModal_input__count">
            {chars_remaining}: {count}/{maxLength}
          </p>
        </div>
        <button className="authModal__button authModal_button__register" aria-label="Register" onClick={checkName}>
          {register_submit_button}
        </button>
      </div>
      {enterUsername && <p className="authModal__error-message">{errorText}</p>}
      {errorMessage !== '' && <p className="authModal__error-message">{errorMessage}</p>}
    </>
  );
};

export default Register;

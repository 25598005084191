import React, { Component } from 'react';

// Import Components
import ChallengesArrowDown from 'components/inline-svgs/challenges/ChallengesArrowDown';

/**
 * Challenge Dropdown Component.
 */

class ChallengeDropdown extends Component {
  constructor() {
    super();

    this.state = {
      showList: false,
    };

    this.showOptionList = this.showOptionList.bind(this);
    this.clickHanlder = this.clickHanlder.bind(this);
  }

  clickHanlder(e) {
    const activeDropdownType = e.currentTarget.getAttribute('data-id');
    this.props.callback(activeDropdownType);

    this.showOptionList();
  }

  showOptionList() {
    this.setState({ showList: !this.state.showList });
  }

  renderOptions(data) {
    const { activeDropdownType, dailyChallengesCount, cancelledChallengesCount, completedChallengesCount } = this.props;

    const list = [];
    for (const [key, value] of Object.entries(data)) {
      if (key === 'cant_do') {
        if (cancelledChallengesCount > 0) {
          list.push(
            <span className={key === activeDropdownType ? 'selected' : ''} onClick={this.clickHanlder} key={key} data-id={key}>
              {value}
            </span>
          );
        }
      } else if (key === 'daily') {
        if (dailyChallengesCount > 0) {
          list.push(
            <span className={key === activeDropdownType ? 'selected' : ''} onClick={this.clickHanlder} key={key} data-id={key}>
              {value}
            </span>
          );
        }
      } else if (key === 'complete') {
        if (completedChallengesCount > 0) {
          list.push(
            <span className={key === activeDropdownType ? 'selected' : ''} onClick={this.clickHanlder} key={key} data-id={key}>
              {value}
            </span>
          );
        }
      } else {
        list.push(
          <span className={key === activeDropdownType ? 'selected' : ''} onClick={this.clickHanlder} key={key} data-id={key}>
            {value}
          </span>
        );
      }
    }
    return list;
  }

  render() {
    const { activeDropdownType, filters } = this.props;
    const active = filters[activeDropdownType];

    return (
      <section className={'challenge-dropdown__container ' + (this.state.showList ? 'challenge-dropdown__container--open' : 'challenge-dropdown__container--close')}>
        <div className="challenge-dropdown__header" onClick={this.showOptionList}>
          <div className="challenge-dropdown__title">{active}</div>
          <div className="challenge-dropdown__arrow">
            <ChallengesArrowDown />
          </div>
        </div>
        <div className="challenge-dropdown__list">{this.renderOptions(filters)}</div>
      </section>
    );
  }
}

export default ChallengeDropdown;

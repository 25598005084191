//magic things
import React from 'react';

const NavSchoolsIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57.55 46" className="nav-schools-icon">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="header">
          <g id="nav_cap">
            <g id="grad_icon">
              <path
                className="cls-1"
                d="M57.55 15.59A3.79 3.79 0 0156 18.8l-9.8 5.72-10.72 6.26A12.87 12.87 0 0131 33.16a3.81 3.81 0 01-.59.05 7.89 7.89 0 01-3.8-1.36L12.27 24.6l-4-2s-4-1.93-3.92-2c-2.04-.6-2.21-3.11-2.21-3.11-.36-2 2.61-3.68 2.61-3.68L26 1.47A8.4 8.4 0 0128.84.05a3.29 3.29 0 01.54 0 3.66 3.66 0 011.83.52l23.49 11.7s2.85 1.07 2.85 3.32z"
              />
              <path className="cls-1" d="M26.58 31.85L12.27 24.6v-.08h33.94l-10.73 6.26A12.87 12.87 0 0131 33.16a3.81 3.81 0 01-.59.05 7.89 7.89 0 01-3.83-1.36z" />
              <path
                className="cls-1"
                d="M47.39 26.7v7.24c-.15.25-.32.49-.49.73-3.46 4.8-9.81 8-17.07 8s-13.61-3.22-17.07-8c-.17-.24-.34-.48-.49-.72v-6.58L25.33 34a10 10 0 005.05 1.69 5.69 5.69 0 001-.09 14.27 14.27 0 005.48-2.76zM7.22 35.57c1.33 3.48 1.63 9.66-3 10.36-5.07.76-5.07-4.67-2.58-6.85a4.66 4.66 0 001.51-3.29 3.2 3.2 0 01.63-5.51V16.6h2.54v13.68a3.2 3.2 0 01.9 5.29z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default NavSchoolsIcon;

//magic things
import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
//import selectors
import { getComingSoonData } from 'store/selectors/ComingSoonpage.selectors';
import { getHeaderData } from 'store/selectors/App.selectors';
import { getUrlDynamic } from 'constants/app/AppUrls';
import { PAGES } from 'constants/content-library/ContentLibraryConstants';
import { LANGUAGE_CODE } from 'constants/app/AppConstants';

import { getImageAltDataByGroupedKey } from 'store/selectors/App.selectors';
const BitmovinPlayer = React.lazy(() => import(/* webpackChunkName: 'bmplayer' */ 'components/media-container/BitmovinPlayer'));
import { loadVideo, resetVideo } from 'store/actions/Video.actions';

import PictureMappingsSingleImage from 'components/picture-mappings/PictureMappingsSingleImage';
import { getUrlStatic } from 'constants/app/AppUrls';

import { COMING_SOON_PAGE_VIEW_TEMPLATE, COMING_SOON_INTERACTION_TEMPLATE } from 'constants/ensighten-tracking/EnsightenTrackingConstants';
import { TRACKING_ARRAY } from 'constants/ensighten-tracking/EnsightenTrackingConstants';
import { activeTracking } from 'store/selectors/EnsightenTracking.selectors';

import { getSiteMetaData } from 'store/selectors/App.selectors';
import SiteMetaData from 'components/generic/SiteMetaData';

class PageComingSoon extends Component {
  constructor(props) {
    super(props);

    this.state = { showOverlay: false };
    this.bindTriggerOverlay = this.triggerOverlay.bind(this);
    this.bindHideOverlay = this.hideOverlay.bind(this);
  }

  isOTbannerVisible() {
    const otBanner = document.getElementById('optanon-popup-bg');
    if (otBanner && window.getComputedStyle(otBanner, null).getPropertyValue('display') == 'block') {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    if (document.getElementsByTagName('body')[0].classList.contains('coming-soon')) {
      document.getElementsByTagName('body')[0].classList.remove('coming-soon');
    }
  }

  componentDidMount() {
    document.getElementsByTagName('body')[0].classList.add('coming-soon');
    if (this.isOTbannerVisible()) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    }

    if (!this.props.activeTracking) {
      console.warn('ensighten tracking disabled');
    } else {
      TRACKING_ARRAY.push({ type: 'pageview', data: COMING_SOON_PAGE_VIEW_TEMPLATE });
    }
  }

  triggerOverlay() {
    // if (!this.props.activeTracking) {
    //   console.warn('ensighten tracking disabled');
    // } else {
    //   TRACKING_ARRAY.push({ type: 'interaction', data: COMING_SOON_INTERACTION_TEMPLATE });
    // }

    document.getElementsByTagName('body')[0].style.overflow = 'hidden';

    const { avs_video_id = '' } = this.props.pageData;

    this.props.resetVideo();
    this.props.loadVideo({ id: avs_video_id, interactive: true });

    window.scrollTo(0, 0);
    this.setState({
      showOverlay: true,
    });
  }

  hideOverlay() {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';

    this.setState({
      showOverlay: false,
    });
  }

  render() {
    const { pageData, headerData } = this.props;
    const { header_logo = '' } = headerData;
    const { header_title = '', header_subtitle = '', body_copy = '', video = false, avs_video_id = '', video_cta = '' } = pageData;
    const { headerAlt = '' } = this.props;
    // <PictureMappingsSingleImage src={getUrlStatic(`images/coming-soon/header/coming-soon-header-${LANGUAGE_CODE}.jpg`)} imageAlt={headerAlt} />

    return (
      <React.Fragment>
        {this.state.showOverlay && (
          <div ref={this.myRef} className="coming-soon-page__overlay">
            <div className="coming-soon-page__overlay__close-button">
              <button onClick={this.bindHideOverlay} aria-label={'close'}>
                {'X'}
              </button>
            </div>
            <Suspense fallback={<div>Loading...</div>}>
              <BitmovinPlayer contentType={'video'} entryID={0} avs_id={avs_video_id} thumbs={''} experienceRewardPercentage={''} onRewardExperience={''} standalone={true} />
            </Suspense>
          </div>
        )}
        <main role="main" className="coming-soon-bg">
          <div id="coming-soon" className="coming-soon-page">
            <section className="upper-section">
              <figure>
                <picture>
                  <img src={getUrlStatic(`images/coming-soon/header/coming-soon-header-${LANGUAGE_CODE}.jpg`)} alt={headerAlt} />
                </picture>
              </figure>
            </section>

            <section className="coming-soon-page__container">
              <div className="coming-soon-page__container__bg">
                <div className="container">
                  <h1 className={'coming-soon-page__title' + (header_subtitle === '' ? ' coming-soon-page__title--widthout-sub-header' : '')} data-text={header_title}>
                    {header_title}
                  </h1>

                  {header_subtitle !== '' && (
                    <h2 className="coming-soon-page__subtitle" data-text={header_subtitle}>
                      {header_subtitle}
                    </h2>
                  )}

                  <div className="coming-soon-page__body" dangerouslySetInnerHTML={{ __html: body_copy }} />
                </div>
              </div>
            </section>

            <section className="lower-section">
              <div className=" container">
                {video === '1' && (
                  <button className="coming-soon-page__cta" aria-label={video_cta} onClick={this.bindTriggerOverlay}>
                    {video_cta}
                  </button>
                )}
              </div>
            </section>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    metadata: getSiteMetaData(state, PAGES.COMING_SOON),
    headerAlt: getImageAltDataByGroupedKey(state, 'coming_soon_alt', 'coming_soon_header_alt'),
    pageData: getComingSoonData(state),
    headerData: getHeaderData(state),
    activeTracking: activeTracking(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadVideo: (opts) => {
      dispatch(loadVideo(opts));
    },
    resetVideo: (opts) => {
      dispatch(resetVideo(opts));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageComingSoon);

import React, { Component } from 'react';
import { getUrlDynamic, getUrlStaticImages } from 'constants/app/AppUrls';
import { WEBPSUPPORT } from 'constants/app/AppConstants';
import { LazyImage } from 'react-lazy-images';
import { convert_to_webp } from 'constants/app/AppServices';

import PictureMappingsNoImage from 'components/picture-mappings/PictureMappingsNoImage';

class PictureMappings extends Component {
  createImageSrcSet(src) {
    const { sizes = '100vw' } = this.props;
    let regExCheck = new RegExp('.gif$', 'i');
    let fileExt = regExCheck.test(src[0].file_name);

    // Use modrenizr to check browser for WEBP
    let webpFormat;
    let otherFormat;

    if (typeof src === 'string') {
      if (WEBPSUPPORT != false && !fileExt) {
        webpFormat = `${getUrlDynamic(convert_to_webp(src))} 100w`;
      }

      otherFormat = `${getUrlDynamic(src)} 100w`;

      return (
        <React.Fragment>
          {WEBPSUPPORT != false && !fileExt && <source type="image/webp" srcSet={Array.isArray(webpFormat) ? webpFormat.reverse().toString() : webpFormat.toString()} sizes={sizes} />}
          <source srcSet={otherFormat} sizes={sizes} />
        </React.Fragment>
      );
    }

    if (WEBPSUPPORT != false && !fileExt) {
      webpFormat = src.map((image, key) => {
        return `${getUrlDynamic(convert_to_webp(image.file_name))} ${image.width}w`;
      });
    }

    otherFormat = src.map((image, key) => {
      return `${getUrlDynamic(image.file_name)} ${image.width}w`;
    });

    return (
      <React.Fragment>
        {WEBPSUPPORT != false && !fileExt && <source type="image/webp" srcSet={webpFormat.reverse().toString()} sizes={sizes} />}
        <source srcSet={otherFormat.reverse().toString()} sizes={sizes} />
      </React.Fragment>
    );
  }

  render() {
    const { src = {}, imageAlt = '', imageTitle = '', enableLazyLoad = true } = this.props;

    if (src != null && Object.keys(src).length !== 0) {
      if (enableLazyLoad) {
        return (
          <LazyImage
            placeholder={({ ref }) => (
              <div ref={ref}>
                <img src={getUrlStaticImages('lazyload/lazyload-placeholder.png')} className="image bg-gray" alt={imageAlt} title={imageTitle} />
              </div>
            )}
            actual={() => (
              <picture>
                {this.createImageSrcSet(src)}
                {typeof src === 'object' ? <img src={getUrlDynamic(src[0].file_name)} alt={imageAlt} title={imageTitle} /> : <img src={getUrlDynamic(src)} alt={imageAlt} title={imageTitle} />}
              </picture>
            )}
            error={() => <span>This should not appear</span>}
            loading={() => <span>This should not appear</span>}
            debounceDurationMs={1000 + Math.random() * 300}
          />
        );
      } else {
        return (
          <picture>
            {this.createImageSrcSet(src)}
            {typeof src === 'object' ? <img src={getUrlDynamic(src[0].file_name)} alt={imageAlt} title={imageTitle} /> : <img src={getUrlDynamic(src)} alt={imageAlt} title={imageTitle} />}
          </picture>
        );
      }
    } else {
      return <PictureMappingsNoImage imageAlt={imageAlt} imageTitle={imageTitle} />;
    }
  }
}

export default PictureMappings;

export const USER_SET_AUTH = 'USER_SET_AUTH';
export const USER_REMOVE_AUTH = 'USER_REMOVE_AUTH';
export const USER_SET_AUTH_CREDENTIALS = 'USER_SET_AUTH_CREDENTIALS';

export const setAuth = ({ external_id, token }) => ({
  type: USER_SET_AUTH,
  payload: { external_id, token },
});

export const setAuthCredentials = ({ name, passcode }) => ({
  type: USER_SET_AUTH_CREDENTIALS,
  payload: { name, passcode },
});

export const removeAuth = () => ({
  type: USER_REMOVE_AUTH,
});

import { parseContentData } from 'store/actions/parsers/ContentDataParser';

export const parseCustomPagesData = (contentData, key) => {
  const content = contentData[key] || {};
  const allIDs = [];
  const byID = {};
  for (const item of content) {
    const { is_enabled, id } = item;
    const isEnabled = is_enabled === '1';
    if (isEnabled) {
      const parsed = parseContentData(item);
      allIDs.push(id);
      byID[id] = { ...parsed };
    }
  }
  return { data: { allIDs, byID } };
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import EnsightenAd from 'components/ads/EnsightenAd';
import { getAdData, getAdsSlug, getAdTrigger } from 'store/selectors/Ads.selectors';
import { getAdvertisingCookiesEnabled } from 'store/selectors/OneTrust.selectors';

import { dispatchEnsightenPageLoad } from 'constants/app/AppServices';

class Ad extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.gridCount > 0) {
      const { adTrigger } = this.props;
      dispatchEnsightenPageLoad(adTrigger);
    }
  }

  render() {
    const { adData, format, adSlug, adsActive } = this.props;
    const advertising_slug = adSlug;

    return adData ? (
      <div className="ad_inner">
        {adsActive && <EnsightenAd type={format} div_id={adData} />}
        <div className="ad_label">{advertising_slug}</div>
      </div>
    ) : null;
  }
}

function mapStateToProps(state, ownprops) {
  const { format, page, gridCount } = ownprops;
  const id = gridCount;

  return {
    adData: getAdData(state, format, page, id),
    adSlug: getAdsSlug(state),
    adTrigger: getAdTrigger(state, 'default'),
    adsActive: getAdvertisingCookiesEnabled(state),
  };
}

export default connect(mapStateToProps, null)(Ad);

import React from 'react';
import { connect } from 'react-redux';
import Swiper from 'react-id-swiper';
import { isRTL } from 'utils/general';
import { Link } from 'react-router-dom';
import { LazyImage } from 'react-lazy-images';

// import constants
import { getUrlDynamic } from 'constants/app/AppUrls';

// Import Components
import { getManifestoData, getSiteTextDataByGroupedKey, getSiteUrlByName } from 'store/selectors/App.selectors';
import { getSchoolsPageManifesto } from 'store/selectors/Schoolspage.selectors';
import PictureMappings from 'components/picture-mappings/PictureMappings';

// tracking
import { PAGES, CONTENT } from 'constants/content-library/ContentLibraryConstants';
import { INTERACTION } from 'constants/ensighten-tracking/EnsightenTrackingConstants';
import { triggerInteraction } from 'store/actions/EnsightenTracking.actions';

const ManifestoCarousel = (props) => {
  let params = {
    slidesPerView: 1,
    loop: false,
    autoplay: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
  };

  const handleTrackClick = (opts) => {
    const { type } = opts;

    const title = type === 'climate_action' ? 'school quiz' : type === 'kahoot' ? 'lesson plan' : '';

    if (title !== '') {
      props.trackInteraction({ interactionType: INTERACTION.CLICK_SCHOOLS_LINK, contentType: CONTENT.SCHOOLS, title });
    }
  };

  // check if the site is RTL
  if (isRTL()) {
    params.rtl = true;
  }
  if (props.data.length > 0) {
    return (
      <div className="manifesto manifesto--carousel">
        <div className={`manifesto__grid manifesto__grid--${props.type}`}>
          <Swiper {...params}>
            {props.data.map((slide, index) => {
              const { image = '', link_type = '', track_page_link = '', target = '', url = '', download_file = '' } = slide;

              if (link_type !== 'no_link') {
                if (link_type === 'external' && track_page_link !== 'none') {
                  return (
                    <a href={url} target={target} key={index} rel="noreferrer" aria-label="carousel image link" onClick={() => handleTrackClick({ type: track_page_link })}>
                      <div className="swiper-slide">
                        <PictureMappings src={image} sizes={'small'} imageAlt={''} imageTitle={''} />
                      </div>
                    </a>
                  );
                }

                if (link_type === 'internal' && target !== '_blank') {
                  return (
                    <Link to={url} key={index}>
                      <div className="swiper-slide">
                        <PictureMappings src={image} sizes={'small'} imageAlt={''} imageTitle={''} />
                      </div>
                    </Link>
                  );
                }
                if (link_type === 'download') {
                  return (
                    <a href={getUrlDynamic(download_file)} target="_blank" key={index} rel="noreferrer" aria-label="download">
                      <div className="swiper-slide">
                        <PictureMappings src={image} sizes={'small'} imageAlt={''} imageTitle={''} />
                      </div>
                    </a>
                  );
                }
                return (
                  <a href={url} target={target} key={index} rel="noreferrer" aria-label="carousel image link">
                    <div className="swiper-slide">
                      <PictureMappings src={image} sizes={'small'} imageAlt={''} imageTitle={''} />
                    </div>
                  </a>
                );
              }

              return (
                <div key={index} className="swiper-slide">
                  <PictureMappings src={image} sizes={'small'} imageAlt={''} imageTitle={''} />
                </div>
              );
            })}
          </Swiper>
        </div>
      </div>
    );
  } else {
    return '';
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackInteraction: (opts) => {
      dispatch(triggerInteraction(opts));
    },
  };
};

const mapStateToProps = (state, props) => {
  const { genreTags = '', characterTags = '', genre = '', type = '', id = '', noContent, pageType } = props;

  const data = pageType === 'schools' ? getSchoolsPageManifesto(state) : getManifestoData(state);
  const returnableData = [];
  if (!Array.isArray(data) && data.images && data.images.length > 0) {
    data.images.map((slide, index) => {
      const image = { ...slide };
      if (image.link_type === 'internal') {
        image.url = getSiteUrlByName(state, image.page);
      }
      returnableData.push(image);
    });
  }

  return {
    data: returnableData,
    tag_title: getSiteTextDataByGroupedKey(state, 'tagged_content', 'title'),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManifestoCarousel);

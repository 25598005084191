//magic things
import React from 'react';

const NavOtherIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54" className="nav-other-icon">
    <g className="cls-1">
      <path d="M27.3 5.5c-12.7 0-23.1 9-23.1 20 0 5.7 2.7 10.8 7.1 14.4L6.9 51.1l12.2-6.9c2.6.8 5.3 1.3 8.2 1.3 12.7 0 23.1-9 23.1-20 0-11.1-10.4-20-23.1-20z" fill="#020203" />
      <path d="M27.3 5.5c-12.7 0-23.1 9-23.1 20 0 5.7 2.7 10.8 7.1 14.4L6.9 51.1l12.2-6.9c2.6.8 5.3 1.3 8.2 1.3 12.7 0 23.1-9 23.1-20 0-11.1-10.4-20-23.1-20z" fill="#020203" />
    </g>
    <path className="cls-2" d="M27.3 2.5c-12.7 0-23.1 9-23.1 20 0 5.7 2.7 10.8 7.1 14.4L6.9 48.1l12.2-6.9c2.6.8 5.3 1.3 8.2 1.3 12.7 0 23.1-9 23.1-20 0-11.1-10.4-20-23.1-20z" fill="#b0ca0a" />
    <path className="cls-2" d="M27.3 2.5c-12.7 0-23.1 9-23.1 20 0 5.7 2.7 10.8 7.1 14.4L6.9 48.1l12.2-6.9c2.6.8 5.3 1.3 8.2 1.3 12.7 0 23.1-9 23.1-20 0-11.1-10.4-20-23.1-20z" fill="#b0ca0a" />
  </svg>
);

export default NavOtherIcon;

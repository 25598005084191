import { getUrlDynamic } from 'constants/app/AppUrls';
import { DATE_TODAY, DATE_TOMORROW } from 'constants/app/AppConstants';

let dailyCount = 0;
const maxDailyCount = 3;

const parseChallengeData = (challenge) => {
  const { data, _localisation, ...other } = challenge;
  const { download_file = '', has_download, image, require_supervision, download_url = '', download_type, super_challenge = '0' } = data;

  const { name, text, download_type: localiseDownloadType, download_url: localiseDownloadUrl, override_download, download_file: localiseDownloadFile, image: localiseImage = '' } = _localisation;

  const baseChallengeImage = localiseImage !== '' ? localiseImage : image;

  const hasDownload = has_download === '1';

  let download_content = {};

  if (hasDownload) {
    download_content = {
      download_location: download_type === 'url' ? download_url : getUrlDynamic(download_file),
      type: download_type,
    };

    if (override_download === '1') {
      download_content = {
        download_location: localiseDownloadType === 'url' ? localiseDownloadUrl : getUrlDynamic(localiseDownloadFile),
        type: localiseDownloadType,
      };
    }
  }

  // Logic to figure out the challenge is a 'daily' challenge
  const { published, featured } = other;
  let daily = published * 1000 >= DATE_TODAY && published * 1000 <= DATE_TOMORROW;

  if (!daily) {
    daily = featured * 1000 >= DATE_TODAY && featured * 1000 <= DATE_TOMORROW;
  }

  if (daily) {
    dailyCount = dailyCount + 1;
  }

  const challengeImage = daily ? `${baseChallengeImage}?daily=true` : baseChallengeImage;

  const parsedChallenge = {
    ...other,
    supervision: require_supervision === '1',
    super_challenge: super_challenge === '1',
    details: {
      title: name,
      description: text,
    },
    content: {
      image: challengeImage,
      has_download: hasDownload,
      download_content,
    },
    daily,
  };

  return parsedChallenge;
};

export const parseChallenges = (contentData, key) => {
  const content = contentData[key] || {};
  const allIDs = [];
  const byID = {};
  const defaultByIDs = [];
  const byTypeIDs = {
    type1: [],
    type2: [],
    type3: [],
    type4: [],
    type5: [],
    type6: [],
    type7: [],
    type8: [],
    type9: [],
  };
  const dailyByIDs = [];

  for (const challenge of content) {
    const { id } = challenge;
    const parsed = parseChallengeData(challenge);
    if (byTypeIDs[parsed.type]) {
      allIDs.push(id);
      byID[id] = { ...parsed };
      byTypeIDs[parsed.type].push(id);
    }

    if (parsed.daily) {
      dailyByIDs.push(id);
    }
  }

  // We are checking if:
  // 1. the 'daily' count is greater than 1
  // 2. the 'daily' count is less than the max display count of 3
  // If both are true - then we add random challenges to make it up to the max count of 3
  // Else we leave it alone
  let clonedIDs = [...allIDs];
  let i = 0;
  if (dailyCount > 0 && dailyCount < 3) {
    const remainingCount = maxDailyCount - dailyCount;
    for (i; i < remainingCount; ++i) {
      const id = clonedIDs[Math.floor(Math.random() * clonedIDs.length)];
      const index = clonedIDs.indexOf(id);

      if (index !== -1) {
        clonedIDs.splice(index, 1);
      }

      dailyByIDs.push(id);

      byID[id].content.image = `${byID[id].content.image}?daily=true`;
    }
  }

  // Randomly select 3 challenge to display as a fallback
  i = 0;
  for (i; i < maxDailyCount; ++i) {
    const id = clonedIDs[Math.floor(Math.random() * clonedIDs.length)];
    const index = clonedIDs.indexOf(id);

    if (index !== -1) {
      clonedIDs.splice(index, 1);
    }

    defaultByIDs.push(id);
  }

  return { data: { allIDs, byID, byTypeIDs, dailyByIDs, defaultByIDs } };
};

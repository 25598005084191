//magic things
import React, { Component } from 'react';
import { Route, Switch, withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { HelmetProvider } from 'react-helmet-async';

//import action
import { updateActivePage } from 'store/actions/App.actions';

// Import constants
import { PAGES } from 'constants/content-library/ContentLibraryConstants';
import { URL_GLOBAL_GOALS_PAGE, URL_CHALLENGES_PAGE, URL_MY_GOALS_PAGE } from 'constants/app/AppUrls';
import { PAGE } from 'constants/app/AppConstants';

// Import services
import { dispatchEnsightenPageLoad } from 'constants/app/AppServices';

// import pages
import PageHome from 'containers/pages/PageHome';
import PageNotFound from 'containers/pages/PageNotFound';
import PageMyGoals from 'containers/pages/PageMyGoals';
import PageFaq from 'containers/pages/PageFaq';
import PageSchools from 'containers/pages/PageSchools';
import PageComingSoon from 'containers/pages/PageComingSoon';
import PageCustom from 'containers/pages/PageCustom';

// Import Nested Routers
import VideosRouter from 'containers/router/VideosRouter';
// import QuizzesRouter from 'containers/router/QuizzesRouter';
import ChallengesRouter from 'containers/router/ChallengesRouter';

class AppRouter extends Component {
  constructor(props) {
    super(props);
    this.customRoutes = [];
    this.props.customNavFriendlyURLs.map((path, index) => {
      this.customRoutes.push({ key: `custom_${index}`, path: `/${path}`, component: PageCustom });
    });
    this.trackActivePageData = this.trackActivePageData.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location.pathname && this.props.location.pathname !== nextProps.location.pathname) {
      console.log(this.props.location.pathname, nextProps.location.pathname);

      this.trackActivePageData(nextProps.location.pathname, this.props.location.pathname);

      // window.scrollTo(0, 0);
    }

    return true;
  }

  trackActivePageData(nextPage, prevPage) {
    console.log('++++---- trackActivePageData');

    let perviousPage = '';
    let perviousPageSubpage = '';
    if (prevPage) {
      perviousPage = prevPage.split('/')[1];
      perviousPageSubpage = prevPage.split('/')[2] ? PAGE.SUB : PAGE.LANDING;
    }

    const page = nextPage.split('/')[1];
    const subpage = nextPage.split('/')[2] ? PAGE.SUB : PAGE.LANDING;
    this.props.updateActivePage({ perviousPage, perviousPageSubpage, page, subpage });
  }

  componentDidMount() {
    const { adTrigger } = this.props;
    dispatchEnsightenPageLoad(adTrigger);
    this.trackActivePageData(this.props.location.pathname, '');
  }

  componentDidUpdate(prevProps) {
    // if (this.props.location.pathname !== prevProps.location.pathname) {
    //   this.trackActivePageData(prevProps.location.pathname);
    //
    //   window.scrollTo(0, 0);
    // }
  }

  render() {
    const { adTrigger, siteUrls } = this.props;
    return (
      <HelmetProvider>
        <React.Fragment>
          <Switch location={this.props.location}>
            <Route exact path={'/'} render={(props) => <PageHome {...props} adTrigger={adTrigger} />} />
            <Route path={`/coming-soon`} render={(props) => <PageComingSoon />} />
            <Route path={`/${siteUrls.my_goals}`} component={PageMyGoals} />
            <Route path={`/${siteUrls.faq}`} component={PageFaq} />
            <Route path={`/${siteUrls.schools}`} component={PageSchools} />
            <Route path={`/${siteUrls.challenges}`} render={(props) => <ChallengesRouter {...props} adTrigger={adTrigger} />} />
            <Route path={`/${siteUrls.videos}`} render={(props) => <VideosRouter {...props} adTrigger={adTrigger} />} />
            {this.customRoutes.map((route) => (
              <Route key={route.key} path={route.path} component={route.component} />
            ))}
            <Route component={PageNotFound} />
          </Switch>
        </React.Fragment>
      </HelmetProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateActivePage: ({ perviousPage, perviousPageSubpage, page, subpage }) => {
      dispatch(updateActivePage({ perviousPage, perviousPageSubpage, page, subpage }));
    },
  };
};
export default withRouter(connect(null, mapDispatchToProps)(AppRouter));

import React from 'react';

const ChallengesFaunaIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.29 35.08" className="challenge-fauna-icon">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="challenge_01">
          <path
            className="cls-1"
            d="M22.56 26.53a62.93 62.93 0 00-2.18-7.73c-.6-1.92-1.16-4.08-3.17-5s-4.55.16-6.6 1.27L5 18.08a11.17 11.17 0 00-2.67 1.77c-2.55 2.48-1.85 7.84 1.71 9 2 .67 4.13 0 6.16.61 2.85.9 3.4 4.35 6 5.31 3.43 1.25 6.26-2.17 6.48-5.25a12.45 12.45 0 00-.12-2.99zM33.79 17a2.58 2.58 0 00-2.61-1.06 5.8 5.8 0 00-3.65 1.84 9.39 9.39 0 00-2 3.29 5.82 5.82 0 00-.42 3c.44 2.62 3.59 3.09 5.64 2.07 2.37-1.23 4.55-6.81 3.04-9.14zM4 13.81a4.79 4.79 0 004.72-3C10.42 7.24 10 3 4.89 4.22a6.62 6.62 0 00-3.27 2C.29 7.6-.49 9.77.35 11.53A4.38 4.38 0 004 13.81zM28.76 12.76A8.37 8.37 0 0031 7.63c.1-2.38-2.18-3.63-4.35-2.79a7.67 7.67 0 00-4.72 5.59 4.11 4.11 0 00.07 2.28c.89 2.32 3.61 2.37 5.45 1.15a7.71 7.71 0 001.31-1.1zM12.66 8.18a4 4 0 00.77 1 3.55 3.55 0 004.79 0 12.38 12.38 0 002.38-4.05 4.7 4.7 0 00.29-3.29c-.94-2.76-4.55-2-6.26-.56A6.76 6.76 0 0012.22 5a4.8 4.8 0 00.44 3.18z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ChallengesFaunaIcon;

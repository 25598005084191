import React, { useState } from 'react';
import * as DeviceDetection from 'utils/deviceDetection';

// import constants
import { getUrlFrontendImage } from 'constants/app/AppUrls';
import { USER_AVATARS } from 'constants/user/UserConstants';

// import components
import NavLoginIcon from 'components/inline-svgs/navigation/NavLoginIcon';
import ChallengeDownloadIcon from 'components/inline-svgs/challenges/ChallengeDownloadIcon';

const YourAccount = ({ setModalType, yourAccountTitle, yourAccountCard, yourAccountCardButton, yourAccountAvatar, yourAccountAvatarButton, membershipCard }) => {
  const downloadCard = () => {
    if (DeviceDetection.isiOS() && DeviceDetection.iOSversion()[0] < 13) {
      const image = new Image();
      image.src = membershipCard;
      const w = window.open('');
      w.document.write(image.outerHTML);
    } else {
      download(membershipCard, 'membership-card.png', 'image/png');
    }
  };

  return (
    <div className="authModal--account">
      <div className="authModal__header">{yourAccountTitle}</div>
      <p className="authModal__center">{yourAccountCard}</p>
      <button className="authModal__button authModal__button_download" aria-label={yourAccountCardButton} onClick={downloadCard}>
        <div className="icon">
          <ChallengeDownloadIcon />
        </div>{' '}
        {yourAccountCardButton}
      </button>
      <p className="authModal__center">{yourAccountAvatar}</p>
      <button
        className="authModal__button authModal__button_welcome authModal_button__login"
        aria-label={yourAccountAvatarButton}
        onClick={() => {
          setModalType('avatar');
        }}
      >
        <NavLoginIcon /> {yourAccountAvatarButton}
      </button>
    </div>
  );
};

export default YourAccount;

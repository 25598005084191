// import constants
import * as AppConstants from 'constants/app/AppConstants';
import * as AppServices from 'constants/app/AppServices';
import * as AppUrls from 'constants/app/AppUrls';

import { recaptchaActive, recaptchaAvaiable, attachRecaptcha } from 'utils/recaptcha';

// import services
import { loginPlayer, createNewPlayer, setPlayerName, getPlayer } from 'services/apiPlayer';

// import actions
import { setUserData, setUsername, setUserPasscode, removeUsername, setAnonUserData } from 'store/actions/User.actions';
import { setAuth } from 'store/actions/UserAuth.actions';
import * as LoginActions from 'store/actions/Login.actions';

// import selectors
import { getUsername, setUser } from 'store/selectors/User.selectors';

import { INTERACTION } from 'constants/ensighten-tracking/EnsightenTrackingConstants';
import { triggerInteraction } from 'store/actions/EnsightenTracking.actions';

const loginUserFlow =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    switch (action.type) {
      // user login has been requested, ping the api and get results
      case LoginActions.LOGIN_REQUESTED:
        loginUser({ ...action.payload, state: getState() }, dispatch);
        break;
      case LoginActions.ANON_LOGIN_REQUESTED: {
        const { external_id, token } = action.payload;
        getUserData({ external_id, token }, dispatch);
        break;
      }
      case LoginActions.ANON_SIGN_UP_REQUESTED:
        anonRecaptchaCheck({ state: getState(), callback: action.payload }, dispatch);
        break;
      case LoginActions.SIGN_UP_REQUESTED:
        // temp store username for later
        dispatch(setUsername(action.payload));
        recaptchaCheck({ state: getState() }, dispatch);
        break;
      case LoginActions.SETUP_NAME_REQUESTED: {
        const { external_id, token, name } = action.payload;
        dispatch(setUsername(action.payload.name));
        requestNameSetup({ external_id, token, state: getState() }, dispatch);
        break;
      }
    }
  };

const anonRecaptchaCheck = ({ state, callback }, dispatch) => {
  if (recaptchaActive()) {
    if (recaptchaAvaiable()) {
      grecaptcha.ready(function () {
        grecaptcha
          .execute(AppConstants.RECAPTCHA_KEY, { action: 'submit' })
          .then(function (token) {
            anonRequestSignUp({ token, callback, state }, dispatch);
          })
          .catch((err) => {
            console.log('problem with recaptcha', { err });
          });
      });
    } else {
      attachRecaptcha(() => {
        grecaptcha.ready(function () {
          grecaptcha
            .execute(AppConstants.RECAPTCHA_KEY, { action: 'submit' })
            .then(function (token) {
              anonRequestSignUp({ token, callback, state }, dispatch);
            })
            .catch((err) => {
              console.log('problem with recaptcha', { err });
              anonRequestSignUp({ token: '', callback, state }, dispatch);
            });
        });
      });
    }
  } else {
    anonRequestSignUp({ token: '', callback, state }, dispatch);
  }
};

const anonRequestSignUp = ({ token, callback, state }, dispatch) => {
  dispatch(LoginActions.setLoginStatus({ status: LoginActions.STATE_PENDING }));
  createNewPlayer({ token })
    .then((result) => {
      if (result.result === 'SUCCESS') {
        const { external_id, token } = result;
        console.log('callback', callback);
        dispatch(setAuth({ external_id, token }));
        dispatch(LoginActions.setLoginStatus({ status: LoginActions.STATE_ANON_LOGGED_IN }));
        dispatch(callback());
      } else {
        dispatch(
          LoginActions.setLoginError({
            error: result.reason,
          })
        );
      }
    })
    .catch((err) => {
      dispatch(LoginActions.setLoginStatus({ status: LoginActions.STATE_IDLE }));
      dispatch(
        LoginActions.setLoginError({
          error: err.reason,
        })
      );
    });
};

const recaptchaCheck = ({ state }, dispatch) => {
  if (recaptchaActive()) {
    if (recaptchaAvaiable()) {
      grecaptcha.ready(function () {
        grecaptcha
          .execute(AppConstants.RECAPTCHA_KEY, { action: 'submit' })
          .then(function (token) {
            requestSignUp({ token, state }, dispatch);
          })
          .catch((err) => {
            console.log('problem with recaptcha', { err });
          });
      });
    } else {
      attachRecaptcha(() => {
        grecaptcha.ready(function () {
          grecaptcha
            .execute(AppConstants.RECAPTCHA_KEY, { action: 'submit' })
            .then(function (token) {
              requestSignUp({ token, state }, dispatch);
            })
            .catch((err) => {
              console.log('problem with recaptcha', { err });
              requestSignUp({ token, state }, dispatch);
            });
        });
      });
    }
  } else {
    requestSignUp({ token: '', state }, dispatch);
  }
};

const requestSignUp = ({ token, state }, dispatch) => {
  dispatch(LoginActions.setLoginStatus({ status: LoginActions.STATE_PENDING }));
  createNewPlayer({ token })
    .then((result) => {
      if (result.result === 'SUCCESS') {
        const { external_id, token } = result;

        requestNameSetup({ external_id, token, state }, dispatch);
      } else {
        dispatch(removeUsername());
        dispatch(
          LoginActions.setLoginError({
            error: result.reason,
          })
        );
      }
    })
    .catch((err) => {
      dispatch(LoginActions.setLoginStatus({ status: LoginActions.STATE_IDLE }));
      dispatch(
        LoginActions.setLoginError({
          error: err.reason,
        })
      );
    });
};

const requestNameSetup = ({ external_id, token, state }, dispatch) => {
  const name = getUsername(state);
  setPlayerName({ external_id, token, name })
    .then((result) => {
      if (result.result === 'SUCCESS') {
        const { password } = result;
        dispatch(LoginActions.setLoginType('signup'));
        dispatch(triggerInteraction({ interactionType: INTERACTION.SUCCESSFUL_SIGN_UP }));
        loginUser({ name: '', password, state }, dispatch);
      } else {
        dispatch(
          LoginActions.setLoginError({
            error: result.reason,
          })
        );
      }
    })
    .catch((err) => {
      dispatch(removeUsername());
      dispatch(LoginActions.setLoginStatus({ status: LoginActions.STATE_IDLE }));
      dispatch(
        LoginActions.setLoginError({
          error: err.reason,
        })
      );
    });
};

const loginUser = ({ name, password, state }, dispatch) => {
  let username = name;
  if (username == '') {
    username = getUsername(state);
  } else {
    dispatch(LoginActions.setLoginStatus({ status: LoginActions.STATE_PENDING }));
  }
  // ping the api to loging
  loginPlayer({ name: username, password })
    .then((result) => {
      // if we land in here, then login should have been a success
      // normalise user data from cms into app logic
      if (result.result === 'SUCCESS') {
        // parse data
        const { player, token, external_id } = result;

        // dispatch "set user" action to finalise store
        dispatch(setUserPasscode(password));
        dispatch(setUserData({ player, token, external_id }));
        dispatch(triggerInteraction({ interactionType: INTERACTION.SUCCESSFUL_LOGIN }));
        dispatch(LoginActions.setLoginStatus({ status: LoginActions.STATE_LOGGED_IN }));
      } else {
        dispatch(LoginActions.setLoginStatus({ status: LoginActions.STATE_IDLE }));
        dispatch(
          LoginActions.setLoginError({
            error: result.reason,
          })
        );
      }
    })
    .catch((err) => {
      console.log('err.reason', err.reason);
      // well.. that went a little tits up - we need to somehow represent this to the end user
      dispatch(LoginActions.setLoginStatus({ status: LoginActions.STATE_IDLE }));
      dispatch(
        LoginActions.setLoginError({
          error: err.reason,
        })
      );
    });
};

const getUserData = ({ external_id, token }, dispatch) => {
  dispatch(LoginActions.setLoginStatus({ status: LoginActions.STATE_PENDING }));

  // ping the api to loging
  getPlayer({ external_id, token })
    .then((result) => {
      // if we land in here, then login should have been a success
      // normalise user data from cms into app logic
      if (result.result === 'SUCCESS') {
        console.log('result', result);

        // parse data
        const { player } = result;

        // dispatch "set user" action to finalise store
        dispatch(setAnonUserData({ player }));
        dispatch(setAuth({ external_id, token }));

        dispatch(LoginActions.setLoginStatus({ status: LoginActions.STATE_ANON_LOGGED_IN }));
      }
    })
    .catch((err) => {
      // well.. that went a little tits up - we need to somehow represent this to the end user
      dispatch(LoginActions.setLoginStatus({ status: LoginActions.STATE_IDLE }));
      dispatch(
        LoginActions.setLoginError({
          error: err.reason,
        })
      );
    });
};

export default [loginUserFlow];

export const getEntryEmojiUUID = (state, entryID, emojiID) => {
  // returns emoji uuid from entryID and emojiID
  const entry = state.likes.byID[entryID] || {};
  return entry[emojiID] || null;
};

export const getEntryIsLiked = (state, entryID, emojiID) => {
  const entry = state.likes.byID[entryID] || {};
  return entry[emojiID] ? true : false;
};

// Get number of unique likes
export const getLikeCount = (state) => {
  return state.likes.likeCount || 0;
};

export const getAllCategoriesLiked = (state) => {
  return Object.keys(state.likes.likeCountByCategory).reduce((acc, id) => {
    acc = !state.likes.likeCountByCategory[id] ? false : acc;
    return acc;
  }, true);
};

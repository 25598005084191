import React from 'react';
import { connect } from 'react-redux';

// import Selectors
import { getVideoPageItemByID } from 'store/selectors/Videopage.selectors';

// Import Components
import ContentItemDefault from 'components/content-grid/content-items/existing-items/ContentItemDefault';

const VideoPlaylistGrid = (props) => {
  const { videos } = props;

  return (
    <ul className="video-playlist__grid">
      {videos.map((video, index) => {
        return (
          <li key={index}>
            <ContentItemDefault itemSize={'small'} playlistReferal={props.playlistID} {...video} />
          </li>
        );
      })}
    </ul>
  );
};

const mapStateToProps = (state, props) => {
  const { data } = props;

  const videos = props.data.map((id) => {
    return getVideoPageItemByID(state, id);
  });

  return {
    videos: videos,
  };
};

export default connect(mapStateToProps)(VideoPlaylistGrid);

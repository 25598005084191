// import constants
import { getContentLink } from 'constants/app/AppUrls';

// Schools Page Based Selectors
import { getVideoIDByFriendlyURL, getVideoPageItemByID } from 'store/selectors/Videopage.selectors';

// import selectors
import { getChallengesItemByID } from 'store/selectors/Challenges.selectors';

export const getSchoolsPageManifesto = (state) => {
  return state.schoolspage.manifesto;
};

export const getSchoolsPageResources = (state) => {
  const returnableData = [];

  state.schoolspage.resources.map((item, index) => {
    // resource_content_type types:
    // existing
    // external
    // internal

    const { data, name } = item;
    const { resource_content_type, content } = data;

    switch (resource_content_type) {
      case 'existing':
        const { resource_type, video = '', challenge = '' } = content;
        if (resource_type === 'video') {
          const vidData = { ...getVideoPageItemByID(state, video) };
          vidData.title = name;
          vidData.content_type = resource_type;
          returnableData.push(vidData);
        } else {
          const challengeData = { ...getChallengesItemByID(state, challenge) };
          challengeData.title = name;
          challengeData.content_type = 'challenge';
          returnableData.push(challengeData);
        }
        break;

      case 'external':
        const externalData = { ...content };
        externalData.title = name;
        externalData.content_type = resource_content_type;
        returnableData.push(externalData);
        break;

      case 'internal':
        const internalData = { ...content };
        internalData.title = name;
        internalData.content_type = resource_content_type;
        returnableData.push(internalData);
        break;
      default:
    }
  });

  return returnableData;
};

export const getSchoolsPageSuperChallenges = (state) => {
  const challenges = state.schoolspage.super_challenges;
  const challengesArray = [];

  challenges.map((challengeItem, index) => {
    const { challenge } = challengeItem;
    const { id } = challenge;

    const returnedChallenge = getChallengesItemByID(state, id);

    if (returnedChallenge) {
      challengesArray.push(returnedChallenge);
    }
  });

  return challengesArray;
};

//magic things
import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

// Import Content SVGs
import NavChallengesIcon from 'components/inline-svgs/navigation/NavChallengesIcon';
import NavVideoIcon from 'components/inline-svgs/navigation/NavVideoIcon';
import NavGlobalGoalsIcon from 'components/inline-svgs/navigation/NavGlobalGoalsIcon';
import NavMapIcon from 'components/inline-svgs/navigation/NavMapIcon';
import NavDownloadIcon from 'components/inline-svgs/navigation/NavDownloadIcon';
import NavQuizIcon from 'components/inline-svgs/navigation/NavQuizIcon';
import NavOtherIcon from 'components/inline-svgs/navigation/NavOtherIcon';
import NavMyGoalsIcon from 'components/inline-svgs/navigation/NavMyGoalsIcon';
import NavLangToggleIcon from 'components/inline-svgs/navigation/NavLangToggleIcon';
import NavSchoolsIcon from 'components/inline-svgs/navigation/NavSchoolsIcon';
import { NAV_TYPE } from 'constants/app/AppConstants';

import AppExitSiteLink from 'containers/base/AppExitSiteLink';
import AppAuthLink from 'containers/base/AppAuthLink';

const loginLink = true;

class NavigationLink extends Component {
  getLinkIcon(type) {
    switch (type) {
      case 'challenges':
        return <NavChallengesIcon />;
      case 'quizzes':
        return <NavQuizIcon />;
      case 'videos':
        return <NavVideoIcon />;
      case 'download':
        return <NavDownloadIcon />;
      case 'map':
        return <NavMapIcon />;
      case 'global-goals':
        return <NavGlobalGoalsIcon />;
      case 'campaign':
        return <NavLangToggleIcon />;
      case 'my-goals':
        return <NavMyGoalsIcon />;
      case 'other':
        return <NavOtherIcon />;
      case 'schools':
        return <NavSchoolsIcon />;
      default:
    }
  }

  render() {
    const { activePage } = this.props;

    if (!this.props.isOnline) {
      return this.props.navigation.map((link, index) => {
        const { id, icon = false, type, page, name, friendlyURL, externalURL, linkTarget, leaving_message } = link;
        const linkIconType = icon ? icon : page;

        if (id === 'challenges') {
          return (
            <li key={index} className={'navigation__link' + ('/' + page === location.pathname ? ' active' : '')}>
              <NavLink to={'/' + friendlyURL} className="link" onClick={this.props.clickHandler}>
                <div className="navigation__link__icon">{this.getLinkIcon(linkIconType)}</div>
                <div className="navigation__link__title">{name}</div>
              </NavLink>
            </li>
          );
        } else {
          return (
            <li key={index} className={'navigation__link'}>
              <div className="link link--disabled">
                <div className="navigation__link__icon">{this.getLinkIcon(linkIconType)}</div>
                <div className="navigation__link__title">{name}</div>
              </div>
            </li>
          );
        }
      });
    }

    return (
      <>
        {this.props.navigation.map((link, index) => {
          const { icon = false, type, page, name, friendlyURL, externalURL, linkTarget, leaving_message } = link;
          const linkIconType = icon ? icon : page;

          if (type === NAV_TYPE.EXTERNAL) {
            if (leaving_message) {
              return (
                <li key={index} className="navigation__link">
                  <div className="link">
                    <AppExitSiteLink link={externalURL}>
                      <div className="navigation__link__icon">{this.getLinkIcon(linkIconType)}</div>
                      <div className="navigation__link__title" data-text={name}>
                        {name}
                      </div>
                    </AppExitSiteLink>
                  </div>
                </li>
              );
            }
            return (
              <li key={index} className="navigation__link">
                <a href={externalURL} target={linkTarget} className="link">
                  <div className="navigation__link__icon">{this.getLinkIcon(linkIconType)}</div>
                  <div className="navigation__link__title" data-text={name}>
                    {name}
                  </div>
                </a>
              </li>
            );
          } else {
            // both CUSTOM and INTERNAL (existing_url) rendered below - using the friendlyURL for link
            // see NavigationDataParser for friendlyURL conversion
            return (
              <li key={index} className={'navigation__link' + (friendlyURL === activePage ? ' active' : '')}>
                <NavLink to={'/' + friendlyURL} className="link" onClick={this.props.clickHandler}>
                  <div className="navigation__link__icon">{this.getLinkIcon(linkIconType)}</div>
                  <div className="navigation__link__title" data-text={name}>
                    {name}
                  </div>
                </NavLink>
              </li>
            );
          }
        })}
        {loginLink && (
          <li className="navigation__link">
            <div className="link">
              <AppAuthLink />
            </div>
          </li>
        )}
      </>
    );
  }
}

export default withRouter(NavigationLink);

import React, { Component } from 'react';
import { WEBPSUPPORT } from 'constants/app/AppConstants';
import { convert_to_webp } from 'constants/app/AppServices';

class PictureMappingsSingleImage extends Component {
  render() {
    const { src = '', sizes = '100vw', imageAlt = '', imageTitle = imageAlt, clazz = '' } = this.props;

    function createImageSrcSet(src) {
      return (
        <React.Fragment>
          {WEBPSUPPORT != false && <source type="image/webp" srcSet={convert_to_webp(src)} sizes={sizes} />}
          <source srcSet={src} sizes={sizes} />
        </React.Fragment>
      );
    }

    return src !== '' ? (
      <figure>
        <picture>
          {createImageSrcSet(src)}
          <img src={src} alt={imageAlt} title={imageTitle} className={clazz} />
        </picture>
      </figure>
    ) : null;
  }
}

export default PictureMappingsSingleImage;

//magic things
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import components
import FooterCNContent from 'components/base/footer/FooterCNContent';
import FooterLegal from 'components/base/footer/FooterLegal';
import FooterTurnerInfo from 'components/base/footer/FooterTurnerInfo';
import { getFooterData, getSiteUrlByName } from 'store/selectors/App.selectors';
import { SITE_STATUS } from 'constants/app/AppConstants';
import FooterUpperContent from 'components/base/footer/FooterUpperContent';
import FooterLogos from 'components/base/footer/FooterLogos';

import { attachOneTrustListeners } from 'store/actions/OneTrust.actions';

class AppFooter extends Component {
  constructor(props) {
    super(props);
    this.bindedAttachOneTrustListeners = this.props.attachOneTrustListeners.bind(this);
  }

  render() {
    const { footerData, attachOneTrustListeners, faqLink } = this.props;
    const { legalLinks, cnText, logos, legalText, cnAlt, cnLink, cnLinkText = '', wwfText } = footerData;

    const aboveLogos = [];
    const belowLogos = [];

    logos.map((logo) => {
      const { logo_position } = logo;
      if (logo_position === 'above') {
        aboveLogos.push(logo);
      } else {
        belowLogos.push(logo);
      }
    });

    return (
      <footer className={`footer ${SITE_STATUS === 'coming_soon' ? 'coming-soon-footer' : ''}`} id="app-footer">
        <div className="container">
          <section className="footer__left-section">
            <FooterLegal legalLinks={legalLinks} faqLink={faqLink} />
          </section>
          <section className="footer__right-section">
            {aboveLogos.length > 0 && <FooterLogos logos={aboveLogos} />}
            <FooterCNContent cnText={cnText} cnAlt={cnAlt} />
            <FooterTurnerInfo legalText={legalText} logos={logos} wwfText={wwfText} />
            {belowLogos.length > 0 && <FooterLogos logos={belowLogos} />}
          </section>
        </div>
      </footer>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    attachOneTrustListeners: () => {
      dispatch(attachOneTrustListeners());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    footerData: getFooterData(state),
    faqLink: getSiteUrlByName(state, 'faq'),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppFooter);

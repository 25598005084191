//magic things
import React, { Component } from 'react';
import { connect } from 'react-redux';

//import selectors
import { getHomeContent, getHomeMarqueData, getHomePageHeroItem } from 'store/selectors/Homepage.selectors';
import { getSiteMetaData, getSiteTextDataByGroup, getOnlineStatus } from 'store/selectors/App.selectors';

// import constants
import { HOME_PAGE_GRID } from 'constants/content-grid/ContentGridConstants';
import { PAGES, CONTENT } from 'constants/content-library/ContentLibraryConstants';
import { PAGE } from 'constants/app/AppConstants';
import { dispatchEnsightenPageLoad } from 'constants/app/AppServices';

// import containers
import MarqueArea from 'containers/content/MarqueArea';
import ContentItemGrid from 'containers/content/ContentItemGrid';
import PageOfflineMessage from 'containers/pages/PageOfflineMessage';

// import actions
import { triggerPageView } from 'store/actions/EnsightenTracking.actions';

// import components
import SiteMetaData from 'components/generic/SiteMetaData';
import Manifesto from 'components/manifesto/Manifesto';
import Challenges from 'components/challenges/Challenges';
import StatsCTA from 'components/stats-cta/StatsCTA';

class PageHome extends Component {
  componentDidMount() {
    this.props.trackPageView({ contentType: PAGES.HOME, pageType: PAGE.LANDING });
    const { adTrigger } = this.props;
    dispatchEnsightenPageLoad(adTrigger);
  }

  render() {
    const { marqueData } = this.props;
    const { todays_challenges = '', grid_title = '' } = this.props.pageText;
    return (
      <section className={`main-content home`}>
        <SiteMetaData {...this.props.metadata} />
        {!this.props.isOnline ? (
          <PageOfflineMessage />
        ) : (
          <>
            <MarqueArea data={marqueData} />
            <Manifesto type={'large'} />
            <section className="challenges__container">
              <div className="container">
                <h1>{todays_challenges}</h1>
                <Challenges daily={true} />
              </div>
            </section>
            <div className="homepage container">
              <h1>{grid_title}</h1>
              <ContentItemGrid data={this.props.contentItems} layout={HOME_PAGE_GRID} />
            </div>
          </>
        )}
      </section>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    metadata: getSiteMetaData(state, PAGES.HOME),
    marqueData: getHomeMarqueData(state),
    contentItems: getHomeContent(state),
    pageText: getSiteTextDataByGroup(state, 'homepage'),
    isOnline: getOnlineStatus(state),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    trackPageView: (opts) => {
      dispatch(triggerPageView(opts));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PageHome);

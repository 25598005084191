//magic things
import React from 'react';

const DownloadIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 105" className="download-icon">
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <g className="cls-1">
          <circle cx="50" cy="55" r="50" />
        </g>
        <circle className="cls-2" cx="50" cy="50" r="50" />
        <path
          id="Path_38"
          data-name="Path 38"
          d="M64.6,41.61l.08-6.25a3.65,3.65,0,0,0-3.6-3.71l-21.93-.3A3.66,3.66,0,0,0,35.44,35l-.08,6.26-4.53-.07a1.83,1.83,0,0,0-1,3.36l18,11.83a3.63,3.63,0,0,0,3.93.06L70.06,45.06a1.82,1.82,0,0,0,.59-2.51,1.8,1.8,0,0,0-1.53-.87Z"
        />
        <g data-name="Group 9">
          <path id="Path_39" data-name="Path 39" d="M64.92,69.5H35a4.57,4.57,0,1,1,0-9.14H64.91a4.57,4.57,0,1,1,0,9.14Z" />
        </g>
      </g>
    </g>
  </svg>
);

export default DownloadIcon;

//magic things
import React from 'react';

const NavLoginIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" className="nav-login-icon">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path className="cls-1" d="M36.6,41.77Z" />
      </g>
      <g id="Layer_3" data-name="Layer 3">
        <g id="header">
          <g id="login">
            <circle className="cls-1" cx="25" cy="25" r="25" />
            <circle className="cls-2" cx="25" cy="25" r="22" />
            <path className="cls-1" d="M24.69,29.19c4,0,7.25-3.94,7.25-8.8s-1.07-8.81-7.25-8.81-7.25,3.94-7.25,8.81S20.69,29.19,24.69,29.19Z" />
            <path
              className="cls-1"
              d="M38.36,42.28c-.13-8.47-1.24-10.89-9.7-12.42a5.07,5.07,0,0,1-4,1.52,5.07,5.07,0,0,1-4-1.52C12.35,31.38,11.18,33.75,11,42c0,.67,0,.71,0,.63v.89s2,4.06,13.69,4.06,13.69-4.06,13.69-4.06v-.66A4.55,4.55,0,0,1,38.36,42.28Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default NavLoginIcon;

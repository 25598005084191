//magic things
import React from 'react';

const NavLangToggleIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.96 45" className="nav-Lang-toggle-icon">
    <defs />
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_3" data-name="Layer 3">
        <g opacity=".2">
          <path d="M0 24a21 21 0 1120.94 21A21 21 0 010 24zm26.22 0H15.71a60 60 0 00.52 7.83h9.48a60.44 60.44 0 00.51-7.83zm-13.14 0H2.8c-.19 0-.23.06-.22.23 0 .54 0 1.09.1 1.63a20.67 20.67 0 001.64 5.83.28.28 0 00.31.2h9a59.29 59.29 0 01-.55-7.89zm15.2 7.85h9.06a.23.23 0 00.26-.17c.32-.86.67-1.71.94-2.58a16.47 16.47 0 00.81-4.88c0-.17 0-.26-.23-.26H28.85a60.57 60.57 0 01-.57 7.9zm-12.49-10.5h10.35v-.13c-.14-1.61-.28-3.22-.41-4.83 0-.21-.1-.27-.31-.27h-8.91c-.25 0-.32.09-.34.31-.12 1.51-.26 3-.39 4.51 0 .12.01.26.01.41zm-2.13-5.23h-9c-.16 0-.25 0-.3.19-.33.94-.69 1.86-1 2.81-.2.63-.32 1.28-.47 1.92-.05.21 0 .28.23.29s.58 0 .87 0h8.93c.21 0 .27-.06.29-.27.09-1.16.19-2.32.31-3.48 0-.45.08-.94.14-1.46zm14.63.05v.21c.16 1.58.33 3.16.48 4.73 0 .22.09.27.3.27h9.67c.39 0 .41 0 .32-.42a22.61 22.61 0 00-1.44-4.61.26.26 0 00-.28-.18h-8.58zM21 42.38a1.86 1.86 0 001.13-.48 5.37 5.37 0 001.39-1.9 23.5 23.5 0 001.68-5.17c.06-.28 0-.3-.25-.33h-8.04c-.17 0-.24.08-.19.26s.14.65.23 1A21.67 21.67 0 0018.38 40a5.81 5.81 0 001.43 1.9 1.87 1.87 0 001.19.48zm4.29-28.9c-.12-.53-.23-1-.36-1.54a18.45 18.45 0 00-1.45-4.07A5.5 5.5 0 0022.08 6 1.67 1.67 0 0020 6a3.89 3.89 0 00-.53.46 6.73 6.73 0 00-1.22 1.89 23 23 0 00-1.5 4.86 2.72 2.72 0 000 .3zm-.08-7.37h-.05l.15.31c.28.58.58 1.15.84 1.74a30.94 30.94 0 011.59 5.06c0 .15.09.23.28.23h8a18.63 18.63 0 00-10.83-7.34zM6 13.44h7.46c.72 0 .72 0 .9-.71a26.17 26.17 0 012.33-6.35 2.51 2.51 0 00.13-.33A18.81 18.81 0 006 13.44zm30 21.13h-7.86a.3.3 0 00-.35.28 25.05 25.05 0 01-2.56 7 .69.69 0 00-.06.14 18.62 18.62 0 0010.75-7.42zm-19.14 7.34c-.09-.15-.15-.25-.2-.35-.23-.47-.49-.92-.7-1.4a30.1 30.1 0 01-1.76-5.42c0-.14-.06-.24-.26-.24H6.01a18.67 18.67 0 0010.85 7.41z" />
          <path d="M0 24a21 21 0 1120.94 21A21 21 0 010 24zm26.22 0H15.71a60 60 0 00.52 7.83h9.48a60.44 60.44 0 00.51-7.83zm-13.14 0H2.8c-.19 0-.23.06-.22.23 0 .54 0 1.09.1 1.63a20.67 20.67 0 001.64 5.83.28.28 0 00.31.2h9a59.29 59.29 0 01-.55-7.89zm15.2 7.85h9.06a.23.23 0 00.26-.17c.32-.86.67-1.71.94-2.58a16.47 16.47 0 00.81-4.88c0-.17 0-.26-.23-.26H28.85a60.57 60.57 0 01-.57 7.9zm-12.49-10.5h10.35v-.13c-.14-1.61-.28-3.22-.41-4.83 0-.21-.1-.27-.31-.27h-8.91c-.25 0-.32.09-.34.31-.12 1.51-.26 3-.39 4.51 0 .12.01.26.01.41zm-2.13-5.23h-9c-.16 0-.25 0-.3.19-.33.94-.69 1.86-1 2.81-.2.63-.32 1.28-.47 1.92-.05.21 0 .28.23.29s.58 0 .87 0h8.93c.21 0 .27-.06.29-.27.09-1.16.19-2.32.31-3.48 0-.45.08-.94.14-1.46zm14.63.05v.21c.16 1.58.33 3.16.48 4.73 0 .22.09.27.3.27h9.67c.39 0 .41 0 .32-.42a22.61 22.61 0 00-1.44-4.61.26.26 0 00-.28-.18h-8.58zM21 42.38a1.86 1.86 0 001.13-.48 5.37 5.37 0 001.39-1.9 23.5 23.5 0 001.68-5.17c.06-.28 0-.3-.25-.33h-8.04c-.17 0-.24.08-.19.26s.14.65.23 1A21.67 21.67 0 0018.38 40a5.81 5.81 0 001.43 1.9 1.87 1.87 0 001.19.48zm4.29-28.9c-.12-.53-.23-1-.36-1.54a18.45 18.45 0 00-1.45-4.07A5.5 5.5 0 0022.08 6 1.67 1.67 0 0020 6a3.89 3.89 0 00-.53.46 6.73 6.73 0 00-1.22 1.89 23 23 0 00-1.5 4.86 2.72 2.72 0 000 .3zm-.08-7.37h-.05l.15.31c.28.58.58 1.15.84 1.74a30.94 30.94 0 011.59 5.06c0 .15.09.23.28.23h8a18.63 18.63 0 00-10.83-7.34zM6 13.44h7.46c.72 0 .72 0 .9-.71a26.17 26.17 0 012.33-6.35 2.51 2.51 0 00.13-.33A18.81 18.81 0 006 13.44zm30 21.13h-7.86a.3.3 0 00-.35.28 25.05 25.05 0 01-2.56 7 .69.69 0 00-.06.14 18.62 18.62 0 0010.75-7.42zm-19.14 7.34c-.09-.15-.15-.25-.2-.35-.23-.47-.49-.92-.7-1.4a30.1 30.1 0 01-1.76-5.42c0-.14-.06-.24-.26-.24H6.01a18.67 18.67 0 0010.85 7.41z" />
        </g>
        <path
          className="cls-2"
          d="M0 21a21 21 0 1120.94 21A21 21 0 010 21zm26.22 0H15.71a60 60 0 00.52 7.83h9.48a60.44 60.44 0 00.51-7.83zm-13.14 0H2.8c-.19 0-.23.06-.22.23 0 .54 0 1.09.1 1.63a20.67 20.67 0 001.64 5.83.28.28 0 00.31.2h9a59.29 59.29 0 01-.55-7.89zm15.2 7.85h9.06a.23.23 0 00.26-.17c.32-.86.67-1.71.94-2.58a16.47 16.47 0 00.81-4.88c0-.17 0-.26-.23-.26H28.85a60.57 60.57 0 01-.57 7.9zm-12.49-10.5h10.35v-.13c-.14-1.61-.28-3.22-.41-4.83 0-.21-.1-.27-.31-.27h-8.91c-.25 0-.32.09-.34.31-.12 1.51-.26 3-.39 4.51 0 .12.01.26.01.41zm-2.13-5.23h-9c-.16 0-.25 0-.3.19-.33.94-.69 1.86-1 2.81-.2.63-.32 1.28-.47 1.92-.05.21 0 .28.23.29s.58 0 .87 0h8.93c.21 0 .27-.06.29-.27.09-1.16.19-2.32.31-3.48 0-.45.08-.94.14-1.46zm14.63.05v.21c.16 1.58.33 3.16.48 4.73 0 .22.09.27.3.27h9.67c.39 0 .41 0 .32-.42a22.61 22.61 0 00-1.44-4.61.26.26 0 00-.28-.18h-8.58zM21 39.38a1.86 1.86 0 001.13-.48 5.37 5.37 0 001.39-1.9 23.5 23.5 0 001.68-5.17c.06-.28 0-.3-.25-.33h-8.04c-.17 0-.24.08-.19.26s.14.65.23 1A21.67 21.67 0 0018.38 37a5.81 5.81 0 001.43 1.9 1.87 1.87 0 001.19.48zm4.29-28.9c-.12-.53-.23-1-.36-1.54a18.45 18.45 0 00-1.45-4.07A5.5 5.5 0 0022.08 3 1.67 1.67 0 0020 3a3.89 3.89 0 00-.53.46 6.73 6.73 0 00-1.22 1.89 23 23 0 00-1.5 4.86 2.72 2.72 0 000 .3zm-.08-7.37l-.05.05.15.31c.28.58.58 1.15.84 1.74a30.94 30.94 0 011.59 5.06c0 .15.09.23.28.23h8a18.63 18.63 0 00-10.83-7.39zM6 10.44h7.46c.72 0 .72 0 .9-.71a26.17 26.17 0 012.33-6.35 2.51 2.51 0 00.13-.33A18.81 18.81 0 006 10.44zm30 21.13h-.4c-2.48 0-5 0-7.46-.05a.3.3 0 00-.35.28 25.05 25.05 0 01-2.56 7 .69.69 0 00-.06.14 18.62 18.62 0 0010.75-7.37zm-19.14 7.34c-.09-.15-.15-.25-.2-.35-.23-.47-.49-.92-.7-1.4a30.1 30.1 0 01-1.76-5.42c0-.14-.06-.24-.26-.24H6.01a18.67 18.67 0 0010.85 7.41z"
        />
        <path
          className="cls-2"
          d="M0 21a21 21 0 1120.94 21A21 21 0 010 21zm26.22 0H15.71a60 60 0 00.52 7.83h9.48a60.44 60.44 0 00.51-7.83zm-13.14 0H2.8c-.19 0-.23.06-.22.23 0 .54 0 1.09.1 1.63a20.67 20.67 0 001.64 5.83.28.28 0 00.31.2h9a59.29 59.29 0 01-.55-7.89zm15.2 7.85h9.06a.23.23 0 00.26-.17c.32-.86.67-1.71.94-2.58a16.47 16.47 0 00.81-4.88c0-.17 0-.26-.23-.26H28.85a60.57 60.57 0 01-.57 7.9zm-12.49-10.5h10.35v-.13c-.14-1.61-.28-3.22-.41-4.83 0-.21-.1-.27-.31-.27h-8.91c-.25 0-.32.09-.34.31-.12 1.51-.26 3-.39 4.51 0 .12.01.26.01.41zm-2.13-5.23h-9c-.16 0-.25 0-.3.19-.33.94-.69 1.86-1 2.81-.2.63-.32 1.28-.47 1.92-.05.21 0 .28.23.29s.58 0 .87 0h8.93c.21 0 .27-.06.29-.27.09-1.16.19-2.32.31-3.48 0-.45.08-.94.14-1.46zm14.63.05v.21c.16 1.58.33 3.16.48 4.73 0 .22.09.27.3.27h9.67c.39 0 .41 0 .32-.42a22.61 22.61 0 00-1.44-4.61.26.26 0 00-.28-.18h-8.58zM21 39.38a1.86 1.86 0 001.13-.48 5.37 5.37 0 001.39-1.9 23.5 23.5 0 001.68-5.17c.06-.28 0-.3-.25-.33h-8.04c-.17 0-.24.08-.19.26s.14.65.23 1A21.67 21.67 0 0018.38 37a5.81 5.81 0 001.43 1.9 1.87 1.87 0 001.19.48zm4.29-28.9c-.12-.53-.23-1-.36-1.54a18.45 18.45 0 00-1.45-4.07A5.5 5.5 0 0022.08 3 1.67 1.67 0 0020 3a3.89 3.89 0 00-.53.46 6.73 6.73 0 00-1.22 1.89 23 23 0 00-1.5 4.86 2.72 2.72 0 000 .3zm-.08-7.37l-.05.05.15.31c.28.58.58 1.15.84 1.74a30.94 30.94 0 011.59 5.06c0 .15.09.23.28.23h8a18.63 18.63 0 00-10.83-7.39zM6 10.44h7.46c.72 0 .72 0 .9-.71a26.17 26.17 0 012.33-6.35 2.51 2.51 0 00.13-.33A18.81 18.81 0 006 10.44zm30 21.13h-.4c-2.48 0-5 0-7.46-.05a.3.3 0 00-.35.28 25.05 25.05 0 01-2.56 7 .69.69 0 00-.06.14 18.62 18.62 0 0010.75-7.37zm-19.14 7.34c-.09-.15-.15-.25-.2-.35-.23-.47-.49-.92-.7-1.4a30.1 30.1 0 01-1.76-5.42c0-.14-.06-.24-.26-.24H6.01a18.67 18.67 0 0010.85 7.41z"
        />
      </g>
    </g>
  </svg>
);

export default NavLangToggleIcon;

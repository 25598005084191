import React, { Component } from 'react';
import { connect } from 'react-redux';

import { PAGES } from 'constants/content-library/ContentLibraryConstants';
import { PAGE } from 'constants/app/AppConstants';

import { getHomeMarqueData } from 'store/selectors/Homepage.selectors';
import { triggerPageView } from 'store/actions/EnsightenTracking.actions';
import { dispatchEnsightenPageLoad } from 'constants/app/AppServices';
import { getSiteMetaData, getSiteTextDataByGroup } from 'store/selectors/App.selectors';
import SiteMetaData from 'components/generic/SiteMetaData';

class PageNotFound extends Component {
  componentDidMount() {
    this.props.trackPageView({ contentType: PAGES.NOTFOUND, pageType: PAGE.LANDING });
    const { adTrigger } = this.props;
    dispatchEnsightenPageLoad(adTrigger);
  }

  render() {
    const { title, description } = this.props.site_text;
    return (
      <React.Fragment>
        <SiteMetaData {...this.props.metadata} />
        <div className="page-not-found__container">
          <section className="flex flex-wrap justify-content-center">
            <div className="page-not-found">
              <h1 className="page-not-found__title">{title}</h1>
              <h2 className="page-not-found__description">{description}</h2>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    metadata: getSiteMetaData(state, PAGES.HOME),
    marqueData: getHomeMarqueData(state),
    site_text: getSiteTextDataByGroup(state, 'error_page'),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    trackPageView: (opts) => {
      dispatch(triggerPageView(opts));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PageNotFound);

import React, { useState, useEffect, useRef } from 'react';

import { CONTENT } from 'constants/content-library/ContentLibraryConstants';
import { IS_IOS, PLAYER_STATES } from 'constants/app/AppConstants';
import { connect } from 'react-redux';

import { Brim, Scream } from 'bream';
import { getUrlStaticImages } from 'constants/app/AppUrls';
import PictureMappingsSingleImage from 'components/picture-mappings/PictureMappingsSingleImage';
// import components
import { getSiteTextDataByGroup } from 'store/selectors/App.selectors';

// import components
import RotateScreen from 'components/rotate-screen/RotateScreen';

const RotateScreenContainer = (props) => {
  const { children, isMobile } = props;

  // states
  const [isPortrait, updateIsPortrait] = useState(false);
  const [brimSetup, updateBrimSetup] = useState(false);
  const [view, updateView] = useState('full');
  const [showMenu, updateShowMenu] = useState(false);

  // refs
  const maskRef = useRef();
  const mainRef = useRef();

  const checkRotate = () => {
    window.scrollTo(0, 0);

    if (window.innerWidth < window.innerHeight) {
      updateIsPortrait(true);
      return false;
    } else {
      updateIsPortrait(false);
      return true;
    }
  };

  useEffect(() => {
    if (isMobile) {
      document.getElementsByTagName('body')[0].classList.add('fullscreen-mode');

      const isPortraitCheck = checkRotate();
      if (isPortraitCheck) {
        if (!isChromeOnIos()) {
          setupGestures();
        }
      } else {
        updateBrimSetup(false);
        if (typeof document.getElementById('brim-treadmill') != 'undefined' && document.getElementById('brim-treadmill') != null) {
          document.getElementById('brim-treadmill').parentNode.removeChild(document.getElementById('brim-treadmill'));
        }
      }
    }
  }, [isPortrait]);

  useEffect(() => {
    if (isMobile) {
      window.addEventListener('resize', checkRotate);
      window.addEventListener('onorientationchange', checkRotate);

      return (isPortrait, isFullscreen) => {
        window.removeEventListener('resize', checkRotate);
        window.removeEventListener('onorientationchange', checkRotate);
        removeListeners();

        if (document.getElementsByTagName('body')[0].classList.contains('show-menu')) {
          document.getElementsByTagName('body')[0].classList.remove('show-menu');
        }

        if (document.getElementsByTagName('body')[0].classList.contains('fullscreen-mode')) {
          document.getElementsByTagName('body')[0].classList.remove('fullscreen-mode');
        }

        if (typeof document.getElementById('brim-treadmill') != 'undefined' && document.getElementById('brim-treadmill') != null) {
          document.getElementById('brim-treadmill').parentNode.removeChild(document.getElementById('brim-treadmill'));
        }
      };
    }
  }, []);

  const isChromeOnIos = () => {
    return navigator.userAgent.indexOf('CriOS') >= 0;
  };

  const setupGestures = () => {
    if (IS_IOS && !brimSetup) {
      if (!document.querySelector('#brim-treadmill')) {
        const brim = new Brim(window, {
          // eslint-disable-next-line new-cap
          viewport: Scream({
            viewport: false,
            width: {
              portrait: window.screen.width,
              landscape: window.screen.height,
            },
          }),
        });
        brim.on('viewchange', (e) => {
          updateView(e.viewName);

          if (e.viewName === 'minimal') {
            setTimeout(() => {
              let main = maskRef.current || '';
              if (main !== '') {
                main.style.height = window.screen.height - 44 + 'px';
              }
            }, 0);
          }
        });

        updateBrimSetup(brim);
      }
      document.querySelector('meta[name="viewport"]').setAttribute('content', ['width=device-width', 'initial-scale=1.0', 'maximum-scale=1.0', 'user-scalable=0', 'viewport-fit=cover'].join(','));
    } else {
      maskRef.current.addEventListener('click', toggleFullScreen, false);
    }
  };

  const toggleMenu = () => {
    if (!showMenu) {
      document.getElementsByTagName('body')[0].classList.add('show-menu');
    } else {
      document.getElementsByTagName('body')[0].classList.remove('show-menu');
    }
    updateShowMenu(!showMenu);
  };

  const removeListeners = () => {
    if (maskRef.current) {
      maskRef.current.removeEventListener('click', toggleFullScreen, false);
    }

    if (document.getElementsByTagName('html')[0].classList.contains('is-fullscreen')) {
      document.getElementsByTagName('html')[0].classList.remove('is-fullscreen');

      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  };

  const toggleFullScreen = () => {
    const doc = window.document;
    const docEl = doc.documentElement;

    const requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
    const cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

    if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
      requestFullScreen.call(docEl);
      document.getElementsByTagName('html')[0].classList.add('is-fullscreen');
      if (!maskRef.current.classList.contains('hidden')) {
        maskRef.current.classList.add('hidden');
      }
    } else {
      cancelFullScreen.call(doc);
    }
  };

  const { ios_swipe_gesture, other_tap_gesture } = props.gesturesText;

  if (isMobile) {
    return (
      <React.Fragment>
        {isPortrait ? (
          <RotateScreen />
        ) : (
          <React.Fragment>
            {!isChromeOnIos() && (
              <div id="brim-mask" ref={maskRef} className={'fullscreen-trigger' + (IS_IOS ? ' ios' : ' non-ios flex flex-wrap justify-content-center align-items-center')}>
                <div className="fullscreen-trigger__container fullscreen-trigger__container--ios">
                  <PictureMappingsSingleImage src={getUrlStaticImages('game-container/game-swipe-icon.png')} imageAlt={ios_swipe_gesture} />
                  <span className="fullscreen-trigger__text">{ios_swipe_gesture}</span>
                </div>
                <div className="fullscreen-trigger__container fullscreen-trigger__container--non-ios">
                  <PictureMappingsSingleImage src={getUrlStaticImages('game-container/game-tap-icon.png')} imageAlt={other_tap_gesture} />
                  <span className="fullscreen-trigger__text">{other_tap_gesture}</span>
                </div>
              </div>
            )}
            <div id="brim-main" ref={mainRef}>
              <button className={'expand-button ' + (showMenu ? 'expand-button__shrink' : 'expand-button__expand')} aria-label={'Expand and shrink button'} onClick={toggleMenu}></button>
              {children}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  } else {
    return children;
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    gesturesText: getSiteTextDataByGroup(state, 'mobile_game_gestures'),
  };
};

export default connect(mapStateToProps)(RotateScreenContainer);

//magic things
import React from 'react';

const SchoolsStarBlackIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 34.24">
    <g data-name="Layer 2">
      <path d="M18 0l5.56 11.27L36 13.08l-9 8.77 2.13 12.39L18 28.39 6.88 34.24 9 21.85l-9-8.77 12.44-1.81L18 0z" data-name="Layer 1" />
    </g>
  </svg>
);

export default SchoolsStarBlackIcon;

import { getRatingsService } from 'services/api';
import { LIKES_INVALIDATE, LIKES_RECEIVE } from 'store/actions/Likes.actions';

export const RATINGS_INVALIDATE = 'ratings.INVALIDATE';
export const RATINGS_REQUEST = 'ratings.REQUEST';
export const RATINGS_RECEIVE = 'ratings.RECEIVE';

const parseLikes = (likes) => {
  /*
   * TODO :
   * data looks like content_type|key|type : count
   * Drop content_type as not needed locally
   * parse this (in action) into
   * {
   *    chris: {"1": 12}
   *    test:  {"1": 64, "2": 35}
   * }
   */
  return Object.keys(likes).reduce((obj, item, index) => {
    const value = likes[item];
    const split = item.split('|');
    const contentType = split[0];
    const entryID = split[1];
    const emojiID = split[2].toString();
    // console.log('contentType', contentType, 'entryID', entryID, 'emojiID', emojiID, '=', value);
    if (emojiID) {
      if (!obj[entryID]) {
        obj[entryID] = {};
        obj[entryID][emojiID] = value;
      } else {
        obj[entryID][emojiID] = value;
      }
    }
    return obj;
  }, {});
};

const _loadRatings = () => {
  return (dispatch) => {
    dispatch({ type: RATINGS_REQUEST });
    getRatingsService().then((response) => {
      if (response.result === 'SUCCESS') {
        const { results } = response;
        const { likes, liked_ids, views } = results;
        const likesByID = parseLikes(likes);
        dispatch({ type: RATINGS_RECEIVE, payload: { likesByID, liked_ids, views } });
      } else {
        console.error('_getRatings', response.reason);
        dispatch({ type: RATINGS_INVALIDATE });
      }
    });
  };
};

export const loadContentRatings = () => {
  return (dispatch, getState) => {
    return dispatch(_loadRatings());
  };
};

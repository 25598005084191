import React, { Component } from 'react';
import { connect } from 'react-redux';

import { recaptchaActive, recaptchaAvaiable, attachRecaptcha } from 'utils/recaptcha';

// import actions
import { requestSignUp, requestLogin, requestLogout, setLoginStatus, STATE_IDLE } from 'store/actions/Login.actions';
import { activeAuthModal } from 'store/actions/App.actions';
import { setUserAvatar } from 'store/actions/User.actions';

// import selectors
import { getLoggedInStatus, getLoginErrorMessageID, getLoginType } from 'store/selectors/Login.selectors';
import { getUserData, getNumberOfCompletedChallenges, getUserAvatar } from 'store/selectors/User.selectors';
import { getSiteTextDataByGroup, getExitSiteModalLink, getSiteTextDataByGroupedKey } from 'store/selectors/App.selectors';

// import components
import CloseSign from 'components/inline-svgs/content-items/CloseSign';
import Register from 'components/AuthModal/Register';
import Login from 'components/AuthModal/Login';
import Welcome from 'components/AuthModal/Welcome';
import Loggedin from 'components/AuthModal/Loggedin';
import Logout from 'components/AuthModal/Logout';
import AvatarSelection from 'components/AuthModal/AvatarSelection';
import YourAccount from 'components/AuthModal/YourAccount';

class AuthModal extends Component {
  constructor(props) {
    super(props);

    this.bindedCloseModal = this.closeModal.bind(this);
    this.bindedSetModalType = this.setModalType.bind(this);

    // login/signup callbacks
    this.signUpUser = this.signUpUser.bind(this);
    this.loginUser = this.loginUser.bind(this);
    this.logoutUser = this.logoutUser.bind(this);
    this.avatarUpdate = this.avatarUpdate.bind(this);

    this.recaptchaLoaded = this.recaptchaLoaded.bind(this);

    this.state = {
      activeModalType: 'welcome',
      hasSeenAvatarSelect: false,
      recaptchaLoaded: false,
    };
  }

  componentDidMount() {
    if (!this.props.isLoggedIn && recaptchaActive()) {
      if (recaptchaAvaiable()) {
        this.recaptchaLoaded();
      } else {
        attachRecaptcha(() => {
          this.recaptchaLoaded();
        });
      }
    } else {
      this.recaptchaLoaded();
    }

    // If npm watch automatically sign in
    if (__WATCH__ && !this.props.isLoggedIn) {
      // this.props.requestLogin({ name: 'metest', password: 6158 });
    }
  }

  recaptchaLoaded() {
    this.setState({ recaptchaLoaded: true });
  }

  getLoggedOutModalContent() {
    const { loginText, errorMessage, loginType } = this.props;
    const { enter_username_error = '', enter_passcode_error = '', enter_only_numbers_error = '', enter_four_numbers_error = '' } = this.props.loginText;

    switch (this.state.activeModalType) {
      case 'register':
        return <Register setModalType={this.bindedSetModalType} callback={this.signUpUser} text={loginText} errorMessage={errorMessage} errorText={enter_username_error} />;
      case 'login':
        return (
          <Login
            setModalType={this.bindedSetModalType}
            callback={this.loginUser}
            text={loginText}
            errorMessage={errorMessage}
            errorText={{ enter_username_error, enter_passcode_error, enter_only_numbers_error, enter_four_numbers_error }}
          />
        );
      case 'welcome':
        return <Welcome setModalType={this.bindedSetModalType} text={loginText} recaptchaLoaded={this.state.recaptchaLoaded} />;
      default:
    }
  }

  getLoggedinModalContent() {
    const { userData, loginText, loginType, numberOfChalleges, activeAvatar } = this.props;
    const { username, passcode, membershipCard } = userData;
    const {
      register_complete = '',
      welcome_back = '',
      logout_button = '',
      challenges_accepted_text = 'Challenges you’ve accepted',
      avatar_confirm = 'Confirm',
      avatar_title = 'Choose Your Avatar',
      my_account = '',
      your_account_title = '',
      your_account_card = '',
      your_account_card_button = '',
      your_account_avatar = '',
      your_account_avatar_button = '',
    } = loginText;

    if (loginType === 'signup' && !this.state.hasSeenAvatarSelect) {
      this.setState({ hasSeenAvatarSelect: true });
      return <AvatarSelection avatarCallback={this.avatarUpdate} avatarConfirm={avatar_confirm} avatarTitle={avatar_title} activeAvatar={activeAvatar}></AvatarSelection>;
    }

    switch (this.state.activeModalType) {
      case 'logout':
        return <Logout logoutCallback={this.logoutUser} logoutText={loginText} membershipCard={membershipCard}></Logout>;

      case 'avatar':
        return <AvatarSelection avatarCallback={this.avatarUpdate} avatarConfirm={avatar_confirm} avatarTitle={avatar_title} activeAvatar={activeAvatar}></AvatarSelection>;

      case 'account':
        return (
          <YourAccount
            setModalType={this.bindedSetModalType}
            yourAccountTitle={your_account_title}
            yourAccountCard={your_account_card}
            yourAccountCardButton={your_account_card_button}
            yourAccountAvatar={your_account_avatar}
            yourAccountAvatarButton={your_account_avatar_button}
            membershipCard={membershipCard}
          ></YourAccount>
        );

      default:
        return (
          <Loggedin
            username={username}
            passcode={passcode}
            membershipCard={membershipCard}
            registerCompleteText={register_complete}
            setModalType={this.bindedSetModalType}
            loginType={loginType}
            logoutText={logout_button}
            myAccount={my_account}
            welcomeBackText={welcome_back}
            numberOfChalleges={numberOfChalleges}
            challengesAcceptedText={challenges_accepted_text}
          />
        );
    }
  }

  signUpUser(name) {
    this.props.requestSignUp(name);
  }

  loginUser(opts) {
    this.props.requestLogin(opts);
  }

  logoutUser() {
    this.props.requestLogout();

    this.setState({
      activeModalType: 'welcome',
    });
  }

  avatarUpdate(id) {
    this.setState({ activeModalType: 'loggedin' });
    this.props.setUserAvatar(id);
  }

  closeModal() {
    // Close ths modal
    this.props.activeAuthModal();
  }

  setModalType(type) {
    if (this.props && this.props.errorMessage !== '') {
      this.props.setLoginStatus(STATE_IDLE);
    }
    this.setState({
      activeModalType: type,
    });
  }

  render() {
    return (
      <section className="authModal__container container flex flex-wrap justify-content-center align-items-start">
        <div className="authModal">
          <button className="authModal__closeIcon" onClick={this.bindedCloseModal} aria-label="Close">
            <CloseSign />
          </button>
          {this.props.isLoggedIn ? this.getLoggedinModalContent() : this.getLoggedOutModalContent()}
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    activeAuthModal: () => {
      dispatch(activeAuthModal());
    },
    requestSignUp: (name) => {
      dispatch(requestSignUp(name));
    },
    requestLogin: (opts) => {
      dispatch(requestLogin(opts));
    },
    requestLogout: () => {
      dispatch(requestLogout());
    },
    setLoginStatus: (status) => {
      dispatch(setLoginStatus(status));
    },
    setUserAvatar: (id) => {
      dispatch(setUserAvatar(id));
    },
  };
};

const mapStateToProps = (state) => {
  const errorID = getLoginErrorMessageID(state);

  return {
    link: getExitSiteModalLink(state),
    loginText: getSiteTextDataByGroup(state, 'login'),
    isLoggedIn: getLoggedInStatus(state),
    userData: getUserData(state),
    errorMessage: !errorID ? '' : getSiteTextDataByGroupedKey(state, 'login', errorID),
    loginType: getLoginType(state),
    numberOfChalleges: getNumberOfCompletedChallenges(state),
    activeAvatar: getUserAvatar(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthModal);

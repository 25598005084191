import { parseMarqueData } from 'store/actions/parsers/MarqueDataParser';

export const parseHomePageData = (contentData, key) => {
  const data = contentData[key] || {};
  const { content = [], ...other } = data; // strips content from data, copying other keys into other
  // convert array into ByID lookup
  const allIDs = [];
  const byID = {};
  const externalContent = [];
  const internalContent = [];

  for (const item of content) {
    const { home_content_type, home_existing_content_type, home_content_item } = item;
    if (home_content_type === 'existing') {
      const { content_id } = home_content_item;
      allIDs.push(content_id);
      byID[content_id] = { type: home_content_type, content_type: home_existing_content_type };
    } else if (home_content_type === 'external' || home_content_type === 'internal') {
      const { home_internal_item, home_external_item } = item;

      /*
       * External items now follow the same data structure as other content
       * we push the content to an array on the reducer
       */

      if (home_content_type === 'external') {
        const { content_id } = home_external_item;
        allIDs.push(content_id);
        byID[content_id] = { type: home_content_type, content_type: home_content_type };
        externalContent.push(home_external_item);
      } else {
        const { content_id } = home_internal_item;
        allIDs.push(content_id);
        byID[content_id] = { type: home_content_type, content_type: home_content_type };
        internalContent.push(home_internal_item);
      }
    }
  }
  const marqueData = parseMarqueData(data);
  return { data: { allIDs, byID, externalContent, internalContent }, marqueData };
};

import React from 'react';

const FooterTurnerInfo = ({ legalText, logos }) => {
  return (
    <section className="turner-info">
      <section className="legal__text">{legalText}</section>
    </section>
  );
};

export default FooterTurnerInfo;

//magic things
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Import constants
import { STATUS_COMPLETED, STATUS_NOT_COMPLETED, STATUS_CANT_DO, STATUS_CAN_DO } from 'constants/challenges/ChallengeItems';
import { CHALLENGE_TYPES } from 'constants/challenges/ChallengeTypes';
import { INTERACTION } from 'constants/ensighten-tracking/EnsightenTrackingConstants';

import { IMAGE_SRC_SIZES, WEBPSUPPORT, NO_IMAGE_FALLBACK, PAGE } from 'constants/app/AppConstants';
import { getUrlStaticImages } from 'constants/app/AppUrls';

// import component
import ChallengesBoxTicked from 'components/inline-svgs/challenges/ChallengesBoxTicked';
import ChallengesBoxUnticked from 'components/inline-svgs/challenges/ChallengesBoxUnticked';
import ChallengeHeader from 'components/challenges/ChallengesHeader';
import ChallengeBody from 'components/challenges/ChallengesBody';
import ChallengeFooter from 'components/challenges/ChallengesFooter';

import ChallengeIcon from 'components/challenges/ChallengeIcon';
import ChallengesArrowDown from 'components/inline-svgs/challenges/ChallengesArrowDown';

import PictureMappings from 'components/picture-mappings/PictureMappings';
import ChallengeDownloadIcon from 'components/inline-svgs/challenges/ChallengeDownloadIcon';
import PictureMappingsSingleImage from 'components/picture-mappings/PictureMappingsSingleImage';

// Import Selecters
import { getSiteTextDataByGroup, getSiteUrlByName } from 'store/selectors/App.selectors';

// Import Actions
import { triggerContentClick, triggerInteraction } from 'store/actions/EnsightenTracking.actions';

/**
 * Challenge Item Component.
 */

class ChallengeItemCompactedView extends Component {
  render() {
    if (!this.props.data) return '';

    // Strip out the data we need to populate the content item
    const { id, likes, type, details, content, supervision } = this.props.data;
    const { image = '', has_download, download_file } = content;
    const { title = '', description = '' } = details;
    const { name: typeName } = CHALLENGE_TYPES[type];

    // Will need this at some point
    // let imageAlt = thumb_alt != '' ? thumb_alt : `${title} ${content_type}`;
    // let imageTitle = thumb_title != '' ? thumb_title : title;

    // CMS site text variables
    const { download = '', read_less = '', read_more = '', supervision_required = '' } = this.props.localisedText;

    const challengeItemImage =
      image === '' ? (
        <div className="image">
          <PictureMappingsSingleImage src={getUrlStaticImages(`challenges/${typeName}.png`)} imageAlt={''} />
        </div>
      ) : (
        <figure className="image">
          <PictureMappings src={image} sizes={IMAGE_SRC_SIZES.DEFAULT} imageAlt={''} imageTitle={''} />
        </figure>
      );

    return (
      <Link to={`/${this.props.baseUrl}`}>
        <div className={`challenge-item--compacted-view challenge-item--${typeName}`}>
          {challengeItemImage}
          <section className="challenge-item__compacted-title">
            <div className="challenge-item__icon">
              <span className="icon">
                <ChallengeIcon type={typeName} />
              </span>
            </div>
            <div className="challenge-item__title">
              <h2>{title}</h2>
            </div>
          </section>
        </div>
      </Link>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    localisedText: getSiteTextDataByGroup(state, 'challenges'),
    baseUrl: getSiteUrlByName(state, 'challenges'),
  };
};

export default connect(mapStateToProps, null)(ChallengeItemCompactedView);

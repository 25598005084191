import React from 'react';
import { Helmet } from 'react-helmet-async';
import { withRouter } from 'react-router-dom';
import { IS_BASE_URL, BASE_URL, LANGUAGE_CODE } from 'constants/app/AppConstants';

const SiteMetaData = (props) => {
  const { title, description, keywords, location } = props;
  const { pathname = '/' } = location;

  return (
    <Helmet defer={false}>
      <title>{title}</title>
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}
      <link rel="canonical" href={IS_BASE_URL ? `${BASE_URL}${LANGUAGE_CODE}${pathname}` : pathname} />
    </Helmet>
  );
};

export default withRouter(SiteMetaData);

//magic things
import React from 'react';

const MyGoalsLockedIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.29 30.01" className="my-goals-locked-icon">
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <g data-name="Layer 2">
          <g data-name="Layer 1-2">
            <path
              d="M21.67 11.44h-1.86V9a8.67 8.67 0 00-17.33-.62 5.61 5.61 0 000 .58v2.48H.62a.62.62 0 00-.62.62v15.47A2.48 2.48 0 002.48 30h17.33a2.48 2.48 0 002.48-2.48V12.06a.62.62 0 00-.62-.62zM13 24.37a.61.61 0 01-.53.68H9.91a.62.62 0 01-.62-.62v-.07l.45-3.5a2.48 2.48 0 113.94-2 2.45 2.45 0 01-1 2zm3.1-12.93H6.2V9a5 5 0 019.9 0z"
              className="cls-1"
              data-name="Path 1284"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default MyGoalsLockedIcon;

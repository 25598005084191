import {
  BITMOVIN_WITH_TRACKING,
  BITMOVIN_WITH_AD,
  PERFORMANCE_COOKIES_ENABLED,
  FUNCTIONAL_COOKIES_ENABLED,
  ADVERTISING_COOKIES_ENABLED,
  ONETRUST_ACTIVE,
  TOGGLE_BITMOVIN,
} from 'store/actions/OneTrust.actions';

const initState = {
  bitmovinWithTracking: false,
  bitmovinWithAd: false,
  bitmovinStatus: 'play',
  performanceCookiesEnabled: false,
  functionalCookiesEnabled: false,
  advertisingCookiesEnabled: false,
  oneTrustActive: false,
};

const reducer = (state = initState, action) => {
  let nextState = {};
  switch (action.type) {
    case BITMOVIN_WITH_TRACKING: {
      nextState.bitmovinWithTracking = action.payload;
      return { ...state, ...nextState };
    }
    case BITMOVIN_WITH_AD: {
      nextState.bitmovinWithAd = action.payload;
      return { ...state, ...nextState };
    }
    case PERFORMANCE_COOKIES_ENABLED: {
      nextState.performanceCookiesEnabled = action.payload;
      return { ...state, ...nextState };
    }
    case FUNCTIONAL_COOKIES_ENABLED: {
      nextState.functionalCookiesEnabled = action.payload;
      return { ...state, ...nextState };
    }
    case ADVERTISING_COOKIES_ENABLED: {
      nextState.advertisingCookiesEnabled = action.payload;
      return { ...state, ...nextState };
    }
    case ONETRUST_ACTIVE: {
      nextState.oneTrustActive = action.payload;
      return { ...state, ...nextState };
    }
    case TOGGLE_BITMOVIN: {
      nextState.bitmovinStatus = action.payload;
      return { ...state, ...nextState };
    }
  }
  return state;
};

export default reducer;

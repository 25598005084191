import { getMicrositeName, BASE_TEMPLATE } from 'constants/ensighten-tracking/EnsightenTrackingConstants';

const getHomePageData = ({ isPWA }) => {
  let data = { ...BASE_TEMPLATE };
  // data.micrositename = getMicrositeName(isPWA);
  data.page_name = data.page_name + decodeURIComponent(location.pathname);
  data.section = 'home';
  data.subsection = 'home|main';
  data.pagetype = 'main';
  data.content_type = 'home page';
  data.content_title = 'cn climate champions|home page';
  data.pageview_event = 1;
  return data;
};

export default getHomePageData;

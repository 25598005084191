// import constants
import { STATUS_COMPLETED, STATUS_NOT_COMPLETED, STATUS_CANT_DO, STATUS_CAN_DO } from 'constants/challenges/ChallengeItems';
import { INTERACTION } from 'constants/ensighten-tracking/EnsightenTrackingConstants';
import { CONTENT } from 'constants/content-library/ContentLibraryConstants';

// import services
import { challengeLiked, challengeUnliked, challengeCompleted, challengeUncompleted, challengeCancelled, challengeUncancelled } from 'services/apiChallenges';
import { setPlayerData } from 'services/apiPlayer';

// import actions
import {
  setUserLikedChallenge,
  removeUserLikedChallenge,
  setUserCompletedChallenge,
  removeUserCompletedChallenge,
  setUserCancelledChallenge,
  removeUserCancelledChallenge,
  addFirstTimeCompletedChallenge,
  setChallengeCompleteDate,
} from 'store/actions/User.actions';
import { onChallengeUpdate, USER_LEVELS_CHALLENGES_INCREMENT, USER_LEVELS_CHALLENGES_DECREMENT } from 'store/actions/UserLevels.actions';
import { requestAnonSignUp } from 'store/actions/Login.actions';
import { activeAuthModal } from 'store/actions/App.actions';
import { triggerInteraction } from 'store/actions/EnsightenTracking.actions';

// import selectors
import {
  hasUserLikedChallenge,
  hasUserCompletedChallenge,
  getMyGoalsGardenID,
  getMyGoalsData,
  getUserAvatar,
  getIsFirstTimeCompletingChallengeByID,
  getFirstTimeCompletedChallenges,
  getPlannerData,
  getStoredData,
} from 'store/selectors/User.selectors';
import { getUserToken, getUserExternalID } from 'store/selectors/UserAuth.selectors';
import { getLoggedInStatus, getAnonLoggedInStatus } from 'store/selectors/Login.selectors';

/*
  General Functionality
*/
const STORE_LAST_ATTEMPT = {};
const CHANLLENGE_COMPLETED = 'CHANLLENGE_COMPLETED';
const LIKE_CHALLENGE = 'LIKE_CHALLENGE';
const CHALLENGE_CANCELLED = 'CHALLENGE_CANCELLED';

const setUpUserCallback = () => {
  return (dispatch, getState) => {
    if (STORE_LAST_ATTEMPT.type === CHANLLENGE_COMPLETED) {
      dispatch(clickedChallengeCompleted({ challenge_id: STORE_LAST_ATTEMPT.id, challengeEnglishName: STORE_LAST_ATTEMPT.challengeEnglishName, path: '', title: STORE_LAST_ATTEMPT.title }));
    } else if (STORE_LAST_ATTEMPT.type === LIKE_CHALLENGE) {
      dispatch(clickedChallengeLiked({ challenge_id: STORE_LAST_ATTEMPT.id, challengeEnglishName: STORE_LAST_ATTEMPT.challengeEnglishName }));
    } else if (STORE_LAST_ATTEMPT.type === CHALLENGE_CANCELLED) {
      dispatch(clickedChallengeCancelled({ challenge_id: STORE_LAST_ATTEMPT.id, reason: STORE_LAST_ATTEMPT.reason, challengeEnglishName: STORE_LAST_ATTEMPT.challengeEnglishName }));
    }

    // trigger the login/register modal to show
    // & scroll the user to the top so they see it
    dispatch(activeAuthModal());
    window.scrollTo(0, 0);
  };
};

/*
  Display more / reset display of challenges
*/

export const CHALLENGE_DISPLAY_MORE = 'CHALLENGE_DISPLAY_MORE';
export const CHALLENGE_DISPLAY_RESET = 'CHALLENGE_DISPLAY_RESET';
export const CHALLENGE_DISPLAY_UPDATE_COUNT = 'CHALLENGE_DISPLAY_UPDATE_COUNT';

export const displayMoreChallenges = () => ({
  type: CHALLENGE_DISPLAY_MORE,
});

export const displayResetChallenges = () => ({
  type: CHALLENGE_DISPLAY_RESET,
});

export const displayUpdateCountChallenges = () => ({
  type: CHALLENGE_DISPLAY_UPDATE_COUNT,
});

/*
  Filterng challenges functionality
*/
export const CHALLENGE_UPDATE_DROPDOWN_FILTER = 'CHALLENGE_UPDATE_DROPDOWN_FILTER';
export const CHALLENGE_ADD_TYPE_FILTER = 'CHALLENGE_ADD_TYPE_FILTER';
export const CHALLENGE_REMOVE_TYPE_FILTER = 'CHALLENGE_REMOVE_TYPE_FILTER';
export const CHALLENGE_REMOVE_ALL_FILTERS = 'CHALLENGE_REMOVE_ALL_FILTERS';

export const updateDropdownFilter = (type) => {
  return (dispatch, getState) => {
    dispatch(displayMoreChallenges());
    dispatch({ type: CHALLENGE_UPDATE_DROPDOWN_FILTER, payload: type });
  };
};

export const addTypeFilter = (type) => {
  return (dispatch, getState) => {
    dispatch({ type: CHALLENGE_ADD_TYPE_FILTER, payload: type });
  };
};

export const removeTypeFilter = (type) => {
  return (dispatch, getState) => {
    dispatch({ type: CHALLENGE_REMOVE_TYPE_FILTER, payload: type });
  };
};

export const removeAllFilters = () => ({
  type: CHALLENGE_REMOVE_ALL_FILTERS,
});

const checkLoggedInStatus = (state) => {
  return getAnonLoggedInStatus(state) || getLoggedInStatus(state);
};

/* 
  Challenge completed functionality
*/
export const CHANLLENGE_UNCOMPLETED_INVALIDATE = 'CHANLLENGE_UNCOMPLETED_INVALIDATE';
export const CHANLLENGE_UNCOMPLETED_REQUEST = 'CHANLLENGE_UNCOMPLETED_REQUEST';
export const CHANLLENGE_UNCOMPLETED_RECEIVE = 'CHANLLENGE_UNCOMPLETED_RECEIVE';

const _challengeUncompletedEntry = (state, challenge_id, token, external_id) => {
  return (dispatch, getState) => {
    dispatch({ type: CHANLLENGE_UNCOMPLETED_REQUEST });
    challengeUncompleted({ external_id, token, challenge_id }).then((response) => {
      if (response.result === 'SUCCESS') {
        dispatch({ type: CHANLLENGE_UNCOMPLETED_RECEIVE });
        dispatch(removeUserCompletedChallenge(challenge_id));
        dispatch(onChallengeUpdate(USER_LEVELS_CHALLENGES_DECREMENT));
      } else {
        dispatch({ type: CHANLLENGE_UNCOMPLETED_INVALIDATE });
      }
    });
  };
};

export const clickedChallengeUncompleted = ({ challenge_id = '', challengeEnglishName = '' }) => {
  return (dispatch, getState) => {
    if (checkLoggedInStatus(getState())) {
      const state = getState();
      const token = getUserToken(state);
      const external_id = getUserExternalID(state);

      const hasCompleted = hasUserCompletedChallenge(state, challenge_id) === STATUS_COMPLETED;
      if (hasCompleted) {
        return dispatch(_challengeUncompletedEntry(state, challenge_id, token, external_id));
      }
    }
  };
};

/* 
  Challenge completed functionality
*/
export const CHANLLENGE_COMPLETED_INVALIDATE = 'CHANLLENGE_COMPLETED_INVALIDATE';
export const CHANLLENGE_COMPLETED_REQUEST = 'CHANLLENGE_COMPLETED_REQUEST';
export const CHANLLENGE_COMPLETED_RECEIVE = 'CHANLLENGE_COMPLETED_RECEIVE';

const _challengeCompletedEntry = (state, challenge_id, token, external_id) => {
  return (dispatch, getState) => {
    dispatch({ type: CHANLLENGE_COMPLETED_REQUEST });

    challengeCompleted({ external_id, token, challenge_id }).then((response) => {
      if (response.result === 'SUCCESS') {
        dispatch({ type: CHANLLENGE_COMPLETED_RECEIVE });
        dispatch(setUserCompletedChallenge(challenge_id));
        dispatch(onChallengeUpdate(USER_LEVELS_CHALLENGES_INCREMENT));
        dispatch(setChallengeCompleteDate(challenge_id, new Date().getDay() === 0 ? 7 : new Date().getDay()));
      } else {
        dispatch({ type: CHANLLENGE_COMPLETED_INVALIDATE });
      }
    });
  };
};

export const clickedChallengeCompleted = ({ challenge_id = '', challengeEnglishName = '', path, title }) => {
  console.log('clicked', { challenge_id, challengeEnglishName, path, title });
  return (dispatch, getState) => {
    if (checkLoggedInStatus(getState())) {
      const state = getState();
      const token = getUserToken(state);
      const external_id = getUserExternalID(state);

      const hasCompleted = hasUserCompletedChallenge(state, challenge_id) === STATUS_COMPLETED;
      if (!hasCompleted) {
        // Check if first time completing challenge
        console.log(challenge_id, path, title);
        checkFirstTimeChallengeCompleted(state, dispatch, challenge_id, path, title);

        return dispatch(_challengeCompletedEntry(state, challenge_id, token, external_id));
      }
    } else {
      STORE_LAST_ATTEMPT.type = CHANLLENGE_COMPLETED;
      STORE_LAST_ATTEMPT.id = challenge_id;
      STORE_LAST_ATTEMPT.title = title;
      STORE_LAST_ATTEMPT.challengeEnglishName = challengeEnglishName;
      dispatch(requestAnonSignUp(setUpUserCallback));
    }
  };
};

const checkFirstTimeChallengeCompleted = (state, dispatch, id, path, title) => {
  const firstTime = getIsFirstTimeCompletingChallengeByID(state, id);

  if (firstTime) {
    dispatch(addFirstTimeCompletedChallenge(id));
    const currentFirstTimeCompletedChallenges = [...getFirstTimeCompletedChallenges(state)];
    currentFirstTimeCompletedChallenges.push(id);

    dispatch(triggerInteraction({ interactionType: INTERACTION.CLICK_CHALLENGE_COMPLETE, contentType: CONTENT.CHALLENGE, path, id, title, firstTime: true }));

    const data = getStoredData(state);

    const token = getUserToken(state);
    const external_id = getUserExternalID(state);

    setPlayerData({ external_id, token, data }).catch((error) => {
      return { result: 'FAILURE', reason: error };
    });
  } else {
    dispatch(triggerInteraction({ interactionType: INTERACTION.CLICK_CHALLENGE_COMPLETE, contentType: CONTENT.CHALLENGE, path, id, title }));
  }
};

/*
  Cancel Challenge functionality
  --
  AKA - user has set the challenge as 'I can't do'
*/

/* 
  Challenge uncancelled functionality
*/
export const CHANLLENGE_UNCANCELLED_INVALIDATE = 'CHANLLENGE_UNCANCELLED_INVALIDATE';
export const CHANLLENGE_UNCANCELLED_REQUEST = 'CHANLLENGE_UNCANCELLED_REQUEST';
export const CHANLLENGE_UNCANCELLED_RECEIVE = 'CHANLLENGE_UNCANCELLED_RECEIVE';

const _challengeUncancelledEntry = (state, challenge_id, token, external_id) => {
  return (dispatch, getState) => {
    dispatch({ type: CHANLLENGE_UNCANCELLED_REQUEST });
    challengeUncancelled({ external_id, token, challenge_id }).then((response) => {
      if (response.result === 'SUCCESS') {
        dispatch({ type: CHANLLENGE_UNCANCELLED_RECEIVE });
      } else {
        dispatch({ type: CHANLLENGE_UNCANCELLED_INVALIDATE });
      }
    });
  };
};

export const clickedChallengeUncancelled = ({ challenge_id = '', challengeEnglishName = '' }) => {
  return (dispatch, getState) => {
    if (checkLoggedInStatus(getState())) {
      const state = getState();
      const token = getUserToken(state);
      const external_id = getUserExternalID(state);

      return dispatch(_challengeUncancelledEntry(state, challenge_id, token, external_id));
    }
  };
};

export const confirmRemoveUsersCancelledChallenge = (challenge_id) => {
  return (dispatch, getState) => {
    dispatch(removeUserCancelledChallenge(challenge_id));
  };
};

/* 
  Challenge cancelled functionality
*/
export const CHANLLENGE_CANCELLED_INVALIDATE = 'CHANLLENGE_CANCELLED_INVALIDATE';
export const CHANLLENGE_CANCELLED_REQUEST = 'CHANLLENGE_CANCELLED_REQUEST';
export const CHANLLENGE_CANCELLED_RECEIVE = 'CHANLLENGE_CANCELLED_RECEIVE';

const _challengeCancelledEntry = (state, challenge_id, token, external_id, reason) => {
  return (dispatch, getState) => {
    dispatch({ type: CHANLLENGE_CANCELLED_REQUEST });

    challengeCancelled({ external_id, token, challenge_id, reason }).then((response) => {
      if (response.result === 'SUCCESS') {
        dispatch({ type: CHANLLENGE_CANCELLED_RECEIVE });
      } else {
        dispatch({ type: CHANLLENGE_CANCELLED_INVALIDATE });
      }
    });
  };
};

export const clickedChallengeCancelled = ({ challenge_id = '', reason = '', challengeEnglishName = '' }) => {
  return (dispatch, getState) => {
    if (checkLoggedInStatus(getState())) {
      const state = getState();
      const token = getUserToken(state);
      const external_id = getUserExternalID(state);

      return dispatch(_challengeCancelledEntry(state, challenge_id, token, external_id, reason));
    } else {
      // TODO: SETUP THIS UP
      STORE_LAST_ATTEMPT.type = CHALLENGE_CANCELLED;
      STORE_LAST_ATTEMPT.id = challenge_id;
      STORE_LAST_ATTEMPT.reason = reason;
      STORE_LAST_ATTEMPT.challengeEnglishName = challengeEnglishName;
      dispatch(requestAnonSignUp(setUpUserCallback));
    }
  };
};

export const confirmUsersCancelledChallenge = (challenge_id) => {
  return (dispatch, getState) => {
    dispatch(setUserCancelledChallenge(challenge_id));
  };
};

/*
  Liking challenges functionality
*/
export const CHANLLENGE_LIKE_INVALIDATE = 'CHANLLENGE_LIKE_INVALIDATE';
export const CHANLLENGE_LIKE_REQUEST = 'CHANLLENGE_LIKE_REQUEST';
export const CHANLLENGE_LIKE_RECEIVE = 'CHANLLENGE_LIKE_RECEIVE';

export const CHANLLENGE_UNLIKE_INVALIDATE = 'CHANLLENGE_UNLIKE_INVALIDATE';
export const CHANLLENGE_UNLIKE_REQUEST = 'CHANLLENGE_UNLIKE_REQUEST';
export const CHANLLENGE_UNLIKE_RECEIVE = 'CHANLLENGE_UNLIKE_RECEIVE';

const _challengeLikeEntry = (state, challenge_id, token, external_id) => {
  return (dispatch, getState) => {
    dispatch({ type: CHANLLENGE_LIKE_REQUEST });

    challengeLiked({ external_id, token, challenge_id }).then((response) => {
      if (response.result === 'SUCCESS') {
        dispatch({ type: CHANLLENGE_LIKE_RECEIVE });
        dispatch(setUserLikedChallenge(challenge_id));
      } else {
        dispatch({ type: CHANLLENGE_LIKE_INVALIDATE });
      }
    });
  };
};

const _challengeUnlikeEntry = (state, challenge_id, token, external_id) => {
  return (dispatch) => {
    dispatch({ type: CHANLLENGE_UNLIKE_REQUEST });
    challengeUnliked({ external_id, token, challenge_id }).then((response) => {
      if (response.result === 'SUCCESS') {
        dispatch({ type: CHANLLENGE_UNLIKE_RECEIVE });
        dispatch(removeUserLikedChallenge(challenge_id));
      } else {
        dispatch({ type: CHANLLENGE_UNLIKE_INVALIDATE });
      }
    });
  };
};

export const clickedChallengeLiked = ({ challenge_id, challengeEnglishName = '' }) => {
  return (dispatch, getState) => {
    const isLoggedIn = getLoggedInStatus(getState());

    if (checkLoggedInStatus(getState())) {
      const state = getState();
      const token = getUserToken(state);
      const external_id = getUserExternalID(state);

      // check if previously liked - if so unlike
      const hasLiked = hasUserLikedChallenge(state, challenge_id);
      if (hasLiked) {
        return dispatch(_challengeUnlikeEntry(state, challenge_id, token, external_id));
      } else {
        // dispatch(triggerInteraction({ interactionType: INTERACTION.CLICK_EMOJI, pageType: PAGE.SUB, contentType, id: entryID, emojiname: emojiEnglishName }));
        return dispatch(_challengeLikeEntry(state, challenge_id, token, external_id));
      }
    } else {
      STORE_LAST_ATTEMPT.type = LIKE_CHALLENGE;
      STORE_LAST_ATTEMPT.id = challenge_id;
      STORE_LAST_ATTEMPT.challengeEnglishName = challengeEnglishName;
      dispatch(requestAnonSignUp(setUpUserCallback));
    }
  };
};

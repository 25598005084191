//magic things
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
// Import constants
import { dispatchEnsightenPageLoad } from 'constants/app/AppServices';

// import container
import PageChallenges from 'containers/pages/PageChallenges';

// Import component
import ErrorBoundary from 'components/custom/ErrorBoundary';

class ChallengesRouter extends Component {
  componentDidMount() {
    const { adTrigger } = this.props;
    dispatchEnsightenPageLoad(adTrigger);
  }

  render() {
    const { match, adTrigger } = this.props;

    return (
      <React.Fragment>
        <Switch location={this.props.location}>
          <Route
            path={`${match.path}/:urlFragment`}
            render={(routeProps) => (
              <ErrorBoundary>
                <PageChallenges {...routeProps} adTrigger={adTrigger} />
              </ErrorBoundary>
            )}
          />
          <Route path={`${match.path}`} component={PageChallenges} />} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default withRouter(ChallengesRouter);

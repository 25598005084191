import { BOOTSTRAP_PAGE_CONTENT } from 'store/actions/Bootstrap.actions';

const initState = {
  allIDs: [],
  byID: {},
  externalContent: [],
  internalContent: [],
  heroID: null,
  marqueData: {},
};

const Homepage = (state = initState, action) => {
  let nextState = {};
  switch (action.type) {
    case BOOTSTRAP_PAGE_CONTENT: {
      const { data, marqueData } = action.payload.homepage;
      const { allIDs, byID, externalContent, internalContent } = data;
      // nextState.heroID = allIDs.shift(); // first item is hero
      nextState.allIDs = allIDs; // rest are content
      nextState.byID = byID;
      nextState.externalContent = externalContent;
      nextState.internalContent = internalContent;
      nextState.marqueData = marqueData;
      return { ...state, ...nextState };
    }
  }
  return state;
};

export default Homepage;

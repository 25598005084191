import React, { useState, useEffect } from 'react';
import { getUrlStaticImages } from 'constants/app/AppUrls';

import { LOCAL_STORAGE_OPTIONS } from 'constants/app/AppConstants';
const STORAGE_NAME = `${LOCAL_STORAGE_OPTIONS.namespace}-my_goals`;

const MyGoalsIntro = ({ nextStep, callback, text, onboardingIntro, setOnboarding }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [showLetsGoStep1, updateShowLetsGoStep1] = useState(onboardingIntro.step_1);
  const [showLetsGoStep2, updateShowLetsGoStep2] = useState(onboardingIntro.step_2);
  const steps = 3;

  const checkUsersViewing = (seenStep) => {
    if (seenStep === 1 && !showLetsGoStep1) {
      setOnboarding('step_1');
    }

    if (seenStep === 2 && !showLetsGoStep2) {
      setOnboarding('step_2');
    }
  };

  useEffect(() => {
    if (currentStep === steps - 1) {
      return;
    }
    const next = (currentStep + 1) % steps;
    const id = setTimeout(() => {
      updateStep(next);
    }, 3000);
    return () => clearTimeout(id);
  }, [currentStep]);

  const updateStep = (target) => {
    checkUsersViewing(target);
    setCurrentStep(target);
  };

  const navTo = (target) => {
    updateStep(target);
  };

  const { welcome_text, goals_title, step_1_text, step_1_title, step_2_text, step_2_title, step_3_text, step_3_title, lets_go_button } = text;

  return (
    <div className="mygoals_intro">
      <img src={getUrlStaticImages('my-goals/introbg_small.jpg')} className="mygoals_intro__bg" alt="Intro Background Image" />
      <section className="mygoals_intro__body">
        <div className="mygoals_introheader">
          {welcome_text}
          <span>{goals_title}</span>
        </div>
        <div className="mygoals_steps">
          {currentStep === 0 && (
            <div className="mygoals_step">
              <div className="mygoals_stepcopy">
                <span>{step_1_title}</span>
                <div dangerouslySetInnerHTML={{ __html: step_1_text }}></div>
              </div>
              <div className="mygoals_stepimage">
                <img src={getUrlStaticImages('my-goals/step_1.png')} alt="Choose your garden" />
              </div>
              {showLetsGoStep1 && (
                <div className="mygoals_steps__button">
                  <button className="authModal__button" aria-label="Register" onClick={() => callback(nextStep)}>
                    {lets_go_button}
                  </button>
                </div>
              )}
            </div>
          )}

          {currentStep === 1 && (
            <div className="mygoals_step">
              <div className="mygoals_stepcopy">
                <span>{step_2_title}</span>
                <div dangerouslySetInnerHTML={{ __html: step_2_text }}></div>
              </div>
              <div className="mygoals_stepimage">
                <img className="breakOut" src={getUrlStaticImages('my-goals/step_2.png')} alt="Grow your plants" />
              </div>

              {showLetsGoStep2 && (
                <div className="mygoals_steps__button">
                  <button className="authModal__button" aria-label="Register" onClick={() => callback(nextStep)}>
                    {lets_go_button}
                  </button>
                </div>
              )}
            </div>
          )}
          {currentStep === 2 && (
            <div className="mygoals_step">
              <div className="mygoals_stepcopy">
                <span>{step_3_title}</span>
                <div dangerouslySetInnerHTML={{ __html: step_3_text }}></div>
              </div>
              <div className="mygoals_stepimage">
                <img src={getUrlStaticImages('my-goals/step_3.png')} alt="Check progess and download rewards" />
              </div>

              <button className="authModal__button" aria-label="Register" onClick={() => callback(nextStep)}>
                {lets_go_button}
              </button>
            </div>
          )}

          <div className="mygoals_stepcontrol">
            <span className={`${currentStep === 0 ? 'active' : ''}`} onClick={() => navTo(0)}>
              &#9679;
            </span>
            <span className={`${currentStep === 1 ? 'active' : ''}`} onClick={() => navTo(1)}>
              &#9679;
            </span>
            <span className={`${currentStep === 2 ? 'active' : ''}`} onClick={() => navTo(2)}>
              &#9679;
            </span>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MyGoalsIntro;

export const hasSavedXpForContentItem = (state, id) => {
  return state.userExperience.byID.hasOwnProperty(id);
};

export const getLevelUpContenType = (state, id) => {
  return state.userExperience.levelUpContentType;
};

export const getTotalXP = (state) => {
  /*
   * TODO : should really add this up each time - loop through byID and add up xp
   */
  return state.userExperience.totalXP;
};

export const getCompletedLevels = (state) => {
  return state.userExperience.completedLevels;
};

export const getCheckRewardCompeletedLevel = (state, id) => {
  let allowedReward = false;
  const completedLevels = getCompletedLevels(state);

  completedLevels.map((level) => {
    if (level === id) {
      allowedReward = true;
    }
  });

  return allowedReward;
};

export const hasViewedLevelUpStatus = (state) => {
  return state.userExperience.viewedLevelUpStatus;
};

export const getLevelUpID = (state) => {
  const completedLevels = state.userExperience.completedLevels;
  return Array.isArray(completedLevels) ? completedLevels[completedLevels.length - 1] : '';
};

export const getCurrentLevelID = (state) => {
  return state.userExperience.currentLevelID;
};

export const hasCompeletedCurrentLevelID = (state, id) => {
  const completedLevels = getCompletedLevels(state);

  return (
    completedLevels.find((level) => {
      return level === id;
    }) || false
  );
};

const parsePlaylist = (playlist) => {
  const { data, id, name, videos } = playlist;

  // data: seems to hold the 'more title' - im not sure what the point of this is
  const { more_videos_title = '' } = data;

  return { more_title: more_videos_title, id, title: name, content: videos };
};

export const parseVideoPlaylist = (contentData, key) => {
  const content = contentData[key] || {};
  const allIDs = [];
  const byID = {};

  for (const playlist of content) {
    const { id } = playlist;
    allIDs.push(id);
    const content = parsePlaylist(playlist);
    byID[id] = { ...content };
  }
  return { allIDs, byID };
};

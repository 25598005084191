//magic things
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getDevice } from 'utils/deviceDetection';

// import constants
import { PAGES } from 'constants/content-library/ContentLibraryConstants';
import { PAGE } from 'constants/app/AppConstants';
import { getUrlStaticImages } from 'constants/app/AppUrls';

// import components
import SiteMetaData from 'components/generic/SiteMetaData';
import Manifesto from 'components/manifesto/Manifesto';
import Challenges from 'components/challenges/Challenges';
import MyGoals from 'components/my-goals/MyGoals';
import PageOfflineMessage from 'containers/pages/PageOfflineMessage';

//import selectors
import { getSiteMetaData, getSiteTextDataByGroupedKey, getOnlineStatus } from 'store/selectors/App.selectors';

// import actions
import { triggerPageView } from 'store/actions/EnsightenTracking.actions';
import { activeMobileNav } from 'store/actions/App.actions';

class PageMyGoals extends Component {
  constructor(props) {
    super(props);

    this.toggleFullscreenClass = this.toggleFullscreenClass.bind(this);
    this.isMobile = getDevice() === 'smartphone';
    this.state = {
      isFullscreen: true,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    // Setup ensighten tracking stuff
    this.props.triggerPageView({ contentType: PAGES.GARDEN, pageType: PAGE.LANDING });

    if (this.isMobile) {
      this.toggleFullscreenClass();
    }
  }

  componentWillUnmount() {
    if (document.getElementsByTagName('body')[0].classList.contains('my-goals')) {
      document.getElementsByTagName('body')[0].classList.remove('my-goals');
    }
  }

  toggleFullscreenClass() {
    if (document.getElementsByTagName('body')[0].classList.contains('my-goals')) {
      document.getElementsByTagName('body')[0].classList.remove('my-goals');
      this.setState({ isFullscreen: false });
      this.props.activeMobileNav();
    } else {
      document.getElementsByTagName('body')[0].classList.add('my-goals');
      this.setState({ isFullscreen: true });
    }
  }

  render() {
    const { siteText, pageTitle } = this.props;
    return (
      <section className={`main-content`}>
        <SiteMetaData {...this.props.metadata} />
        {this.props.isOnline ? (
          <>
            <div className="mygoalspage__frame container">
              <MyGoals isMobile={this.isMobile} toggleFullscreenClass={this.toggleFullscreenClass} isFullscreen={this.state.isFullscreen} />
            </div>
            {!this.isMobile && (
              <React.Fragment>
                <section className="mygoalspage__container">
                  <div className="container">
                    <h1>{pageTitle}</h1>
                    <section className="mygoalspage__featured-items">
                      <div className="mygoalspage__challenges">
                        <Challenges compactedView={true} />
                      </div>
                      <div className="mygoalspage__manifesto">
                        <Manifesto type={'small'} />
                      </div>
                    </section>
                  </div>
                </section>
              </React.Fragment>
            )}
          </>
        ) : (
          <PageOfflineMessage />
        )}
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    triggerPageView: (opts) => {
      dispatch(triggerPageView(opts));
    },
    activeMobileNav: () => {
      dispatch(activeMobileNav());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    metadata: getSiteMetaData(state, PAGES.MY_GOALS),
    pageTitle: getSiteTextDataByGroupedKey(state, 'mappage', 'title'),
    isOnline: getOnlineStatus(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageMyGoals);

import React from 'react';
import { getUrlDynamic, getUrlFrontend, getUrlStatic } from 'constants/app/AppUrls';

const FigureImage = (props) => {
  const { image, imageCaption, dynamicImage, staticImage, alt } = props;

  return (
    <figure>
      <img src={dynamicImage ? getUrlDynamic(image) : staticImage ? getUrlStatic(image) : image} alt={alt ? alt : ''} />
      {imageCaption && <figcaption>{imageCaption}</figcaption>}
    </figure>
  );
};

export default FigureImage;

import React from 'react';

const ChallengesRenewablesIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.46 55.47" className="challenge-renewables-icon">
    <g data-name="Layer 2">
      <g data-name="Layer 1" fillRule="evenodd">
        <path
          fill="#c7eef9"
          d="M48.72 34.48l6.74-3.66-6.06-5.33 4.71-6.1-7.61-2.38h-.01l1.81-7.49-7.95.84-1.2-7.72-7.24 4.04h-.01L27.37 0l-5.04 6.84-7.56-3.48-1.07 7.79-7.71-.99L8 18.01.64 20.58l4.9 6.53L0 32.18l6.85 3.86-2.87 6.65 7.9 1.08v7.63l7.49-2.58 3.2 6.65 5.89-5.17 5.62 4.99 3.1-7.13 7.23 2.23v-7.95l7.52-.74-3.21-7.21v-.01z"
        />
        <path
          d="M43.09 12.72a21.22 21.22 0 00-15.56-6.45A21.25 21.25 0 0012 12.72a21.22 21.22 0 00-6.48 15.57A21.21 21.21 0 0012 43.86a21.22 21.22 0 0015.57 6.45 21.18 21.18 0 0015.56-6.45 21.19 21.19 0 006.46-15.57 21.2 21.2 0 00-6.5-15.57z"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export default ChallengesRenewablesIcon;

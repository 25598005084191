import React, { Component } from 'react';
import { connect } from 'react-redux';
import ChallengeType from 'components/challenges/ChallengeType';
import { CHALLENGE_DISPLAY_ORDER, CHALLENGE_TYPES } from 'constants/challenges/ChallengeTypes';

// import selectors
import { challengeCountByTypeID } from 'store/selectors/Challenges.selectors';

/**
 * Challenge Types Component.
 */

class ChallengeTypes extends Component {
  constructor() {
    super();

    this.clickHanlder = this.clickHanlder.bind(this);
  }

  clickHanlder(e) {
    const activeFilterType = e.currentTarget.getAttribute('data-name');
    this.props.filterCallback(activeFilterType);
  }

  render() {
    const { filtersTitle = null, avaiableTypes } = this.props;
    return (
      <ul className="flex challenge-type-filter">
        {avaiableTypes.map((challenge) => {
          return (
            <ChallengeType
              key={challenge}
              name={CHALLENGE_TYPES[challenge].name}
              localeName={filtersTitle != null ? filtersTitle['filter_title_' + challenge] : ''}
              callback={this.clickHanlder}
              activeFilterTypes={this.props.activeFilterTypes}
            />
          );
        })}
      </ul>
    );
  }
}

const mapStateToProps = (state) => {
  const avaiableTypes = CHALLENGE_DISPLAY_ORDER.filter((type) => {
    return challengeCountByTypeID(state, type) > 0;
  });
  return {
    avaiableTypes,
  };
};

export default connect(mapStateToProps, null)(ChallengeTypes);

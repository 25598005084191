import { APP_CONFIG, COUNTRY, DEVICE_TYPE, ORIENTATION, REGION, SUBREGION, MARKET } from 'constants/app/AppConstants';
import { getDomain, getDomainLocale, parseData } from 'constants/ensighten-tracking/EnsightenTrackingServices';

export const getMicrositeName = (isPWA) => {
  return isPWA ? `cn climate champions|${parseData(APP_CONFIG.language_code)} pwa` : `cn climate champions|${parseData(APP_CONFIG.language_code)}`;
};

// Coming Soon
// ***************
export const COMING_SOON_PAGE_VIEW_TEMPLATE = {
  page_name: parseData(APP_CONFIG.language_code) + '/cn climate champions/' + getDomain,
  section: 'coming soon',
  subsection: 'coming soon|main',
  user_lang: parseData(APP_CONFIG.language_code) + '|default',
  domain: 'web|' + getDomain,
  market_region: parseData(REGION),
  market: parseData(COUNTRY),
  content_type: 'coming soon page',
  content_title: 'cn climate champions|coming soon',
  brand: 'cartoon network',
  franchise: 'cn climate champions',
  previous_page: '',
  pageview_event: 1,
};

export const COMING_SOON_INTERACTION_TEMPLATE = {
  page_name: parseData(APP_CONFIG.language_code) + '/cn climate champions/' + getDomain,
  section: 'coming soon',
  subsection: 'coming soon|main',
  user_lang: parseData(APP_CONFIG.language_code) + '|default',
  domain: 'web|' + getDomain,
  market_region: parseData(REGION),
  market: parseData(COUNTRY),
  content_type: 'coming soon page',
  content_title: 'cn climate champions|coming soon',
  brand: 'cartoon network',
  franchise: 'cn climate champions',
  interaction: 'cn climate champions|cta',
  interactionevent: 1,
};
// ***************

export const VIDEO_TEMPLATE = {
  page_name: parseData(APP_CONFIG.language_code) + '/cn climate champions/' + getDomain,
  section: '',
  subsection: '',
  content_type: '',
  franchise: 'cn climate champions',
  friendlyName: '',
  market_region: parseData(REGION),
  market: parseData(COUNTRY),
  brand: 'cartoon network',
};

export const AD_TEMPLATE = {
  ad_id: '',
  ad_duration: 0,
  ad_type: 'preroll',
};

export const BASE_TEMPLATE = {
  page_name: parseData(APP_CONFIG.language_code) + '/cn climate champions/' + getDomain,
  section: '',
  subsection: '',
  user_lang: parseData(APP_CONFIG.language_code) + '|default',
  domain: 'web|' + getDomain,
  market_region: parseData(REGION),
  market: parseData(COUNTRY),
  content_type: 'section front',
  content_title: 'cn climate champions|',
  brand: 'cartoon network',
  franchise: 'cn climate champions',
  previous_page: '',
};

export const INTERACTION = {
  SUCCESSFUL_SIGN_UP: 'SUCCESSFUL.SIGN_UP',
  SUCCESSFUL_LOGIN: 'SUCCESSFUL.LOGIN',
  CLICK_MAP_MARKER: 'CLICK.MAP_MARKER',
  CLICK_EMOJI: 'CLICK.EMOJI',
  CLICK_UNLIKE_EMOJI: 'CLICK.UNLIKE_EMOJI',
  CLICK_CHALLENGE_COMPLETE: 'CLICK.CHALLENGE_COMPLETE',
  CLICK_CHALLENGE_LIKE: 'CLICK.CHALLENGE_LIKE',
  CLICK_CHALLENGE_UNLIKE: 'CLICK.CHALLENGE_UNLIKE',
  CLICK_CHALLENGE_SHARE: 'CLICK.CHALLENGE_SHARE',
  REWARD_UNLOCKED: 'REWARD.UNLOCKED',
  LEVEL_UP: 'REWARD.LEVEL_UP',
  CLICK_CHALLENGE_UNCOMPLETE: 'CLICK.CHALLENGE_UNCOMPLETE',
  CLICK_SCHOOLS_LINK: 'CLICK.SCHOOLS_LINK',
};

export const SECTION_LOOKUP = {
  home: 'home',
  map: 'map',
  videos: 'watch videos',
  challenges: 'challenges',
  garden: 'garden',
  schools: 'schools',
  quizzes: 'quizzes',
  custom1: 'custom1',
  custom2: 'custom2',
  faq: 'faq',
  my_goals: 'garden',
};

export const SUBSECTION_LOOKUP = {
  home: { section: 'home', subsection: 'home' },
  map: { section: 'map', subsection: 'map' },
  quiz: { section: 'quizzes', subsection: 'quizzes' },
  video: { section: 'watch videos', subsection: 'watch videos' },
  challenges: { section: 'challenges', subsection: 'challenges' },
  garden: { section: 'garden', subsection: 'garden' },
  schools: { section: 'schools', subsection: 'schools' },
  faq: { section: 'faq', subsection: 'faq' },
  my_goals: { section: 'garden', subsection: 'garden' },
};

export const CLICK_EVENTS = {
  sign_up: 'sign up success',
  login: 'login success',
  map_marker: 'map detail|',
  downloads: 'downloadclickedevent',
  download: 'downloadclickedevent',
  game: 'gameclickedevent',
  toptips: 'toptipsclick',
};

export const TURNER_METADATA = window.turner_metadata || false;
export const TRACKING_ARRAY = (TURNER_METADATA && window.turner_metadata.trackAction) || [];

/**
 * "quiz|quiz detail"
 * "videos|video detail"
 * "downloads|download detail"
 * "apps|app detail"
 * "games|games detail"
 */

/**
 * Notes
 * Data sets to be used for tracking
 * Notes: All values to be in English and lowercase

 * PAGE VIEW DATASET
 * Notes:
 * - PageName value:
 * values: is the domain + name of page & sub-page

 * - App value:
 * values: "0" is web based view & "-1" is app based view

 * - previouspage value:
 * values: this stays as ''. this shoudl be handle ensightens end
 */

import React, { useEffect, useState } from 'react';
const DEFAULT_ONBOARDING_TIMEOUT_SECONDS = 10;

const MyGoalsLevel = ({ text, callback, stepOneSeen, stepTwoSeen }) => {
  const [showNextTip, updateShowNextTip] = useState(false);
  let timer = null;

  useEffect(() => {
    // timer = setTimeout(() => {
    //   if (showNextTip) {
    //     callback();
    //   } else {
    //     updateShowNextTip(true);
    //   }
    // }, DEFAULT_ONBOARDING_TIMEOUT_SECONDS * 1000);
    //
    // return () => {
    //   clearTimeout(timer);
    // };
  }, [showNextTip]);

  const { top_tip_title, tip_1_description, tip_2_description } = text;

  if (!stepOneSeen) {
    return (
      <div className="my-goals__onboarding__tip-1">
        <div className="title">{top_tip_title}</div>
        <div className="description" dangerouslySetInnerHTML={{ __html: tip_1_description }} />
      </div>
    );
  } else if (!stepTwoSeen) {
    return (
      <div className="my-goals__onboarding__tip-2">
        <div className="title">{top_tip_title}</div>
        <div className="description" dangerouslySetInnerHTML={{ __html: tip_2_description }} />
      </div>
    );
  } else {
    return '';
  }
};

export default MyGoalsLevel;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSiteTextDataByGroup, getExitSiteModalLink } from 'store/selectors/App.selectors';
import { activeExitSiteModal } from 'store/actions/App.actions';

class SiteExitModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countdownRunning: false,
      seconds: 7,
      countdownTimer: null,
      countdownPlaceholder: '',
    };

    this.countdownRef = React.createRef();
    this.bindedCountdown = this.countdown.bind(this);
    this.bindedCloseModal = this.closeModal.bind(this);
  }

  countdown() {
    if (this.state.seconds == 0) {
      clearTimeout(this.state.countdownTimer);

      // go to link
      window.open(this.props.link, '_blank');
      this.bindedCloseModal();
    } else {
      if (this.state.countdownPlaceholder != '') {
        // get the locale text for seconds
        // replace {x} with the remaining seconds
        this.countdownRef.current.innerHTML = this.state.countdownPlaceholder.replace('{x}', this.state.seconds);
      }

      this.setState({
        seconds: this.state.seconds - 1,
      });
    }
  }

  closeModal() {
    // Close ths modal
    this.props.activeExitSiteModal();
  }

  componentDidMount() {
    const { countdown = '' } = this.props.exitSiteText;

    if (countdown != '') {
      this.setState({
        countdownPlaceholder: countdown,
      });
    }

    if (!this.state.countdownRunning) {
      // this.state.countdownTimer = setInterval(this.bindedCountdown, 1000);
      this.setState({
        countdownTimer: setInterval(this.bindedCountdown, 1000),
        countdownRunning: true,
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.state.countdownTimer);
    this.setState({
      seconds: 7,
      countdownRunning: false,
    });
  }

  render() {
    const { exitSiteText, link } = this.props;
    const { description, countdown = '', continue_text, title, go_back } = exitSiteText;

    return (
      <section className="modal--exit-site modal__container flex flex-wrap justify-content-center align-items-start">
        <div className="modal">
          <div className="modal__header">
            <div className="modal__header__title">{title}</div>
            <div className="modal__header__description">{description}</div>
          </div>
          <div className="modal__countdown" ref={this.countdownRef}>
            {countdown != '' ? countdown.replace('{x}', this.state.seconds) : ''}
          </div>
          <div className="modal__buttons">
            <button className="modal__buttons__exit" onClick={this.bindedCloseModal} aria-label={go_back}>
              {go_back}
            </button>
            <a href={link} target="_blank" aria-label={description} onClick={this.bindedCloseModal} rel="noreferrer noopener">
              <button className="modal__buttons__continue" aria-label={continue_text}>
                {continue_text}
              </button>
            </a>
          </div>
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    activeExitSiteModal: () => {
      dispatch(activeExitSiteModal());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    link: getExitSiteModalLink(state),
    exitSiteText: getSiteTextDataByGroup(state, 'exit_site'),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteExitModal);

import React, { useEffect } from 'react';
import LegelInfoModal from 'containers/modal/LegalInfoModal';
import { getUrlStaticImages } from 'constants/app/AppUrls';
import { Link } from 'react-router-dom';

let listenersAdded = false;

const FooterLegal = (props) => {
  const { legalLinks, attachOneTrustListeners } = props;

  function addListeners() {
    if (!listenersAdded) {
      listenersAdded = true;
      attachOneTrustListeners();
    }
  }

  useEffect(() => {
    if (listenersAdded) {
      attachOneTrustListeners();
    }
  }, []);

  const renderLinks = legalLinks.map((link, index) => {
    const { clazz = '', legal_link, legal_target, legal_link_text, oneTrustLink = false, info_target = '' } = link;

    if (oneTrustLink) {
      listenersAdded = true;
    }

    if (info_target === 'popup') {
      return (
        <li key={index} className="legal__link">
          <LegelInfoModal {...link} />
        </li>
      );
    }
    if (info_target === 'faq') {
      return (
        <li key={index} className="legal__link">
          <Link key={index} to={props.faqLink} className="legal__link link">
            {legal_link_text}
          </Link>
        </li>
      );
    }
    return (
      <li key={index} className="legal__link">
        <a className={`${clazz} link`} href={legal_link} target={legal_target} rel="noreferrer noopener">
          {legal_link_text}
        </a>
      </li>
    );
  });

  return (
    <section className="legal flex-grow-2">
      <ul className="legal__links flex flex-wrap">{renderLinks}</ul>
    </section>
  );
};

export default FooterLegal;

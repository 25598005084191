import React from 'react';

// import components
import NavLoginIcon from 'components/inline-svgs/navigation/NavLoginIcon';
import ChallengeDownloadIcon from 'components/inline-svgs/challenges/ChallengeDownloadIcon';

const Logout = ({ membershipCard, logoutCallback, logoutText }) => {
  const { logout_body_1 = '', logout_body_2 = '', logout_button = '', logout_download = '', logout_title = '' } = logoutText;

  return (
    <>
      <div className="authModal__header authModal__center">{logout_title}</div>
      <p className="authModal__center">{logout_body_1}</p>
      <p className="authModal__center">{logout_body_2}</p>

      <div className="authModal__buttons authModal__center">
        <button className="authModal__button authModal__button_welcome authModal_button__login" aria-label={logout_button} onClick={logoutCallback}>
          <NavLoginIcon /> {logout_button}
        </button>
        <button className="authModal__button authModal__button_download" aria-label={logout_download}>
          <a href={membershipCard} target="_blank" rel="noreferrer" download="cc_membership_card">
            <div className="icon">
              <ChallengeDownloadIcon />
            </div>{' '}
            {logout_download}
          </a>
        </button>
      </div>
    </>
  );
};

export default Logout;

import { SITE_STATUS } from 'constants/app/AppConstants';
import { isEmptyObj } from 'utils/general';

// APP Actions
import { BOOTSTRAP_INIT, completeBootstrap } from 'store/actions/App.actions';
// Setup import items
// Mainly App based imports
import * as AppServices from 'constants/app/AppServices';
// Import Dispatches
import { setHeroSwitchData } from 'store/actions/HeroSwitch.actions';
import {
  BOOTSTRAP_ADS_DATA,
  dispatchConfigData,
  dispatchContentData,
  dispatchNavigationData,
  getNormaliseComingSoonContentData,
  dispatchComingSoonData,
  dispatchIdlePromptData,
  dispatchShowcaseMapData,
  dispatchVideoPlaylistData,
} from 'store/actions/Bootstrap.actions';
import { ENSIGHTEN_TRACKING_STATUS } from 'store/actions/EnsightenTracking.actions';

// Bootstrap stuff
const BootstrapFlow =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    switch (action.type) {
      case BOOTSTRAP_INIT:
        {
          const JsonData = action.payload;
          doBootstrap({ dispatch, getState, JsonData });
        }
        break;
    }
  };

const doBootstrap = ({ dispatch, getState, JsonData }) => {
  const configData = AppServices.getNormalisePublicConfigData(JsonData); // Config data
  const contentData = AppServices.getNormalisePublicContentData(JsonData); // Content data
  const comingSoonData = AppServices.getNormaliseComingSoonContentData(JsonData); //Coming soon data
  const adData = AppServices.getNormalisedPublicAdsData(JsonData);
  dispatchComingSoonData(dispatch, comingSoonData);

  const showcaseMapData = AppServices.getNormaliseShowcaseMapContentData(JsonData);

  dispatchShowcaseMapData(dispatch, showcaseMapData);

  // *******
  // Dispatch Config data
  // *******
  dispatchConfigData(dispatch, configData);

  if (SITE_STATUS !== 'coming_soon') {
    // *******
    // Dispatch Content data
    // *******
    dispatchContentData(dispatch, contentData);

    // *******
    // Dispatch Nav data
    // *******
    dispatchNavigationData(dispatch, configData, contentData);

    // *******
    // Dispatch Video Playlist data
    // *******
    dispatchVideoPlaylistData(dispatch, contentData);
  }

  // *******
  // Dispatch Ads data
  // *******
  if (adData && !isEmptyObj(adData)) {
    dispatch({ type: BOOTSTRAP_ADS_DATA, payload: adData });
  }

  // *******
  // Dispatch Trcaking Status
  // *******
  dispatch({ type: ENSIGHTEN_TRACKING_STATUS, payload: configData.tracking });
  dispatch(completeBootstrap());
};

export default [BootstrapFlow];

import React from 'react';
import FigureImage from 'components/generic/FigureImage';
import AppExitSiteLink from 'containers/base/AppExitSiteLink';

const FooterLogos = ({ logos = [] }) => {
  function renderLogos() {
    return logos.map((logo, index) => {
      if (logo.logo_link_enabled === '1') {
        return (
          <div key={index} className="footer-logo">
            <a href={logo.logo_link} target="_blank" rel="noreferrer noopener">
              <FigureImage image={logo.logo_image} dynamicImage={true} alt={logo.logo_alt} />
            </a>
          </div>
        );
      }
      return (
        <div key={index} className="footer-logo">
          <FigureImage image={logo.logo_image} dynamicImage={true} alt={logo.logo_alt} />
        </div>
      );
    });
  }
  return <div className="turner-info__images flex flex-wrap ">{renderLogos()}</div>;
};

export default FooterLogos;

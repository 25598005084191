import React from 'react';

const GridRelatedTitleRow = (props) => {
  return (
    <section className="grid-row">
      <h2 className="content-grid__related-title">{props.moreTitle}</h2>
    </section>
  );
};

export default GridRelatedTitleRow;

import { startApp } from '../../index.js';
import {
  getBitmovinWithTracking,
  getBitmovinWithAd,
  getBitmovinStatus,
  getPerformanceCookiesEnabled,
  getFunctionalCookiesEnabled,
  getAdvertisingCookiesEnabled,
  getOneTrustActive,
} from 'store/selectors/OneTrust.selectors';
import { loadContentRatings } from 'store/actions/Ratings.actions';

export const BITMOVIN_WITH_TRACKING = '[ONETRUST] BITMOVIN_WITH_TRACKING';
export const BITMOVIN_WITH_AD = '[ONETRUST] BITMOVIN_WITH_AD';
export const PERFORMANCE_COOKIES_ENABLED = '[ONETRUST] PERFORMANCE_COOKIES_ENABLED';
export const FUNCTIONAL_COOKIES_ENABLED = '[ONETRUST] FUNCTIONAL_COOKIES_ENABLED';
export const ADVERTISING_COOKIES_ENABLED = '[ONETRUST] ADVERTISING_COOKIES_ENABLED';
export const ONETRUST_ACTIVE = '[ONETRUST] ONETRUST_ACTIVE';
export const TOGGLE_BITMOVIN = '[ONETRUST] TOGGLE_BITMOVIN';

export const oneTrustActive = (status) => ({
  type: ONETRUST_ACTIVE,
  payload: status,
});

export const toggleBitmovinStatus = (status) => ({
  type: TOGGLE_BITMOVIN,
  payload: status,
});

export const bitmovinWithTracking = (status) => ({
  type: BITMOVIN_WITH_TRACKING,
  payload: status,
});

export const bitmovinWidthAd = (status) => ({
  type: BITMOVIN_WITH_AD,
  payload: status,
});

export const performanceCookiesEnabled = (status) => ({
  type: PERFORMANCE_COOKIES_ENABLED,
  payload: status,
});

export const functionalCookiesEnabled = (status) => ({
  type: FUNCTIONAL_COOKIES_ENABLED,
  payload: status,
});

export const advertisingCookiesEnabled = (status) => ({
  type: ADVERTISING_COOKIES_ENABLED,
  payload: status,
});

let listenTracker = false;

const isOTbannerVisible = () => {
  const otBanner = document.getElementById('onetrust-banner-sdk');
  if (otBanner && window.getComputedStyle(otBanner, null).getPropertyValue('display') == 'block') {
    return true;
  }
  return false;
};

const shouldContentScroll = (force = false) => {
  if (force) {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  } else {
    document.getElementsByTagName('body')[0].style.overflow = isOTbannerVisible() ? 'hidden' : 'auto';
  }
};

const addCloseListener = (dispatch) => {
  const optanonPopupClose = document.getElementById('close-pc-btn-handler');
  if (optanonPopupClose) {
    if (!listenTracker) {
      listenTracker = true;
      optanonPopupClose.addEventListener('click', () => {
        // Play bitmovin video
        dispatch({ type: TOGGLE_BITMOVIN, payload: 'play' });
      });
    }
  }
};

export const attachOneTrustListeners = () => {
  return (dispatch, getState) => {
    document.body.addEventListener('click', function (evt) {
      const { id, classList } = evt.target;
      const classCheckList = 'save-preference-btn-handler,ot-pc-refuse-all-handler';
      const idCheckList = 'onetrust-accept-btn-handler,accept-recommended-btn-handler,onetrust-reject-all-handler,ot-pc-refuse-all-handler';
      if (classList) {
        for (const classClick of classList) {
          if (classClick && classCheckList.includes(classClick)) {
            location.reload();
          }
        }
      }
      if (id && id !== '' && idCheckList.includes(id)) {
        location.reload();
      }
    });

    /**
     * respond to OneTrust prefs open and close : pause/play videos
     * cant pause/play games as they would need to be individually adjusted to respond to play/pause
     */
    const optanonPopup = document.getElementsByClassName('optanon-toggle-display');
    for (let i = 0; i < optanonPopup.length; i++) {
      optanonPopup[i].addEventListener('click', () => {
        shouldContentScroll();

        // Pause bitmovin video
        console.log('pause vid');

        dispatch({ type: TOGGLE_BITMOVIN, payload: 'pause' });

        addCloseListener(dispatch);
      });
    }
  };
};

export const handleDomLoad = () => {
  return (dispatch, getState) => {
    const state = getState();

    const logClr = 'background: grey; color: yellow';
    const cookiePolicy = window.TurnerToons.COOKIE_POLICY || {};
    cookiePolicy.domLoaded = true;

    const oneTrustActive = cookiePolicy.oneTrustActive;
    const oneTrustStarted = cookiePolicy.oneTrustStarted;

    if (oneTrustActive) {
      dispatch({ type: ONETRUST_ACTIVE, payload: oneTrustActive });
    }

    if (oneTrustActive && !oneTrustStarted) {
      const oneTrustBannerVisible = document.cookie.match(/^(.*;)?\s*OptanonAlertBoxClosed\s*=\s*[^;]+(.*)?$/) === null;

      if (oneTrustBannerVisible) {
        startApp(false);
        shouldContentScroll();
      } else {
        startApp(true);
      }
    } else {
      startApp(true);
    }
  };
};

export const handleOneTrustInit = () => {
  return (dispatch, getState) => {
    const logClr = 'background: grey; color: yellow';
    const cookiePolicy = window.TurnerToons.COOKIE_POLICY || {};
    cookiePolicy.oneTrustStarted = true;

    const domLoaded = cookiePolicy.domLoaded;
    const oneTrustBannerVisible = isOTbannerVisible();
    const oneTrustStarted = cookiePolicy.oneTrustStarted;

    if (oneTrustBannerVisible) {
      shouldContentScroll();
    }

    loadContentRatings();

    if (domLoaded && oneTrustBannerVisible) {
      location.reload();
    }
  };
};

export const initialiseSiteMedia = (device) => {
  return (dispatch, getState) => {
    const state = getState();
    const oneTrustActive = getOneTrustActive(state);

    const oneTrustBannerVisible = isOTbannerVisible();

    if (oneTrustBannerVisible) {
      shouldContentScroll();
    }

    const otActiveGroups = window.OnetrustActiveGroups ? window.OnetrustActiveGroups.split(',') : [];

    const performanceCookiesEnabled = otActiveGroups.indexOf('pf') !== -1;
    const advertisingCookiesEnabled = otActiveGroups.indexOf('ad') !== -1;
    const functionalCookiesEnabled = otActiveGroups.indexOf('bb') !== -1;
    dispatch({ type: PERFORMANCE_COOKIES_ENABLED, payload: performanceCookiesEnabled });
    dispatch({ type: FUNCTIONAL_COOKIES_ENABLED, payload: functionalCookiesEnabled });
    dispatch({ type: ADVERTISING_COOKIES_ENABLED, payload: advertisingCookiesEnabled });

    if (!oneTrustActive) {
      /**
       * BOOMWSXIV-1042 : If oneTrust is disabled in cms we default to enabling tracking
       */

      dispatch({ type: BITMOVIN_WITH_TRACKING, payload: true });
    } else {
      /**
       * Bitmovin player handles whether to play ad internally based on cookiePolicy.advertisingCookiesEnabled
       */
      if (performanceCookiesEnabled && advertisingCookiesEnabled) {
        dispatch({ type: BITMOVIN_WITH_TRACKING, payload: true });
        dispatch({ type: BITMOVIN_WITH_AD, payload: true });
      } else if (performanceCookiesEnabled && !advertisingCookiesEnabled) {
        dispatch({ type: BITMOVIN_WITH_TRACKING, payload: true });
        dispatch({ type: BITMOVIN_WITH_AD, payload: false });
      } else if (!performanceCookiesEnabled && advertisingCookiesEnabled) {
        dispatch({ type: BITMOVIN_WITH_TRACKING, payload: false });
        dispatch({ type: BITMOVIN_WITH_AD, payload: true });
      } else {
        dispatch({ type: BITMOVIN_WITH_TRACKING, payload: false });
        dispatch({ type: BITMOVIN_WITH_AD, payload: false });
      }
    }
  };
};

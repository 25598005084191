import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getImageAltDataByGroupedKey, getMobileNavState } from 'store/selectors/App.selectors';
import { activeMobileNav } from 'store/actions/App.actions';

import { getUrlDynamic } from 'constants/app/AppUrls';

const SiteLogo = (props) => {
  const { headerData, siteLogoAlt } = props;

  // hack for now
  function removeActiveClasses() {
    let nav = document.getElementById('nav-links');
    Array.from(nav.childNodes).map((item) => {
      if (item.classList.contains('active')) {
        item.classList.remove('active');
      }
    });
    if (props.mobileNavState) {
      props.activeMobileNav();
    }
  }

  return (
    <section className="site-logo">
      <Link to={'/'} className="site-logo__link" onClick={removeActiveClasses} aria-label="Homepage">
        <img src={getUrlDynamic(headerData.header_logo)} alt={siteLogoAlt} />
      </Link>
    </section>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    activeMobileNav: () => {
      dispatch(activeMobileNav());
    },
  };
}
const mapStateToProps = (state) => {
  return {
    mobileNavState: getMobileNavState(state),
    siteLogoAlt: getImageAltDataByGroupedKey(state, 'logos_alt', 'site_logo_alt'),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteLogo);

export const parseMarqueData = (data) => {
  /**
   * hero_switch is in config section of data
   * other marques (video, image, takeover) will be in page data prepended with marque_
   */
  const { marque_slider = [], marque_type } = data; // strips content from data, copying other keys into other
  if (marque_type === 'map') {
    return marque_type;
  } else {
    return marque_slider; // returns object with all marque data only
  }
};

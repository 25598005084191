//magic things
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// import utils
import { generateSharableImage } from 'utils/sharableImage';

// Import constants
import { PAGE } from 'constants/app/AppConstants';
import { STATUS_COMPLETED, STATUS_NOT_COMPLETED, STATUS_CANT_DO, STATUS_CAN_DO } from 'constants/challenges/ChallengeItems';
import { CHALLENGE_TYPES, CHALLENGE_COLOUR_BY_TYPE } from 'constants/challenges/ChallengeTypes';
import { INTERACTION } from 'constants/ensighten-tracking/EnsightenTrackingConstants';
import { CONTENT } from 'constants/content-library/ContentLibraryConstants';

// import component
import ChallengesBoxTicked from 'components/inline-svgs/challenges/ChallengesBoxTicked';
import ChallengesBoxUnticked from 'components/inline-svgs/challenges/ChallengesBoxUnticked';
import ChallengeHeader from 'components/challenges/ChallengesHeader';
import ChallengeBody from 'components/challenges/ChallengesBody';
import ChallengeFooter from 'components/challenges/ChallengesFooter';
import ContentIcon from 'components/generic/ContentIcon';

// Import Selecters
import { getSiteTextDataByGroup, getHeaderData } from 'store/selectors/App.selectors';
import { hasUserLikedChallenge, hasUserCompletedChallenge, hasUserCancelledChallenge } from 'store/selectors/User.selectors';

// Import Actions
import { triggerContentClick, triggerInteraction } from 'store/actions/EnsightenTracking.actions';
import {
  confirmRemoveUsersCancelledChallenge,
  confirmUsersCancelledChallenge,
  clickedChallengeLiked,
  clickedChallengeCompleted,
  clickedChallengeUncompleted,
  clickedChallengeUncancelled,
  clickedChallengeCancelled,
} from 'store/actions/Challenges.actions';

/**
 * Challenge Item Component.
 */

class ChallengeItem extends Component {
  constructor(props) {
    super(props);
    this.updateChallengeStatus = this.updateChallengeStatus.bind(this);
    this.handleCompactView = this.handleCompactView.bind(this);
    this.handleChallengeLike = this.handleChallengeLike.bind(this);
    this.cancelledChallenge = this.cancelledChallenge.bind(this);
    this.makeSharableImage = this.makeSharableImage.bind(this);
    this.setSharableImage = this.setSharableImage.bind(this);
    this.checkOnlineStatus = this.checkOnlineStatus.bind(this);
    this.confirmCancelledChallenge = this.confirmCancelledChallenge.bind(this);

    this.state = {
      compactView: true,
      canDoStatus: STATUS_CAN_DO,
      sharableImageURL: '',
      sharableImageActive: false,
      confirmCancelled: false,
      confirmRemoveCancelled: false,
      confirmCompleted: false,
      confirmUncompleted: false,
    };
  }

  componentWillUnmount() {
    this.confirmCancelledChallenge();
  }

  checkOnlineStatus() {
    const { isOnline = true, toggleOfflineMessage } = this.props;

    if (!isOnline) {
      toggleOfflineMessage();
      return false;
    }

    return true;
  }

  confirmCancelledChallenge() {
    if (this.state.confirmCancelled) {
      const { id } = this.props.data;

      this.props.confirmUsersCancelledChallenge(id);
      this.setState({ confirmCancelled: false });
    } else if (this.state.confirmRemoveCancelled) {
      const { id } = this.props.data;
      this.setState({ confirmRemoveCancelled: false });

      this.props.confirmRemoveUsersCancelledChallenge(id);
    }
  }

  cancelledChallenge(type, reason = '') {
    if (this.checkOnlineStatus()) {
      const { id } = this.props.data;

      if (type === STATUS_CANT_DO) {
        this.setState({ confirmCancelled: true });
        this.props.clickedChallengeCancelled({ challenge_id: id, reason });
      } else {
        this.setState({ confirmRemoveCancelled: true });
        this.props.clickedChallengeUncancelled({ challenge_id: id });
      }
    }
  }

  setSharableImage(image) {
    this.setState({
      sharableImageURL: image,
      sharableImageActive: true,
    });
  }

  makeSharableImage() {
    if (this.checkOnlineStatus()) {
      const { id, type, details, content, name } = this.props.data;
      const { image = '' } = content;
      const { title = '', description = '' } = details;

      const { hasCompletedChallenge, logo, shareableText } = this.props;
      const hasCompleted = hasCompletedChallenge === STATUS_COMPLETED;

      this.props.trackInteraction({ interactionType: INTERACTION.CLICK_CHALLENGE_SHARE, contentType: CONTENT.CHALLENGE, path: this.props.location, id, title: name });

      generateSharableImage({ title, image, color: CHALLENGE_COLOUR_BY_TYPE[type], type }, logo, hasCompleted, shareableText, (data) => {
        this.setSharableImage(data);
      });
    }
  }

  updateChallengeStatus(type) {
    if (this.checkOnlineStatus()) {
      const { id } = this.props.data;
      switch (type) {
        case STATUS_NOT_COMPLETED: {
          this.setState({ confirmUncompleted: true });
          const { id, details, name } = this.props.data;
          const { title = '' } = details;
          this.props.trackInteraction({ interactionType: INTERACTION.CLICK_CHALLENGE_UNCOMPLETE, contentType: CONTENT.CHALLENGE, path: this.props.location, id, title: name });
          setTimeout(() => {
            this.props.clickedChallengeUncompleted({ challenge_id: id });
            this.setState({ confirmUncompleted: false });
          }, 750);
          break;
        }
        case STATUS_COMPLETED: {
          this.setState({ confirmCompleted: true });
          const { location, pathID } = this.props;
          const { id, details, name } = this.props.data;
          const { title = '' } = details;

          setTimeout(() => {
            console.log({ challenge_id: id, path: location, id, title: name });
            this.props.clickedChallengeCompleted({ challenge_id: id, path: location, id, title: name });
            this.setState({ confirmCompleted: false });
          }, 750);
          break;
        }
        default:
          break;
      }
    }
  }

  handleCompactView() {
    if (window.innerWidth < 768) {
      this.setState({ compactView: !this.state.compactView });
    }
  }

  handleChallengeLike() {
    if (this.checkOnlineStatus()) {
      const { id, details, name } = this.props.data;
      const { title = '' } = details;
      this.props.clickedChallengeLiked({ challenge_id: id, path: this.props.location.pathname, title: name });
    }
  }

  render() {
    if (!this.props.data) return '';

    // Strip out the data we need to populate the content item
    const { id, likes, type, details, content, supervision, super_challenge } = this.props.data;
    const { image = '', has_download, download_content } = content;
    const { download_type, download_location } = download_content;

    const { title = '', description = '' } = details;
    const { name: typeName } = CHALLENGE_TYPES[type];

    const { hasCompletedChallenge, hasUserCancelledChallenge } = this.props;

    // Will need this at some point
    // let imageAlt = thumb_alt != '' ? thumb_alt : `${title} ${content_type}`;
    // let imageTitle = thumb_title != '' ? thumb_title : title;

    // CMS site text variables
    const { download = '', read_less = '', read_more = '', supervision_required = '' } = this.props?.localisedText || {};

    return (
      <div className="challenge-item__wrapper">
        <section className={`challenge-item challenge-item--${typeName} challenge-item--${this.state.compactView ? 'hide' : 'show'}`}>
          <ChallengeHeader callback={this.handleCompactView} title={title} type={typeName} />
          <section className="challenge-item__compacted">
            <ChallengeBody
              text={{ supervision_required, description, download, read_less, read_more }}
              supervision={supervision}
              hasDownload={has_download}
              image={image}
              downloadType={download_type}
              downloadLocation={download_location}
              challengeType={typeName}
              superChallenge={super_challenge}
              checkOnlineStatus={this.checkOnlineStatus}
            />
            <ChallengeFooter
              title={title}
              handleChallengeLike={this.handleChallengeLike}
              challengeStatusCallback={this.updateChallengeStatus}
              text={this.props.localisedText}
              type={typeName}
              challengeCompleted={hasCompletedChallenge}
              canDoStatus={hasUserCancelledChallenge}
              likesCount={likes}
              hasLiked={this.props.hasLikedChallenge}
              cancelledChallenge={this.cancelledChallenge}
              makeSharableImage={this.makeSharableImage}
              sharableImageURL={this.state.sharableImageURL}
              sharableImageActive={this.state.sharableImageActive}
              checkOnlineStatus={this.checkOnlineStatus}
              confirmCancelledChallenge={this.confirmCancelledChallenge}
              confirmCancelled={this.state.confirmCancelled}
              confirmRemoveCancelled={this.state.confirmRemoveCancelled}
              confirmCompleted={this.state.confirmCompleted}
              confirmUncompleted={this.state.confirmUncompleted}
            />
          </section>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    triggerContentClick: (opts) => {
      dispatch(triggerContentClick(opts));
    },
    trackInteraction: (opts) => {
      dispatch(triggerInteraction(opts));
    },
    clickedChallengeLiked: (opts) => {
      dispatch(clickedChallengeLiked(opts));
    },
    clickedChallengeCompleted: (opts) => {
      dispatch(clickedChallengeCompleted(opts));
    },
    clickedChallengeUncompleted: (opts) => {
      dispatch(clickedChallengeUncompleted(opts));
    },
    clickedChallengeUncancelled: (opts) => {
      dispatch(clickedChallengeUncancelled(opts));
    },
    clickedChallengeCancelled: (opts) => {
      dispatch(clickedChallengeCancelled(opts));
    },
    confirmUsersCancelledChallenge: (id) => {
      dispatch(confirmUsersCancelledChallenge(id));
    },
    confirmRemoveUsersCancelledChallenge: (id) => {
      dispatch(confirmRemoveUsersCancelledChallenge(id));
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  const { data = {} } = ownProps;
  const { id = false } = data;

  if (id) {
    return {
      localisedText: getSiteTextDataByGroup(state, 'challenges'),
      hasLikedChallenge: hasUserLikedChallenge(state, id),
      hasCompletedChallenge: hasUserCompletedChallenge(state, id),
      hasUserCancelledChallenge: hasUserCancelledChallenge(state, id),
      logo: getHeaderData(state).header_logo,
      shareableText: getSiteTextDataByGroup(state, 'sharable_text').challenge_text,
    };
  } else {
    return {
      localisedText: getSiteTextDataByGroup(state, 'challenges'),
      hasLikedChallenge: false,
      hasCompletedChallenge: false,
      hasUserCancelledChallenge: false,
      logo: getHeaderData(state).header_logo,
      shareableText: getSiteTextDataByGroup(state, 'sharable_text').challenge_text,
    };
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChallengeItem));

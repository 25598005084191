import { USER_SET_AUTH, USER_REMOVE_AUTH, USER_SET_AUTH_CREDENTIALS } from 'store/actions/UserAuth.actions';
import { Base64 } from 'utils/base64';

const initState = {
  token: '',
  external_id: '',
  name: '',
  code: '',
};

const UserAuth = (state = initState, action) => {
  let nextState = {};

  switch (action.type) {
    case USER_SET_AUTH: {
      const { token, external_id } = action.payload;

      nextState.token = token;
      nextState.external_id = external_id;
      return { ...state, ...nextState };
    }
    case USER_SET_AUTH_CREDENTIALS: {
      const { name, passcode } = action.payload;
      nextState.name = Base64.encode(name);
      nextState.code = Base64.encode(passcode);
      return { ...state, ...nextState };
    }
    case USER_REMOVE_AUTH: {
      nextState.token = '';
      nextState.external_id = '';
      nextState.name = '';
      nextState.code = '';
      return { ...state, ...nextState };
    }
  }

  return state;
};

export default UserAuth;

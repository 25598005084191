//magic things
import React, { Component } from 'react';
import { connect } from 'react-redux';

// import constants
import { PAGES } from 'constants/content-library/ContentLibraryConstants';
import { PAGE } from 'constants/app/AppConstants';

// import components
import SiteMetaData from 'components/generic/SiteMetaData';
import Faq from 'components/faq/Faq';
import PageOfflineMessage from 'containers/pages/PageOfflineMessage';

//import selectors
import { getSiteMetaData, getOnlineStatus } from 'store/selectors/App.selectors';

// import actions
import { triggerPageView } from 'store/actions/EnsightenTracking.actions';

class PageFaq extends Component {
  componentDidMount() {
    // Setup ensighten tracking stuff
    this.props.trackPageView({ contentType: PAGES.FAQ, pageType: PAGE.LANDING });
  }
  render() {
    return (
      <section className={`main-content`}>
        <SiteMetaData {...this.props.metadata} />
        {this.props.isOnline ? (
          <div className="faqspage">
            <Faq />
          </div>
        ) : (
          <PageOfflineMessage />
        )}
      </section>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    trackPageView: (opts) => {
      dispatch(triggerPageView(opts));
    },
  };
}

const mapStateToProps = (state) => {
  return {
    metadata: getSiteMetaData(state, PAGES.FAQ),
    isOnline: getOnlineStatus(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageFaq);

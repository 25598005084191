import React from 'react';

const ResourceIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124.65 127.04" className="challenge-creative-icon">
    <g id="Layer_2" data-name="Layer 2">
      <g id="updated">
        <g id="category_v3">
          <g id="brush">
            <g id="creative_selected">
              <path
                className="cls-1"
                d="M112 116.54h-.24C109.19 113.9 101 112 91.28 112c-11.81 0-21.38 2.86-21.38 6.38s9.57 6.37 21.38 6.37a65 65 0 009.41-.65c2 1.76 6.35 3 11.34 3 7 0 12.62-2.35 12.62-5.25s-5.65-5.31-12.65-5.31zM74.82 80.8a3.28 3.28 0 00.31-.28 2.7 2.7 0 00.3-.29c1.93-2.09 7-9.82-5.89-23.7C58.47 44.64 26.77 17.75 5.68 2.29L2.82.21C1.6-.66-.62 1.4.16 2.68L2 5.69c14 22.12 38.53 55.66 49.6 67.54 12.92 13.88 21 9.34 23.22 7.57z"
              />
              <path d="M102.84 109.29s1-21.14-10.11-31c0 0-7.42-12.88-19.9-1.26C60.71 88.36 72.67 97 72.67 97c9.01 11.76 30.17 12.29 30.17 12.29z" className="cls-1" />
              <path
                className="cls-1"
                d="M102.84 109.29s1-21.14-10.11-31c0 0 11.53 7.48-.94 19.09-1.93 1.79 1 4.87-.64 5.64-.89.41-6.48-1.16-7.3-1C76.67 103.69 72.67 97 72.67 97c9.01 11.76 30.17 12.29 30.17 12.29z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ResourceIcon;

import React, { Component } from 'react';

// import components
import ChallengeIcon from 'components/challenges/ChallengeIcon';

/**
 * Challenge Type Component.
 */

class ChallengeType extends Component {
  render() {
    const { name, callback, activeFilterTypes, localeName } = this.props;

    const extraClass =
      activeFilterTypes.length > 0 && activeFilterTypes.indexOf(name) !== -1 ? 'challenge-filter-type-icon--active' : activeFilterTypes.length > 0 ? 'challenge-filter-type-icon--deactive' : '';

    return (
      <li onClick={callback} data-name={name}>
        <div className={`challenge-filter-type-icon challenge-filter-type-icon--${name} ${extraClass}`}>
          <div className="challenge-filter-type-icon__title">{localeName}</div>
          <ChallengeIcon type={name} />
        </div>
      </li>
    );
  }
}

export default ChallengeType;

import React, { Component } from 'react';

// import constants
import { STATUS_CANT_DO } from 'constants/challenges/ChallengeItems';

// Import Components
import ChallengesArrowDown from 'components/inline-svgs/challenges/ChallengesArrowDown';
import ChallengesBoxUnticked from 'components/inline-svgs/challenges/ChallengesBoxUnticked';
import ChallengesBoxTicked from 'components/inline-svgs/challenges/ChallengesBoxTicked';

/**
 * Challenge Cant Do Dropdown Component.
 */

class ChallengeDeactivateDropdown extends Component {
  constructor() {
    super();

    this.state = {
      reasonSelected: null,
      submitted: false,
    };

    this.showOptionList = this.showOptionList.bind(this);
    this.inputSelectHandler = this.inputSelectHandler.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
  }

  submitHandler(e) {
    e.preventDefault();

    if (this.state.reasonSelected !== null) {
      this.setState({ submitted: true });
      this.props.cancelledChallenge(STATUS_CANT_DO, this.state.reasonSelected);
      this.props.hasSubmitted(true);
    } else {
      console.log('You must select a REASON!!!');
    }
  }

  inputSelectHandler(name) {
    this.setState({ reasonSelected: name });
  }

  showOptionList() {
    if (this.state.submitted) {
      this.props.confirmCancelledChallenge();
    }
    this.props.showList();

    if (this.state.submitted) {
      this.props.callback(STATUS_CANT_DO);
    }
  }

  renderOptions(values) {
    const options = [];
    for (const [key, value] of Object.entries(values)) {
      if (key.includes('reason_reason') && value && value !== '') {
        options.push(value);
      }
    }

    if (options.length > 0) {
      return options.map((option, index) => {
        return (
          <label
            key={index}
            className={'option' + (this.state.reasonSelected === `reason${index + 1}` ? ' selected' : '')}
            onClick={() => {
              this.inputSelectHandler(`reason${index + 1}`);
            }}
          >
            <input type="radio" className="reason-input" name={`reason${index + 1}`} value={option} />
            <div className="icon">{this.state.reasonSelected === `reason${index + 1}` ? <ChallengesBoxTicked /> : <ChallengesBoxUnticked />}</div>
            <div className="label">{option}</div>
          </label>
        );
      });
    }
  }

  render() {
    // localised cms text
    const {
      actually_i_can = '',
      i_cannot_do_this = '',
      let_us_know_why = '',
      cant_do_submit = '',
      tell_us_why_you_cant_do_this = '',
      thank_you_cant_do_body = '',
      thank_you_cant_do_close = '',
      thank_you_cant_do_title = '',
      ...rest
    } = this.props.localisedText;

    const { challengeType, showList } = this.props;

    return (
      <div className={`challenge-dropdown--cant-do ${challengeType}`}>
        <section className={'challenge-dropdown__container ' + (this.props.shouldShowList ? 'challenge-dropdown__container--open' : 'challenge-dropdown__container--close')}>
          <div className="challenge-dropdown__header" onClick={this.showOptionList}>
            <div className="challenge-dropdown__title">{i_cannot_do_this}</div>
            <div className="challenge-dropdown__arrow">
              <ChallengesArrowDown />
            </div>
          </div>
          <div className="challenge-dropdown__list challenge-dropdown__list--survey">
            {!this.state.submitted ? (
              <form onSubmit={this.submitHandler}>
                <div className="title">{let_us_know_why}</div>
                {this.renderOptions(rest)}
                <div className="challenge-dropdown__submit">
                  <button type="submit" aria-label="submit">
                    {cant_do_submit}
                  </button>
                </div>
              </form>
            ) : (
              <React.Fragment>
                <div className="title">{thank_you_cant_do_title}</div>
                <div className="description">{thank_you_cant_do_body}</div>
                <div className="challenge-dropdown__submit">
                  <button onClick={this.showOptionList} aria-label="close">
                    {thank_you_cant_do_close}
                  </button>
                </div>
              </React.Fragment>
            )}
          </div>
        </section>
      </div>
    );
  }
}

export default ChallengeDeactivateDropdown;

import { getUrlDynamic } from 'constants/app/AppUrls';

const parseEditorialData = (editorial) => {
  const { data, _localisation, ...other } = editorial;
  const { coord = {}, image } = data;
  const { name, text, title, hero_image = '', hero_media = 'inherit', hero_avs_video_id = '' } = _localisation;

  let content = {
    type: hero_media,
    data: hero_media === 'avs_video' ? hero_avs_video_id : getUrlDynamic(hero_image),
  };

  if (hero_media === 'inherit') {
    content = {
      type: data.hero_media,
      data: data.hero_media === 'avs_video' ? data.hero_avs_video_id : getUrlDynamic(data.hero_image),
    };
  }

  // coords should only have one marker
  // something is worng if there is more than one
  // we just grab the first one
  const parsedCoord = JSON.parse(coord);
  const marker = parsedCoord[Object.keys(parsedCoord)[0]];

  const parsedEditorial = {
    ...other,
    coord: marker,
    details: {
      title,
      description: text,
      image: getUrlDynamic(image),
    },
    content,
  };
  return parsedEditorial;
};

export const parseEditorials = (contentData, key) => {
  const content = contentData[key] || {};
  const allIDs = [];
  const byID = {};
  const markerRefs = {};

  for (const editorial of content) {
    const { id } = editorial;
    const parsed = parseEditorialData(editorial);
    allIDs.push(id);
    byID[id] = { ...parsed };

    const { coord, details } = parsed;
    markerRefs[`editorial_${id}`] = {};
    markerRefs[`editorial_${id}`].id = id;
    markerRefs[`editorial_${id}`].coord = coord;
    markerRefs[`editorial_${id}`].image = details.image;
  }
  return { data: { allIDs, byID, markerRefs } };
};

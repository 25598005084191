//magic things
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import components
import ChallengesArrowDown from 'components/inline-svgs/challenges/ChallengesArrowDown';

class Text extends Component {
  render() {
    const { data } = this.props;
    const { text, page = '', url } = data;

    return (
      <section className="challenges-counter challenges-counter--text">
        <Link to={`/${url}`} aria-label={url}>
          <div className="challenges-counter__text">{text}</div>
          <div className="challenges-counter__icon">
            <ChallengesArrowDown />
          </div>
        </Link>
      </section>
    );
  }
}

export default Text;

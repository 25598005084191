import { trackLikeService, trackUnlikeService } from 'services/api';
import { getEntryEmojiUUID } from 'store/selectors/Likes.selectors';
import { loadContentRatings } from 'store/actions/Ratings.actions';
import { triggerInteraction } from 'store/actions/EnsightenTracking.actions';
import { INTERACTION } from 'constants/ensighten-tracking/EnsightenTrackingConstants';
import { PAGE } from 'constants/app/AppConstants';
import { saveEmojiLikeExperience } from 'store/actions/Experience.actions';

export const LIKES_INVALIDATE = 'likes.INVALIDATE';
export const LIKES_REQUEST = 'likes.REQUEST';
export const LIKES_RECEIVE = 'likes.RECEIVE';

export const UNLIKE_INVALIDATE = 'unlike.INVALIDATE';
export const UNLIKE_REQUEST = 'unlike.REQUEST';
export const UNLIKE_RECEIVE = 'unlike.RECEIVE';

const _likeEntry = (state, contentType, entryID, emojiID) => {
  return (dispatch, getState) => {
    dispatch({ type: LIKES_REQUEST });
    trackLikeService(contentType, entryID, emojiID).then((response) => {
      if (response.result === 'SUCCESS') {
        const { uuid } = response;
        dispatch({ type: LIKES_RECEIVE, payload: { entryID, emojiID, uuid } });
        dispatch(loadContentRatings());
        dispatch(saveEmojiLikeExperience({ contentType }));
      } else {
        console.error('like', response.reason);
        dispatch({ type: LIKES_INVALIDATE });
      }
    });
  };
};

const _unlikeEntry = (state, contentType, entryID, emojiID, uuid) => {
  return (dispatch) => {
    dispatch({ type: UNLIKE_REQUEST });
    trackUnlikeService(contentType, entryID, emojiID, uuid).then((response) => {
      if (response.result === 'SUCCESS') {
        dispatch({ type: UNLIKE_RECEIVE, payload: { entryID, emojiID } });
        dispatch(loadContentRatings());
      } else {
        console.error('unlike', response.reason);
        dispatch({ type: UNLIKE_INVALIDATE });
      }
    });
  };
};

export const clickedEntryCategory = ({ contentType, entryID, emojiID, emojiEnglishName, contentEnglishName }) => {
  return (dispatch, getState) => {
    // check if previously liked - if so unlike
    const uuid = getEntryEmojiUUID(getState(), entryID, emojiID);
    if (uuid) {
      dispatch(triggerInteraction({ interactionType: INTERACTION.CLICK_UNLIKE_EMOJI, pageType: PAGE.SUB, contentType, id: entryID, emojiname: emojiEnglishName, contentEnglishName }));
      return dispatch(_unlikeEntry(getState(), contentType, entryID, emojiID, uuid));
    } else {
      dispatch(triggerInteraction({ interactionType: INTERACTION.CLICK_EMOJI, pageType: PAGE.SUB, contentType, id: entryID, emojiname: emojiEnglishName, contentEnglishName }));
      return dispatch(_likeEntry(getState(), contentType, entryID, emojiID));
    }
  };
};

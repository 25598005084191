import React, { useEffect, useState, useRef } from 'react';

const states = {
  READY: 'ready',
  CLICKED: 'clicked',
};

const Emoji = ({ id, label, count, icon, gif, png, clickHandler, userHasLiked, english_name }) => {
  let timer = null;
  let hover = null;

  const [state, setState] = useState(states.READY);

  useEffect(() => {
    return () => {
      clearTimeout(timer, hover);
    };
  }, []);

  const handleClick = (e) => {
    if (state === states.READY) {
      if (!userHasLiked) {
        setState(states.CLICKED);
        timer = setTimeout(() => {
          setState(states.READY);
        }, 1100);
      } else {
        clearTimeout(timer);
      }
    } else {
      clearTimeout(timer);
      setState(states.READY);
    }
    clickHandler(e);
  };

  const emojiRef = useRef(null);

  const touchStart = (e) => {
    let emojis = document.querySelectorAll('.emoji-item.hovering');
    if (emojis.length > 0) {
      Array.from(emojis).map((item) => {
        item.classList.remove('hovering');
      });
    }
    emojiRef.current.classList.add('hovering');
  };

  const touchEnd = () => {
    hover = setTimeout(() => {
      emojiRef.current.classList.remove('hovering');
    }, 2000);
  };

  const display = state === states.CLICKED ? gif : png;

  return (
    <div ref={emojiRef} className="emoji-item flex justify-content-center" data-id={id} data-englishname={english_name} onClick={handleClick} onTouchStart={touchStart} onTouchEnd={touchEnd}>
      <div className="emoji-item__label">
        <div className="label">{label}</div>
      </div>
      <img className={'emoji-item__icon' + (userHasLiked ? ' emoji-item__icon--liked' : '')} src={display} alt={label} />
      <div className="emoji-item__count">
        <span className="count">{count}</span>
      </div>
    </div>
  );
};
export default Emoji;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import Selectors
import { getHeaderData } from 'store/selectors/App.selectors';

// Import Containers
import AppNav from 'containers/base/AppNav';
import SiteLogo from 'components/generic/SiteLogo';

class AppHeader extends Component {
  render() {
    const { headerData } = this.props;

    return (
      <header className="header" id="app-header">
        <div className="header__upper container flex flex-wrap">
          <SiteLogo headerData={headerData} />
          <AppNav />
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    headerData: getHeaderData(state),
  };
};

export default withRouter(connect(mapStateToProps)(AppHeader));

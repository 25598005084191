//magic things
import React from 'react';

const MyGoalsDownloadIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.02 48.61" className="my-goals-downloads-icon">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g className="cls-1">
          <path d="M37.44,7.72V19.46H44.3a1.71,1.71,0,0,1,.92,3.16L25.76,36a5.15,5.15,0,0,1-5.5,0L.78,22.61a1.71,1.71,0,0,1,.94-3.15H8.59V7.72a5.15,5.15,0,0,1,5.14-5.15H32.29A5.16,5.16,0,0,1,37.44,7.72Z" />
        </g>
        <path
          className="cls-2"
          d="M37.44,5.15V16.88H44.3a1.72,1.72,0,0,1,.92,3.17L25.76,33.45a5.15,5.15,0,0,1-5.5,0L.78,20a1.72,1.72,0,0,1,.94-3.16H8.59V5.15A5.15,5.15,0,0,1,13.73,0H32.29A5.16,5.16,0,0,1,37.44,5.15Z"
        />
        <g className="cls-1">
          <path d="M39.93,48.61H6.1a4,4,0,0,1,0-8H39.93a4,4,0,0,1,0,8Z" />
        </g>
        <path className="cls-2" d="M39.93,46.21H6.1a4,4,0,0,1,0-8H39.93a4,4,0,0,1,0,8Z" />
      </g>
    </g>
  </svg>
);

export default MyGoalsDownloadIcon;

//magic things
import React from 'react';
import { getUrlStatic } from 'constants/app/AppUrls';
import { LANGUAGE_CODE, PWAASSETSUFFIX } from 'constants/app/AppConstants';

const FooterUpperContent = ({ cnLink, cnLinkText }) => (
  <div className="upper-content">
    <a href={cnLink} target="_blank" rel="noreferrer noopener" className="upper-content__link">
      {cnLinkText}
    </a>
  </div>
);

export default FooterUpperContent;

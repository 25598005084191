import { CUSTOM_CONTENT_TYPE } from 'constants/app/AppConstants';

export const parseCustom = (data) => {
  const { id = '', title = '', content_type = '', meta_title = '', meta_description = '', external_content_type, external_content } = data;

  const externalContentType = external_content_type === 'iframe' ? CUSTOM_CONTENT_TYPE.IFRAME : CUSTOM_CONTENT_TYPE.ZIP;

  return {
    id,
    title,
    contentType: content_type,
    metaTitle: meta_title,
    metaDescription: meta_description,
    externalContentType,
    externalContent: external_content,
  };
};

import { parseCustom } from 'store/actions/parsers/CustomDataParser';

export const parseContentData = (data) => {
  const { content_type } = data;
  switch (content_type) {
    case 'custom':
      return parseCustom(data);
    default:
      return data;
  }
};

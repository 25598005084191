export const getSiteNavigationData = (state) => {
  const allIds = state.navigation.allIDs;
  return allIds.map((id, index) => {
    return state.navigation.byID[id];
  });
};

/**
 * Custom page
 */
export const getCustomNavFriendlyURLs = (state) => {
  const customIDs = state.navigation.customIDs;
  return customIDs.reduce((acc, id) => {
    const item = state.navigation.byID[id];
    acc.push(item.friendlyURL);
    return acc;
  }, []);
};

export const getPageIdFromFriendlyURL = (state, path) => {
  const friendlyURL = path.replace('/', '');
  const customIDs = state.navigation.customIDs;
  return customIDs.reduce((acc, id) => {
    const item = state.navigation.byID[id];
    acc = item.friendlyURL === friendlyURL ? item.page : acc;
    return acc;
  }, '');
};

// App Page Based Selectors

export const getAppPageItemByID = (state, id) => {
  return state.apppage.byID[id] || false;
};

export const getAppPageContent = (state) => {
  const allIds = state.apppage.allIDs;
  return allIds.map((id, index) => {
    return state.apppage.byID[id];
  });
};

export const getAppPageContentSlice = (state, total) => {
  return getAppPageContent(state).slice(0, total);
};

export const getAppMarqueData = (state) => {
  return state.apppage.marqueData;
};

export const getAppIDByFriendlyURL = (state, url = '') => {
  const content = getAppPageContent(state);
  return content.reduce((arr, item, index) => {
    return url === content[index].friendly_url ? content[index].id : arr;
  }, null);
};

export const getAppPageItemXP = (state, id) => {
  const item = getAppPageItemByID(state, id);
  let { override_xp = 0, title } = item;
  return { title, xp: override_xp !== 0 ? Math.trunc(override_xp) : override_xp };
};

import React, { Suspense, useEffect, useState } from 'react';
import { getUrlDynamic } from 'constants/app/AppUrls';
import MarqueCta from 'components/marque/MarqueCta';
import { WEBPSUPPORT, PWAASSETSUFFIX } from 'constants/app/AppConstants';
import { convert_to_webp } from 'constants/app/AppServices';
import { PAGES } from 'constants/content-library/ContentLibraryConstants';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { loadVideo, resetVideo } from 'store/actions/Video.actions';
const BitmovinPlayer = React.lazy(() => import(/* webpackChunkName: 'bmplayer' */ 'components/media-container/BitmovinPlayer'));

const MarqueVideo = (props) => {
  const [isActive, updateIsActive] = useState(false);
  const [bitmovinStatus, updateBitmovinStatus] = useState('play');

  useEffect(() => {
    return () => {
      // componentWillUnmount
      props.resetVideo();
    };
  }, []);

  useEffect(() => {
    if (props.active && !isActive) {
      const { marque_data, index } = props;
      const { avs_id } = marque_data;
      updateIsActive(true);

      // props.resetVideo();
      props.loadVideo({ id: avs_id, interactive: true });
    }

    if (isActive) {
      if (props.active) {
        updateBitmovinStatus('play');
      } else {
        updateBitmovinStatus('pause');
      }
    }
  }, [props.active]);

  const { marque_data, marque_cta_active, marque_cta, onMarqueCtaClick, contentType = 'video', active, index } = props;
  const { avs_id, english_tracking_name, thumb_alt, thumb_video_image } = marque_data;
  if (!active && !isActive) {
    return '';
  } else {
    return (
      <section className="marque-area marque-area--video">
        <div className="marque-area__video container">
          <Suspense fallback={<div>Loading...</div>}>
            <BitmovinPlayer
              playerID={`player_${index}`}
              contentType={'video'}
              entryID={0}
              avs_id={avs_id}
              thumbs={thumb_video_image}
              experienceRewardPercentage={''}
              onRewardExperience={''}
              standalone={true}
              overrideBitmovinStatus={bitmovinStatus}
              endEvent={props.onVideoEnd}
            />
          </Suspense>
        </div>
        {marque_cta_active == 'true' ? <MarqueCta {...marque_cta} onMarqueCtaClick={onMarqueCtaClick} /> : ''}
      </section>
    );
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadVideo: (opts) => {
      // console.log('{}{}{}{}{}{}{}{}{}{}{} loadVideo');
      dispatch(loadVideo(opts));
    },
    resetVideo: (opts) => {
      // console.log('{}{}{}{}{}{}{}{}{}{}{} resetVideo');
      dispatch(resetVideo(opts));
    },
  };
};

export default withRouter(connect(null, mapDispatchToProps)(MarqueVideo));

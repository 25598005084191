// Get document direction
import { APP_MODES, NUMBER_FORMATTING, DATE_TODAY } from 'constants/app/AppConstants';

export const isRTL = () => {
  let direction;
  document.dir !== undefined ? (direction = document.dir) : (direction = document.getElementsByTagName('html')[0].getAttribute('dir'));
  return direction === 'rtl';
};

export const getAppMode = () => {
  if (window.matchMedia('(display-mode: standalone)').matches) {
    return { mode: APP_MODES.APP, description: 'Viewing mode: WebApp' };
  } else if (window.navigator.standalone === true) {
    return { mode: APP_MODES.APP, description: 'Viewing mode: WebApp' };
  } else {
    return { mode: APP_MODES.DESKTOP, description: 'Viewing mode: Desktop' };
  }
};

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export const debounce = (func, duration = 300) => {
  let timeout;
  return function (...args) {
    const effect = () => {
      timeout = null;
      return func.apply(this, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(effect, duration);
  };
};

// Format Large numbers
export function kFormatter(num) {
  return Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k' : Math.sign(num) * Math.abs(num);
}

// Check of object is empty
export const isEmptyObj = (obj) => {
  if (typeof obj !== 'undefined') {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  } else {
    return null;
  }
};

export const numberWithFormatting = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, NUMBER_FORMATTING);
};

const formattedTimestamp = (date) => {
  const DATE_OFFSET_DAY = date.getDate();
  const DATE_OFFSET_MONTH = date.getMonth() + 1;
  const DATE_OFFSET_YEAR = date.getFullYear();
  return Date.parse(DATE_OFFSET_MONTH + '/' + DATE_OFFSET_DAY + '/' + DATE_OFFSET_YEAR) / 1000;
};

export const dateRange = (offsetDate) => {
  const currentDateId = new Date().getDay();

  const DATE_OFFSET = new Date();
  const DATE_PREVIOUS_WEEK = new Date();

  // if the offset matches the currentdateID
  if (currentDateId === offsetDate || (currentDateId === 0 && offsetDate === 7)) {
    DATE_PREVIOUS_WEEK.setDate(DATE_PREVIOUS_WEEK.getDate() - 6);

    return {
      current: DATE_TODAY / 1000,
      prev_week: formattedTimestamp(DATE_PREVIOUS_WEEK),
    };
  }

  // if currentdtaeId is not 0 but offsetDate is 7 (7 is how the design treats Sunday)
  if (currentDateId !== 0 && offsetDate === 7) {
    DATE_OFFSET.setDate(DATE_OFFSET.getDate() + (offsetDate - currentDateId));
    DATE_PREVIOUS_WEEK.setDate(DATE_OFFSET.getDate() - 6);

    return {
      current: formattedTimestamp(DATE_OFFSET),
      prev_week: formattedTimestamp(DATE_PREVIOUS_WEEK),
    };
  }

  if (currentDateId > offsetDate) {
    DATE_OFFSET.setDate(DATE_OFFSET.getDate() - Math.abs(offsetDate - currentDateId));
  } else {
    DATE_OFFSET.setDate(DATE_OFFSET.getDate() + Math.abs(offsetDate - currentDateId));
  }
  DATE_PREVIOUS_WEEK.setDate(DATE_OFFSET.getDate() - 6);

  return {
    current: formattedTimestamp(DATE_OFFSET),
    prev_week: formattedTimestamp(DATE_PREVIOUS_WEEK),
  };
};

//magic things
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Base64 } from 'utils/base64';

// import constants
import { SITE_STATUS } from 'constants/app/AppConstants';

// import containers
import AppHeader from 'containers/base/AppHeader';
import AppFooter from 'containers/base/AppFooter';
import AppRouter from 'containers/router/AppRouter';
import PageComingSoon from 'containers/pages/PageComingSoon';
import SiteExitModal from 'containers/modal/SiteExitModal';
import AuthModal from 'containers/modal/AuthModal';
import LevelUpModal from 'containers/modal/LevelUpModal';
import OfflineModal from 'containers/modal/OfflineModal';
import AppCookieBar from 'containers/base/AppCookieBar';

// impoprt selectors
import { getBootstrapComplete, getExitSiteModalState, getAuthModalState, getSiteUrls, getOfflineModalState, getHideCookieBar } from 'store/selectors/App.selectors';
import { getCustomNavFriendlyURLs } from 'store/selectors/Navigation.selectors';
import { getAdTrigger } from 'store/selectors/Ads.selectors';
import { hasUserLikedChallenge, hasUserCompletedChallenge } from 'store/selectors/User.selectors';
import { getUserToken, getUserExternalID, getUserName, getUserCode } from 'store/selectors/UserAuth.selectors';

// import actions
import { loadContentRatings } from 'store/actions/Ratings.actions';
import { requestAnonLogin, requestLogin } from 'store/actions/Login.actions';
import { setOnlineStatus, checkCookieBar } from 'store/actions/App.actions';

class App extends Component {
  constructor(props) {
    super(props);
    this.updateNetworkChange = this.updateNetworkChange.bind(this);
  }

  componentDidMount() {
    this.updateNetworkChange();
    window.addEventListener('online', this.updateNetworkChange);
    window.addEventListener('offline', this.updateNetworkChange);
    this.props.checkCookieBar();

    if (this.props.bootstrapComplete) {
      this.autoLoginUser();
      this.props.loadContentRatings();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bootstrapComplete !== this.props.bootstrapComplete) {
      this.props.loadContentRatings();
      this.autoLoginUser();
    }
  }

  updateNetworkChange(event) {
    if (navigator.onLine) {
      this.props.setOnlineStatus('online');
    } else {
      this.props.setOnlineStatus('offline');
    }
  }

  autoLoginUser() {
    const { token = '', external_id = '', name = '', code = '' } = this.props;
    if (name !== '' && code !== '') {
      this.props.requestLogin({ name: Base64.decode(name), password: Base64.decode(code) });
    } else if (token !== '' && external_id !== '') {
      this.props.requestAnonLogin({ external_id, token });
    }
  }

  render() {
    const { bootstrapComplete, customNavFriendlyURLs, adTrigger, siteUrls } = this.props;
    if (bootstrapComplete === false) {
      // Placeholder loading
      return null;
    }

    if (SITE_STATUS === 'coming_soon') {
      return (
        <React.Fragment>
          <PageComingSoon />
          <AppFooter />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {!this.props.hideCookieBar && <AppCookieBar />}
          <div style={{ position: 'relative' }}>
            {this.props.activeExitSiteModal && <SiteExitModal />}
            {this.props.activeAuthModal && <AuthModal />}
            {this.props.activeOfflineModal && <OfflineModal />}
            <LevelUpModal />
            <AppHeader />
            <main role="main" className="main">
              <AppRouter customNavFriendlyURLs={customNavFriendlyURLs} siteUrls={siteUrls} adTrigger={adTrigger} />
            </main>
            <AppFooter />
          </div>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    bootstrapComplete: getBootstrapComplete(state),
    customNavFriendlyURLs: getCustomNavFriendlyURLs(state),
    adTrigger: getAdTrigger(state, 'default'),
    activeExitSiteModal: getExitSiteModalState(state),
    activeAuthModal: getAuthModalState(state),
    token: getUserToken(state),
    external_id: getUserExternalID(state),
    name: getUserName(state),
    code: getUserCode(state),
    siteUrls: getSiteUrls(state),
    activeOfflineModal: getOfflineModalState(state),
    hideCookieBar: getHideCookieBar(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadContentRatings: () => {
      dispatch(loadContentRatings());
    },
    requestAnonLogin: ({ external_id, token }) => {
      dispatch(requestAnonLogin({ external_id, token }));
    },
    requestLogin: ({ name, password }) => {
      dispatch(requestLogin({ name, password }));
    },
    setOnlineStatus: (status) => {
      dispatch(setOnlineStatus(status));
    },
    checkCookieBar: () => {
      dispatch(checkCookieBar());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);

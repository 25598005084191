import { getVideoPageItemByID } from 'store/selectors/Videopage.selectors';

// Video Playlist Selectors
export const getRelatedPlaylistByID = (state, id, videoID) => {
  const playlist = getVideoPlaylistByID(state, id);
  const { more_title } = playlist;

  const filtered = playlist.content.filter((video_id) => {
    return video_id !== videoID;
  });

  const content = filtered.map((videoID) => {
    return getVideoPageItemByID(state, videoID);
  });

  return content.length !== 0 ? { more_title, content } : false;
};

export const getVideoPlaylistByID = (state, id) => {
  return state.videoplaylist.byID[id] || false;
};

export const getVideoPlaylistsIDs = (state) => {
  return state.videoplaylist.allIDs;
};

export const getAllVideoPlaylists = (state) => {
  const ids = getVideoPlaylistsIDs(state);

  const playlists = ids.map((id) => {
    return getVideoPlaylistByID(state, id);
  });

  return playlists;
};

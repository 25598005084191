import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmptyObj } from 'utils/general';

// import constants
import { getUrlStatic } from 'constants/app/AppUrls';
import { LANGUAGE_CODE } from 'constants/app/AppConstants';

// import selectors
import { getSiteTextDataByGroup } from 'store/selectors/App.selectors';

// import Components
import PictureMappingsSingleImage from 'components/picture-mappings/PictureMappingsSingleImage';
import FaqItem from './FaqItem';

/**
 * FAQ Component
 * Places FAQ on the page
 */

class Faq extends Component {
  componentDidMount() {
    document.getElementsByTagName('body')[0].classList.add('faq');
  }

  componentWillUnmount() {
    if (document.getElementsByTagName('body')[0].classList.contains('faq')) {
      document.getElementsByTagName('body')[0].classList.remove('faq');
    }
  }

  renderFaqs() {
    const { faqText = {} } = this.props;
    const { faq_items = [] } = faqText;

    return faq_items.map((item, index) => {
      return (
        <li key={index}>
          <FaqItem {...item} />
        </li>
      );
    });

    return null;
  }

  render() {
    const { faqText = {} } = this.props;
    const { faq_title = '' } = faqText;

    //<PictureMappingsSingleImage src={getUrlStatic(`images/coming-soon/header/coming-soon-header-${LANGUAGE_CODE}.jpg`)} imageAlt={title} />

    return (
      <>
        <div className="faq__header">
          <figure>
            <picture>
              <img src={getUrlStatic(`images/coming-soon/header/coming-soon-header-${LANGUAGE_CODE}.jpg`)} alt={faq_title} />
            </picture>
          </figure>
          <div className="faq__header__title">
            <h1>{faq_title}</h1>
          </div>
        </div>
        <div className="faq__body">
          <ul className="faq__list container">{this.renderFaqs()}</ul>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    faqText: getSiteTextDataByGroup(state, 'faq'),
  };
};

export default connect(mapStateToProps, null)(Faq);

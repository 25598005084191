// Quiz Page Based Selectors

export const getQuizPageItemByID = (state, id) => {
  return state.quizpage.byID[id] || false;
};

export const getQuizPageContent = (state) => {
  const allIds = state.quizpage.allIDs;
  return allIds.map((id, index) => {
    return state.quizpage.byID[id];
  });
};

export const getQuizPageContentSlice = (state, total) => {
  return getQuizPageContent(state).slice(0, total);
};

export const getQuizMarqueData = (state) => {
  return state.quizpage.marqueData;
};

export const getQuizIDByFriendlyURL = (state, url = '') => {
  const content = getQuizPageContent(state);
  return content.reduce((arr, item, index) => {
    return url === content[index].friendly_url ? content[index].id : arr;
  }, null);
};

export const getQuizPageItemXP = (state, id) => {
  const item = getQuizPageItemByID(state, id);
  let { override_xp = 0, title } = item;
  return { title, xp: override_xp !== 0 ? Math.trunc(override_xp) : override_xp };
};

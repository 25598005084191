//magic things
import React, { Component } from 'react';
import { connect } from 'react-redux';
//import selectors
import { getVideoMarqueData, getVideoPageContent, getVideoPromoData, getVideoPageItemByID } from 'store/selectors/Videopage.selectors';
// import constants
import { VIDEOS_PAGE_GRID } from 'constants/content-grid/ContentGridConstants';
import { PAGES } from 'constants/content-library/ContentLibraryConstants';
import { PAGE } from 'constants/app/AppConstants';
// import components
import PromoSection from 'components/promo-section/PromoSection';
import ContentItemGrid from 'containers/content/ContentItemGrid';
import VideoPlaylists from 'components/video-playlist/VideoPlaylists';
import StatsCTA from 'components/stats-cta/StatsCTA';
import PageOfflineMessage from 'containers/pages/PageOfflineMessage';

import { triggerInteraction, triggerPageView } from 'store/actions/EnsightenTracking.actions';
import { getSiteMetaData } from 'store/selectors/App.selectors';
import SiteMetaData from 'components/generic/SiteMetaData';
import { clickedMarqueCTA } from 'store/actions/App.actions';

// HeroSwitch
import { getUrlDynamic } from 'constants/app/AppUrls';
import { getDynamicFooter } from 'store/selectors/HeroSwitch.selectors';

import { getSiteTextDataByGroup, getOnlineStatus } from 'store/selectors/App.selectors';

class PageVideos extends Component {
  constructor(props) {
    super(props);
    this.handleMarqueCtaClick = this.onMarqueCtaClick.bind(this);
  }

  componentDidMount() {
    // Setup ensighten tracking stuff
    this.props.trackPageView({ contentType: PAGES.VIDEOS, pageType: PAGE.LANDING });
  }

  onMarqueCtaClick(e) {
    this.props.clickedMarqueCTA({ pageContentType: PAGES.VIDEOS });
  }

  render() {
    const { promoData, pageText } = this.props;
    return (
      <section className={`main-content`}>
        <SiteMetaData {...this.props.metadata} />
        {this.props.isOnline ? (
          <>
            <div className="videopage container">
              <StatsCTA currentPage={'videos'} />
              <h1>{pageText.title}</h1>
              <PromoSection data={promoData} />
            </div>
            <section className="video-playlists__container">
              <div className="container">
                <VideoPlaylists />
              </div>
            </section>
          </>
        ) : (
          <PageOfflineMessage />
        )}
      </section>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clickedMarqueCTA: (opts) => {
      dispatch(clickedMarqueCTA(opts));
    },
    trackPageView: (opts) => {
      dispatch(triggerPageView(opts));
    },
  };
}

const mapStateToProps = (state) => {
  const data = getVideoPromoData(state);

  let renderPromos = [];

  if (data.content && data.content.length > 0) {
    renderPromos = data.content.map((id) => {
      return getVideoPageItemByID(state, id.video);
    });
  }

  return {
    metadata: getSiteMetaData(state, PAGES.VIDEOS),
    contentItems: getVideoPageContent(state),
    promoData: renderPromos,
    pageText: getSiteTextDataByGroup(state, 'videopage'),
    isOnline: getOnlineStatus(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageVideos);

import { RATINGS_INVALIDATE, RATINGS_RECEIVE, RATINGS_REQUEST } from 'store/actions/Ratings.actions';

const initState = {
  isFetchingRatings: false,
  didInvalidateRatings: false,
  liked_ids: null,
  likesByID: {},
  views: null,
};

const reducer = (state = initState, action) => {
  let nextState = {};
  switch (action.type) {
    case RATINGS_INVALIDATE:
      nextState.isFetchingRatings = false;
      nextState.didInvalidateRatings = true;
      return { ...state, ...nextState };

    case RATINGS_REQUEST:
      nextState.isFetchingRatings = true;
      nextState.didInvalidateRatings = false;
      return { ...state, ...nextState };

    case RATINGS_RECEIVE: {
      nextState.isFetchingRatings = false;
      nextState.didInvalidateRatings = false;
      const { liked_ids, likesByID, views } = action.payload;
      nextState.liked_ids = liked_ids;
      nextState.likesByID = likesByID;
      nextState.views = views;
      return { ...state, ...nextState };
    }
  }
  return state;
};

export default reducer;

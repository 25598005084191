export const getTotalLevelsCompleted = (state) => state.userLevels.totalLevelsCompleted;

export const getCurrentLevelID = (state) => state.userLevels.currentLevelID;

export const getPreviousIDLevelID = (state) => state.userLevels.previousLevelID;

export const getAllLevelsCompleted = (state) => state.userLevels.allLevelsCompleted;

export const totalChallengesCompleted = (state) => state.userLevels.totalChallengesCompleted;

export const getHasSeenLevelUp = (state) => state.userLevels.hasSeenLevelUp;

export const showLevelUpPopup = (state) => {
  return state.userLevels.showLevelUpPopup;
};

// import constants
import { STATUS_COMPLETED, STATUS_NOT_COMPLETED, STATUS_CANT_DO, STATUS_CAN_DO } from 'constants/challenges/ChallengeItems';
import { PLANNER_EMPTY_SLOT } from 'constants/app/AppConstants';

// import helpers
import { dateRange } from 'utils/general';

// import selectors
import { getChallengesItemByID } from 'store/selectors/Challenges.selectors';

// get stored dataset
export const getStoredData = (state) => {
  return {
    avatar: getUserAvatar(state),
    first_time_completed_challenges: getFirstTimeCompletedChallenges(state),
    planner: getPlannerData(state),
    completed_challenges: completedPlayerChallenges(state),
    ...getMyGoalsData(state),
  };
};

export const getUsername = (state) => {
  return state.user.username;
};

export const getPasscode = (state) => {
  return state.user.passcode;
};

export const getUserToken = (state) => {
  return state.user.token;
};

export const getUserAvatar = (state) => {
  return state.user.avatar;
};

export const getUserAdditionalData = (state) => {
  return state.user.additionial_data;
};

export const getUserExternalID = (state) => {
  return state.user.external_id;
};

export const getUserData = (state) => {
  return state.user;
};

export const getPlannerData = (state) => {
  return state.user.planner;
};

export const getPlannerDataByDateId = (state, id) => {
  return state.user.planner[id] || [];
};

export const getPlannerChallengesByDateId = (state, id) => {
  const date = state.user.planner[id];

  const returnableData = {};
  for (const key in date) {
    if (key) {
      if (date[key] !== PLANNER_EMPTY_SLOT) {
        returnableData[key] = { ...getChallengesItemByID(state, date[key]), hasCompleted: checkDateCompletedPlayerChallenges(state, id, date[key]) };
      } else {
        returnableData[key] = PLANNER_EMPTY_SLOT;
      }
    }
  }

  return returnableData;
};

export const getFirstTimeCompletedChallenges = (state) => {
  return state.user.first_time_completed_challenges;
};

export const getIsFirstTimeCompletingChallengeByID = (state, id) => {
  let isFirstTime = true;

  for (let i = 0; i < state.user.first_time_completed_challenges.length; i++) {
    if (state.user.first_time_completed_challenges[i] === id) {
      isFirstTime = false;
    }
  }

  return isFirstTime;
};

export const hasUserLikedChallenge = (state, id) => {
  return state.user.likes.indexOf(id) !== -1;
};

export const checkDateCompletedPlayerChallenges = (state, dateID, challenge_id) => {
  const challengeTimeStamps = state.user.completed_player_challenges[challenge_id] || null;
  let hasCompleted = false;

  if (challengeTimeStamps !== null) {
    const dateCheck = dateRange(dateID);

    challengeTimeStamps.map((stamp) => {
      hasCompleted = dateCheck.prev_week < stamp;
      if (hasCompleted) {
        hasCompleted = stamp >= dateCheck.current;
      }
    });
  }

  return hasCompleted;
};

export const completedPlayerChallenges = (state) => {
  return state.user.completed_player_challenges;
};

export const completedChallenges = (state) => {
  return state.user.completed_challenges;
};

export const cancelledChallenges = (state) => {
  return state.user.cancelled_challenges;
};

export const getNumberOfCompletedChallenges = (state) => {
  return state.user.completed_challenges.length;
};

export const hasUserCompletedChallenge = (state, id) => {
  return state.user.completed_challenges.indexOf(id) !== -1 ? STATUS_COMPLETED : STATUS_NOT_COMPLETED;
};

export const hasUserCancelledChallenge = (state, id) => {
  return state.user.cancelled_challenges.indexOf(id) !== -1 ? STATUS_CANT_DO : STATUS_CAN_DO;
};

export const getUsersCurrentLevel = (state) => {
  return state.user.current_level;
};

// MY GOALS
// NOTE: Should this be it's own section????
export const getMyGoalsGardenID = (state) => {
  if (state.user.my_goals) {
    return state.user.my_goals.garden_id || '';
  }
  return {};
};

export const getMyGoalsData = (state) => {
  return state.user.my_goals;
};

import { LANGUAGE_CODE } from 'constants/app/AppConstants';

export const parseData = (data) => {
  // Needs to convert data to lowercase
  // I think it aslo needs to remove certain characters / not sure what tho at the moment
  return (data || '').toLowerCase();
};

export const getDomain = (() => {
  let domain = document.domain.replace('www.', '');
  return parseData(domain);
})();

export const getDomainLocale = (() => {
  let domain = document.domain.replace('www.', '') + '/' + LANGUAGE_CODE;
  return parseData(domain);
})();

export const _adRandomNumber = (length) => {
  return Math.floor(Math.random() * length);
};

// Functions to handle trackng

import { HERO_SWITCH_DEFAULT_ACTIVE_STATE } from 'store/actions/ActiveHeroSwitch.actions';
import { CONTENT } from 'constants/content-library/ContentLibraryConstants';

// helper vars
let placeholderArray = [];
let countTracker = 1; // skips first entry

const randomNumber = (length) => {
  return Math.floor(Math.random() * length);
};

// Get isRandom value
export const isRandom = (state) => {
  return state.heroSwitch.isRandom;
};

// Get current content ID
export const getCurrentActiveState = (state) => {
  return state.activeHeroSwitch.currentActiveState || '';
};

// Get number of times switch has been clicked
export const getHeroSwitchClickCount = (state) => {
  return state.activeHeroSwitch.clickCount || 0;
};

// Get all Hero Switch headers
export const getHeroSwitchData = (state) => {
  return state.heroSwitch.heroSwitchData;
};

// Get first Hero Switch header
export const getHeroSwitchHeader = (state) => {
  if (state.heroSwitch.heroSwitchData) {
    return state.heroSwitch.heroSwitchData[0];
  }

  return false;
};

// Get Hero Switch footers
export const getHeroSwitchFooters = (state) => {
  return state.heroSwitch.heroSwitchFooters;
};

// Get Hero Switch footers
export const getDynamicFooter = (state, page) => {
  let marqueeType;
  if (page === 'characters') {
    marqueeType = state[page].marque.marque_type; //characters has different namings
  } else {
    marqueeType = state[page] ? state[page].marqueData.marque_type : null;
  }
  const heroSwitchPresent = marqueeType === CONTENT.HERO_SWITCH;
  const clickCount = getHeroSwitchClickCount(state);
  const isPristine = heroSwitchPresent && clickCount < 1;
  const hsFooters = getHeroSwitchFooters(state);
  let activeFooterBackground = {};

  if (hsFooters) {
    activeFooterBackground = isPristine ? hsFooters.find((e) => e.background_name === 'Earth').background_image_large : hsFooters.find((e) => e.background_name === 'Space').background_image_large;
  }

  return [isPristine, activeFooterBackground];
};

export const currentActiveStateData = (state) => {
  if (state.heroSwitch.heroSwitchData) {
    let id = getCurrentActiveState(state);
    return state.heroSwitch.heroSwitchData.find((data) => {
      return data.id === id;
    });
  }

  return false;
};

export const randomlySelectContent = (state) => {
  let currentActiveHero = getCurrentActiveState(state);

  if (placeholderArray.length === 0) {
    placeholderArray = getHeroSwitchData(state).filter((data) => {
      return data.id != currentActiveHero;
    });
  }

  let random = randomNumber(placeholderArray.length);
  let selected = placeholderArray[random].id;
  placeholderArray.splice(random, 1);

  return selected;
};

export const selectNextOrderedContent = (state) => {
  let heroSwitchData = getHeroSwitchData(state);
  let dataCount = heroSwitchData.length;
  let selected;

  if (countTracker === dataCount - 1) {
    selected = heroSwitchData[countTracker].id;
    countTracker = 1; //skips first entry
  } else if (countTracker === 0) {
    selected = heroSwitchData[0].id;
    countTracker = countTracker + 1;
  } else {
    selected = heroSwitchData[countTracker].id;
    countTracker = countTracker + 1;
  }

  return selected;
};

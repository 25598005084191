//magic things
import React from 'react';

const NavChallengesIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.5 58.5" className="nav-challenge-icon">
    <g data-name="Layer 2">
      <g className="cls-2" fill="none" strokeLinecap="round" strokeLinejoin="round">
        <rect x="1.5" y="7" width="50" height="50" rx="12" strokeWidth="3" />
        <path strokeWidth="10" d="M13.59 28L25.5 40l34-35" />
      </g>
    </g>
  </svg>
);

export default NavChallengesIcon;

export const siteTextDataParser = (data) => {
  const {
    additional_copy_1,
    additional_copy_2,
    aloe_vera_text,
    amryllis_text,
    cactus_text,
    cordyline_fructicosa_text,
    daisy_text,
    flamingo_flower_text,
    hyacinth_text,
    jasmine_text,
    lavender_text,
    nonsite_manifesto1,
    nonsite_manifesto2,
    nonsite_manifesto3,
    nonsite_manifesto4,
    nonsite_manifesto5,
    orchid_text,
    sunflower_text,
    cta_text,
    fern_text,
    share_image_completed_text,
    share_image_generic_text,
    faq_items,
    faq_title,
    ...rest
  } = data;

  return {
    faq: {
      faq_items,
      faq_title,
    },
    sharable_text: {
      challenge_text: {
        cta_text,
        share_image_completed_text,
        share_image_generic_text,
      },
      image_text: {
        aloe_vera_text,
        amryllis_text,
        cactus_text,
        cordyline_fructicosa_text,
        daisy_text,
        flamingo_flower_text,
        hyacinth_text,
        jasmine_text,
        lavender_text,
        orchid_text,
        sunflower_text,
        fern_text,
        additional_copy_1,
        additional_copy_2,
      },
    },
    manifesto: {
      nonsite_manifesto1,
      nonsite_manifesto2,
      nonsite_manifesto3,
      nonsite_manifesto4,
      nonsite_manifesto5,
    },
    ...rest,
  };
};

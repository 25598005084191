export const ONBOARDING_MAP = 'ONBOARDING_MAP';
export const ONBOARDING_MY_GOALS = 'ONBOARDING_MY_GOALS';
export const ONBOARDING_MY_GOALS_INTRO = 'ONBOARDING_MY_GOALS_INTRO';

export const setOnboardingMap = ({ step }) => ({
  type: ONBOARDING_MAP,
  payload: { step },
});

export const setOnboardingMyGoals = ({ step }) => ({
  type: ONBOARDING_MY_GOALS,
  payload: { step },
});

export const setOnboardingMyGoalsIntro = ({ step }) => ({
  type: ONBOARDING_MY_GOALS_INTRO,
  payload: { step },
});

//magic things
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { DOWNLOADS_PAGE_GRID } from 'constants/content-grid/ContentGridConstants';

// import constants
import { PAGES, CONTENT } from 'constants/content-library/ContentLibraryConstants';
import { INTERACTION } from 'constants/ensighten-tracking/EnsightenTrackingConstants';
import { PAGE } from 'constants/app/AppConstants';

// import components
import SiteMetaData from 'components/generic/SiteMetaData';
import Faq from 'components/faq/Faq';
import PageOfflineMessage from 'containers/pages/PageOfflineMessage';
import ContentItemGrid from 'containers/content/ContentItemGrid';
import AreYouTeacher from 'components/schools/AreYouTeacher';
import SuperChallenges from 'components/schools/SuperChallenges';
import Manifesto from 'components/manifesto/Manifesto';

//import selectors
import { getSiteMetaData, getOnlineStatus, getSiteTextDataByGroup, getSiteUrlByName, getChallengesUrlByType } from 'store/selectors/App.selectors';
import { getSchoolsPageManifesto, getSchoolsPageSuperChallenges, getSchoolsPageResources } from 'store/selectors/Schoolspage.selectors';

// import actions
import { triggerPageView, triggerInteraction } from 'store/actions/EnsightenTracking.actions';

class PageSchools extends Component {
  constructor(props) {
    super(props);

    this.handleTrackClick = this.handleTrackClick.bind(this);
  }
  componentDidMount() {
    // Setup ensighten tracking stuff
    this.props.trackPageView({ contentType: PAGES.SCHOOLS, pageType: PAGE.LANDING });
  }

  handleTrackClick(opts) {
    const { title } = opts;
    this.props.trackInteraction({ interactionType: INTERACTION.CLICK_SCHOOLS_LINK, contentType: CONTENT.SCHOOLS, title });
  }

  render() {
    const { challenges, pageText, challengeSchoolUrl, resources } = this.props;

    const {
      schools_main_title = '',
      schools_are_you_teacher = '',
      schools_are_you_teacher_title = '',
      schools_climate_action_curriculum_button = '',
      schools_climate_action_curriculum_url = '',
      schools_contact_us = '',
      schools_more_schools_button = '',
      schools_sign_up = '',
      schools_super_challenge_badge = '',
      schools_take_the_quiz_button = '',
      schools_take_the_quiz_url = '',
      schools_title = '',
      schools_what_is_super_challenge = '',
      schools_what_is_super_challenge_1 = '',
      schools_what_is_super_challenge_2 = '',
      schools_what_is_super_challenge_3 = '',
      schools_what_is_super_challenge_4 = '',
      schools_resources_title = '',
    } = pageText;

    return (
      <section className={`main-content`}>
        <SiteMetaData {...this.props.metadata} />
        {this.props.isOnline ? (
          <>
            <Manifesto type={'large'} pageType={'schools'} />
            <div className="schoolspage__ideas">
              <div className="container">
                <h3 className="schoolspage__ideas__title">{schools_main_title}</h3>
              </div>
            </div>
            <SuperChallenges pageText={pageText} challenges={challenges} challengeSchoolUrl={challengeSchoolUrl} />
            <AreYouTeacher pageText={pageText} trackClick={this.handleTrackClick} />
            <div className="schoolspage container">
              <h1>{schools_resources_title}</h1>
              <ContentItemGrid data={resources} layout={DOWNLOADS_PAGE_GRID} />
            </div>
          </>
        ) : (
          <PageOfflineMessage />
        )}
      </section>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    trackPageView: (opts) => {
      dispatch(triggerPageView(opts));
    },
    trackInteraction: (opts) => {
      dispatch(triggerInteraction(opts));
    },
  };
}

const mapStateToProps = (state) => {
  const baseUrl = getSiteUrlByName(state, 'challenges');

  return {
    metadata: getSiteMetaData(state, PAGES.SCHOOLS),
    isOnline: getOnlineStatus(state),
    challenges: getSchoolsPageSuperChallenges(state),
    pageText: getSiteTextDataByGroup(state, 'schools'),
    challengeSchoolUrl: `${baseUrl}/${getChallengesUrlByType(state, 'type9')}`,
    resources: getSchoolsPageResources(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageSchools);

//magic things
import React, { Component } from 'react';
import * as DeviceDetection from 'utils/deviceDetection';

// import Constants
import { getUrlStaticImages } from 'constants/app/AppUrls';
import { STATUS_COMPLETED, STATUS_NOT_COMPLETED, STATUS_CANT_DO, STATUS_CAN_DO } from 'constants/challenges/ChallengeItems';

// import component
import ChallengesShareIcon from 'components/inline-svgs/challenges/ChallengesShareIcon';
import ChallengesLikeIcon from 'components/inline-svgs/challenges/ChallengesLikeIcon';
import ChallengesBoxTicked from 'components/inline-svgs/challenges/ChallengesBoxTicked';
import ChallengesBoxUnticked from 'components/inline-svgs/challenges/ChallengesBoxUnticked';
import ChallengesBoxCross from 'components/inline-svgs/challenges/ChallengesBoxCross';
import ChallengeDeactivateDropdown from 'components/challenges/ChallengeDeactivateDropdown';
import ChallengeActivateDropdown from 'components/challenges/ChallengeActivateDropdown';
import ChallengeDownloadIcon from 'components/inline-svgs/challenges/ChallengeDownloadIcon';

/**
 * Challenge Item Footer Component.
 */

// *** NOTE:
// Download function - 'download(sharableImageURL, 'sharable-image.png', 'image/png');'
// Third party lib found in static folder 'download/download.js'
// Adds download function to window object
// Provides better support for downloading the membership image across devices

class ChallengeFooter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeChallenge: true,
      showList: false,
      hasSubmittedReason: false,
    };

    this.handleLike = this.handleLike.bind(this);
    this.challengeStatus = this.challengeStatus.bind(this);
    this.showOptionList = this.showOptionList.bind(this);
    this.hasSubmitted = this.hasSubmitted.bind(this);
    this.handleSubmitClose = this.handleSubmitClose.bind(this);
    this.downloadSharableImage = this.downloadSharableImage.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.canDoStatus !== prevProps.canDoStatus && !this.state.hasSubmittedReason) {
      this.setState({ activeChallenge: this.props.canDoStatus !== STATUS_CANT_DO });
    }
    if (this.props.hasLiked === prevProps.hasLicked) {
      return;
    }
  }

  showOptionList() {
    if (this.props.checkOnlineStatus()) {
      this.setState({ showList: !this.state.showList });
    }
  }

  downloadSharableImage() {
    const { sharableImageURL } = this.props;
    if (DeviceDetection.isiOS() && DeviceDetection.iOSversion()[0] < 13) {
      const image = new Image();
      image.src = sharableImageURL;
      const w = window.open('');
      w.document.write(image.outerHTML);
    } else {
      download(sharableImageURL, 'sharable-image.png', 'image/png');
    }
  }

  handleLike() {
    this.props.handleChallengeLike();
  }

  hasSubmitted(type) {
    this.setState({ hasSubmittedReason: type });
  }

  handleSubmitClose(type) {
    this.setState({ hasSubmittedReason: false });
    // this.setState({ activeChallenge: type !== STATUS_CANT_DO });
  }

  challengeStatus(type) {
    if (this.props.canDoStatus === STATUS_CAN_DO) {
      const { challengeCompleted } = this.props;
      this.props.challengeStatusCallback(challengeCompleted === STATUS_COMPLETED ? STATUS_NOT_COMPLETED : STATUS_COMPLETED);
    }
  }

  render() {
    const {
      text = {},
      type,
      challengeCompleted,
      likesCount = 0,
      canDoStatus,
      makeSharableImage,
      sharableImageURL,
      sharableImageActive,
      confirmCancelledChallenge,
      confirmCancelled,
      confirmRemoveCancelled,
      confirmCompleted,
      confirmUncompleted,
      title,
    } = this.props;
    const { completed = '', mark_as_done_button = '', share_button = '', like_button = '', cant_do = '', download_button = '' } = text;
    return (
      <div className={'challenge-item__footer' + (this.state.showList ? ' challenge-item__footer--open-options' : '')}>
        <div className="challenge-item__confirmation">
          <div className={'challenge-item__done' + (challengeCompleted === STATUS_COMPLETED ? ' challenge-item__done--completed' : '')} onClick={this.challengeStatus}>
            <button aria-label="challenge tickbox" className={'tickbox tickbox--' + (challengeCompleted === STATUS_COMPLETED ? 'ticked' : 'unticked')}>
              {confirmCompleted ? (
                <ChallengesBoxTicked />
              ) : confirmUncompleted ? (
                <ChallengesBoxUnticked />
              ) : confirmRemoveCancelled ? (
                <ChallengesBoxUnticked />
              ) : canDoStatus === STATUS_CANT_DO || confirmCancelled ? (
                <ChallengesBoxCross />
              ) : challengeCompleted === STATUS_COMPLETED ? (
                <ChallengesBoxTicked />
              ) : (
                <ChallengesBoxUnticked />
              )}
            </button>
            <p className="status">
              {confirmRemoveCancelled
                ? mark_as_done_button
                : confirmCompleted
                ? completed
                : confirmUncompleted
                ? mark_as_done_button
                : canDoStatus === STATUS_CANT_DO || confirmCancelled
                ? cant_do
                : challengeCompleted === STATUS_COMPLETED
                ? completed
                : mark_as_done_button}
            </p>
          </div>
          {challengeCompleted !== STATUS_COMPLETED && (
            <div className="challenge-item__survey">
              {this.state.activeChallenge ? (
                <ChallengeDeactivateDropdown
                  localisedText={text}
                  challengeType={type}
                  callback={this.handleSubmitClose}
                  showList={this.showOptionList}
                  shouldShowList={this.state.showList}
                  cancelledChallenge={this.props.cancelledChallenge}
                  hasSubmitted={this.hasSubmitted}
                  confirmCancelledChallenge={confirmCancelledChallenge}
                />
              ) : (
                <ChallengeActivateDropdown
                  localisedText={text}
                  challengeType={type}
                  callback={this.handleSubmitClose}
                  showList={this.showOptionList}
                  shouldShowList={this.state.showList}
                  cancelledChallenge={this.props.cancelledChallenge}
                  hasSubmitted={this.hasSubmitted}
                  confirmCancelledChallenge={confirmCancelledChallenge}
                />
              )}
            </div>
          )}
        </div>
        <div className="challenge-item__social">
          {sharableImageActive ? (
            <button className="share" aria-label="Share Challenge" onClick={this.downloadSharableImage}>
              <ChallengeDownloadIcon />
              <span>{download_button}</span>
            </button>
          ) : (
            <button className="share" aria-label="Share Challenge" onClick={makeSharableImage}>
              <ChallengesShareIcon />
              <span>{share_button}</span>
            </button>
          )}
          <button className={'like' + (typeof this.props.hasLiked == 'boolean' && this.props.hasLiked ? ' like--liked' : '')} aria-label="Like Challenge" onClick={this.handleLike}>
            <ChallengesLikeIcon />
            <span>{typeof this.props.hasLiked == 'boolean' && this.props.hasLiked ? this.props.likesCount + 1 : this.props.likesCount}</span>
          </button>
        </div>
      </div>
    );
  }
}

export default ChallengeFooter;

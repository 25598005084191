//magic things
import React from 'react';

const NavVideoIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.4 45" className="nav-video-icon">
    <g data-name="Layer 2">
      <g data-name="Layer 3">
        <g data-name="Path 55">
          <path d="M45.36 3H5a5.16 5.16 0 00-5 5.25v31.5A5.16 5.16 0 005 45h40.36a5.16 5.16 0 005-5.25V8.25a5.16 5.16 0 00-5-5.25zM18.9 34.5v-21L35.7 24z" className="cls-1" />
          <path d="M45.36 0H5a5.16 5.16 0 00-5 5.25v31.5A5.16 5.16 0 005 42h40.36a5.16 5.16 0 005-5.25V5.25a5.16 5.16 0 00-5-5.25zM18.9 31.5v-21L35.7 21z" className="cls-2" />
        </g>
      </g>
    </g>
  </svg>
);

export default NavVideoIcon;

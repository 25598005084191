import { parseData } from 'constants/ensighten-tracking/EnsightenTrackingServices';
import { getSectionData, getSectionDataDetail, getSectionDataOEQ, getSectionDataFeatured, getDataByBaseClick } from 'store/actions/ensightenTracking/interaction/base';
import { CLICK_EVENTS } from 'constants/ensighten-tracking/EnsightenTrackingConstants';
import { getMicrositeName } from 'constants/ensighten-tracking/EnsightenTrackingConstants';

import { PAGE } from 'constants/app/AppConstants';

export const getOmniData = (opts) => {
  const data = getSectionData(opts);
  data.interaction = 'omnitrix click';
  data.omnitrixclick = 1;
  return data;
};

export const getEmojiData = (opts) => {
  const { contentType, contentEnglishName } = opts;
  const data = getSectionDataDetail(opts);

  if (contentType === 'video') {
    data.section = 'watch videos';
    data.subsection = 'watch videos|watch videos detail';
    data.content_type = `video`;
    data.content_title = `cn climate champions|${parseData(contentEnglishName)}`;
  } else {
    data.section = 'quizzes';
    data.subsection = 'quizzes|main';
    data.content_type = `quiz`;
    data.content_title = `cn climate champions|quizzes page`;
  }

  data.interaction = 'content emoji click|' + parseData(opts.emojiname);
  // data.emojiname = parseData(opts.emojiname);

  delete data.content_id;

  return data;
};

export const getUnlikeEmojiData = (opts) => {
  const { contentType, contentEnglishName } = opts;
  const data = getSectionDataDetail(opts);

  if (contentType === 'video') {
    data.section = 'watch videos';
    data.subsection = 'watch videos|watch videos detail';
    data.content_type = `video`;
    data.content_title = `cn climate champions|${parseData(contentEnglishName)}`;
  } else {
    data.section = 'quizzes';
    data.subsection = 'quizzes|main';
    data.content_type = `quiz`;
    data.content_title = `cn climate champions|quizzes page`;
  }

  data.interaction = 'remove content emoji|' + parseData(opts.emojiname);
  // data.emojiname = parseData(opts.emojiname);

  delete data.content_id;

  return data;
};

export const getCharacterData = (opts) => {
  const { pathname } = opts.path;
  let data = {};
  const split = pathname.split('/').filter(function (i) {
    return i;
  });

  if (split && split.length > 1) {
    data = getSectionDataDetail(opts);
  } else {
    data = getSectionData(opts);
  }
  data.interaction = 'select character';
  data.selectcharacter = 1;
  return data;
};

export const getTopTipsImpressionData = (opts) => {
  const { id, title } = opts;
  const { pathname } = opts.path;
  const baseClick = getDataByBaseClick(opts.path);
  const { contentType, pageType } = baseClick;
  let data = getSectionDataOEQ({ pageType, contentType, detail: pageType === PAGE.SUB });

  const { isPWA } = opts;
  // data.micrositename = getMicrositeName(isPWA);
  data.page_name = data.page_name + decodeURIComponent(location.pathname);
  // data.show = 'climate champions';
  data.content_title = parseData(title);
  data.content_id = id != '' ? id : 'nvs';

  data.interaction = 'top tips impression';
  data.interaction_event = 1;
  data.toptipsimpression = 1;
  return data;
};

export const getTopTipsClickData = (opts) => {
  const { id, title } = opts;
  const { pathname } = opts.path;
  const baseClick = getDataByBaseClick(opts.path);
  const { contentType, pageType } = baseClick;
  let data = getSectionDataOEQ({ pageType, contentType, detail: pageType === PAGE.SUB });

  const { isPWA } = opts;
  // data.micrositename = getMicrositeName(isPWA);
  data.page_name = data.page_name + decodeURIComponent(location.pathname);
  // data.show = 'climate champions';
  data.franchise = 'cn climate champions';
  data.content_title = parseData(title);
  data.content_id = id != '' ? id : 'nvs';

  data.interaction = 'top tips click';
  // data.contentclickevent = 1;
  data.interaction_event = 1;
  data.toptipsclick = 1;
  return data;
};

export const getCharacterClickAudioData = (opts) => {
  const data = getSectionDataDetail(opts);
  data.interaction = 'voice clip click';
  data.voiceclipclick = 1;
  return data;
};

export const getDownloadData = (opts) => {
  const data = getSectionDataDetail(opts);
  data.pagetype = 'download';
  data.content_type = 'download';
  data.interaction = 'content download';
  data.contentdownload = 1;
  return data;
};

export const getRewardDownloadData = (opts) => {
  const data = getSectionData(opts);
  data.rewardlevel = opts.level.replace('_', ' ');
  data.pagetype = 'rewards';
  data.rewarddownload = 1;
  data.content_type = 'rewards';
  data.interaction = 'reward download click';
  data.contentdownload = 1;
  return data;
};

export const getRewardLevelCompleteData = (opts) => {
  const { level, contentType } = opts;
  const data = getSectionData(opts);
  data.pagetype = contentType;
  data.interaction = 'reward level complete';
  data.rewardlevelcomplete = 1;
  data.content_type = contentType;
  data.rewardlevel = opts.level.replace('_', ' ');
  data.content_title = 'rewards';
  return data;
};

export const getRewardData = (opts) => {
  const { level, contentType } = opts;

  const data = getSectionData(opts);
  data.interaction = `reward unlocked|${level.replace('_', ' ')}`;
  // data.badgeunlocked = 1;
  // data.pagetype = contentType;
  data.content_type = contentType;
  data.rewardlevel = level.replace('_', ' ');
  data.content_title = 'rewards';
  data.currentTime = 'nvs';
  return data;
};

// Challenge base interactions
export const getChallengeLikedData = (opts) => {
  const { title, contentType, page, subpage } = opts;

  const data = getSectionData({ pageType: subpage, contentType: page, detail: true });

  // data.section = 'challenges';
  // data.subsection = 'challenges|main';
  // data.pagetype = 'category';
  // data.content_type = 'challenge page';
  data.content_title = `cn climate champions|${parseData(title)}`;
  data.interaction_event = 1;
  // data.englishname = parseData(title);
  data.interaction = `challenges like|${parseData(title)}`;

  delete data.content_id;

  return data;
};

export const getChallengeUnlikedData = (opts) => {
  const { title, contentType, page, subpage } = opts;

  const data = getSectionData({ pageType: subpage, contentType: page, detail: true });

  // data.section = 'challenges';
  // data.subsection = 'challenges|main';
  // data.pagetype = 'category';
  // data.content_type = 'challenge page';
  data.content_title = `cn climate champions|${parseData(title)}`;
  data.interaction_event = 1;
  // data.englishname = parseData(title);
  data.interaction = `challenges unlike|${parseData(title)}`;

  delete data.content_id;

  return data;
};

export const getChallengeShareData = (opts) => {
  const { level, contentType, title, page, subpage } = opts;

  const data = getSectionData({ pageType: subpage, contentType: page, detail: true });

  // data.section = 'challenges';
  // data.subsection = 'challenges|main';
  // data.pagetype = 'category';
  // data.content_type = 'challenge page';
  data.content_title = `cn climate champions|${parseData(title)}`;
  data.interaction_event = 1;
  // data.englishname = parseData(title);
  data.interaction = `challenges share|${parseData(title)}`;

  delete data.content_id;
  return data;
};

export const getChallengeTakedData = (opts) => {
  const { level, contentType, title, page, subpage, firstTime = false } = opts;

  const data = getSectionData({ pageType: subpage, contentType: page, detail: true });

  // data.section = 'challenges';
  // data.subsection = `challenges|main`;
  // data.pagetype = 'category';
  // data.content_type = 'challenge page';
  data.content_title = `cn climate champions|${parseData(title)}`;
  data.interaction_event = 1;
  // data.englishname = parseData(title);
  data.interaction = `take the challenge|${parseData(title)}`;
  if (firstTime) {
    data.interaction = `take the challenge|${parseData(title)}|first challenge`;
  }

  delete data.content_id;
  return data;
};

export const getChallengeUncompleteData = (opts) => {
  const { level, contentType, title, page, subpage } = opts;

  const data = getSectionData({ pageType: subpage, contentType: page, detail: true });

  // data.section = 'challenges';
  // data.subsection = `challenges|main`;
  // data.pagetype = 'category';
  // data.content_type = 'challenge page';
  data.content_title = `cn climate champions|${parseData(title)}`;
  data.interaction_event = 1;
  // data.englishname = parseData(title);
  data.interaction = `unaccept challenge|${parseData(title)}`;

  delete data.content_id;
  return data;
};

export const getSchoolsLinkClickData = (opts) => {
  const { title } = opts;

  const data = getSectionData(opts);
  data.section = 'schools';
  data.subsection = `schools|main`;
  data.pagetype = 'category';
  data.content_type = 'schools page';
  data.content_title = `cn climate champions|schools page`;
  data.interaction_event = 1;
  data.interaction = parseData(title);

  delete data.contentID;
  delete data.englishname;
  delete data.contentclickevent;
  return data;
};

// others
export const getSignUpData = (opts) => {
  const { level, contentType, page, subpage } = opts;
  const data = getSectionData({ pageType: subpage, contentType: page, detail: true });
  // data.pagetype = 'category';
  data.content_title = 'cn climate champions|sign up';
  data.content_type = page;
  data.interaction_event = 1;
  data.interaction = `sign up success`;

  delete data.content_id;
  return data;
};

export const getLoginData = (opts) => {
  const { level, contentType, page, subpage } = opts;

  const data = getSectionData({ pageType: subpage, contentType: page, detail: true });
  // data.pagetype = 'category';
  data.content_title = 'cn climate champions|login';
  data.content_type = page;
  data.interaction_event = 1;
  data.interaction = `login success`;
  delete data.content_id;

  return data;
};

export const getMapMarkerData = (opts) => {
  const { level, contentType } = opts;

  const data = getSectionData(opts);
  data.section = 'map';
  data.subsection = 'map|main';
  data.pagetype = 'category';
  data.content_type = 'map page';
  data.content_title = `cn climate championn|map page`;
  data.interaction_event = 1;
  data.interaction = contentType === 'editorial' ? 'map detail|editorial' : 'map detail|challenge';
  return data;
};

export const getRewardUnlockedData = (opts) => {
  const { level, contentType, id = '' } = opts;
  let levelTitle = '';
  if (id !== '') {
    levelTitle = id.toLowerCase();
  }

  const data = getSectionData(opts);
  data.section = 'challenges';
  data.subsection = 'challenges|main';
  // data.pagetype = 'category';
  data.content_type = 'game reward';
  data.content_title = `cn climate champions|${levelTitle}`;
  data.interaction_event = 1;
  data.interaction = `game reward|${levelTitle}`;

  delete data.content_id;
  return data;
};

export const getRewardLevelUpData = (opts) => {
  const { level, contentType, id = '' } = opts;
  let levelTitle = '';
  if (id !== '') {
    levelTitle = id.toLowerCase();
  }

  const data = getSectionData(opts);
  data.section = 'challenges';
  data.subsection = 'challenges|main';
  // data.pagetype = 'category';
  data.content_type = 'level up';
  data.content_title = `cn climate champions|${parseData(levelTitle)}`;
  data.gamelevel = levelTitle;
  data.interaction_event = 1;
  data.interaction = `level up|${parseData(levelTitle)}`;

  delete data.content_id;

  return data;
};

export const getFeaturedContentData = (opts) => {
  /**
   * Data for the hero links
   * Featured content can be any of type : ‘game', ‘quiz’, ‘downloads’, ‘app’ , 'video'
   * pageContentType : ATM featured content is potentially in Hero area on ALL pages
   * contentType : type of content
   */
  const { contentItem, contentType } = opts;
  const { english_tracking_name } = contentItem;
  const data = getSectionDataFeatured(opts);
  data.interaction = 'featured content click';
  data.featuredcontent = data.content_title;
  data.englishname = parseData(english_tracking_name);
  data.featuredcontentevent = 1;

  const clickEvent = [CLICK_EVENTS[contentType]] || 'unknownclickedevent';
  data[clickEvent] = 1; // videoclickedevent | gameclickedevent | quizclickevent | appclickevent | downloadsclickevent
  return data;
};

export const getContentData = (opts) => {
  /**
   * ContentData can be any of type : ‘game', ‘quiz’, ‘downloads’, ‘app’ , 'video'
   */
  const { contentType } = opts;
  const clickEvent = [CLICK_EVENTS[contentType]] || 'unknownclickedevent';

  const baseClick = getDataByBaseClick(opts);
  const { contentType: baseContentType, pageType: basePageType } = baseClick;
  opts.contentType = baseContentType;
  opts.pageType = basePageType;
  const { contentItem } = opts;
  const { english_tracking_name, game_genre = 'nvs' } = contentItem;

  const data = getSectionDataDetail(opts);
  data.interaction = `${contentType} thumbnail click`;
  data[`${contentType}title`] = parseData(english_tracking_name);
  data[`${contentType}category`] = game_genre; // only games, using genre
  data[clickEvent] = 1; // videoclickedevent | gameclickedevent | quizclickevent | appclickevent | downloadsclickevent
  return data;
};

export const getTaggedContentData = (opts) => {
  const { contentItem, contentType } = opts;
  console.log('contentItem', contentItem);
  const { english_tracking_name, game_genre = 'nvs' } = contentItem;
  const data = getSectionDataDetail(opts);
  const clickEvent = [CLICK_EVENTS[contentType]] || 'unknownclickedevent';
  data.interaction = 'recommended content click';
  data.recommendedcontentevent = 1;
  data[clickEvent] = 1; // videoclickedevent | gameclickedevent | quizclickevent | appclickevent | downloadsclickevent
  return data;
};

import { parseData } from 'constants/ensighten-tracking/EnsightenTrackingServices';

const getVideoReadyData = ({ isPWA, duration, contentItem, title }) => {
  const { id = 'nvs', title: contentTitle, english_tracking_name } = contentItem;
  const showName = title !== '' ? parseData(title) : english_tracking_name ? parseData(english_tracking_name) : parseData(contentTitle);

  // let data = { ...VIDEO_TEMPLATE };
  let data = {};
  data.content_duration = duration;
  data.content_name = showName;
  data.content_id = id;
  data.content_type = 'clip';
  data.content_showName = showName;

  // "playerid": "", /* unique player id if multiple instances on a page */
  // "content_duration": 0, /* video length in seconds */
  // "content_dataCreated": "", /* YYYY-MM-DD (e.g. 2018-08-30) */
  // "content_dataAired": "", /* YYYY-MM-DD (e.g. 2018-08-30) */
  // "content_name": "", /* video title */
  // "content_id": "", /* video id */
  // "content_type": "", /* clip, vod, live */
  // "content_showName": "", /* show name */
  // "content_seasonNumber": "", /* season number (e.g. 1, 2, 3, etc.) */
  // "content_episodeNumber": "", /* episode number (e.g. 1, 2, 3, etc.) */
  // "content_genre": "", /* genre (e.g. drama, comedy, etc.) */
  // "content_rating": "", /* rating (e.g. tvy, tvg, tvpg, tvm, etc.) */
  // "content_originator": "", /* originator (e.g. warnermedia, sony, disney, etc.) */
  // "content_network": "", /* network (e.g. fox, brabo, espn, etc.) */
  // "content_mvpd": "", /* mvpd (e.g. comcast, directv, dish, etc.) */
  // "content_authorized": "", /* true or false */
  // "day_part": "", /* time of day (e.g. morning, daytime, primetime, etc.) */
  // "content_feed": "" /* feed (e.g. east-hd, west-hd, east-sd, etc.) */
  return data;
};

export default getVideoReadyData;

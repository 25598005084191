import { CHALLENGES_PER_LEVEL, UNLOCK_LEVELS_CHALLENGE_THRESHOLD } from 'constants/my-goals/MyGoalsConstants';

export const parseUserLevelsData = (data = []) => {
  const completedChallenges = data.length;
  const index = 1;
  let allLevelsCompleted = false;

  let currentLevel = 'level_1';

  for (const [key, value] of Object.entries(UNLOCK_LEVELS_CHALLENGE_THRESHOLD)) {
    if (value <= completedChallenges) {
      currentLevel = key;
      continue;
    }

    break;
  }

  // IS completedChallenges greater than last level threshold?
  if (completedChallenges >= UNLOCK_LEVELS_CHALLENGE_THRESHOLD.level_12) {
    allLevelsCompleted = !allLevelsCompleted;
  }

  const totalCompletedLevels = parseInt(currentLevel.replace('level_', ''));
  const currentLevelChallengesCompleted = completedChallenges;

  return { totalCompletedLevels, currentLevel, currentLevelChallengesCompleted, allLevelsCompleted };
};

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { CHANGE_GARDEN, LAST_LEVEL_ID } from 'constants/my-goals/MyGoalsConstants';

// import selectors
import { getSiteTextDataByGroup, getSiteTextDataByGroupedKey } from 'store/selectors/App.selectors';
import { getLoggedInStatus } from 'store/selectors/Login.selectors';
import { getCurrentLevelID, totalChallengesCompleted, getAllLevelsCompleted } from 'store/selectors/UserLevels.selectors';
import MyGoalsArrowIcon from 'components/inline-svgs/my-goals/MyGoalsArrowIcon';
import { getUserData, getNumberOfCompletedChallenges } from 'store/selectors/User.selectors';

// import components
import MyGoalsLockedIcon from 'components/inline-svgs/my-goals/MyGoalsLockedIcon';
import MyGoalsLevel from 'components/my-goals/MyGoalsLevel';

const MyGoalsLevels = (props) => {
  const { localisedText, isLoggedIn, currentLevel, chanllengesCompleted, perviousStep, stepCallback, onboardingClicked, isOnboardingCompleted, numberOfChallegesText, numberOfChalleges } = props;

  const [show, updateShow] = useState(false);
  const handleOpenState = () => {
    if (!isOnboardingCompleted) {
      onboardingClicked(1);
    }
    updateShow(!show);
  };

  const {
    current_level_title = '',
    change_garden_button = '',
    level_1_title: level_1 = '',
    level_2_title: level_2 = '',
    level_3_title: level_3 = '',
    level_4_title: level_4 = '',
    level_5_title: level_5 = '',
    level_6_title: level_6 = '',
    level_7_title: level_7 = '',
    level_8_title: level_8 = '',
    level_9_title: level_9 = '',
    level_10_title: level_10 = '',
    level_11_title: level_11 = '',
    level_12_title: level_12 = '',
    reward_text = 'test',
  } = localisedText;

  const levels = [level_1, level_2, level_3, level_4, level_5, level_6, level_7, level_8, level_9, level_10, level_11, level_12];

  const renderLevels = () => {
    let completed = true;

    return levels.map((name, index) => {
      if (`level_${index + 1}` === currentLevel && !props.hasCompletedAllLevels) {
        completed = false;

        return (
          <li key={index}>
            <MyGoalsLevel
              name={name}
              inProgress={true}
              isCompleted={false}
              chanllengesCompleted={chanllengesCompleted}
              level={`level_${index + 1}`}
              nextLevel={index + 2 > 12 ? 'completed' : `level_${index + 2}`}
            />
          </li>
        );
      } else {
        return (
          <li key={index}>
            <MyGoalsLevel name={name} inProgress={false} isCompleted={completed} level={`level_${index + 1}`} nextLevel={index + 2 > 12 ? 'completed' : `level_${index + 2}`} />
          </li>
        );
      }
    });
  };

  return (
    <React.Fragment>
      <button onClick={handleOpenState} aria-label="show levels" className={'mygoals__level__show-button' + (show ? ' mygoals__level__show-button--close' : ' mygoals__level__show-button--open')}>
        <MyGoalsArrowIcon />
        {reward_text !== '' && (
          <div className="title">
            <p>{reward_text}</p>
          </div>
        )}
      </button>
      <div className={'mygoals__levels__container' + (show ? ' mygoals__levels__container--show' : ' mygoals__levels__container--hide')}>
        <div className="mygoals__current-level">
          {!props.hasCompletedAllLevels && (
            <>
              {current_level_title}
              <MyGoalsLevel name={localisedText[`${currentLevel}_title`]} inProgress={true} headerLevel={true} />
            </>
          )}
          {numberOfChalleges > 0 && (
            <div className="mygoals__challenges-completed">
              <span className="text">{numberOfChallegesText === '' ? 'challenges youve accepted' : numberOfChallegesText}</span>
              <span className="count">{numberOfChalleges}</span>
            </div>
          )}
        </div>
        <ul className="mygoals__levels">{renderLevels()}</ul>
        <div className="mygoals__change-garden">
          <button aria-label={change_garden_button} onClick={() => stepCallback(CHANGE_GARDEN)}>
            {change_garden_button}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    localisedText: getSiteTextDataByGroup(state, 'my_goals'),
    isLoggedIn: getLoggedInStatus(state),
    currentLevel: getCurrentLevelID(state),
    chanllengesCompleted: totalChallengesCompleted(state),
    numberOfChallegesText: getSiteTextDataByGroupedKey(state, 'login', 'challenges_accepted_text'),
    numberOfChalleges: getNumberOfCompletedChallenges(state),
    hasCompletedAllLevels: getAllLevelsCompleted(state),
  };
};

export default connect(mapStateToProps, null)(MyGoalsLevels);

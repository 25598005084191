// **************************************
// PWA: Service Worker logic
// **************************************
import { getAppMode } from './general';
import { APP_MODES, BASE_URL, FRONTEND_URL, IS_DESKTOP, IS_BASE_URL, LOCAL_STORAGE_OPTIONS } from 'constants/app/AppConstants';
const STORAGE_NAME = `${LOCAL_STORAGE_OPTIONS.namespace}-ios_prompt`;

function checkLandscape() {
  if (window.innerWidth < window.innerHeight) {
    return false;
  } else {
    return true;
  }
}

function toggleIosPrompt() {
  const PROMPT_CONTAINER = document.getElementById('install-prompt');
  console.log(PROMPT_CONTAINER);
  PROMPT_CONTAINER.style.display = checkLandscape() ? 'none' : 'flex';
}

function setWithExpiry(key, value, ttl) {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key);

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

function triggerIosMessage() {
  const checkLocalStorage = getWithExpiry(STORAGE_NAME);

  if (getAppMode().mode != APP_MODES.APP && !IS_DESKTOP && localStorage.getItem(STORAGE_NAME) === null) {
    setTimeout(() => {
      if (!checkLandscape()) {
        window.addEventListener('onorientationchange', toggleIosPrompt);
        window.addEventListener('resize', toggleIosPrompt);

        const PROMPT_CONTAINER = document.getElementById('install-prompt');
        const IOS_TEXT = document.getElementById('ios-text');
        const IOS_DISMISS_BUTTON = document.getElementById('ios-close-button');

        PROMPT_CONTAINER.classList.add('ios');
        PROMPT_CONTAINER.style.display = 'flex';
        IOS_TEXT.classList.remove('ios-text--hidden');

        IOS_DISMISS_BUTTON.addEventListener('click', function (e) {
          window.removeEventListener('onorientationchange', toggleIosPrompt);
          window.removeEventListener('resize', toggleIosPrompt);

          setWithExpiry(
            STORAGE_NAME,
            {
              closed_prompt: true,
            },
            604800000
          );

          IOS_TEXT.classList.add('ios-text--hidden');
          PROMPT_CONTAINER.style.display = 'none';
        });
      }
    }, 5000);
  }
}

export const triggerSW = () => {
  if (__SERVICE_WORKER__) {
    const isHTTPS = window.location.protocol === 'https:';

    if ('serviceWorker' in navigator && isHTTPS) {
      const baseURL = '/';
      const frontendURL = '/static/frontend/';

      console.log(`${frontendURL}sw.js`);

      navigator.serviceWorker
        .register(`${frontendURL}sw.js`, { scope: baseURL })
        .then((registration) => {
          console.log('registration', registration);

          // Here we are checking if the SW has been updated
          registration.onupdatefound = (e) => {
            const installingWorker = registration.installing;
            if (installingWorker) {
              // If the current registration represents the "installing" service worker, then wait
              // until the installation step (during which the resources are pre-fetched) completes
              // to display the file list.
              installingWorker.onstatechange = () => {
                switch (installingWorker.state) {
                  case 'installed':
                    if (navigator.serviceWorker.controller) {
                      console.log('new one found');

                      if (!IS_DESKTOP && window.DeviceDetection.isIOS && getAppMode().mode === APP_MODES.APP) {
                        document.getElementById('notification').style.display = 'flex';
                        document.getElementById('reload').addEventListener('click', function () {
                          installingWorker.postMessage({ action: 'skipWaiting' });
                        });
                      } else {
                        installingWorker.postMessage({ action: 'skipWaiting' });
                      }
                    } else {
                      console.log('nope');
                    }
                    break;
                }
              };
            } else {
              // Otherwise, if this isn't the "installing" service worker, then installation must have been
              // completed during a previous visit to this page, and the resources are already pre-fetched.
              // So we can show the list of files right away.
              console.log('previous visit to this page installed');
            }
          };
        })
        .catch((registrationError) => {
          console.info('SW registration failed: ', registrationError);
        });

      navigator.serviceWorker.addEventListener('controllerchange', function () {
        window.location.reload(true);
      });

      // If iOS display the install promopt
      if (window.DeviceDetection.isIOS) {
        !navigator.userAgent.match('CriOS') && triggerIosMessage();
      } else {
        // Setup install prompt on Andriod devices if not desktop
        let deferredPrompt;
        if (!IS_DESKTOP) {
          const PROMPT_CONTAINER_DEFAULT = document.getElementById('install-prompt');
          const DEFAULT_TEXT = document.getElementById('default-text');
          const INSTALL_BUTTON = document.getElementById('user-click');
          const DISMISS_BUTTON = document.getElementById('prompt-close-button');
          // Listen for beforeinstallprompt
          window.addEventListener('beforeinstallprompt', function (e) {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            // Stash the event so it can be triggered later.
            deferredPrompt = e;

            PROMPT_CONTAINER_DEFAULT.classList.add('other');
            PROMPT_CONTAINER_DEFAULT.style.display = 'flex';
            DEFAULT_TEXT.classList.remove('default-text--hidden');

            DISMISS_BUTTON.addEventListener('click', function (e) {
              PROMPT_CONTAINER_DEFAULT.style.display = 'none';
            });

            INSTALL_BUTTON.addEventListener('click', (e) => {
              // hide our user interface that shows our A2HS button
              PROMPT_CONTAINER_DEFAULT.style.display = 'none';
              DEFAULT_TEXT.classList.add('default-text--hidden');
              // Show the prompt
              deferredPrompt.prompt();
              // Wait for the user to respond to the prompt
              deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                  console.log('User accepted the A2HS prompt');
                } else {
                  PROMPT_CONTAINER_DEFAULT.style.display = 'none';
                  console.log('User dismissed the A2HS prompt');
                }
                deferredPrompt = null;
              });
            });
          });
        }
      }
    } else {
      console.info('service worker not available.');
    }
  }
};

import { getUrlStaticImages, getUrlDynamic } from 'constants/app/AppUrls';

/*
  Function to generate the sgarble challenge image
*/

let cachedSources = {};

const defaultChallengeImage = {
  width: 397,
  height: 223,
};

const defaultChallengeIcon = {
  width: 40,
  height: 40,
};

const iconPaths = {
  type8: 'shareable-image/creative-icon.svg',
  type3: 'shareable-image/fauna-icon.svg',
  type5: 'shareable-image/energy-icon.svg',
  type6: 'shareable-image/flora-icon.svg',
  type1: 'shareable-image/food-icon.svg',
  type2: 'shareable-image/recycling-icon.svg',
  type7: 'shareable-image/transport-icon.svg',
  type4: 'shareable-image/water-icon.svg',
};

// Helper functions
function wrapText(context, text, x, y, maxWidth, lineHeight) {
  let words = text.split(' ');
  let line = '';
  let lineCount = 0;
  let i;
  let test;
  let metrics;

  for (i = 0; i < words.length; i++) {
    test = words[i];
    metrics = context.measureText(test);
    while (metrics.width > maxWidth) {
      // Determine how much of the word will fit
      test = test.substring(0, test.length - 1);
      metrics = context.measureText(test);
    }
    if (words[i] != test) {
      words.splice(i + 1, 0, words[i].substr(test.length));
      words[i] = test;
    }

    test = line + words[i] + ' ';
    metrics = context.measureText(test);

    if (metrics.width > maxWidth && i > 0) {
      let lineText = context.measureText(line).width;
      context.fillText(line, x + (maxWidth / 2 - lineText / 2), y);
      line = words[i] + ' ';
      y += lineHeight;
      lineCount++;
    } else {
      line = test;
    }
  }

  let lastLineText = context.measureText(line).width;
  context.fillText(line, x + (maxWidth / 2 - lastLineText / 2), y);
}

function roundedImage(ctx, x, y, width, height, radius) {
  ctx.beginPath();
  ctx.moveTo(x + 0, y);
  ctx.lineTo(x + width - 0, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + 0);
  ctx.lineTo(x + width, y + height - 25);
  ctx.quadraticCurveTo(x + width, y + height, x + width - 25, y + height);
  ctx.lineTo(x + 0, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - 0);
  ctx.lineTo(x, y + 25);
  ctx.quadraticCurveTo(x, y, x + 25, y);
  ctx.closePath();
}

export const generateSharableImage = (challengeData, logo, hasCompleted, siteText, callback) => {
  // get the ball rolling
  const imageSources = {
    logo: getUrlDynamic(logo),
    challenge_image: getUrlDynamic(challengeData.image),
    challenge_icon: getUrlStaticImages(iconPaths[challengeData.type]),
    bg: getUrlStaticImages('shareable-image/bg-small.png'),
  };

  let images = {};
  let loadedImages = 0;
  let numImages = Object.keys(imageSources).length - 1;
  // get num of sources
  for (const [key, value] of Object.entries(imageSources)) {
    if (value) {
      images[key] = new Image();
      images[key].crossOrigin = 'anonymous';
      images[key].src = value + '?not-cache';
      images[key].onload = () => {
        if (loadedImages >= numImages) {
          generateImage(images, { challengeData, hasCompleted, siteText, callback });
        } else {
          loadedImages = loadedImages + 1;
        }
      };
    }
  }
};

const generateImage = (images, data) => {
  /*
    Setup the canvas element
  */
  const canvas = document.createElement('canvas');
  const dpr = window.devicePixelRatio || 1;
  // Get the size of the canvas in CSS pixels.
  // const rect = canvas.getBoundingClientRect();
  canvas.width = 630;
  canvas.height = 630;
  const context = canvas.getContext('2d');
  context.webkitImageSmoothingEnabled = false;
  context.mozImageSmoothingEnabled = false;
  context.msImageSmoothingEnabled = false;
  context.imageSmoothingEnabled = false;
  context.direction = 'ltr';

  // Add canvas to the DOM to draw on
  canvas.display = 'none';
  document.body.appendChild(canvas);

  /*
    Draw elements
  */
  // add bg image
  context.drawImage(images.bg, 0, 0);

  // add coloured boxes
  context.fillStyle = data.challengeData.color;
  context.fillRect(0, 105, canvas.width, 67);

  context.fillStyle = '#ed0080';
  context.fillRect(0, 495, canvas.width, 111);

  // add challenge image
  // Create rounded cliping mask : see roundedImage function
  context.save();
  roundedImage(context, canvas.width / 2 - defaultChallengeImage.width / 2, 210, defaultChallengeImage.width, defaultChallengeImage.height, 25);
  context.clip();
  context.drawImage(images.challenge_image, canvas.width / 2 - defaultChallengeImage.width / 2, 210, defaultChallengeImage.width, defaultChallengeImage.height);
  context.restore();

  // add logo image
  context.drawImage(images.logo, 0, canvas.height - 210);

  // add icon
  let ratio = images.challenge_icon.width / images.challenge_icon.height;
  let newHeight = defaultChallengeIcon.width / ratio;
  context.drawImage(images.challenge_icon, 128, 139 - newHeight / 2, defaultChallengeIcon.width, newHeight);

  const rtl = document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl';

  // ---- add text
  // default white
  context.fillStyle = 'white';

  if (data.hasCompleted) {
    context.font = '21px Lubalin Graph ITC Turner Latin';
    wrapText(context, data.siteText.share_image_completed_text, 33, 40, 567, 24);
  } else {
    context.font = '21px Lubalin Graph ITC Turner Latin';
    wrapText(context, data.siteText.share_image_generic_text, 33, 40, 567, 24);
  }

  context.font = 'bold 22px avenir-rounded';

  if (rtl) {
    context.direction = 'rtl';
    const width = context.measureText(data.challengeData.title).width;
    wrapText(context, data.challengeData.title, 196 + width, 147, 318, 24);
  } else {
    wrapText(context, data.challengeData.title, 196, 147, 318, 24);
  }
  context.direction = 'ltr';

  context.font = '23px Lubalin Graph ITC Turner Latin';
  wrapText(context, data.siteText.cta_text, 223, 530, 357, 26);

  /*
    trigger callback to set base64 to state
  */
  if (data.callback) {
    data.callback(canvas.toDataURL());
  }

  /*
    Remove canvas when done 
  */
  if (canvas.parentNode) {
    canvas.parentNode.removeChild(canvas);
  }
};

import { getEmojiData, getEmojiIDs } from 'store/selectors/Emoji.selectors';
import { getEntryIsLiked } from 'store/selectors/Likes.selectors';

export const getEntryEmojiLikeCount = (state, entryID, emojiID) => {
  // returns all an emoji rating for an entry
  // console.log('getEntryEmojiLikeCount', entryID, emojiID, state.ratings.likesByID);
  return state.ratings.likesByID[entryID][emojiID] || '0';
};

export const getEntryEmojiAvailableLikeCounts = (state, contentType, entryID) => {
  // returns all available emoji ratings for an entry returned
  // from the server (an emoji may not have a rating)
  // console.log('getEntryEmojiAvailableLikeCounts', contentType, entryID, state.ratings.likesByID);
  return state.ratings.likesByID[entryID] || {};
};

export const getEntryEmojiAllLikeCounts = (state, contentType, entryID) => {
  const emojis = getEmojiIDs(state);
  const emojiData = getEmojiData(state);
  const available = getEntryEmojiAvailableLikeCounts(state, contentType, entryID);
  return emojis.reduce((obj, id, index) => {
    const cnt = available[id] || 0;
    const emoji = { ...emojiData[id] };
    emoji.count = cnt;
    emoji.userHasLiked = getEntryIsLiked(state, entryID, id);
    obj.push(emoji);
    return obj;
  }, []);
};

import {
  USER_LEVELS_SAVE,
  USER_LEVELS_LEVEL_UP,
  USER_LEVELS_ALL_LEVELS_COMPLETED,
  USER_LEVELS_UPDATE,
  USER_LEVELS_CHALLENGES_INCREMENT,
  USER_LEVELS_CHALLENGES_DECREMENT,
  USER_LEVELS_UP_POPUP_SHOW,
  USER_LEVELS_UP_POPUP_HIDE,
  USER_LEVELS_SEEN_LEVEL,
} from 'store/actions/UserLevels.actions';

const initState = {
  totalLevelsCompleted: 1,
  currentLevelID: 'level_1',
  previousLevelID: 'level_1',
  allLevelsCompleted: false,
  totalChallengesCompleted: 0,
  showLevelUpPopup: false,
  hasSeenLevelUp: false,
};

const reducer = (state = initState, action) => {
  let nextState = {};
  switch (action.type) {
    case USER_LEVELS_SAVE: {
      const { totalCompletedLevels, currentLevel, currentLevelChallengesCompleted } = action.payload;
      nextState.totalLevelsCompleted = totalCompletedLevels;
      nextState.currentLevelID = currentLevel;
      nextState.totalChallengesCompleted = currentLevelChallengesCompleted;
      return { ...state, ...nextState };
    }
    case USER_LEVELS_UP_POPUP_SHOW: {
      nextState.showLevelUpPopup = true;
      return { ...state, ...nextState };
    }
    case USER_LEVELS_UP_POPUP_HIDE: {
      nextState.showLevelUpPopup = false;
      return { ...state, ...nextState };
    }
    case USER_LEVELS_LEVEL_UP: {
      const { id, prevId, type } = action.payload;

      nextState.hasSeenLevelUp = false;
      nextState.totalLevelsCompleted = type === USER_LEVELS_CHALLENGES_DECREMENT ? state.totalLevelsCompleted - 1 : state.totalLevelsCompleted + 1;
      nextState.currentLevelID = id;
      nextState.previousLevelID = prevId;

      return { ...state, ...nextState };
    }
    case USER_LEVELS_ALL_LEVELS_COMPLETED: {
      nextState.allLevelsCompleted = action.payload;
      return { ...state, ...nextState };
    }
    case USER_LEVELS_CHALLENGES_INCREMENT: {
      if (!state.allLevelsCompleted) {
        nextState.totalChallengesCompleted = state.totalChallengesCompleted + 1;
      }
      return { ...state, ...nextState };
    }
    case USER_LEVELS_CHALLENGES_DECREMENT: {
      if (state.totalChallengesCompleted != 0) {
        nextState.totalChallengesCompleted = state.totalChallengesCompleted - 1;
      }
      return { ...state, ...nextState };
    }
    case USER_LEVELS_SEEN_LEVEL: {
      nextState.hasSeenLevelUp = true;
      return { ...state, ...nextState };
    }
  }
  return state;
};

export default reducer;

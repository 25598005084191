import React, { Component } from 'react';

// import component
import ChallengesArrowDown from 'components/inline-svgs/challenges/ChallengesArrowDown';

class FaqItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
    this.toggleShow = this.toggleShow.bind(this);
  }

  toggleShow() {
    this.setState({ show: !this.state.show });
  }

  render() {
    const { answer = '', question = '' } = this.props;
    return (
      <div className={'faq-item ' + (this.state.show ? 'faq-item--show' : 'faq-item--hide')}>
        <div className="faq-item__title" onClick={this.toggleShow}>
          <div className="question">{question}</div>
          <div className="icon">
            <ChallengesArrowDown />
          </div>
        </div>
        <div className="faq-item__description" dangerouslySetInnerHTML={{ __html: answer }}></div>
      </div>
    );
  }
}

export default FaqItem;

//magic things
import React from 'react';

const SchoolsTeacherIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" className="schools-teacher-icon">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <circle className="cls-1" cx="45" cy="45" r="45" />
        <circle cx="45" cy="45" r="39.6" fill="#ed0080" />
        <g id="grad_icon">
          <path
            className="cls-1"
            d="M75.64 35.11A4 4 0 0174 38.53l-10.44 6.09-11.42 6.66s-2.66 2.15-4.8 2.53a3.35 3.35 0 01-.63 0 8.4 8.4 0 01-4.05-1.44L27.42 44.7l-4.21-2.13s-4.27-2-4.17-2.15c-2.21-.57-2.4-3.28-2.4-3.28-.38-2.15 2.78-3.92 2.78-3.92L42 20.08a9 9 0 013-1.52 4 4 0 01.58 0 3.86 3.86 0 012 .56l25 12.51s3.06 1.08 3.06 3.48z"
          />
          <path className="cls-1" d="M42.66 52.42L27.42 44.7v-.08h36.14l-11.42 6.66s-2.66 2.15-4.8 2.53a3.35 3.35 0 01-.63 0 8.4 8.4 0 01-4.05-1.39z" />
          <path
            className="cls-1"
            d="M64.82 46.93v7.72c-.17.26-.34.52-.53.77C60.61 60.53 53.85 64 46.12 64s-14.49-3.43-18.18-8.54c-.18-.25-.35-.51-.52-.77v-7l13.91 7a10.78 10.78 0 005.38 1.8 6.59 6.59 0 001.09-.09 15.42 15.42 0 005.83-2.94zM22.05 56.38c1.41 3.71 1.73 10.29-3.2 11-5.41.81-5.4-5-2.75-7.3a4.9 4.9 0 001.6-3.49 3.41 3.41 0 01.67-5.87V36.19h2.72v14.56a3.41 3.41 0 011 5.63z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SchoolsTeacherIcon;

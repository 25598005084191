import { ENSIGHTEN_TRACKING_STATUS } from 'store/actions/EnsightenTracking.actions';

const initState = {
  isActive: false,
};

const tracking = (state = initState, action) => {
  let nextState = {};
  switch (action.type) {
    case ENSIGHTEN_TRACKING_STATUS:
      nextState.isActive = action.payload;
      return { ...state, ...nextState };
    default:
  }
  return state;
};

export default tracking;

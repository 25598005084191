import React from 'react';

const CloseSign = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" className="close-sign" viewBox="0 0 26.12 26.12">
    <g>
      <g>
        <line x1="3.78" y1="22.34" x2="22.34" y2="3.78" />
        <line x1="3.78" y1="3.78" x2="22.34" y2="22.34" />
      </g>
    </g>
  </svg>
);

export default CloseSign;

//magic things
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import components
import SchoolsStarWhiteIcon from 'components/inline-svgs/schools-page/SchoolsStarWhiteIcon';
import SchoolsStarBlackIcon from 'components/inline-svgs/schools-page/SchoolsStarBlackIcon';
import SchoolsTeacherIcon from 'components/inline-svgs/schools-page/SchoolsTeacherIcon';
import SuperChallengeIcon from 'components/generic/SuperChallengeIcon';

// import components
import ChallengeItem from 'components/challenges/ChallengeItem';

class SuperChallenges extends Component {
  render() {
    const { challenges, pageText, challengeSchoolUrl } = this.props;

    const {
      schools_main_title = '',
      schools_are_you_teacher = '',
      schools_are_you_teacher_title = '',
      schools_climate_action_curriculum_button = '',
      schools_climate_action_curriculum_url = '',
      schools_contact_us = '',
      schools_more_schools_button = '',
      schools_sign_up = '',
      schools_super_challenge_badge = '',
      schools_take_the_quiz_button = '',
      schools_take_the_quiz_url = '',
      schools_title = '',
      schools_what_is_super_challenge = '',
      schools_what_is_super_challenge_1 = '',
      schools_what_is_super_challenge_2 = '',
      schools_what_is_super_challenge_3 = '',
      schools_what_is_super_challenge_4 = '',
      schools_resources_title = '',
    } = pageText;

    return (
      <section className="challenges__container">
        <div className="container">
          <h1>{schools_title}</h1>
          <ul className="challenges-grid challenges-grid--full">
            {challenges.length > 0 &&
              challenges.map((item, index) => {
                return (
                  <li key={index}>
                    <ChallengeItem data={item} />
                  </li>
                );
              })}
            <li>
              <section className="schools__super-challenges">
                <div className="schools__super-challenges__header">
                  <div className="schools__super-challenges__icon">
                    <SuperChallengeIcon />
                  </div>
                  {schools_what_is_super_challenge}
                </div>
                <div className="schools__super-challenges__item schools__super-challenges__item-1">
                  <SchoolsStarBlackIcon />
                  {schools_what_is_super_challenge_1}
                </div>
                <div className="schools__super-challenges__item schools__super-challenges__item-2">
                  <SchoolsStarBlackIcon />
                  {schools_what_is_super_challenge_2}
                </div>
                <div className="schools__super-challenges__item schools__super-challenges__item-2">
                  <SchoolsStarBlackIcon />
                  {schools_what_is_super_challenge_3}
                </div>
                <div className="schools__super-challenges__item schools__super-challenges__item-3">
                  <SchoolsStarBlackIcon />
                  {schools_what_is_super_challenge_4}
                </div>
              </section>
            </li>
          </ul>

          <button type="button" className="schools__more-challenges__button" aria-label="Show more challenge">
            <Link to={challengeSchoolUrl}>
              <SchoolsTeacherIcon />
              {schools_more_schools_button}
            </Link>
          </button>
        </div>
      </section>
    );
  }
}

export default SuperChallenges;

//magic things
import React from 'react';

const NavGlobalGoalsIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.47 50.52" className="nav-map-icon">
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <path
          d="M16.73 3.93A16.75 16.75 0 000 20.66C0 28 10.17 43.19 14.54 49.38a2.68 2.68 0 004.39 0C23.3 43.19 33.47 28 33.47 20.66A16.75 16.75 0 0016.73 3.93zM26 21.22a9.3 9.3 0 11-10-10h.74a9.29 9.29 0 019.26 10z"
          className="cls-1"
        />
        <path
          d="M16.73 0A16.76 16.76 0 000 16.73c0 7.34 10.17 22.54 14.54 28.73a2.68 2.68 0 004.39 0c4.37-6.19 14.54-21.39 14.54-28.73A16.76 16.76 0 0016.73 0zM26 17.29a9.3 9.3 0 11-10-10h.74a9.3 9.3 0 019.26 10z"
          className="cls-2"
        />
      </g>
    </g>
  </svg>
);

export default NavGlobalGoalsIcon;

import { getMicrositeName, BASE_TEMPLATE, SECTION_LOOKUP } from 'constants/ensighten-tracking/EnsightenTrackingConstants';
import { PAGES } from 'constants/content-library/ContentLibraryConstants';

const getRewardPageData = ({ contentType, isPWA, level }) => {
  let data = { ...BASE_TEMPLATE };
  const section = SECTION_LOOKUP[contentType];
  // data.micrositename = getMicrositeName(isPWA);
  data.page_name = data.page_name + decodeURIComponent(location.pathname);
  data.section = section;
  data.subsection = `${section}|main`;
  data.pagetype = 'main';
  data.content_type = 'section front';
  data.promopageevent = 1;
  data.content_title = 'rewards promo';
  data.rewardlevel = level.replace('_', ' ');
  data.pageview_event = 1;
  return data;
};

export default getRewardPageData;

import React from 'react';

const ChallengesLikeIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.06 36.3" className="challenge-like-icon">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="share_like">
          <g id="like">
            <g id="Group_327-2" data-name="Group 327-2">
              <path id="Path_990" data-name="Path 990" className="cls-1" d="M0 13.2v20.53a2.59 2.59 0 002.61 2.57h8.07V10.63h-8A2.62 2.62 0 000 13.2z" />
              <path
                id="Path_991"
                data-name="Path 991"
                className="cls-1"
                d="M37.19 13H29.4a1.31 1.31 0 01-1.32-1.29 1.27 1.27 0 01.17-.63l2.69-4.78a3.87 3.87 0 00.35-3.3 4 4 0 00-2.8-2.48L26.55 0a1.31 1.31 0 00-1.29.38L14.74 11.91a6.45 6.45 0 00-1.69 4.34v13.57a6.56 6.56 0 006.6 6.48h13.11a6.6 6.6 0 006.35-4.77l2.82-12.7a4.78 4.78 0 00-3.65-5.74 5.06 5.06 0 00-1.09-.09z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ChallengesLikeIcon;

import { getMicrositeName, BASE_TEMPLATE, SUBSECTION_LOOKUP } from 'constants/ensighten-tracking/EnsightenTrackingConstants';
import { parseData } from 'constants/ensighten-tracking/EnsightenTrackingServices';
import { CONTENT } from 'constants/content-library/ContentLibraryConstants';

const getSubPageData = ({ contentType, contentItem = {}, isPWA, level }) => {
  let data = { ...BASE_TEMPLATE };
  const { english_tracking_name, title, id, game_genre } = contentItem;
  const { section, subsection } = SUBSECTION_LOOKUP[contentType];
  // data.micrositename = getMicrositeName(isPWA);
  data.page_name = data.page_name + decodeURIComponent(location.pathname);
  data.section = section;
  data.subsection = `${section}|${subsection} detail`;
  // data.pagetype = contentType;
  // data.show = 'climate champions';
  data.content_type = contentType;
  data.content_title = english_tracking_name ? parseData(english_tracking_name) : parseData(title);
  data.content_id = id;
  // data.englishname = parseData(english_tracking_name);
  data.franchise = 'cn climate champions';
  data.pageview_event = 1;

  switch (contentType) {
    case CONTENT.REWARD:
      data.content_type = 'rewards';
      data.pagetype = 'rewards';
      data.rewardlevel = level.replace('_', ' ');
      data.promopageevent = 1;
      data.rewarddetailview = 1;
      break;
    case CONTENT.GAME:
      data.gametitle = parseData(title);
      data.gamecategory = parseData(game_genre);
      data.gamedetailviewevent = 1;
      break;
    case CONTENT.VIDEO:
      // data.videotitle = 'climate champions|' + parseData(english_tracking_name);
      // data.videocategory = 'nvs';
      // data.videodetailviewevent = 1;
      break;
    case CONTENT.APP:
      data.appdetailviewevent = 1;
      break;
    case CONTENT.QUIZ:
      data.quizname = parseData(title);
      data.quizlaunchevent = 1;
      break;
    case CONTENT.DOWNLOADS:
      data.pagetype = 'download';
      data.content_type = 'downloads';
      data.downloaddetailviewevent = 1;
      break;
    default:
  }
  return data;
};

export default getSubPageData;

import { getUrlStaticImages } from 'constants/app/AppUrls';

// My Goals States
export const STATE_INTRO = 'STATE_INTRO';
export const STATE_CHOOSE = 'STATE_CHOOSE';
export const STATE_GARDEN = 'STATE_GARDEN';

// Unlock Metrics
/*
  Levels unlock
  
  Nature Nurturer : Unlocked
  Rubbish Renewer : 2 challenges
  Ecco Enthusiast : + 3 challenges (total 5)
  Wildlife Warrior : + 3 challenges (total 8)
  Resource Recycler : + 3 challenges (total 11)
  Water Wizard : + 4 challenges (total 14)
  Sustainable Star : + 5 challenges (total 19)
  Sunshine Saviour : + 6 challenges (total 25)
  Uber Upcycler : + 7 challenges (total 32)
  Actively Awesome : + 9 challenges (total 41)
  Energy Expert : + 10 challenges (total 51)
  Climate Champion : + 11 Challenges (total 62)
*/
export const CHALLENGES_PER_LEVEL = 12;
export const UNLOCK_LEVELS_CHALLENGE_THRESHOLD = {
  level_1: 0,
  level_2: 2,
  level_3: 5,
  level_4: 8,
  level_5: 11,
  level_6: 14,
  level_7: 19,
  level_8: 25,
  level_9: 32,
  level_10: 41,
  level_11: 51,
  level_12: 62,
};
export const TOTAL_NUMBER_OF_LEVELS = 12;
export const LAST_LEVEL_ID = 'level_12';

export const GARDEN_DEFAULT_WIDTH = 942;
export const GARDEN_DEFAULT_HEIGHT = 538;

export const GARDENS = {
  garden_1: {
    name: 'Garden 1',
    img: getUrlStaticImages(`my-goals/garden_choice_1.jpg`),
    data: {
      placement_1: {
        x: 40,
        y: 454,
        zIndex: 3,
        coversToolTip: true,
      },
      placement_2: {
        x: 145,
        y: 494,
        zIndex: 3,
        coversToolTip: true,
      },
      placement_3: {
        x: 250,
        y: 451,
        zIndex: 3,
        coversToolTip: true,
      },
      placement_4: {
        x: 190,
        y: 287,
        zIndex: 2,
        coversToolTip: false,
      },
      placement_5: {
        x: 287,
        y: 273,
        zIndex: 2,
        coversToolTip: false,
      },
      placement_6: {
        x: 220,
        y: 168,
        zIndex: 1,
        coversToolTip: false,
      },
      placement_7: {
        x: 500,
        y: 454,
        zIndex: 3,
        coversToolTip: true,
      },
      placement_8: {
        x: 360,
        y: 494,
        zIndex: 3,
        coversToolTip: true,
      },
      placement_9: {
        x: 618,
        y: 474,
        zIndex: 3,
        coversToolTip: true,
      },
      placement_10: {
        x: 728,
        y: 442,
        zIndex: 3,
        coversToolTip: true,
      },
      placement_11: {
        x: 630,
        y: 269,
        zIndex: 2,
        coversToolTip: false,
      },
      placement_12: {
        x: 760,
        y: 118,
        zIndex: 1,
        coversToolTip: true,
      },
      avaiable_placements: [
        'placement_1',
        'placement_2',
        'placement_3',
        'placement_4',
        'placement_5',
        'placement_6',
        'placement_7',
        'placement_8',
        'placement_9',
        'placement_10',
        'placement_11',
        'placement_12',
      ],
    },
  },
  garden_2: {
    name: 'Garden 2',
    img: getUrlStaticImages(`my-goals/garden_choice_2.jpg`),
    data: {
      placement_1: {
        x: 100,
        y: 374,
        zIndex: 2,
        coversToolTip: false,
      },
      placement_2: {
        x: 200,
        y: 466,
        zIndex: 3,
        coversToolTip: false,
      },
      placement_3: {
        x: 204,
        y: 391,
        zIndex: 2,
        coversToolTip: false,
      },
      placement_4: {
        x: 307,
        y: 367,
        zIndex: 2,
        coversToolTip: false,
      },
      placement_5: {
        x: 93,
        y: 225,
        zIndex: 1,
        coversToolTip: false,
      },
      placement_6: {
        x: 210,
        y: 225,
        zIndex: 1,
        coversToolTip: false,
      },
      placement_7: {
        x: 330,
        y: 225,
        zIndex: 1,
        coversToolTip: false,
      },
      placement_8: {
        x: 407,
        y: 392,
        zIndex: 2,
        coversToolTip: false,
      },
      placement_9: {
        x: 710,
        y: 184,
        zIndex: 1,
        coversToolTip: false,
      },
      placement_10: {
        x: 680,
        y: 417,
        zIndex: 2,
        coversToolTip: true,
      },
      placement_11: {
        x: 797,
        y: 417,
        zIndex: 2,
        coversToolTip: true,
      },
      placement_12: {
        x: 822,
        y: 184,
        zIndex: 1,
        coversToolTip: false,
      },
      avaiable_placements: [
        'placement_1',
        'placement_2',
        'placement_3',
        'placement_4',
        'placement_5',
        'placement_6',
        'placement_7',
        'placement_8',
        'placement_9',
        'placement_10',
        'placement_11',
        'placement_12',
      ],
    },
  },
  garden_3: {
    name: 'Garden 3',
    img: getUrlStaticImages(`my-goals/garden_choice_3.jpg`),
    data: {
      placement_1: {
        x: 30,
        y: 397,
        zIndex: 3,
        coversToolTip: false,
      },
      placement_2: {
        x: 160,
        y: 426,
        zIndex: 3,
        coversToolTip: true,
      },
      placement_3: {
        x: 380,
        y: 444,
        zIndex: 3,
        coversToolTip: true,
      },
      placement_4: {
        x: 667,
        y: 191,
        zIndex: 2,
        coversToolTip: true,
      },
      placement_5: {
        x: 23,
        y: 195,
        zIndex: 1,
        coversToolTip: false,
      },
      placement_6: {
        x: 298,
        y: 261,
        zIndex: 1,
        coversToolTip: false,
      },
      placement_7: {
        x: 397,
        y: 268,
        zIndex: 1,
        coversToolTip: false,
      },
      placement_8: {
        x: 502,
        y: 434,
        zIndex: 3,
        coversToolTip: true,
      },
      placement_9: {
        x: 498,
        y: 275,
        zIndex: 1,
        coversToolTip: false,
      },
      placement_10: {
        x: 580,
        y: 379,
        zIndex: 2,
        coversToolTip: false,
      },
      placement_11: {
        x: 657,
        y: 407,
        zIndex: 3,
        coversToolTip: false,
      },
      placement_12: {
        x: 764,
        y: 406,
        zIndex: 3,
        coversToolTip: false,
      },
      avaiable_placements: [
        'placement_1',
        'placement_2',
        'placement_3',
        'placement_4',
        'placement_5',
        'placement_6',
        'placement_7',
        'placement_8',
        'placement_9',
        'placement_10',
        'placement_11',
        'placement_12',
      ],
    },
  },
  garden_4: {
    name: 'Garden 4',
    img: getUrlStaticImages(`my-goals/garden_choice_4.jpg`),
    data: {
      placement_1: {
        x: 100,
        y: 374,
        zIndex: 2,
        coversToolTip: false,
      },
      placement_2: {
        x: 220,
        y: 506,
        zIndex: 3,
        coversToolTip: true,
      },
      placement_3: {
        x: 210,
        y: 340,
        zIndex: 2,
        coversToolTip: false,
      },
      placement_4: {
        x: 357,
        y: 502,
        zIndex: 3,
        coversToolTip: true,
      },
      placement_5: {
        x: 393,
        y: 365,
        zIndex: 2,
        coversToolTip: false,
      },
      placement_6: {
        x: 320,
        y: 305,
        zIndex: 1,
        coversToolTip: false,
      },
      placement_7: {
        x: 470,
        y: 305,
        zIndex: 1,
        coversToolTip: false,
      },
      placement_8: {
        x: 522,
        y: 444,
        zIndex: 3,
        coversToolTip: true,
      },
      placement_9: {
        x: 670,
        y: 284,
        zIndex: 1,
        coversToolTip: false,
      },
      placement_10: {
        x: 650,
        y: 467,
        zIndex: 3,
        coversToolTip: true,
      },
      placement_11: {
        x: 567,
        y: 237,
        zIndex: 1,
        coversToolTip: false,
      },
      placement_12: {
        x: 792,
        y: 292,
        zIndex: 1,
        coversToolTip: false,
      },
      avaiable_placements: [
        'placement_1',
        'placement_2',
        'placement_3',
        'placement_4',
        'placement_5',
        'placement_6',
        'placement_7',
        'placement_8',
        'placement_9',
        'placement_10',
        'placement_11',
        'placement_12',
      ],
    },
  },
};

export const GARDEN_PLACEMENT_IMAGE = getUrlStaticImages(`my-goals/garden-placement.png`);

export const PLANTS = ['plant_1', 'plant_2', 'plant_3', 'plant_4', 'plant_5', 'plant_6', 'plant_7', 'plant_8', 'plant_9', 'plant_10', 'plant_11', 'plant_12'];

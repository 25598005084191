import React from 'react';
import { CHALLENGE_NAMES } from 'constants/challenges/ChallengeTypes';
import ChallengesFaunaIcon from 'components/inline-svgs/challenges/ChallengesFaunaIcon';
import ChallengesWaterIcon from 'components/inline-svgs/challenges/ChallengesWaterIcon';
import ChallengesEnergyIcon from 'components/inline-svgs/challenges/ChallengesEnergyIcon';
import ChallengesFloraIcon from 'components/inline-svgs/challenges/ChallengesFloraIcon';
import ChallengesRecycleIcon from 'components/inline-svgs/challenges/ChallengesRecycleIcon';
import ChallengesTravelIcon from 'components/inline-svgs/challenges/ChallengesTravelIcon';
import ChallengesRenewablesIcon from 'components/inline-svgs/challenges/ChallengesRenewablesIcon';
import ChallengesFoodIcon from 'components/inline-svgs/challenges/ChallengesFoodIcon';
import ChallengesCreativeIcon from 'components/inline-svgs/challenges/ChallengesCreativeIcon';
import ChallengesSchoolsIcon from 'components/inline-svgs/challenges/ChallengesSchoolsIcon';

const ChallengeIcon = (props) => {
  const getChallengeIcon = (type) => {
    switch (type) {
      case CHALLENGE_NAMES.FOOD:
        return <ChallengesFoodIcon />;
      case CHALLENGE_NAMES.CREATIVE:
        return <ChallengesCreativeIcon />;
      case CHALLENGE_NAMES.RECYCLE:
        return <ChallengesRecycleIcon />;
      case CHALLENGE_NAMES.FAUNA:
        return <ChallengesFaunaIcon />;
      case CHALLENGE_NAMES.WATER:
        return <ChallengesWaterIcon />;
      case CHALLENGE_NAMES.ENERGY:
        return <ChallengesEnergyIcon />;
      case CHALLENGE_NAMES.FLORA:
        return <ChallengesFloraIcon />;
      case CHALLENGE_NAMES.TRAVEL:
        return <ChallengesTravelIcon />;
      case CHALLENGE_NAMES.SCHOOLS:
        return <ChallengesSchoolsIcon />;
      default:
        return null;
    }
  };
  const { type } = props;
  return getChallengeIcon(type);
};

export default ChallengeIcon;

import request from 'superagent/lib/client';
import { getUrlSiteData, getUrlGetTrackedData, getUrlTrackLike, getUrlTrackUnLike, getUrlMapData, getUrlGeoPosition } from 'constants/app/AppUrls';

export const trackViewService = (external_id, xsscookie) => {};

export const getGeoPosition = () => {
  const url = getUrlGeoPosition();
  return request
    .get(url)
    .type('form')
    .then((response) => {
      return JSON.parse(response.text);
    })
    .catch((error) => {
      return { result: 'FAILURE', reason: error };
    });
};

export const getMapData = () => {
  const url = getUrlMapData();
  return request
    .get(url)
    .type('form')
    .then((response) => {
      // console.log('%%% getSiteData', response, '%%%');
      return JSON.parse(response.text);
    })
    .catch((error) => {
      return { result: 'FAILURE', reason: error };
    });
};

export const getSiteData = () => {
  const url = __WATCH__ ? 'https://staging-cc.turner-apps.com/api/get_site_data/en-us' : getUrlSiteData();
  return request
    .get(url)
    .type('form')
    .then((response) => {
      // console.log('%%% getSiteData', response, '%%%');
      return JSON.parse(response.text);
    })
    .catch((error) => {
      return { result: 'FAILURE', reason: error };
    });
};

export const trackLikeService = (contentType, entryID, emojiID) => {
  const url = getUrlTrackLike();
  // console.info('trackLikeService | url', url, ' | contentType', contentType, ' | key', entryID, ' | type', emojiID);
  return request
    .post(url)
    .type('form')
    .send({ content_type: contentType, key: entryID, type: emojiID })
    .then((response) => {
      // console.log('%%% trackLikeService', response, '%%%');
      return JSON.parse(response.text);
    })
    .catch((error) => {
      return { result: 'FAILURE', reason: error };
    });
};

export const trackUnlikeService = (contentType, entryID, emojiID, uuid) => {
  const url = getUrlTrackUnLike();
  // console.info('trackUnlikeService | url', url, ' | contentType', contentType, ' | key', entryID, ' | type', emojiID, ' | uuid', uuid);
  return request
    .post(url)
    .type('form')
    .send({ content_type: contentType, key: entryID, type: emojiID, uuid })
    .then((response) => {
      // console.log('%%% trackUnlikeService', response, '%%%');
      return JSON.parse(response.text);
    })
    .catch((error) => {
      return { result: 'FAILURE', reason: error };
    });
};

export const getRatingsService = () => {
  const url = getUrlGetTrackedData();
  return request
    .get(url)
    .type('form')
    .then((response) => {
      // console.log('%%% getRatingsService', response, '%%%');
      return JSON.parse(response.text);
    })
    .catch((error) => {
      return { result: 'FAILURE', reason: error };
    });
};

/**
 * errorDescription: "200"
 * message: "200"
 * resultCode: "OK"
 * resultObj: {
   checkCacheResult: ""
   height: "360"
   src: "https://ti-content-global.cdn.turner.com/PROD-APAC/C_INSPGA_02219369_REC_IT/b90da206-bbfb-43b8-b9de-9302634da455/PCTV.m3u8"
   vastTag: ""
   width: "480"
 * }
 * systemTime: 1569249024
 */
export const requestAvsDataObject = (url) => {
  return request
    .get(url)
    .then((response) => {
      // const api_response = JSON.parse(response.text);
      // const { body } = response;
      // console.log('requestAvsDataObject', response);
      // if (body.resultCode === 'OK') {
      //   return body.resultObj;
      // } else {
      //   return { result: 'FAILURE', reason: body.reason };
      // }
      const { body } = response;
      return body;
    })
    .catch((error) => {
      return { result: 'FAILURE', reason: error };
    });
};

import { getMapData, getGeoPosition } from 'services/api';
import { OVERRIDE_GEO_LOCATION } from 'constants/app/AppConstants';

export const MAP_DATA_INVALIDATE = 'MAP_DATA_INVALIDATE';
export const MAP_DATA_REQUEST = 'MAP_DATA_REQUEST';
export const MAP_DATA_RECEIVE = 'MAP_DATA_RECEIVE';
export const MAP_DATA_UPDATE_FILTER = 'MAP_DATA_UPDATE_FILTER';

export const MAP_ADD_TYPE_FILTER = 'MAP_ADD_TYPE_FILTER';
export const MAP_REMOVE_TYPE_FILTER = 'MAP_REMOVE_TYPE_FILTER';
export const MAP_ALL_TYPE_FILTER = 'MAP_ALL_TYPE_FILTER';

export const MAP_UPDATE_ZOOM = 'MAP_UPDATE_ZOOM';

export const GEO_DATA_INVALIDATE = 'GEO_DATA_INVALIDATE';
export const GEO_DATA_REQUEST = 'GEO_DATA_REQUEST';
export const GEO_DATA_RECEIVE = 'GEO_DATA_RECEIVE';

export const GEO_DATA_UPDATE_LOCATION = 'GEO_DATA_UPDATE_LOCATION';

export const addTypeFilter = (type) => {
  return (dispatch, getState) => {
    dispatch({ type: MAP_ADD_TYPE_FILTER, payload: type });
  };
};

export const removeTypeFilter = (type) => {
  return (dispatch, getState) => {
    dispatch({ type: MAP_REMOVE_TYPE_FILTER, payload: type });
  };
};

export const updateCurrentLevel = () => ({
  type: MAP_UPDATE_ZOOM,
});

export const removeAllTypeFilters = () => ({
  type: MAP_ALL_TYPE_FILTER,
});

export const requestMapData = (state) => {
  return (dispatch, getState) => {
    dispatch({ type: MAP_DATA_REQUEST });
    getMapData().then((response) => {
      if (response.result === 'SUCCESS') {
        dispatch({ type: MAP_DATA_RECEIVE, payload: response });
      } else {
        console.error('Map Data: ', response.reason);
        dispatch({ type: MAP_DATA_INVALIDATE });
      }
    });
  };
};

export const requestGeoData = (state) => {
  return (dispatch, getState) => {
    dispatch({ type: GEO_DATA_REQUEST });
    if (OVERRIDE_GEO_LOCATION !== null) {
      dispatch({ type: GEO_DATA_RECEIVE, payload: { country: OVERRIDE_GEO_LOCATION } });
    } else {
      getGeoPosition().then((response) => {
        dispatch({ type: GEO_DATA_RECEIVE, payload: response });
      });
    }
  };
};

export const updateGeoLocation = (location) => {
  return (dispatch, getState) => {
    dispatch({ type: GEO_DATA_UPDATE_LOCATION, payload: location });
  };
};

export const updateFilterType = (type) => {
  return (dispatch, getState) => {
    dispatch({ type: MAP_DATA_UPDATE_FILTER, payload: type });
  };
};

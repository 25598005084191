import React from 'react';
import * as DeviceDetection from 'utils/deviceDetection';

// import constants
import { getUrlFrontendImage } from 'constants/app/AppUrls';

// import utils
import { numberWithFormatting } from 'utils/general';

// *** NOTE:
// Download function - 'download(membershipCard, 'membership-card.png', 'image/png');'
// Third party lib found in static folder 'download/download.js'
// Adds download function to window object
// Provides better support for downloading the membership image across devices

const Loggedin = ({ username, passcode, membershipCard, registerCompleteText, setModalType, loginType, welcomeBackText, logoutText, numberOfChalleges, challengesAcceptedText, myAccount }) => {
  const downloadCard = () => {
    if (DeviceDetection.isiOS() && DeviceDetection.iOSversion()[0] < 13) {
      const image = new Image();
      image.src = membershipCard;
      const w = window.open('');
      w.document.write(image.outerHTML);
    } else {
      download(membershipCard, 'membership-card.png', 'image/png');
    }
  };

  return (
    <>
      {loginType === 'signup' ? <div className="authModal__header">{registerCompleteText}</div> : <div className="authModal__header">{welcomeBackText}</div>}
      {numberOfChalleges > 0 && (
        <div className="authModal__challenges-appected">
          <span className="text">{challengesAcceptedText}</span>
          <span className="count">{numberWithFormatting(numberOfChalleges)}</span>
        </div>
      )}
      <div className="authModal__card">
        {membershipCard !== '' ? (
          <>
            <img src={membershipCard} width="420" height="236" className="membership-card" />
            <div className="authModal__card_download" onClick={downloadCard}>
              <img src={getUrlFrontendImage('auth-modal/download.png')} alt="Download" width="100" height="100" />
            </div>
          </>
        ) : (
          <div className="authModal__card--preloader">
            <div id="preloader-progress" className="preloader-overlay__progress"></div>
          </div>
        )}
      </div>
      <div className="authModal__card__buttons">
        <button
          className="authModal__button authModal__button--logout"
          aria-label={logoutText}
          onClick={() => {
            setModalType('logout');
          }}
        >
          {logoutText}
        </button>
        <button
          className="authModal__button authModal__button--logout"
          aria-label={myAccount}
          onClick={() => {
            setModalType('account');
          }}
        >
          {myAccount}
        </button>
      </div>
    </>
  );
};

export default Loggedin;

'use strict';

let DeviceDetection = function () {
  let t = this;
  t.init();
  return t;
};

DeviceDetection.prototype = {
  init: function () {
    let t = this;
    t.isiPad = navigator.userAgent.match(/iPad/i) != null;
    t.isIOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false;
    t.isAndroidNative =
      navigator.userAgent.indexOf('Mozilla/5.0') > -1 &&
      navigator.userAgent.indexOf('Android ') > -1 &&
      navigator.userAgent.indexOf('AppleWebKit') > -1 &&
      !(navigator.userAgent.indexOf('Chrome') > -1);
    t.touch = Modernizr.touchevents;
    t.altTouch = t.alternativeTouchDetect();
    t.testForType();
    if (t.isIOS) {
      document.documentElement.className += ' ' + t.type + ' ios';
    } else {
      document.documentElement.className += ' ' + t.type;
    }

    return t;
  },
  testForType: function () {
    let t = this;
    if (typeof Modernizr !== 'undefined') {
      if (Modernizr.mq('(min-device-width : 768px) and (min-device-height : 768px)') && t.touch) {
        t.type = 'tablet';
      } else if (t.touch) {
        t.type = 'mobile';
      } else {
        t.type = 'desktop';
      }
    } else {
      t.type = 'desktop';
      console.log('load Modernizr with media queries and touchevents');
    }
  },
  alternativeTouchDetect: function () {
    return 'ontouchstart' in window || (window.DocumentTouch && document instanceof window.DocumentTouch) || navigator.maxTouchPoints > 0 || window.navigator.msMaxTouchPoints > 0;
  },
};
export { DeviceDetection };

//magic things
import React from 'react';

const SchoolsQuizIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" className="schools-quiz-icon">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <circle className="cls-1" cx="45" cy="45" r="45" />
        <circle cx="45" cy="45" r="39.6" fill="#ed0080" />
        <path
          className="cls-1"
          d="M69.56 67.49l-10.86-3a27 27 0 01-5.78 3.15 26.39 26.39 0 01-9.64 1.81h-1a26.41 26.41 0 1126.59-33 25.91 25.91 0 01.82 6.55 26.7 26.7 0 01-.29 3.92 26.11 26.11 0 01-2 6.75l5.33 9.8a2.79 2.79 0 01-3.17 4.02zM39.34 34.2a3.88 3.88 0 013.57-2.2 3.23 3.23 0 013.52 3.38c0 1.49-.77 2.31-2 3.42l-1.59 1.49c-2.36 2.22-3.76 3.91-3.76 5.93A3.61 3.61 0 0042.86 50c1.26 0 3-.43 3.86-3.13a5.69 5.69 0 012.46-3.23c3.32-2.56 5.4-4.53 5.4-8.77 0-6.7-5.64-9.65-11.38-9.65-4.48 0-8.34 1.84-10.46 5.31a4.6 4.6 0 00-.74 2.56 3.56 3.56 0 003.71 3.67c1.51 0 2.62-.63 3.63-2.56zm8.2 22a4.7 4.7 0 10-4.68 4.58 4.62 4.62 0 004.68-4.6z"
        />
      </g>
    </g>
  </svg>
);

export default SchoolsQuizIcon;

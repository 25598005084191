import { getMicrositeName, BASE_TEMPLATE, SECTION_LOOKUP } from 'constants/ensighten-tracking/EnsightenTrackingConstants';

const getVideoPageData = ({ contentType, isPWA }) => {
  let data = { ...BASE_TEMPLATE };
  const section = SECTION_LOOKUP[contentType];

  // data.micrositename = getMicrositeName(isPWA);
  data.page_name = data.page_name + decodeURIComponent(location.pathname);
  data.section = section;
  data.subsection = `${section}|main`;
  data.pagetype = 'category';
  data.content_type = 'section front';
  data.content_title = `${data.content_title}watch videos`;
  data.pageview_event = 1;
  return data;
};

export default getVideoPageData;

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getSiteTextDataByGroupedKey } from 'store/selectors/App.selectors';
import GenericNoWifi from 'components/inline-svgs/generic/GenericNoWifi';

// import actions
import { activeOfflineModal } from 'store/actions/App.actions';

const OfflineModal = (props) => {
  const { exitModal, text } = props;
  const { title, description_1, link } = text;

  return (
    <section className="modal--offline-message modal__container flex flex-wrap justify-content-center align-items-center">
      <div className="modal">
        <div className="page-not-found__background">
          <section className="page-not-found__container flex flex-wrap justify-content-center">
            <div className="page-not-found">
              <div className="page-not-found__icon">
                <GenericNoWifi />
              </div>
              <h1 className="page-not-found__title">{title}</h1>
              <p className="page-not-found__description">{description_1}</p>
            </div>
            <div className="page-not-found__link--container flex">
              <button className="page-not-found__link" onClick={() => props.activeOfflineModal()} aria-label={link}>
                <span>X</span>
                <span>{link}</span>
              </button>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state, props) => {
  return {
    text: getSiteTextDataByGroupedKey(state, 'pwa', 'offline_message'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    activeOfflineModal: () => {
      dispatch(activeOfflineModal());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfflineModal);

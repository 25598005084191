import React from 'react';

const ChallengeBoxCross = (props) => (
  <div
    dangerouslySetInnerHTML={{
      __html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.09 67.35" class="challenge-box-cross"><g data-name="Layer 2"><g data-name="Layer 1"><g data-name="Layer 2"><g data-name="Layer 1-2"><g data-name="challenge 01"><g data-name="to do"><rect x="1.75" y="7.02" width="58.58" height="58.58" rx="12" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.51" stroke="#000" fill="none" opacity=".2"/><rect x="1.75" y="4.68" width="58.58" height="58.58" rx="12" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.51" fill="none"/><g opacity=".25" font-size="55.8" font-family="AvenirNextRoundedStd-Bold,Avenir Next Rounded Std" font-weight="700"><text style="isolation:isolate" transform="translate(14.47 50.43)">x</text><text style="isolation:isolate" stroke-linejoin="bevel" stroke-width="2" stroke="#000" fill="none" transform="translate(14.47 50.43)">x</text></g><text transform="translate(14.47 48.43)" style="isolation:isolate" class="cls-1" font-size="55.8" font-family="AvenirNextRoundedStd-Bold,Avenir Next Rounded Std" font-weight="700">x</text><text transform="translate(14.47 48.43)" style="isolation:isolate" stroke="#f6f6f6" stroke-linejoin="bevel" stroke-width="2" font-size="55.8" font-family="AvenirNextRoundedStd-Bold,Avenir Next Rounded Std" font-weight="700" fill="none">x</text></g></g></g></g></g></g></svg>`,
    }}
  ></div>
);

export default ChallengeBoxCross;

import React from 'react';
import { connect } from 'react-redux';

// import actions
import { toggleCookieBar } from 'store/actions/App.actions';

// import selectors
import { getCookieBarDescription, getSiteTextDataByGroupedKey } from 'store/selectors/App.selectors';

const AppCookieBar = (props) => {
  const { cookieBarDescription, toggleCookieBar } = props;
  return (
    <div id="tos-bar">
      <div id="tos-wrap">
        <div id="tos-text" dangerouslySetInnerHTML={{ __html: cookieBarDescription }} />
        <div id="tos-close">
          <p>
            <span onClick={toggleCookieBar}>CLOSE</span>
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cookieBarDescription: getCookieBarDescription(state),
    closeLabel: getSiteTextDataByGroupedKey(state, 'cookie_bar', 'cookie_bar_close_label'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleCookieBar: () => {
      dispatch(toggleCookieBar());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppCookieBar);

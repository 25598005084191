import React from 'react';

const ChallengeBoxUnticked = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.99 54.99" className="challenge-box-unticked">
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <rect className="cls-1" x="1.5" y="3.5" width="49.99" height="49.99" rx="12" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" opacity=".2" />
        <rect className="cls-2" x="1.5" y="1.5" width="49.99" height="49.99" rx="12" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
      </g>
    </g>
  </svg>
);

export default ChallengeBoxUnticked;

import React from 'react';

const ChallengesShareIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.26 41.73" className="challenge-share-icon">
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <path
          data-name="Path 992"
          d="M31.31 27.81a6.93 6.93 0 00-5.11 2.25l-12.58-7.18a7 7 0 000-4.05l12.58-7.17a6.94 6.94 0 10-1.65-3.06L11.8 15.86a7 7 0 100 10l12.75 7.26A7 7 0 1033 28a6.74 6.74 0 00-1.69-.19z"
          className="cls-1"
        />
      </g>
    </g>
  </svg>
);

export default ChallengesShareIcon;

export const VIDEO_PLAYLIST = 'VIDEO_PLAYLIST';

export const resetVideo = () => ({
  type: VIDEO_RESET,
});

export const loadVideo = ({ id, interactive = false }) => {
  return (dispatch, getState) => {
    if (interactive) {
      const state = getState();

      const avsData = getAvsData(state);
      const { avsPlayURLpattern, avsShowName } = avsData;
      const url = avsPlayURLpattern.replace('{format}', 'PCTV').replace('{id}', id);
      return dispatch(retrieveAvsDataObject(getState(), url));
    }
    const state = getState();
    const { avs_id } = getVideoPageItemByID(state, id);
    const avsData = getAvsData(state);
    const { avsPlayURLpattern, avsShowName } = avsData;
    const url = avsPlayURLpattern.replace('{format}', 'PCTV').replace('{id}', avs_id);
    return dispatch(retrieveAvsDataObject(getState(), url, id));
  };
};

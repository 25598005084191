import { PAGE, REWARD_BANNER_DURATION } from 'constants/app/AppConstants';
import { INTERACTION } from 'constants/ensighten-tracking/EnsightenTrackingConstants';
import { getContentItemTitleAndXpByType } from 'store/selectors/ContentLibrary.selectors';
import { getContentTypeDefaultExperienceTotal, getUserLevel, getContentTypeDefaultExperience, getXPEnabled } from 'store/selectors/Experience.selectors';
import { hasSavedXpForContentItem } from 'store/selectors/UserExperience.selectors';
import { getSiteTextDataByGroupedKey } from 'store/selectors/App.selectors';
import { triggerInteraction } from 'store/actions/EnsightenTracking.actions';
import { CONTENT } from 'constants/content-library/ContentLibraryConstants';
import { getAllCategoriesLiked, getLikeCount } from 'store/selectors/Likes.selectors';
import { getHeroSwitchClickCount } from 'store/selectors/HeroSwitch.selectors';
import { getPerformanceCookiesEnabled } from 'store/selectors/OneTrust.selectors';

export const USER_EXPERIENCE = {
  SAVE: 'UserExperience.SAVE',
  LEVEL_UP: 'UserExperience.LEVEL_UP',
  HAS_VIEWED_LEVEL_UP: 'UserExperience.HAS_VIEWED_LEVEL_UP',
  ALL_LEVELS_COMPLETED: 'UserExperience.ALL_LEVELS_COMPLETED',
  LEVEL_UP_CONTENT_TYPE: 'UserExperience.LEVEL_UP_CONTENT_TYPE',
};
export const EXPERIENCE = {
  SHOW_NOTIFICATION: 'Experience.SHOW_NOTIFICATION',
  HIDE_NOTIFICATION: 'Experience.HIDE_NOTIFICATION',
};

export const timeoutComplete = (dispatch, type, id) => {
  dispatch({ type, payload: { id } });
};

export const updateViewedLevelUpStatus = () => ({
  type: USER_EXPERIENCE.HAS_VIEWED_LEVEL_UP,
});

export const createTimer = (duration, dispatch, type, id = '') => {
  return setTimeout(() => {
    timeoutComplete(dispatch, type, id);
  }, duration);
};

export const storeLevelUpContentType = (dispatch, type, contentType) => {
  dispatch({ type, payload: { contentType } });
};

const updateExperience = ({ state, contentType, id, pageType, xp, xpReward }) => {
  return (dispatch, getState) => {
    const { levelID, allLevelsCompleted = false } = getUserLevel(state);
    dispatch({ type: USER_EXPERIENCE.SAVE, payload: { id, xp } });
    const timerID = createTimer(REWARD_BANNER_DURATION, dispatch, EXPERIENCE.HIDE_NOTIFICATION, id);
    dispatch({ type: EXPERIENCE.SHOW_NOTIFICATION, payload: { id, timerID, xpReward } });
    const updatedLevel = getUserLevel(getState()); // retrieving updated state here

    if (updatedLevel.levelID !== levelID) {
      dispatch(triggerInteraction({ interactionType: INTERACTION.REWARD_UNLOCKED, pageType: pageType, contentType: contentType, level: levelID }));

      dispatch({ type: USER_EXPERIENCE.LEVEL_UP, payload: { newLevelID: updatedLevel.levelID } });
      storeLevelUpContentType(dispatch, USER_EXPERIENCE.LEVEL_UP_CONTENT_TYPE, contentType);
    } else if (updatedLevel.allLevelsCompleted) {
      const { levelID } = updatedLevel;
      dispatch(triggerInteraction({ interactionType: INTERACTION.REWARD_UNLOCKED, pageType: pageType, contentType: contentType, level: levelID }));

      dispatch({ type: USER_EXPERIENCE.ALL_LEVELS_COMPLETED, payload: { addLastLevel: levelID } });
    }
  };
};

export const saveHeroSwitchexperience = ({ contentType }) => {
  return (dispatch, getState) => {
    const state = getState();
    const enabled = getXPEnabled(state);
    if (!enabled || getPerformanceCookiesEnabled(state) === false) {
      return;
    }
    const id = CONTENT.HERO_SWITCH;
    const pageType = PAGE.LANDING;
    if (!hasSavedXpForContentItem(state, id)) {
      const clickCount = getHeroSwitchClickCount(state);
      const { xp_total: xp, xp_count } = getContentTypeDefaultExperience(state, id);
      if (clickCount >= xp_count) {
        const { xp_reward_messages = {} } = getSiteTextDataByGroupedKey(state, 'xp', 'xp_rewards');
        const xpReward = { msg: xp_reward_messages[id] || '', xp };
        dispatch(updateExperience({ state, contentType, id, pageType, xp, xpReward }));
      }
    }
  };
};

export const saveEmojiLikeExperience = ({ contentType }) => {
  return (dispatch, getState) => {
    const state = getState();
    const enabled = getXPEnabled(state);
    if (!enabled || getPerformanceCookiesEnabled(state) === false) {
      return;
    }

    let pageType = PAGE.SUB;
    let id = CONTENT.EMOJI;
    if (!hasSavedXpForContentItem(state, id)) {
      const likeCount = getLikeCount(state);
      const { xp_total: xp, xp_count } = getContentTypeDefaultExperience(state, id);
      if (likeCount >= xp_count) {
        const { xp_reward_messages = {} } = getSiteTextDataByGroupedKey(state, 'xp', 'xp_rewards');
        const xpReward = { msg: xp_reward_messages[id] || '', xp };
        dispatch(updateExperience({ state, contentType, id, pageType, xp, xpReward }));
      }
    }
    id = CONTENT.EMOJI + '_category';
    if (!hasSavedXpForContentItem(state, id)) {
      const allCategoriesLiked = getAllCategoriesLiked(state);
      if (allCategoriesLiked) {
        const { xp_total_all: xp } = getContentTypeDefaultExperience(state, CONTENT.EMOJI);
        const { xp_reward_messages = {} } = getSiteTextDataByGroupedKey(state, 'xp', 'xp_rewards');
        const xpReward = { msg: xp_reward_messages[id] || '', xp };
        dispatch(updateExperience({ state, contentType, id, pageType, xp, xpReward }));
      }
    }
  };
};

export const saveVideoItemExperience = ({ contentType, id, pageType = PAGE.SUB }) => {
  return (dispatch, getState) => {
    const state = getState();

    const enabled = getXPEnabled(state);
    if (!enabled || getPerformanceCookiesEnabled(state) === false) {
      return;
    }
    if (!hasSavedXpForContentItem(state, id)) {
      const { xp_total, xp_percentage } = getContentTypeDefaultExperience(state, contentType);
      // retrieve any xp override
      let { title, xp } = getContentItemTitleAndXpByType(state, contentType, id);
      if (xp === 0) {
        xp = xp_total;
      }
      if (xp) {
        const { xp_reward_messages = {} } = getSiteTextDataByGroupedKey(state, 'xp', 'xp_rewards');
        const msgTemplate = xp_reward_messages[contentType] || '';
        const xpReward = { msg: msgTemplate.replace('{name}', title), xp };
        dispatch(updateExperience({ state, contentType, id, pageType, xp, xpReward }));
      }
    }
  };
};

export const saveContentItemExperience = ({ contentType, id, pageType = PAGE.SUB }) => {
  console.log('contentType', contentType);
  return (dispatch, getState) => {
    const state = getState();
    const enabled = getXPEnabled(state);

    if (!enabled || getPerformanceCookiesEnabled(state) === false) {
      return;
    }
    if (!hasSavedXpForContentItem(state, id)) {
      // retrieve any xp override
      let { title, xp } = getContentItemTitleAndXpByType(state, contentType, id);
      if (xp === 0) {
        xp = getContentTypeDefaultExperienceTotal(state, contentType);
      }
      if (xp) {
        const { xp_reward_messages = {} } = getSiteTextDataByGroupedKey(state, 'xp', 'xp_rewards');
        const msgTemplate = xp_reward_messages[contentType] || '';
        const xpReward = { msg: msgTemplate.replace('{name}', title), xp };
        dispatch(updateExperience({ state, contentType, id, pageType, xp, xpReward }));
      }
    }
  };
};

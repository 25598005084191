export const CONTENT = {
  IMAGE: 'image',
  VIDEO: 'video',
  QUIZ: 'quiz',
  DOWNLOAD: 'download',
  EMOJI: 'emoji',
  EXTERNAL: 'external',
  INTERNAL: 'internal',
  CHALLENGE: 'challenge',
  SCHOOLS: 'schools',
};
export const ALL_CONTENT_TYPES = [CONTENT.QUIZ, CONTENT.VIDEO];

export const PAGES = {
  HOME: 'home',
  VIDEOS: 'videos',
  QUIZZES: 'quizzes',
  CUSTOM1: 'custom1',
  CUSTOM2: 'custom2',
  NOTFOUND: 'notfound',
  COMING_SOON: 'coming_soon',
  CHALLENGES: 'challenges',
  MY_GOALS: 'my-goals',
  GLOBAL_GOALS: 'gloabl-goals',
  MAP: 'map',
  FAQ: 'faq',
  GARDEN: 'garden',
  SCHOOLS: 'schools',
};
/**
 * Annoyingly we have story and character used intechangably - so am using lookups here!!
 */
export const CONTENT_PAGES = {
  videos: PAGES.VIDEOS,
  quizzes: PAGES.QUIZZES,
  coming_soon: PAGES.COMING_SOON,
  challenges: PAGES.CHALLENGES,
  map: PAGES.MAP,
  my_goals: PAGES.MY_GOALS,
  global_goals: PAGES.GLOBAL_GOALS,
  faq: PAGES.FAQ,
};
// lookup for subpage url fragment from content type
export const CONTENT_SUBPAGES = {
  story: PAGES.STORY,
  video: PAGES.VIDEOS,
  quiz: PAGES.QUIZZES,
  challenge: PAGES.CHALLENGES,
};
export const EMOJI_IDS = { 1: 'like', 2: 'lol', 3: 'wow', 4: 'snore', 5: 'yay', 6: 'love' };

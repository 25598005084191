// Each ID corresponds to a localise CMS site text field
export const USER_ERRORS = {
  ALREADY_SET_NAME: 'already_set_name_error',
  CONTAINS_PROFANITY: 'profanity_error',
  NAME_ALREADY_EXISTS: 'name_already_exists_error',
  UNKNOWN_USER_OR_PASSWORD: 'unknown_user_or_password_error',
  ALREADY_LIKED: '',
  NOT_LIKED: '',
  ALREADY_COMPLETE: '',
};

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

// Import Components
import RenderGrid from 'components/content-grid/grid/renderGrid';
import { getSiteTextDataByGroupedKey } from 'store/selectors/App.selectors';
import { getAdMPUByIDLength, getAdLeaderBoardByIDLength } from 'store/selectors/Ads.selectors';

const ContentItemGrid = (props) => {
  // Set some state
  const [itemsPerRow, updateItemsPerRow] = useState(3);
  const [gridItemCount, updateGridItemCount] = useState(10);
  const [currentDataPos, updateCurrentDataPos] = useState(0);
  const [displayMoreButton, updateDisplayMoreButton] = useState(false);
  const [moreRenderedGrids, updateMoreRenderedGrids] = useState([]);
  const [moreItemsToPass, updateMoreItemsToPass] = useState(0);
  const [moreGridLayout, updateMoreGridLayout] = useState(null);

  const [gridCount, updateGridCount] = useState(0);

  let prevAmount;

  // pass state values to watch so it knows when to re-render
  useEffect(() => {
    if (typeof prevAmount != 'undefined' && props.data.length != prevAmount) {
      updateDisplayMoreButton(false);
      updateMoreItemsToPass(0);
      updateMoreGridLayout(null);
      updateMoreRenderedGrids([]);
    }
    if (props.data && gridItemCount < props.data.length) {
      updateDisplayMoreButton(true);
    }
  }, [updateCurrentDataPos, updateMoreRenderedGrids, updateDisplayMoreButton, props.data, gridCount]);

  useEffect(() => {
    if (gridCount != 0) {
      renderGridLayout();
    }
  }, [moreGridLayout]);

  prevAmount = usePrevious(props.data.length);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  function updateGridLayout() {
    let currentNumberOfGrids = gridCount + 2;
    let gridLayout = props.layout.reduce((arr, item, index) => {
      if (item === 'Grid1xMpuRow' && currentNumberOfGrids > props.mpuIDLength) {
        return arr;
      }
      if (item === 'MpuLeaderboard' && currentNumberOfGrids > props.leaderboardIDLength) {
        return arr;
      }
      arr.push(item);
      return arr;
    }, []);
    updateMoreGridLayout(gridLayout);
    updateMoreItemsToPass(gridLayout.length * 3);
    updateGridCount(gridCount + 1);
  }

  function renderGridLayout() {
    if (currentDataPos < props.data.length) {
      let dataPos = currentDataPos == 0 ? gridItemCount : currentDataPos;
      let nextGrid = (
        <RenderGrid
          key={'more-grid-' + dataPos}
          extraClass={'more-grid-' + dataPos}
          layout={moreGridLayout}
          data={props.data.slice(dataPos, dataPos + Math.min(props.data.length - currentDataPos, moreItemsToPass))}
          gridCount={gridCount}
        />
      );

      updateCurrentDataPos(dataPos + moreItemsToPass);
      updateMoreRenderedGrids([...moreRenderedGrids, nextGrid]);

      if (Math.min(props.data.length - currentDataPos, moreItemsToPass) <= moreItemsToPass) {
        updateDisplayMoreButton(false);
      }
    }
  }

  // handle more items function
  const handleMoreItems = () => updateGridLayout();
  return (
    <section className="grid flex flex-wrap">
      {props.data && (
        <RenderGrid
          data={props.data.slice(0, gridItemCount)}
          layout={props.layout}
          key="initial-grid"
          extraClass="initial-grid"
          moreTitle={props.moreTitle}
          gridCount={0}
          onlyOneItem={props.data.length === 1}
        />
      )}
      {moreRenderedGrids}
      {displayMoreButton && (
        <button className="grid__more-button" onClick={handleMoreItems} aria-label="show more">
          {props.moreButtonText}
        </button>
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    moreButtonText: getSiteTextDataByGroupedKey(state, 'content_grid', 'more_button'),
    mpuIDLength: getAdMPUByIDLength(state),
    leaderboardIDLength: getAdLeaderBoardByIDLength(state),
  };
};

export default connect(mapStateToProps)(ContentItemGrid);

//magic things
import React from 'react';

const AppIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 105" className="app-icon">
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <g className="cls-1">
          <circle cx="50" cy="55" r="50" />
        </g>
        <circle className="cls-2" cx="50" cy="50" r="50" />
        <path d="M59.23,23.79H40.77A5.8,5.8,0,0,0,35,29.63V69.37a5.8,5.8,0,0,0,5.77,5.84H59.23A5.8,5.8,0,0,0,65,69.37V29.63A5.8,5.8,0,0,0,59.23,23.79ZM50,72.78a3.45,3.45,0,0,1-3.46-3.45h0A3.46,3.46,0,1,1,50,72.78ZM60.71,64.5H39.29V30.21H60.71Z" />
      </g>
    </g>
  </svg>
);

export default AppIcon;

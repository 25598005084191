//magic things
import React from 'react';

const MyGoalsArrowIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.21 27.1" className="my-goals-arrow-icon">
    <g data-name="Layer 2">
      <path fill="none" className="cls-1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="12" d="M6 6l15.11 15.11L36.21 6" data-name="Layer 1" />
    </g>
  </svg>
);

export default MyGoalsArrowIcon;

import React, { Component } from 'react';

// import components
import VideoPlaylistCarousel from 'components/video-playlist/VideoPlaylistCarousel';
import VideoPlaylistGrid from 'components/video-playlist/VideoPlaylistGrid';
import ArrowDown from 'components/inline-svgs/challenges/ChallengesArrowDown';

const PLAYLIST_STATE = {
  FULL: 'full',
  CAROUSEL: 'carousel',
};

class VideoPlaylists extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: PLAYLIST_STATE.CAROUSEL,
    };

    this.updateStatus = this.updateStatus.bind(this);
  }

  updateStatus(type) {
    const nextState = this.state.status === PLAYLIST_STATE.CAROUSEL ? PLAYLIST_STATE.FULL : PLAYLIST_STATE.CAROUSEL;
    this.setState({ status: nextState });
  }

  render() {
    const { playlist, more, less } = this.props;
    const { status } = this.state;

    const label = status === PLAYLIST_STATE.CAROUSEL ? more : less;

    return (
      <div className="video-playlist">
        <div className="video-playlist__header">
          <h2 className="video-playlist__title">{playlist.title}</h2>
          {playlist.content.length > 3 && (
            <button className={`video-playlist__button video-playlist__button--${status === PLAYLIST_STATE.CAROUSEL ? 'more' : 'less'}`} aria-label={label} onClick={this.updateStatus}>
              {label}
              <div className="video-playlist__button__arrow">
                <ArrowDown />
              </div>
            </button>
          )}
        </div>
        {status === PLAYLIST_STATE.CAROUSEL ? <VideoPlaylistCarousel data={playlist.content} playlistID={playlist.id} /> : <VideoPlaylistGrid data={playlist.content} playlistID={playlist.id} />}
      </div>
    );
  }
}

export default VideoPlaylists;

export const CHALLENGE_NAMES = {
  FOOD: 'food',
  RECYCLE: 'recycle',
  FAUNA: 'fauna',
  WATER: 'water',
  ENERGY: 'energy',
  FLORA: 'flora',
  TRAVEL: 'travel',
  CREATIVE: 'creative',
  SCHOOLS: 'schools',
};

export const CHALLENGE_DISPLAY_ORDER = ['type2', 'type7', 'type5', 'type4', 'type3', 'type6', 'type8', 'type1', 'type9'];

export const CHALLENGE_TYPES_BY_NAME = {
  [CHALLENGE_NAMES.FOOD]: 'type1',
  [CHALLENGE_NAMES.RECYCLE]: 'type2',
  [CHALLENGE_NAMES.FAUNA]: 'type3',
  [CHALLENGE_NAMES.WATER]: 'type4',
  [CHALLENGE_NAMES.ENERGY]: 'type5',
  [CHALLENGE_NAMES.FLORA]: 'type6',
  [CHALLENGE_NAMES.TRAVEL]: 'type7',
  [CHALLENGE_NAMES.CREATIVE]: 'type8',
  [CHALLENGE_NAMES.SCHOOLS]: 'type9',
};

export const CHALLENGE_COLOUR_BY_TYPE = {
  type1: '#3ec407',
  type2: '#00b04b',
  type3: '#fe6e56',
  type4: '#00bdb6',
  type5: '#ffc100',
  type6: '#85b900',
  type7: '#ec0080',
  type8: '#8c08ff',
  type9: '#8c08ff',
};

export const CHALLENGE_TYPES = {
  type1: {
    id: 'type1',
    name: CHALLENGE_NAMES.FOOD,
  },
  type2: {
    id: 'type2',
    name: CHALLENGE_NAMES.RECYCLE,
  },
  type3: {
    id: 'type3',
    name: CHALLENGE_NAMES.FAUNA,
  },
  type4: {
    id: 'type4',
    name: CHALLENGE_NAMES.WATER,
  },
  type5: {
    id: 'type5',
    name: CHALLENGE_NAMES.ENERGY,
  },
  type6: {
    id: 'type6',
    name: CHALLENGE_NAMES.FLORA,
  },
  type7: {
    id: 'type7',
    name: CHALLENGE_NAMES.TRAVEL,
  },
  type8: {
    id: 'type8',
    name: CHALLENGE_NAMES.CREATIVE,
  },
  type9: {
    id: 'type9',
    name: CHALLENGE_NAMES.SCHOOLS,
  },
};

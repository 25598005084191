import { createSelector } from 'reselect';
import { isEmptyObj } from 'utils/general';

import { CHALLENGE_DISPLAY_ORDER, CHALLENGE_TYPES_BY_NAME, CHALLENGE_TYPES } from 'constants/challenges/ChallengeTypes';

export const getShowcaseMapZoomLevel = (state) => state.showcasemap.currentZoomLevel;
export const showcaseMapAreaData = (state) => state.showcasemap.areas;
export const showcaseMapRegionData = (state) => state.showcasemap.regions;
export const showcaseMapCountriesData = (state) => state.showcasemap.countries;
export const getTotalOfCompletedChallenges = (state) => state?.showcasemap?.total_of_completed_challenges || 0;

export const getShowcaseMapAreaData = createSelector([showcaseMapAreaData], (areaData) => areaData);

export const getShowcaseMapRegionData = createSelector([showcaseMapRegionData], (regionData) => regionData);

export const getShowcaseMapCountriesData = createSelector([showcaseMapCountriesData], (countriesData) => countriesData);

export const getShowcaseMapAreaDataByID = (state, id) => {
  return state.showcasemap.areas[id] || {};
};

export const getShowcaseMapRegionDataByID = (state, id) => {
  return state.showcasemap.regions[id] || {};
};

export const getShowcaseMapCountriesDataByID = (state, id) => {
  return state.showcasemap.countries[id] || {};
};

export const getActiveFilterTypes = (state) => {
  return state.showcasemap.activeTypeFilters;
};

export const getActiveFilterTypesByID = (state) => {
  const filters = getActiveFilterTypes(state);
  const types = getTaskTypes(state);

  const returnableData = [];

  for (const [key, value] of Object.entries(types)) {
    if (value) {
      if (filters.indexOf(value) !== -1) {
        returnableData.push(key);
      }
    }
  }

  return returnableData;
};

export const getRandomRegionDataByType = (state) => {
  const regions = getShowcaseMapRegionData(state);
  const returnableData = [];
  const filters = getActiveFilterTypesByID(state);
  const baseTypeName = filters.length > 0 ? getTaskTypeName(state, filters[0]) : 'all';

  for (const region in regions) {
    if (region) {
      const keys = Object.keys(regions[region].coord);

      if (keys.length > 0) {
        const selectedMarker = regions[region].coord[keys[(keys.length * Math.random()) << 0]];
        let baseTypeCount = 0;

        regions[region].areaIDs.map((areaID, index) => {
          const area = getShowcaseMapAreaDataByID(state, areaID);
          const { countryIDs } = area;

          countryIDs.map((countryID, index) => {
            const countryMapData = getMapDataByID(state, countryID);
            const { challenge_type_summary = null } = countryMapData;
            if (challenge_type_summary) {
              if (filters.length === 0) {
                for (const challenge_type in challenge_type_summary) {
                  if (challenge_type) {
                    baseTypeCount = baseTypeCount + Number(challenge_type_summary[challenge_type]);
                  }
                }
              } else {
                if (challenge_type_summary[filters[0]]) {
                  baseTypeCount = baseTypeCount + Number(challenge_type_summary[filters[0]]);
                }
              }
            }
          });
        });
        returnableData.push({ coord: { ...selectedMarker }, id: regions[region].name, taskData: { count: baseTypeCount, name: baseTypeName } });
      }
    }
  }

  return returnableData;
};

export const getRandomAreaData = (state) => {
  const filters = getActiveFilterTypesByID(state);
  const returnableData = [];

  const areas = getShowcaseMapAreaData(state);
  const baseTypeName = filters.length > 0 ? getTaskTypeName(state, filters[0]) : 'all';
  for (const area in areas) {
    if (area) {
      const keys = Object.keys(areas[area].coord);
      if (keys.length > 0) {
        const selectedMarker = areas[area].coord[keys[(keys.length * Math.random()) << 0]];
        let baseTypeCount = 0;
        let areaHasCountryCoords = false;

        areas[area].countryIDs.map((countryID, index) => {
          if (!isEmptyObj(getShowcaseMapCountriesDataByID(state, countryID).coord)) {
            areaHasCountryCoords = true;
          }

          const countryMapData = getMapDataByID(state, countryID);
          const { challenge_type_summary } = countryMapData;
          if (challenge_type_summary) {
            if (filters.length === 0) {
              for (const challenge_type in challenge_type_summary) {
                if (challenge_type) {
                  baseTypeCount = baseTypeCount + Number(challenge_type_summary[challenge_type]);
                }
              }
            } else {
              if (challenge_type_summary[filters[0]]) {
                baseTypeCount = baseTypeCount + Number(challenge_type_summary[filters[0]]);
              }
            }
          }
        });

        returnableData.push({ coord: { ...selectedMarker }, id: areas[area].name, areaHasCountryCoords, taskData: { count: baseTypeCount, name: baseTypeName } });
      }
    }
  }

  return returnableData;
};

export const getRandomAreaDataByID = (state, id) => {
  const filters = getActiveFilterTypesByID(state);

  const area = getShowcaseMapAreaDataByID(state, id);
  const baseTypeName = filters.length > 0 ? getTaskTypeName(state, filters[0]) : 'all';

  const keys = Object.keys(area.coord);

  if (keys.length > 0) {
    const selectedMarker = area.coord[keys[(keys.length * Math.random()) << 0]];
    let baseTypeCount = 0;
    let areaHasCountryCoords = false;

    area.countryIDs.map((countryID, index) => {
      const countryMapData = getMapDataByID(state, countryID);
      const { challenge_type_summary } = countryMapData;
      if (challenge_type_summary) {
        if (filters.length === 0) {
          for (const challenge_type in challenge_type_summary) {
            if (challenge_type) {
              baseTypeCount = baseTypeCount + Number(challenge_type_summary[challenge_type]);
            }
          }
        } else {
          if (challenge_type_summary[filters[0]]) {
            baseTypeCount = baseTypeCount + Number(challenge_type_summary[filters[0]]);
          }
        }
      }
    });
    return { coord: { ...selectedMarker }, id: area.name, taskData: { count: baseTypeCount, name: baseTypeName } };
  }

  return null;
};

export const getRandomConuntryData = (state) => {
  const filters = getActiveFilterTypesByID(state);
  const returnableData = [];

  const countries = getShowcaseMapCountriesData(state);
  const baseTypeName = filters.length > 0 ? getTaskTypeName(state, filters[0]) : 'all';

  let areaID = 1;
  let countryDataReturned = false;

  for (const country in countries) {
    if (country) {
      if (parseInt(countries[country].areaID) !== areaID) {
        if (!countryDataReturned) {
          // return area related marker from pervious AreaID
          const area = getRandomAreaDataByID(state, areaID);
          if (area !== null && !isEmptyObj(area)) {
            returnableData.push({ ...area });
          }
        }

        // update area ID
        areaID = parseInt(countries[country].areaID);
        countryDataReturned = false;
      }

      const keys = Object.keys(countries[country].coord);
      if (keys.length > 0) {
        countryDataReturned = true;
        const filter = baseTypeName === 'all' ? CHALLENGE_TYPES[CHALLENGE_DISPLAY_ORDER[Math.floor(Math.random() * CHALLENGE_DISPLAY_ORDER.length)]].name : baseTypeName;
        const filterType = baseTypeName === 'all' ? CHALLENGE_TYPES_BY_NAME[filter] : filters[0];

        const selectedMarker = countries[country].coord[keys[(keys.length * Math.random()) << 0]];
        let baseTypeCount = 0;

        const countryMapData = getMapDataByID(state, country);
        const { challenge_type_summary } = countryMapData;
        if (challenge_type_summary) {
          if (filter === 'all') {
            for (const challenge_type in challenge_type_summary) {
              if (challenge_type) {
                baseTypeCount = baseTypeCount + Number(challenge_type_summary[challenge_type]);
              }
            }
          } else {
            if (challenge_type_summary[filterType]) {
              baseTypeCount = baseTypeCount + Number(challenge_type_summary[filterType]);
            }
          }
        }
        returnableData.push({ coord: { ...selectedMarker }, id: country, taskData: { count: baseTypeCount, name: filter } });
      }
    }
  }
  return returnableData;
};

// Get map data
export const getMapDataByCountry = (state) => {
  const id = getGeoDatalocation(state);

  if (id && id !== '') {
    // Grab country data
    // This should include a area ID
    let countryData = getShowcaseMapCountriesDataByID(state, id);
    const { area } = countryData;

    // Grab area data
    // Should should include a region ID
    let areaData = getShowcaseMapAreaDataByID(state, area.id);
    const { region } = areaData;

    // Grab area data
    // Should should include a region ID
    let regionData = getShowcaseMapRegionDataByID(state, region.id);

    return { countryData, areaData, regionData };
  }
  return {};
};

export const getMapData = (state) => {
  return state.showcasemap.map_data;
};

export const getChallengesCompletedByType = (state, type) => {
  return state.showcasemap.challenges_completed[type];
};

export const getMapDataFetched = (state) => {
  return state.showcasemap.map_data.fetched;
};

export const getMapDataIDs = (state) => {
  return state.showcasemap.map_data.allIDs;
};

export const getMapDataByID = (state, id) => {
  return state.showcasemap.map_data.byID[id] || {};
};

// ** MAP DATA STRUCTURE
// get_map_data returns, for each country,
// - last_completed (timestamp)
// - last_challenge (id of task)
// - last_challenge_type (id of task type)
// - last_challenge_count (total number of this task completed in this country)
// - challenge_type_summary which is a map of task id (task1-task7) to total completions for that country
//
//
// {
//  "country":"GB",
//  "last_completed":"1607944754",
//  "last_challenge":"6",
//  "last_challenge_count":7,
//  "challenge_type_summary":{
//     "type1":"21",
//     "type2":"21",
//     "type3":"21",
//     "type4":"28",
//     "type5":"21",
//     "type6":"21",
//     "type7":"21"
// },
// "last_challenge_type":"type5"

export const getRandomCountryIDByRegion = (state, id) => {
  const regions = state.showcasemap.regions;
  let areaID = null;

  for (const [key, value] of Object.entries(regions)) {
    if (value.name === id) {
      const randomNumber = Math.floor(Math.random() * value.areaIDs.length);
      areaID = value.areaIDs[randomNumber];
      break;
    }
  }
  const countryID = getRandomCountryIDByAreaID(state, areaID);

  return countryID;
};

export const getRandomCountryIDByAreaID = (state, id) => {
  const area = state.showcasemap.areas[id];
  let countryID = null;

  for (const [key, value] of Object.entries(area)) {
    if (key === 'countryIDs') {
      countryID = value[Math.floor(Math.random() * value.length)];
    }
  }

  return countryID;
};

export const getRandomCountryIDByArea = (state, id) => {
  const areas = state.showcasemap.areas;
  let countryID = null;

  for (const [key, value] of Object.entries(areas)) {
    if (value.name === id) {
      countryID = value.countryIDs[Math.floor(Math.random() * value.countryIDs.length + 1)];
      break;
    }
  }

  return countryID;
};

export const getLastTaskByCountryID = (state, id) => {
  let data = state.showcasemap.map_data.byID[id] || '';

  if (data && data != '') {
    data = data.last_challenge;
  }

  return data;
};

export const getLastTaskCountByCountryID = (state, id) => {
  let data = state.showcasemap.map_data.byID[id] || '';

  if (data && data != '') {
    data = data.last_challenge_count;
  }

  return data;
};

export const getLastTaskTypeByCountryID = (state, id) => {
  let data = state.showcasemap.map_data.byID[id] || '';

  if (data && data != '') {
    data = data.last_challenge_type;
  }

  return data;
};

export const getLastTaskIDByCountryID = (state, id) => {
  const upperID = (id || '').toLowerCase();
  if (upperID === '') {
    return false;
  }

  let data = state.showcasemap.map_data.byID[id.toUpperCase()] || '';

  if (data && data != '') {
    data = data.last_challenge;
  }

  return data;
};

export const getLastTaskByCurrentCountry = (state) => {
  let data = state.showcasemap.map_data.byID[getGeoDatalocation(state)] || '';

  if (data && data != '') {
    data = data.last_challenge;
  }

  return data;
};

export const getLastTaskCountByCurrentCountry = (state) => {
  let data = state.showcasemap.map_data.byID[getGeoDatalocation(state)] || '';

  if (data && data != '') {
    data = data.last_challenge_count;
  }

  return data;
};

export const getLastTaskTypeByCurrentCountry = (state) => {
  let data = state.showcasemap.map_data.byID[getGeoDatalocation(state)] || '';

  if (data && data != '') {
    data = data.last_challenge_type;
  }

  return data;
};

export const getTaskTypeSummaryCount = (state) => {
  let data = state.showcasemap.map_data.byID[getGeoDatalocation(state)] || '';

  if (data && data != '') {
    let taskType = getLastTaskTypeByCurrentCountry(state) || '';

    data = data.challenge_type_summary[taskType];
  }

  return data;
};

// Get GEO data
export const getGeoDatalocation = (state, id) => {
  return state.showcasemap.geo_data.location;
};

export const getGeoDataFetched = (state) => {
  return state.showcasemap.geo_data.fetched;
};

// Get Task Type Name
export const getTaskTypeName = (state, id) => {
  return state.showcasemap.challenge_types[id] || '';
};
export const getTaskTypes = (state, id) => {
  return state.showcasemap.challenge_types;
};

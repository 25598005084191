import request from 'superagent/lib/client';
import { getUrlTrackChallengeLike, getUrlTrackChallengeUnLike, getUrlChallegeCompleted, getUrlChallegeUncompleted, getUrlChallegeCancelled, getUrlChallegeUncancelled } from 'constants/app/AppUrls';

export const challengeLiked = ({ external_id, token, challenge_id }) => {
  const url = getUrlTrackChallengeLike();
  return request
    .post(url)
    .type('form')
    .send({ external_id, token, challenge_id })
    .then((response) => {
      return JSON.parse(response.text);
    })
    .catch((error) => {
      return { result: 'FAILURE', reason: error };
    });
};

export const challengeUnliked = ({ external_id, token, challenge_id }) => {
  const url = getUrlTrackChallengeUnLike();
  return request
    .post(url)
    .type('form')
    .send({ external_id, token, challenge_id })
    .then((response) => {
      return JSON.parse(response.text);
    })
    .catch((error) => {
      return { result: 'FAILURE', reason: error };
    });
};

export const challengeCompleted = ({ external_id, token, challenge_id }) => {
  const url = getUrlChallegeCompleted();
  return request
    .post(url)
    .type('form')
    .send({ external_id, token, challenge_id })
    .then((response) => {
      return JSON.parse(response.text);
    })
    .catch((error) => {
      return { result: 'FAILURE', reason: error };
    });
};

export const challengeUncompleted = ({ external_id, token, challenge_id }) => {
  const url = getUrlChallegeUncompleted();
  return request
    .post(url)
    .type('form')
    .send({ external_id, token, challenge_id })
    .then((response) => {
      return JSON.parse(response.text);
    })
    .catch((error) => {
      return { result: 'FAILURE', reason: error };
    });
};

export const challengeCancelled = ({ external_id, token, challenge_id, reason }) => {
  console.log({ external_id, token, challenge_id, reason });
  const url = getUrlChallegeCancelled();
  return request
    .post(url)
    .type('form')
    .send({ external_id, token, challenge_id, reason })
    .then((response) => {
      return JSON.parse(response.text);
    })
    .catch((error) => {
      return { result: 'FAILURE', reason: error };
    });
};

export const challengeUncancelled = ({ external_id, token, challenge_id }) => {
  const url = getUrlChallegeUncancelled();
  return request
    .post(url)
    .type('form')
    .send({ external_id, token, challenge_id })
    .then((response) => {
      return JSON.parse(response.text);
    })
    .catch((error) => {
      return { result: 'FAILURE', reason: error };
    });
};

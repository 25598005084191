import { NAV_TYPE } from 'constants/app/AppConstants';
import { SET_NAVIGATION_LINKS } from 'constants/app/AppUrls';
import { isEmptyObj } from 'utils/general';

const parse = (data, urls, customPages) => {
  const { id, name = '', page = '', nav_item_type, isCustom = false, nav_icon = false, friendly_url, show_leaving_message = true } = data;
  const type = isCustom ? NAV_TYPE.CUSTOM : nav_item_type === 'existing_page' ? NAV_TYPE.INTERNAL : nav_item_type === 'external_url' ? NAV_TYPE.EXTERNAL : NAV_TYPE.NULL;
  const parsed = {
    id,
    type,
    name,
    page,
    icon: nav_icon,
  };
  if (type === NAV_TYPE.INTERNAL) {
    if (urls[page]) {
      parsed.friendlyURL = urls[page];
    } else {
      parsed.friendlyURL = page;
    }
  }
  if (type === NAV_TYPE.EXTERNAL) {
    parsed.externalURL = data.external_url;
    parsed.linkTarget = data.external_url_target;
    parsed.leaving_message = show_leaving_message !== 'false' ? true : false;
  }
  if (type === NAV_TYPE.CUSTOM) {
    /**
     * Check for the existance of custom page data
     * If there is none then ignore the nav item
     */
    parsed.friendlyURL = friendly_url;
    const hasLinkedPage = customPages.reduce((acc, item) => {
      acc = item.id === page ? true : acc;
      return acc;
    }, false);
    if (!hasLinkedPage) {
      return false;
    }
  }
  return parsed;
};

export const parseNavigation = (content = [], urls = {}, customPages = [], siteText = {}) => {
  const allIDs = [];
  const customIDs = [];
  const byID = {};

  const { setup = [], show_global_goals = false } = content;
  const { navigation } = siteText;

  // parse set site links
  if (SET_NAVIGATION_LINKS.length > 0 && !isEmptyObj(urls) && !Array.isArray(urls)) {
    for (const item of SET_NAVIGATION_LINKS) {
      const itemCopy = { ...item };
      const { id } = itemCopy;
      if (urls[id]) {
        itemCopy.friendlyURL = urls[id];
      }

      itemCopy.name = navigation[id];

      if (id === 'global_goals') {
        if (show_global_goals !== 'false') {
          allIDs.push(id);
          byID[id] = { ...itemCopy };
        }
      } else {
        allIDs.push(id);
        byID[id] = { ...itemCopy };
      }
    }
  }

  // parse CMS links
  if (setup.length > 0) {
    for (const item of setup) {
      const { id } = item;

      const parsed = parse(item, urls, customPages);
      if (parsed) {
        allIDs.push(id);
        byID[id] = { ...parsed };
        if (parsed.type === NAV_TYPE.CUSTOM) {
          customIDs.push(id);
        }
      }
    }
  }

  return { allIDs, customIDs, byID };
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { activeExitSiteModal } from 'store/actions/App.actions';

const AppExitSiteLink = (props) => {
  return (
    <div className="site-exit-button" onClick={() => props.activeExitSiteModal(props.link)}>
      {props.children}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    activeExitSiteModal: (link) => {
      dispatch(activeExitSiteModal(link));
    },
  };
};

export default connect(null, mapDispatchToProps)(AppExitSiteLink);

import React, { Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import constants
import { SUB_VIDEOS_PAGE_GRID } from 'constants/content-grid/ContentGridConstants';
import { CONTENT } from 'constants/content-library/ContentLibraryConstants';
import { PAGE } from 'constants/app/AppConstants';
import PageNotFound from 'containers/pages/PageNotFound';
// Services
// import components
import ContentItemGrid from 'containers/content/ContentItemGrid';
import EmojiBar from 'components/emoji/EmojiBar';
import SiteMetaData from 'components/generic/SiteMetaData';

import { getVideoIDByFriendlyURL, getVideoPageContent, getVideoPageItemByID } from 'store/selectors/Videopage.selectors';
import { getEntryEmojiAllLikeCounts } from 'store/selectors/Ratings.selectors';
import { triggerPageView } from 'store/actions/EnsightenTracking.actions';
import { dispatchEnsightenPageLoad } from 'constants/app/AppServices';
import { loadVideo, resetVideo } from 'store/actions/Video.actions';

import { getRelatedPlaylistByID } from 'store/selectors/VideoPlaylist.selectors';

import { getSiteTextDataByGroupedKey } from 'store/selectors/App.selectors';
import { getContentTypeDefaultExperience } from 'store/selectors/Experience.selectors';
import { saveVideoItemExperience } from 'store/actions/Experience.actions';
import { getPerformanceCookiesEnabled, getOneTrustActive } from 'store/selectors/OneTrust.selectors';

const BitmovinPlayer = React.lazy(() => import(/* webpackChunkName: 'bmplayer' */ 'components/media-container/BitmovinPlayer'));

const SubPageVideos = (props) => {
  const [currentPlaylist, updateCurrentPlaylist] = useState(null);
  const { adTrigger, emojiLikeCounts, contentItem, experience, performanceCookiesEnabled = true, oneTrustActive = false, playlist } = props;
  const { title = '', genre_tags, character_tags, content_type, game_genre, id, avs_id, thumb, description = '' } = contentItem;
  const { meta_title = '', meta_description = '' } = contentItem;

  const { english_tracking_name = title } = contentItem;

  const { xp_percentage: experienceRewardPercentage = 50 } = experience;
  useEffect(() => {
    dispatchEnsightenPageLoad(adTrigger);

    return () => {
      // componentWillUnmount
      props.resetVideo();
    };
  }, []);

  useEffect(() => {
    const { id, contentItem } = props;
    if (contentItem) {
      props.resetVideo();
      props.loadVideo({ id });
      props.trackPageView({ contentType: contentItem.content_type, pageType: PAGE.SUB, contentItem });

      window.scrollTo(0, 0);
    }
  }, [id]);

  const onRewardExperience = () => {
    const { id, contentItem } = props;
    props.saveVideoItemExperience({ contentType: contentItem.content_type, id, pageType: PAGE.SUB });
  };

  return (
    <React.Fragment>
      <SiteMetaData title={meta_title} description={meta_description} />
      <div className="sub-page sub-page--videos">
        {contentItem ? (
          <React.Fragment>
            <section className="sub-page__header container">
              <h1 className="sub-page__title">{title}</h1>
              <div className="sub-page__media-container flex flex-wrap justify-content-center">
                <Suspense fallback={<div>Loading...</div>}>
                  <BitmovinPlayer
                    contentType={CONTENT.VIDEO}
                    entryID={id}
                    avs_id={avs_id}
                    thumbs={thumb}
                    experienceRewardPercentage={experienceRewardPercentage}
                    onRewardExperience={onRewardExperience}
                  />
                </Suspense>
              </div>
            </section>
            <section className="sub-page__emojis__container">
              <div className="sub-page__emojis container">
                <EmojiBar contentType={CONTENT.VIDEO} entryID={id} emojiLikeCounts={emojiLikeCounts} contentEnglishName={english_tracking_name} />
              </div>
            </section>
            {description != '' && (
              <section className="sub-page__description__container container">
                <div className={'sub-page__description' + (performanceCookiesEnabled === true ? '' : ' sub-page__description--no-margin')} style={{ maxWidth: `100%` }}>
                  <p>{description}</p>
                </div>
              </section>
            )}
          </React.Fragment>
        ) : (
          <PageNotFound />
        )}
        {playlist && <div className="sub-page__related-playlist container">{<ContentItemGrid data={playlist.content} layout={SUB_VIDEOS_PAGE_GRID} moreTitle={playlist.more_title} />}</div>}
        <div className="sub-page__content-items container">{<ContentItemGrid data={props.contentItems} layout={SUB_VIDEOS_PAGE_GRID} moreTitle={props.moreTitle} />}</div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { match, location } = ownProps;
  const { urlFragment } = match.params;
  const id = getVideoIDByFriendlyURL(state, urlFragment) || false;

  return {
    id,
    contentItem: getVideoPageItemByID(state, id),
    contentItems: getVideoPageContent(state),
    emojiLikeCounts: getEntryEmojiAllLikeCounts(state, CONTENT.VIDEO, id),
    moreTitle: getSiteTextDataByGroupedKey(state, 'content_grid', 'more_videos'),
    experience: getContentTypeDefaultExperience(state, CONTENT.VIDEO),
    performanceCookiesEnabled: getPerformanceCookiesEnabled(state),
    oneTrustActive: getOneTrustActive(state),
    playlist: ownProps.location.state ? getRelatedPlaylistByID(state, ownProps.location.state.playlist, id) : false,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    trackPageView: (opts) => {
      dispatch(triggerPageView(opts));
    },
    loadVideo: (opts) => {
      // console.log('{}{}{}{}{}{}{}{}{}{}{} loadVideo');
      dispatch(loadVideo(opts));
    },
    resetVideo: (opts) => {
      // console.log('{}{}{}{}{}{}{}{}{}{}{} resetVideo');
      dispatch(resetVideo(opts));
    },
    saveVideoItemExperience: (opts) => {
      dispatch(saveVideoItemExperience(opts));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubPageVideos));

import React, { useState, useEffect, useRef } from 'react';
import { debounce, isEmptyObj } from 'utils/general';
import { getDevice } from 'utils/deviceDetection';

// import constants
import { GARDENS, PLANTS, GARDEN_PLACEMENT_IMAGE, GARDEN_DEFAULT_WIDTH, GARDEN_DEFAULT_HEIGHT, UNLOCK_LEVELS_CHALLENGE_THRESHOLD } from 'constants/my-goals/MyGoalsConstants';
import { LOCAL_STORAGE_OPTIONS } from 'constants/app/AppConstants';
const STORAGE_NAME = `${LOCAL_STORAGE_OPTIONS.namespace}-map-onboarding`;

// import components
import MyGoalsPlant from 'components/my-goals/MyGoalsPlant';
import MyGoalsLevels from 'components/my-goals/MyGoalsLevels';
import MyGoalsOnboarding from 'components/my-goals/MyGoalsOnboarding';

const MyGoalsGarden = ({
  selectedGarden,
  totalLevelsCompleted,
  perviousStep,
  stepCallback,
  setPlantPlacement,
  userData,
  onboardingText,
  challengeCompletedCount,
  userHasSeenLevelUp,
  hasSeenLevelUp,
  onboarding,
  setOnboarding,
}) => {
  // data
  const gardenData = GARDENS[selectedGarden];
  const { img, data } = gardenData;

  // state
  const [isDragging, updateIsDragging] = useState(false);
  const [scale, updateScale] = useState(1);
  const [activeMarker, updateActiveMarker] = useState('');
  const [isOnboardingCompleted, updateIsOnboardingCompleted] = useState(false);
  const [stepOneSeen, updateStepOneSeen] = useState(false);
  const [stepTwoSeen, updateStepTwoSeen] = useState(false);
  const [onboardingCurrentStep, updateOnboardingCurrentStep] = useState(1);
  const [avaiablePlantPlacements, updateAvaiablePlantPlacements] = useState(data.avaiable_placements);
  const [activeGarden, updateActiveGarden] = useState(selectedGarden);
  const [activePlantPlacements, updateActivePlantPlacements] = useState(data);

  // Refs
  const scalableContainer = useRef();
  const gardenContainer = useRef();

  // onboarding functions
  const onboardingClicked = (step) => {
    if (step === 1) {
      updateStepOneSeen(true);
      updateOnboardingCurrentStep(2);
      setOnboarding('step_1');
    } else if (step === 2) {
      updateStepTwoSeen(true);
      setOnboarding('step_2');
      onboardingCompleted();
    }
  };

  const checkOnboarding = () => {
    return onboarding.step_2;
  };

  const checkOnboardingStep = () => {
    if (onboarding.step_1) {
      onboardingClicked(1);
    }
  };

  const onboardingCompleted = () => {
    updateIsOnboardingCompleted(true);
  };

  // Use Effect
  useEffect(() => {
    const hasSeenOnboarding = checkOnboarding();
    if (hasSeenOnboarding) {
      onboardingCompleted();
    } else {
      checkOnboardingStep();
    }

    if (gardenContainer.current && scalableContainer.current) {
      const widthGarden = gardenContainer.current.clientWidth;
      const heightGarden = gardenContainer.current.clientHeight;

      const width = scalableContainer.current.clientWidth;
      const height = scalableContainer.current.clientHeight;

      const scale = Math.min(width / widthGarden, height / heightGarden);

      updateScale(scale);
    }

    window.addEventListener('resize', deboucedFunction);
    return () => {
      window.removeEventListener('resize', deboucedFunction);
    };
  }, []);

  // useEffect(() => {
  //
  //   if (activeGarden === '' || activeGarden !== selectedGarden) {
  //     updateActiveGarden(selectedGarden);
  //   }
  // }, [selectedGarden]);

  // useEffect(() => {
  //   const gardenData = GARDENS[selectedGarden];
  //   const { img, data } = gardenData;
  //
  //   updateActivePlantPlacements(data);
  //   console.log(selectedGarden, data);
  //
  //   updateAvaiablePlantPlacements(data.avaiable_placements);
  // }, []);

  // functions
  const updatePlanePlacement = ({ plant_id, placement_id }) => {
    const avaiablePlacementIndex = avaiablePlantPlacements.indexOf(placement_id);
    if (avaiablePlacementIndex !== -1) {
      const nextState = [...avaiablePlantPlacements];
      nextState.splice(avaiablePlacementIndex, 1);
      updateAvaiablePlantPlacements(nextState);
    }
  };

  const deboucedFunction = debounce(
    function () {
      if (gardenContainer.current && scalableContainer.current) {
        const widthGarden = gardenContainer.current.clientWidth;
        const heightGarden = gardenContainer.current.clientHeight;

        const width = scalableContainer.current.clientWidth;
        const height = scalableContainer.current.clientHeight;

        const scale = Math.min(width / widthGarden, height / heightGarden);

        updateScale(scale);
      }
    }.bind(this),
    300
  );

  const updateDragging = (id = false, type) => {
    updateIsDragging(type);

    if (id) {
      if (activeMarker === '') {
        document.getElementById(id).classList.add('placement_marker--active');
        updateActiveMarker(id);
      } else if (id !== activeMarker) {
        document.getElementById(activeMarker).classList.remove('placement_marker--active');
        document.getElementById(id).classList.add('placement_marker--active');
        updateActiveMarker(id);
      }
    } else if (activeMarker !== '') {
      document.getElementById(activeMarker).classList.remove('placement_marker--active');
      updateActiveMarker('');
    }
  };

  const renderPlants = () => {
    const returnableData = [];
    const { plant_placements = {} } = userData;

    if (!isEmptyObj(plant_placements)) {
      for (const [key, value] of Object.entries(plant_placements)) {
        const avaiablePlacementIndex = avaiablePlantPlacements.indexOf(value);
        if (avaiablePlacementIndex !== -1) {
          const nextState = [...avaiablePlantPlacements];
          nextState.splice(avaiablePlacementIndex, 1);
          updateAvaiablePlantPlacements(nextState);
        }
      }
    }

    const onboardingPlacements = [];

    if (!isOnboardingCompleted) {
      avaiablePlantPlacements.map((index) => {
        if (!activePlantPlacements[index].coversToolTip) {
          onboardingPlacements.push(index);
        }
      });
    }

    for (let i = 0; i < totalLevelsCompleted; i++) {
      const plant_level = UNLOCK_LEVELS_CHALLENGE_THRESHOLD[`level_${i + 1}`];
      const completed_offset = challengeCompletedCount - plant_level + 1;
      const plant_frame = completed_offset > 7 ? 7 : completed_offset;

      let plantPlacement = !isOnboardingCompleted
        ? onboardingPlacements[(onboardingPlacements.length * Math.random()) << 0]
        : avaiablePlantPlacements[(avaiablePlantPlacements.length * Math.random()) << 0];

      if (!isEmptyObj(plant_placements) && plant_placements[PLANTS[i]]) {
        plantPlacement = plant_placements[PLANTS[i]];
      }

      returnableData.push(
        <MyGoalsPlant
          activeGarden={activeGarden}
          scale={scale}
          plant={PLANTS[i]}
          key={i}
          callback={updateDragging}
          placements={activePlantPlacements}
          setPlantPlacement={setPlantPlacement}
          plantPlacement={plantPlacement}
          updatePlanePlacement={updatePlanePlacement}
          plantFrame={plant_frame}
          hasSeenLevelUp={hasSeenLevelUp}
          userHasSeenLevelUp={userHasSeenLevelUp}
          currentPlant={i === totalLevelsCompleted - 1}
          onboardingClicked={onboardingClicked}
          isOnboardingCompleted={isOnboardingCompleted}
          onboardingCurrentStep={onboardingCurrentStep}
        />
      );
    }

    return returnableData;
  };

  const renderPlacementMarkers = () => {
    const markers = [];
    for (const [key, value] of Object.entries(data)) {
      if (key !== 'avaiable_placements') {
        const { x, y, zIndex } = value;
        markers.push(<div id={key} key={key} className={`placement_marker ${zIndex}`} src={GARDEN_PLACEMENT_IMAGE} style={{ transform: `translate(${x}px, ${y}px)` }}></div>);
      }
    }
    return markers;
  };

  return (
    <div ref={scalableContainer} className="mygoals__container" style={{ height: '100%', width: '100%', backgroundImage: 'url(' + img + ')' }}>
      <div
        ref={gardenContainer}
        style={{
          width: `${GARDEN_DEFAULT_WIDTH}px`,
          height: `${GARDEN_DEFAULT_HEIGHT}px`,
          transform: `scale(${scale}) translateX(-50%)`,
          transformOrigin: 'top left',
          position: 'relative',
          left: '50%',
        }}
      >
        <div className="mygoals_garden">
          <div className={'mygoals_garden__placements' + (isDragging ? ' is-dragging' : '')}>{renderPlacementMarkers()}</div>
          {renderPlants()}
        </div>
        <MyGoalsLevels perviousStep={perviousStep} stepCallback={stepCallback} onboardingClicked={onboardingClicked} isOnboardingCompleted={isOnboardingCompleted} />
        {!isOnboardingCompleted && <MyGoalsOnboarding text={onboardingText} callback={onboardingCompleted} stepOneSeen={stepOneSeen} stepTwoSeen={stepTwoSeen} />}
      </div>
    </div>
  );
};

export default MyGoalsGarden;

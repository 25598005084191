// Is Ensighten Tracking Active?
export const activeTracking = (state) => {
  return state.ensightenTracking.isActive;
};

// // Get Page View Data
// export const getPageViewTracking = () => {
//   return BASE_TEMPLATE;
// };
//
// // Get Interaction Data Sets
// export const getInteractionTracking = (type) => {
//   switch (type) {
//     case 'featured':
//       return INTERACTION_FEATURED_DATA;
//     case 'web_game':
//       return INTERACTION_WEB_GAME_DATA;
//     default:
//       return '';
//   }
// };

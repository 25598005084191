import { BOOTSTRAP_APP_DATA } from 'store/actions/Bootstrap.actions';
import { URL_DYNAMIC, URL_STATIC, URL_FRONTEND, getUrlStaticImages } from 'constants/app/AppUrls';
import { EMOJI_IDS } from 'constants/content-library/ContentLibraryConstants';

const initState = {
  byID: {},
  alliDs: [],
};

const reducer = (state = initState, action) => {
  let nextState = {};

  switch (action.type) {
    case BOOTSTRAP_APP_DATA: {
      const { emojis = [] } = action.payload;
      const allIDs = [];
      const byID = {};
      for (const emoji of emojis) {
        const { id, icon, english_name } = emoji;
        emoji.english_name = english_name != '' ? english_name : 'nvs';
        emoji.icon = URL_DYNAMIC + icon;
        emoji.gif = getUrlStaticImages('emoji/' + EMOJI_IDS[id] + '.gif');
        emoji.png = getUrlStaticImages('emoji/' + EMOJI_IDS[id] + '.png');
        allIDs.push(id);
        byID[id] = emoji;
      }
      nextState.byID = byID;
      nextState.alliDs = allIDs;
      return { ...state, ...nextState };
    }
  }
  return state;
};

export default reducer;

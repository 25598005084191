import React, { Component } from 'react';
import { getUrlStaticImages } from 'constants/app/AppUrls';
import { WEBPSUPPORT, NO_IMAGE_FALLBACK } from 'constants/app/AppConstants';

const PictureMappingsNoImage = (props) => {
  const { src, imageAlt = '', imageTitle = '' } = props;

  return (
    <picture>
      <img
        src={getUrlStaticImages(WEBPSUPPORT != false ? NO_IMAGE_FALLBACK.replace(new RegExp('.(' + ['jpg'].join('|') + ')$', 'i'), '.webp') : NO_IMAGE_FALLBACK)}
        alt={imageAlt}
        title={imageTitle}
      />
    </picture>
  );
};

export default PictureMappingsNoImage;

import { ONBOARDING_MAP, ONBOARDING_MY_GOALS, ONBOARDING_MY_GOALS_INTRO } from 'store/actions/Onboarding.actions';

const initState = {
  map: {
    step_1: false,
    step_2: false,
    step_3: false,
    step_4: false,
    step_5: false,
  },
  my_goals: {
    step_1: false,
    step_2: false,
  },
  my_goals_intro: {
    step_1: false,
    step_2: false,
  },
};

const Onboarding = (state = initState, action) => {
  let nextState = {};

  switch (action.type) {
    case ONBOARDING_MAP: {
      const { step } = action.payload;
      const currentState = { ...state.map };
      currentState[step] = true;
      nextState.map = currentState;
      return { ...state, ...nextState };
    }
    case ONBOARDING_MY_GOALS: {
      const { step } = action.payload;
      const currentState = { ...state.my_goals };
      currentState[step] = true;
      nextState.my_goals = currentState;
      return { ...state, ...nextState };
    }
    case ONBOARDING_MY_GOALS_INTRO: {
      const { step } = action.payload;
      const currentState = { ...state.my_goals_intro };
      currentState[step] = true;
      nextState.my_goals_intro = currentState;
      return { ...state, ...nextState };
    }
  }

  return state;
};

export default Onboarding;

import * as DeviceDetection from 'utils/deviceDetection';

export const APP_CONFIG = window.siteData;
if (!APP_CONFIG) {
  console.error('missing site data');
} else {
  console.info('APP_CONFIG', APP_CONFIG);
}

export const PWAASSETSUFFIX = '?runtime=true';

const canUseWebP = () => {
  const elem = document.createElement('canvas');
  // eslint-disable-next-line no-extra-boolean-cast
  if (!!(elem.getContext && elem.getContext('2d'))) {
    const testString = !(window.mozInnerScreenX == null) ? 'png' : 'webp';
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/' + testString) == 0;
  }
  // very old browser like IE 8, canvas not supported
  return false;
};

function msieversion() {
  const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
  const msie = ua.indexOf('MSIE '); // IE 10 or older
  const trident = ua.indexOf('Trident/'); //IE 11

  return msie > 0 || trident > 0;
}

const isIE = msieversion();

if (isIE) {
  document.getElementsByTagName('html')[0].classList.add('is-ie');
}

// Site Status
// Can be : 'none', 'coming_soon', 'full'
export const SITE_STATUS = APP_CONFIG.site_status;
export const TRACKING_ACTIVE = APP_CONFIG.tracking;
export const FRONTEND_URL = APP_CONFIG.frontend_url;
export const FRONTEND_URL_IMAGES = FRONTEND_URL + 'img/';
export const LANGUAGE_CODE = APP_CONFIG.language_code;
export const BASE_URL = APP_CONFIG.base_url;
export const CACHEBUST = APP_CONFIG.cachebust;
export const REGION = APP_CONFIG.region;
export const COUNTRY = APP_CONFIG.country;
export const IS_BASE_URL = APP_CONFIG.is_base_url;
export const RECAPTCHA_KEY = APP_CONFIG.recaptcha;
export const OVERRIDE_GEO_LOCATION = APP_CONFIG.geoIpOverride;
export const LOCAL_STORAGE_OPTIONS = { states: ['userAuth', 'likes', 'onboarding'], namespace: 'climatechampions' };

// Dates
const DATE = new Date();
const DATE_DAY = DATE.getDate();
const DATE_MONTH = DATE.getMonth() + 1; // Jan is 0
const DATE_YEAR = DATE.getFullYear();

export const DATE_TODAY = Date.parse(DATE_MONTH + '/' + DATE_DAY + '/' + DATE_YEAR);
export const DATE_TOMORROW = Date.parse(DATE_MONTH + '/' + (DATE_DAY + 1) + '/' + DATE_YEAR);

export const WEBPSUPPORT = !__WATCH__ ? canUseWebP() : false;

// Device Detection
export const IS_DESKTOP = DeviceDetection.getDevice() === 'desktop';
export const IS_IOS = DeviceDetection.isiOS();

export const DEVICE_TYPE = DeviceDetection.getDevice();
export const ORIENTATION = DeviceDetection.getOrientation();

// Reward Banner Duration before collapses
export const REWARD_BANNER_DURATION = 6000;

export const APP_STORE = {
  APPLE: 'STORE_APPLE',
  GOOGLE: 'STORE_GOOGLE',
  AMAZON: 'STORE_AMAZON',
};

export const NAV_TYPE = {
  EXTERNAL: 'TYPE_EXTERNAL',
  INTERNAL: 'TYPE_EXISTING_PAGE',
  CUSTOM: 'TYPE_CUSTOM',
  NULL: 'TYPE_NULL',
};

export const CUSTOM_CONTENT_TYPE = {
  IFRAME: 'TYPE_IFRAME',
  ZIP: 'TYPE_ZIP',
};

export const IMAGE_SRC_SIZES = {
  LARGE: '(min-width: 768px) 66vw, 100vw',
  SMALL: '(min-width: 768px) 33vw, 50vw',
  DEFAULT: '100vw',
};

export const APP_MODES = {
  UNKNOWN: 'UNKNOWN',
  APP: 'APP',
  DESKTOP: 'DESKTOP',
};

export const PAGE = {
  LANDING: 'landing-page',
  SUB: 'sub-page',
};

export const PLAYER_STATES = {
  PLAYED: 'played',
  PLAYING: 'playing',
};

export const ONLINE_STATUS = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
  UNKNOWN: 'UNKNOWN',
};

// IdlePrompt constants
export const IDLE_PROMPT = {
  EXTERNAL_LINK: 'external',
  INTERNAL_LINK: 'internal',
  EXISTING_LINK: 'existing',
  NO_ID: 'no_id',
  NOT_ALLOWED_ON_PAGE: 'not_allowed_on_page',
  REACHED_FREQUENCY_LIMIT: 'reached_frequency_limit',
};

export const IDLE_PROMPT_FREQUENCY = {
  ONCE_ONLY: 'once',
  DAILY: 'daily',
  TIMES_PER_WEEK: 'times_per_week',
  TIMES_PER_MONTH: 'times_per_month',
};

/**
 * Got video src from here:
 * https://github.com/bitmovin/bitmovin-player-web-samples/blob/master/webpack-demo/src/index.js
 *
 * Used to test videos locally
 */
export const BITMOVIN_FALLBACK = {
  dash: 'https://bitdash-a.akamaihd.net/content/MI201109210084_1/mpds/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.mpd',
  hls: 'https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8',
  progressive: 'https://bitdash-a.akamaihd.net/content/MI201109210084_1/MI201109210084_mpeg-4_hd_high_1080p25_10mbits.mp4',
  poster: 'https://bitdash-a.akamaihd.net/content/MI201109210084_1/poster.jpg',
};

export const NO_IMAGE_FALLBACK = 'no-image/no-image-placeholder.png';

// Get number formatting based on lang code
const BASE_NUMBER_FORMATTING = {
  'en-gb': ',',
  'ar-ae': ',',
  'bg-bg': ' ',
  'cs-cz': ' ',
  'da-dk': ' ',
  'de-de': ' ',
  'es-es': ' ',
  'fr-fr': ' ',
  'hu-hu': ' ',
  'it-it': '.',
  'no-no': ' ',
  'nl-nl': '.',
  'pl-pl': ' ',
  'pt-pt': ' ',
  'ro-ro': '.',
  'ru-ru': ' ',
  'sv-se': ' ',
  'tr-tr': '.',
  'en-ae': ',',
  'en-za': ',',
};

export const NUMBER_FORMATTING = typeof BASE_NUMBER_FORMATTING[LANGUAGE_CODE] === 'undefined' ? ',' : BASE_NUMBER_FORMATTING[LANGUAGE_CODE];

export const PLANNER_EMPTY_SLOT = 'PLANNER_EMPTY_SLOT';
export const DEFAULT_PLANNER_DATA = {
  1: {
    slot_1: PLANNER_EMPTY_SLOT,
    slot_2: PLANNER_EMPTY_SLOT,
    slot_3: PLANNER_EMPTY_SLOT,
  },
  2: {
    slot_1: PLANNER_EMPTY_SLOT,
    slot_2: PLANNER_EMPTY_SLOT,
    slot_3: PLANNER_EMPTY_SLOT,
  },
  3: {
    slot_1: PLANNER_EMPTY_SLOT,
    slot_2: PLANNER_EMPTY_SLOT,
    slot_3: PLANNER_EMPTY_SLOT,
  },
  4: {
    slot_1: PLANNER_EMPTY_SLOT,
    slot_2: PLANNER_EMPTY_SLOT,
    slot_3: PLANNER_EMPTY_SLOT,
  },
  5: {
    slot_1: PLANNER_EMPTY_SLOT,
    slot_2: PLANNER_EMPTY_SLOT,
    slot_3: PLANNER_EMPTY_SLOT,
  },
  6: {
    slot_1: PLANNER_EMPTY_SLOT,
    slot_2: PLANNER_EMPTY_SLOT,
    slot_3: PLANNER_EMPTY_SLOT,
  },
  7: {
    slot_1: PLANNER_EMPTY_SLOT,
    slot_2: PLANNER_EMPTY_SLOT,
    slot_3: PLANNER_EMPTY_SLOT,
  },
};

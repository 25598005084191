import React from 'react';

const CheckMark = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" className="check-mark" viewBox="0 0 44.13 34.09">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_3" data-name="Layer 3">
        <polyline className="cls-1" points="6 18.06 16.04 28.09 38.13 6" />
      </g>
    </g>
  </svg>
);

export default CheckMark;

import { CONTENT_PAGES, PAGES } from 'constants/content-library/ContentLibraryConstants';

export const parseMetaData = (metadata) => {
  const allIDs = [];
  const byID = {};
  for (const page of ['coming_soon', 'videos', 'quizzes', 'my_goals', 'global_goals', 'challenges', 'faq']) {
    const id = CONTENT_PAGES[page];
    const o = {};
    o.title = metadata[`seo_${page}_title`] || metadata.meta_title;
    o.title_pattern = metadata[`seo_${page}_title_pattern`] || null;
    o.description = metadata[`seo_${page}_description`] || metadata.meta_description;
    o.description_pattern = metadata[`seo_${page}_description_pattern`] || null;
    o.keywords = metadata[`seo_${page}_keywords`] || metadata.meta_keywords;
    byID[id] = o;
    allIDs.push(id);
  }
  const home = {
    title: metadata.meta_title,
    description: metadata.meta_description,
    keywords: metadata.meta_keywords,
    og_title: metadata.og_title,
    og_description: metadata.og_description,
  };
  byID[PAGES.HOME] = home;
  allIDs.push(PAGES.HOME);
  return { allIDs, byID };
};

import { BOOTSTRAP_ADS_DATA } from 'store/actions/Bootstrap.actions';

export const AD_PROVIDER = {
  ENSIGHTEN: 'ENSIGHTEN',
};

export const AD_FORMAT = {
  MPU: 'MPU',
  LEADERBOARD: 'LEADERBOARD',
};

export const AD_PAGE = {
  HOMEPAGE: 'HOMEPAGE',
  MAIN_PAGE: 'MAIN_PAGE',
  SUB_PAGE: 'SUB_PAGE',
};

const initState = {
  ad_slots: {},
  ad_triggers: {},
  adsEnabled: false,
  adsSlug: false,
  adProvider: null,
  mpuAllIDs: [],
  leaderboardAllIDs: [],
};

const parseIDs = (div_ids) => {
  let allIDs = [];
  let byID = {};

  Object.keys(div_ids).map((value, index) => {
    if (div_ids[value] != '') {
      allIDs.push(div_ids[value]);
      byID = { ...byID, index };
    }
  });

  return allIDs;
};

const reducer = (state = initState, action) => {
  let nextState = {};
  switch (action.type) {
    case BOOTSTRAP_ADS_DATA: {
      const { enable_ads, advertising_slug, ad_slots, ...rest } = action.payload;
      const { leaderboard = {}, mpu = {} } = ad_slots;

      nextState = { ...rest };
      nextState.adsEnabled = enable_ads === '1';
      nextState.adsSlug = advertising_slug;
      nextState.adProvider = nextState.adsEnabled ? AD_PROVIDER.ENSIGHTEN : null;
      nextState.mpuAllIDs = parseIDs(mpu);
      nextState.leaderboardAllIDs = parseIDs(leaderboard);

      return { ...state, ...nextState };
    }
  }
  return state;
};

export default reducer;

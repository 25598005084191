import request from 'superagent/lib/client';
import { getUrlCreateNewPlayer, getUrlGetPlayer, getUrlLoginPlayer, getUrlSetPlayerName, getUrlSetPlayerData } from 'constants/app/AppUrls';

export const setPlayerData = ({ external_id, token, data = {} }) => {
  const url = getUrlSetPlayerData();
  return request
    .post(url)
    .type('form')
    .send({ external_id, token, data: JSON.stringify(data) })
    .then((response) => {
      return JSON.parse(response.text);
    })
    .catch((error) => {
      return { result: 'FAILURE', reason: error };
    });
};

export const createNewPlayer = ({ token }) => {
  const url = getUrlCreateNewPlayer();
  return request
    .post(url)
    .type('form')
    .send({ 'g-recaptcha-response': token })
    .then((response) => {
      return JSON.parse(response.text);
    })
    .catch((error) => {
      return { result: 'FAILURE', reason: error };
    });
};

export const getPlayer = ({ external_id, token }) => {
  const url = getUrlGetPlayer();
  return request
    .post(url)
    .type('form')
    .send({ external_id, token })
    .then((response) => {
      return JSON.parse(response.text);
    })
    .catch((error) => {
      return { result: 'FAILURE', reason: error };
    });
};

export const loginPlayer = ({ name, password }) => {
  const url = getUrlLoginPlayer();
  return request
    .post(url)
    .type('form')
    .send({ name, password })
    .then((response) => {
      return JSON.parse(response.text);
    })
    .catch((error) => {
      return { result: 'FAILURE', reason: error };
    });
};

export const setPlayerName = ({ external_id, token, name }) => {
  const url = getUrlSetPlayerName();

  return request
    .post(url)
    .type('form')
    .send({ external_id, token, name })
    .then((response) => {
      return JSON.parse(response.text);
    })
    .catch((error) => {
      return { result: 'FAILURE', reason: error };
    });
};

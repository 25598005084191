import React, { Component } from 'react';
import { connect } from 'react-redux';
import Emoji from 'components/emoji/Emoji';
import EmojiMore from 'components/emoji/EmojiMore';
import { clickedEntryCategory } from 'store/actions/Likes.actions';

class EmojiBar extends Component {
  constructor(props) {
    super(props);
    this.bindedHandleClicked = this.handleClicked.bind(this);
    this.bindedHandleActiveState = this.handleActiveState.bind(this);

    this.myRef = React.createRef();
  }

  render() {
    const { entryID, emojiLikeCounts } = this.props;
    const renderEmojis = emojiLikeCounts.map((item, index) => {
      return <Emoji key={`${entryID}_${item.id}`} {...item} clickHandler={this.bindedHandleClicked} />;
    });

    return (
      <React.Fragment>
        <div ref={this.myRef} className="emoji-item__section flex justify-content-center">
          <section className="emoji-item__container flex flex-wrap justify-content-center">{renderEmojis}</section>
        </div>
      </React.Fragment>
    );
  }

  handleClicked(e) {
    // console.log('handleClicked', e.currentTarget.getAttribute('data-id'));
    const { contentType, entryID, contentEnglishName } = this.props;
    const emojiID = e.currentTarget.getAttribute('data-id');
    const emojiEnglishName = e.currentTarget.getAttribute('data-englishname');
    this.props.clickedEntryCategory({ contentType, entryID, emojiID, emojiEnglishName, contentEnglishName });
  }

  handleActiveState() {
    let emojiContainer = this.myRef.current;
    emojiContainer.classList.contains('active') ? emojiContainer.classList.remove('active') : emojiContainer.classList.add('active');
  }
}

const mapStateToProps = (state) => {
  return {};
};
function mapDispatchToProps(dispatch) {
  return {
    clickedEntryCategory: (opts) => {
      dispatch(clickedEntryCategory(opts));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EmojiBar);

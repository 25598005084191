import { BOOTSTRAP_NAVIGATION_DATA, BOOTSTRAP_PAGE_CONTENT } from 'store/actions/Bootstrap.actions';

const initState = {
  allIDs: [],
  customIDs: [],
  byID: {},
};

const reducer = (state = initState, action) => {
  let nextState = {};

  switch (action.type) {
    case BOOTSTRAP_NAVIGATION_DATA: {
      const { allIDs, customIDs, byID } = action.payload;
      nextState.allIDs = allIDs;
      nextState.customIDs = customIDs;
      nextState.byID = byID;
      return { ...state, ...nextState };
    }
  }
  return state;
};

export default reducer;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Import constants
import { INTERACTION } from 'constants/ensighten-tracking/EnsightenTrackingConstants';

//import action
import { hideLevelUpPopup } from 'store/actions/UserLevels.actions';
import { triggerInteraction } from 'store/actions/EnsightenTracking.actions';

//import selectors
import { getSiteTextDataByGroup, getSiteTextDataByGroupedKey, getLevelEnglishName } from 'store/selectors/App.selectors';
import { getSiteUrlByName } from 'store/selectors/App.selectors';
import { showLevelUpPopup, getCurrentLevelID, getPreviousIDLevelID } from 'store/selectors/UserLevels.selectors';

class LevelUpModal extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.levelTitle !== this.props.levelTitle && this.props.showLevelUpPopup) {
      const { levelTrackingName, prevLevelTrackingName } = this.props;

      this.props.trackInteraction({ interactionType: INTERACTION.REWARD_UNLOCKED, id: levelTrackingName });
      this.props.trackInteraction({ interactionType: INTERACTION.LEVEL_UP, id: prevLevelTrackingName });
    }
  }

  render() {
    const { text, url, showLevelUpPopup, levelTitle } = this.props;
    const { alert_garden_growing = '', alert_level_up = '', alert_visit_garden_button = '' } = text;
    return (
      <section className={`modal--level-up modal--level-up--${showLevelUpPopup ? 'show' : 'hide'}`}>
        <div className="modal--level-up__button">
          <button onClick={this.props.hideLevelUpPopup} aria-label={'close'}>
            {'X'}
          </button>
        </div>
        <div className="modal--level-up__text">
          <span className="modal--level-up__text__level-up">{alert_level_up}</span>
          <span className="modal--level-up__text__copy">{levelTitle}</span>
          <Link to={url} onClick={this.props.hideLevelUpPopup}>
            {alert_visit_garden_button}
          </Link>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, props) => {
  const level = getCurrentLevelID(state);
  const prevLevel = getPreviousIDLevelID(state);
  return {
    level: getCurrentLevelID(state),
    text: getSiteTextDataByGroup(state, 'my_goals'),
    url: getSiteUrlByName(state, 'my_goals'),
    showLevelUpPopup: showLevelUpPopup(state),
    levelTitle: getSiteTextDataByGroupedKey(state, 'my_goals', `${level}_title`),
    prevLevelTitle: getSiteTextDataByGroupedKey(state, 'my_goals', `${prevLevel}_title`),
    levelTrackingName: getLevelEnglishName(state, level),
    prevLevelTrackingName: getLevelEnglishName(state, prevLevel),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideLevelUpPopup: () => {
      dispatch(hideLevelUpPopup());
    },
    trackInteraction: (opts) => {
      dispatch(triggerInteraction(opts));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LevelUpModal);

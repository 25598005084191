import React, { useState, Suspense } from 'react';
import { connect } from 'react-redux';
// import EmojiMoreIcon from 'components/inline-svgs/emoji/EmojiMoreIcon';
import GenericArrowDown from 'components/inline-svgs/generic/GenericArrowDown';

import { getSiteTextDataByGroup } from 'store/selectors/App.selectors';

const EmojiMoreIcon = React.lazy(() => import(/* webpackChunkName: 'emojimoreicon' */ 'components/inline-svgs/emoji/EmojiMoreIcon'));

const EmojiMore = (props) => {
  const { clickHandler, emojiText } = props;

  const [moreText, updateMoreText] = useState(emojiText.more_emojis);

  function multiClickHandler() {
    if (moreText === emojiText.more_emojis) {
      updateMoreText(emojiText.less_emojis);
    } else {
      updateMoreText(emojiText.more_emojis);
    }
    clickHandler();
  }

  return (
    <div className="emoji-item__more flex justify-content-start" onClick={multiClickHandler}>
      <div className="emoji-item__icon emoji-item__icon--more">
        <Suspense fallback={<div>Loading...</div>}>
          <EmojiMoreIcon />
        </Suspense>
      </div>
      <div className="emoji-item__label-container">
        <div className="emoji-item__text">{moreText}</div>
        <div className="emoji-item__arrow-icon">
          <GenericArrowDown />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    emojiText: getSiteTextDataByGroup(state, 'emojis'),
  };
};

export default connect(mapStateToProps)(EmojiMore);

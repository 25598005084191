import React, { useEffect } from 'react';
import { CHALLENGES_PER_LEVEL, UNLOCK_LEVELS_CHALLENGE_THRESHOLD } from 'constants/my-goals/MyGoalsConstants';
import { LANGUAGE_CODE } from 'constants/app/AppConstants';
import { getUrlFrontendImage } from 'constants/app/AppUrls';

const PLANT_GIFS = {
  level_1: 'aloe',
  level_2: 'amaryllis',
  level_3: 'cactus',
  level_4: 'cordyline',
  level_5: 'daisy',
  level_6: 'fern',
  level_7: 'flamingo',
  level_8: 'hyacinth',
  level_9: 'jasmine',
  level_10: 'lavender',
  level_11: 'orchid',
  level_12: 'sunflower',
};

// import components
import MyGoalsLockedIcon from 'components/inline-svgs/my-goals/MyGoalsLockedIcon';
import MyGoalsDownloadIcon from 'components/inline-svgs/my-goals/MyGoalsDownloadIcon';

const MyGoalsLevel = ({ name, inProgress = false, isCompleted = false, chanllengesCompleted = 0, level, nextLevel, headerLevel = false }) => {
  let widthStyle = { width: 0 };

  if (!isCompleted && inProgress) {
    if (nextLevel !== 'completed') {
      const current_level_threshold = UNLOCK_LEVELS_CHALLENGE_THRESHOLD[level];
      const next_level_threshold = UNLOCK_LEVELS_CHALLENGE_THRESHOLD[nextLevel];

      const level_offset = next_level_threshold - current_level_threshold;
      const current_completed_for_level = chanllengesCompleted - current_level_threshold;

      widthStyle = { width: `${(100 / level_offset) * current_completed_for_level}%` };
    }
  } else if (isCompleted) {
    widthStyle = { width: '100%' };
  }

  useEffect(() => {
    // HACKY - running out of time
    if (!isCompleted && inProgress) {
      document.getElementById('level-progress').style.width = widthStyle.width;
    }
  }, [chanllengesCompleted]);

  const lang = LANGUAGE_CODE.split('-');

  return (
    <div className={'mygoals__level' + (inProgress ? ' mygoals__level--progress' : '')}>
      {headerLevel ? <div className="mygoals__level__percentage" id="level-progress"></div> : <div className="mygoals__level__percentage" style={widthStyle}></div>}
      <div className="mygoals__level__details">
        <div className="name">{name}</div>
        <div className="icon">
          {isCompleted ? (
            <a href={getUrlFrontendImage(`plant-gifs/${lang[0]}/${PLANT_GIFS[level]}_${lang[0]}.gif`)} target="_blank" rel="noreferrer" download={name}>
              <MyGoalsDownloadIcon />
            </a>
          ) : (
            <MyGoalsLockedIcon />
          )}
        </div>
      </div>
    </div>
  );
};

export default MyGoalsLevel;

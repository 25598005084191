export const USER_SET_USERNAME = 'USER_SET_USERNAME';
export const USER_REMOVE_USERNAME = 'USER_REMOVE_USERNAME';
export const USER_SET_PASSCODE = 'USER_SET_PASSCODE';
export const USER_SET_MEMBERSHIP_CARD = 'USER_SET_MEMBERSHIP_CARD';
export const USER_SET_DATA = 'USER_SET_DATA';
export const USER_GENERATE_MEMBERSHIP_CARD = 'USER_GENERATE_MEMBERSHIP_CARD';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_SET_ANON_DETAILS = 'USER_SET_ANON_DETAILS';

export const USER_UPDATE_AVATAR = 'USER_UPDATE_AVATAR';

export const USER_UPDATE_PLANNER = 'USER_UPDATE_PLANNER';
export const USER_REMOVE_PLANNER = 'USER_REMOVE_PLANNER';

export const USER_SET_LIKE_CHALLENGE = 'USER_SET_LIKE_CHALLENGE';
export const USER_REMOVE_LIKE_CHALLENGE = 'USER_REMOVE_LIKE_CHALLENGE';

export const USER_SET_COMPLETED_CHALLENGE = 'USER_SET_COMPLETED_CHALLENGE';
export const USER_REMOVE_COMPLETED_CHALLENGE = 'USER_REMOVE_COMPLETED_CHALLENGE';

export const USER_SET_CANCELLED_CHALLENGE = 'USER_SET_CANCELLED_CHALLENGE';
export const USER_REMOVE_CANCELLED_CHALLENGE = 'USER_REMOVE_CANCELLED_CHALLENGE';

export const USER_MY_GOALS_SET_GARDEN_ID = 'USER_MY_GOALS_SET_GARDEN_ID';
export const USER_MY_GOALS_SET_PLANT_PLACEMENT = 'USER_MY_GOALS_SET_PLANT_PLACEMENT';

export const USER_FIRST_TIME_COMPLETED_CHALLENGE = 'USER_FIRST_TIME_COMPLETED_CHALLENGE';

export const UPDATE_COMPLETED_CHALLENGE_LIST = 'UPDATE_COMPLETED_CHALLENGE_LIST';

// import consts
import { DATE_TODAY, DATE_TODAY_NEXT_WEEK, DATE_TODAY_PREVIOUS_WEEK, PLANNER_EMPTY_SLOT } from 'constants/app/AppConstants';

// import helpers
import { dateRange } from 'utils/general';

// import services
import { setPlayerData } from 'services/apiPlayer';

// import actions
import { setUserLevelsData } from 'store/actions/UserLevels.actions';
import { setAuth, setAuthCredentials } from 'store/actions/UserAuth.actions';

// import selectors
import {
  getPasscode,
  getMyGoalsGardenID,
  getMyGoalsData,
  getUserAvatar,
  getUserAdditionalData,
  getFirstTimeCompletedChallenges,
  getPlannerData,
  getStoredData,
  completedPlayerChallenges,
} from 'store/selectors/User.selectors';
import { getUserToken, getUserExternalID } from 'store/selectors/UserAuth.selectors';

export const setAnonUser = ({ external_id, token }) => ({
  type: USER_SET_ANON_DETAILS,
  payload: { external_id, token },
});

export const updateAvatar = (id) => ({
  type: USER_UPDATE_AVATAR,
  payload: id,
});

export const logoutUser = (name) => ({
  type: USER_LOGOUT,
});

export const setUsername = (name) => ({
  type: USER_SET_USERNAME,
  payload: name,
});

export const removeUsername = () => ({
  type: USER_REMOVE_USERNAME,
});

export const generateMembershipCard = ({ name, passcode }) => ({
  type: USER_GENERATE_MEMBERSHIP_CARD,
  payload: { name, passcode },
});

export const setMembershipCard = (image) => ({
  type: USER_SET_MEMBERSHIP_CARD,
  payload: image,
});

export const setUserPasscode = (passcode) => ({
  type: USER_SET_PASSCODE,
  payload: passcode,
});

export const setUserLikedChallenge = (id) => ({
  type: USER_SET_LIKE_CHALLENGE,
  payload: id,
});

export const removeUserLikedChallenge = (id) => ({
  type: USER_REMOVE_LIKE_CHALLENGE,
  payload: id,
});

export const setUserCompletedChallenge = (id) => ({
  type: USER_SET_COMPLETED_CHALLENGE,
  payload: id,
});

export const removeUserCompletedChallenge = (id) => ({
  type: USER_REMOVE_COMPLETED_CHALLENGE,
  payload: id,
});

export const setUserCancelledChallenge = (id) => ({
  type: USER_SET_CANCELLED_CHALLENGE,
  payload: id,
});

export const removeUserCancelledChallenge = (id) => ({
  type: USER_REMOVE_CANCELLED_CHALLENGE,
  payload: id,
});

export const addFirstTimeCompletedChallenge = (id) => ({
  type: USER_FIRST_TIME_COMPLETED_CHALLENGE,
  payload: id,
});

export const setUserAvatar = (id) => {
  return (dispatch, getState) => {
    const state = getState();

    const userAvatar = getUserAvatar(state) === id;

    // If user is selecting garden not already set to data
    if (!userAvatar) {
      const token = getUserToken(state);
      const external_id = getUserExternalID(state);
      const data = {
        ...getStoredData(state),
        avatar: id,
      };

      setPlayerData({ external_id, token, data })
        .then((response) => {
          dispatch({ type: USER_UPDATE_AVATAR, payload: id });
        })
        .catch((error) => {
          return { result: 'FAILURE', reason: error };
        });
    }
  };
};

export const setAnonUserData = ({ player, token, external_id }) => {
  return (dispatch, getState) => {
    const state = getState();

    const { name, completed_challenges } = player;
    dispatch(setAuth({ token, external_id }));
    dispatch({ type: USER_SET_DATA, payload: { player, token, external_id } });

    if (__WATCH__) {
      dispatch(setUserLevelsData({ completed_challenges: TESTING }));
    } else {
      dispatch(setUserLevelsData({ completed_challenges }));
    }
  };
};

export const setUserData = ({ player, token, external_id }) => {
  return (dispatch, getState) => {
    const state = getState();

    const { name, completed_challenges, cancelled_challenges } = player;
    const passcode = getPasscode(getState());
    dispatch(generateMembershipCard({ name, passcode }));
    dispatch(setAuthCredentials({ name, passcode }));

    dispatch(setAuth({ token, external_id }));
    dispatch({ type: USER_SET_DATA, payload: { player } });

    if (__WATCH__) {
      dispatch(setUserLevelsData({ completed_challenges: TESTING }));
    } else {
      dispatch(setUserLevelsData({ completed_challenges }));
    }
  };
};

export const setChallengeCompleteDate = (id, activeId) => {
  return (dispatch, getState) => {
    const state = getState();
    const token = getUserToken(state);
    const external_id = getUserExternalID(state);

    const date = dateRange(activeId);
    const currentList = { ...completedPlayerChallenges(state) };
    const completedChallengeTimestamps = currentList[id] ? [...currentList[id]] : [];
    completedChallengeTimestamps.push(date.current);

    const data = {
      ...getStoredData(state),
      completed_challenges: { ...currentList, [id]: completedChallengeTimestamps },
    };

    setPlayerData({ external_id, token, data })
      .then((response) => {
        dispatch({ type: UPDATE_COMPLETED_CHALLENGE_LIST, payload: data.completed_challenges });
      })
      .catch((error) => {
        return { result: 'FAILURE', reason: error };
      });
  };
};

export const setUserGardenID = ({ id }) => {
  return (dispatch, getState) => {
    const state = getState();

    const isCurrentGardenID = getMyGoalsGardenID(state) === id;

    // If user is selecting garden not already set to data
    if (!isCurrentGardenID) {
      const token = getUserToken(state);
      const external_id = getUserExternalID(state);
      const data = {
        ...getStoredData(state),
        garden_id: id,
        plant_placements: {},
      };

      dispatch({ type: USER_MY_GOALS_SET_GARDEN_ID, payload: { garden_id: data.garden_id, plant_placements: data.plant_placements } });

      setPlayerData({ external_id, token, data }).catch((error) => {
        return { result: 'FAILURE', reason: error };
      });
    }
  };
};

export const setUserPlantPlacement = ({ id, placement_id }) => {
  return (dispatch, getState) => {
    const state = getState();

    const isCurrentGardenID = getMyGoalsGardenID(state) === id;

    // Is user is not selecting garden not already set to data
    if (!isCurrentGardenID) {
      const token = getUserToken(state);
      const external_id = getUserExternalID(state);

      const newStatePlants = { ...getStoredData(state).plant_placements };

      newStatePlants[id] = {};
      newStatePlants[id] = placement_id;

      const data = {
        ...getStoredData(state),
        plant_placements: newStatePlants,
      };

      setPlayerData({ external_id, token, data })
        .then((response) => {
          dispatch({ type: USER_MY_GOALS_SET_PLANT_PLACEMENT, payload: { garden_id: data.garden_id, plant_placements: data.plant_placements } });
        })
        .catch((error) => {
          return { result: 'FAILURE', reason: error };
        });
    }
  };
};

export const updateUsersPlanner = ({ id, slot_id, challenge_id }) => {
  return (dispatch, getState) => {
    const state = getState();
    const token = getUserToken(state);
    const external_id = getUserExternalID(state);

    const currentDateSlots = { ...getPlannerData(state)[id] };

    if (slot_id === 'random') {
      for (const key in currentDateSlots) {
        if (key) {
          if (currentDateSlots[key] === PLANNER_EMPTY_SLOT) {
            currentDateSlots[key] = challenge_id;
            break;
          }
        }
      }
    } else {
      currentDateSlots[slot_id] = challenge_id;
    }

    let planner = { ...getPlannerData(state), [id]: { ...currentDateSlots } };

    const data = { ...getStoredData(state), planner };

    // dispatch({ type: USER_UPDATE_PLANNER, payload: planner });

    setPlayerData({ external_id, token, data })
      .then((response) => {
        dispatch({ type: USER_UPDATE_PLANNER, payload: planner });
      })
      .catch((error) => {
        return { result: 'FAILURE', reason: error };
      });
  };
};

/* 
  **********
  For User Levels testing purposes
*/
const TESTING = [
  '19',
  '40',
  '19',
  '40',
  '19',
  '40',
  '19',
  '40',
  '19',
  '40',
  '19',
  '40',
  '19',
  '40',
  '19',
  '40',
  '19',
  '40',
  '19',
  '40',
  '19',
  '40',
  '19',
  '40',
  '19',
  '40',
  '19',
  '40',
  '40',
  '19',
  '40',
];

import React, { useEffect, useRef, useState } from 'react';
import { CONTENT } from 'constants/content-library/ContentLibraryConstants';

import Swiper from 'react-id-swiper';
import { isRTL } from 'utils/general';

// import Components
import MarqueVideo from 'components/marque/MarqueVideo';
import MarqueImage from 'components/marque/MarqueImage';
import StatsCTA from 'components/stats-cta/StatsCTA';

const MarqueArea = (props) => {
  const [swiper, setSwiper] = useState(null);
  const [activeSlide, updateActiveSlide] = useState(0);
  const [eventAttached, updateEventAttached] = useState(false);

  let swiperRef = useRef();

  const updateSlide = () => {
    updateActiveSlide(swiper.activeIndex);
  };

  let params = {
    // lazy: true,
    slidesPerView: 1,
    loop: false,
    autoplay: {
      delay: 15000,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      nextEl: '.marque--carousel__button.button-next',
      prevEl: '.marque--carousel__button.button-prev',
    },
  };

  // check if the site is RTL
  if (isRTL()) {
    params.rtl = true;
  }

  useEffect(() => {
    if (swiper !== null && !eventAttached) {
      swiper.on('transitionEnd', updateSlide);
      updateEventAttached(true);
    }

    if (swiper !== null) {
      if (activeSlide + 1 == props.data.length) {
        swiper.autoplay.stop();
      }
    }

    return () => {
      if (swiper !== null && eventAttached) {
        swiper.off('transitionEnd', updateSlide);
        updateEventAttached(false);
      }
    };
  }, [swiper, activeSlide]);

  function renderMarque(data, index) {
    switch (data.slider_type) {
      case CONTENT.IMAGE:
        return <MarqueImage {...data} active={index === activeSlide} />;
      case CONTENT.VIDEO:
        return <MarqueVideo {...data} active={index === activeSlide} index={index} />;
      default:
        return null;
    }
  }

  return (
    <div className="marque marque--carousel">
      <StatsCTA />
      <div className="marque__grid">
        <Swiper {...params} getSwiper={setSwiper}>
          {props.data.map((data, index) => {
            return (
              <div key={index}>
                <div className={`marque-area marque-area--${data.slider_type}`}>{renderMarque(data, index)}</div>
              </div>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default MarqueArea;

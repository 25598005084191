import React, { Component } from 'react';
import { connect } from 'react-redux';

//import constants
import { CHALLENGE_TYPES } from 'constants/challenges/ChallengeTypes';

// import selectors
import { getSiteTextDataByGroup, getChallengesTypeByUrl } from 'store/selectors/App.selectors';
import { getChallengesTypeFilters, getChallengesDropdownFilters, getChallengesActiveDropdownFilter, getDailyChallengesCount } from 'store/selectors/Challenges.selectors';
import { cancelledChallenges, completedChallenges } from 'store/selectors/User.selectors';

// import actions
import { updateDropdownFilter, addTypeFilter, removeTypeFilter, removeAllFilters, displayResetChallenges } from 'store/actions/Challenges.actions';

// import components
import ChallengeTypes from 'components/challenges/ChallengeTypes';
import ChallengeDropdown from 'components/challenges/ChallengeDropdown';

/**
 * Challenge Filter Bar Component.
 */

class ChallengeFilterBar extends Component {
  constructor(props) {
    super(props);

    this.filterCallback = this.filterCallback.bind(this);
    this.dropdownCallback = this.dropdownCallback.bind(this);
  }

  componentDidMount() {
    const { challengeFilter = '' } = this.props;
    if (challengeFilter !== '') {
      const filterName = CHALLENGE_TYPES[challengeFilter].name;
      this.filterCallback(filterName);
    }
  }

  componentWillUnmount() {
    this.props.removeAllFilters();
  }

  dropdownCallback(activeDropdownType) {
    this.props.updateDropdownFilter(activeDropdownType);
    this.props.displayResetChallenges();
  }

  filterCallback(activeFilterType) {
    if (this.props.activeFilterTypes.indexOf(activeFilterType) !== -1) {
      this.props.removeTypeFilter(activeFilterType);
    } else {
      this.props.addTypeFilter(activeFilterType);
    }

    this.props.displayResetChallenges();
  }

  render() {
    const { activeFilterTypes = [], activeDropdownFilter, dropdownFilters, filtersTitle, dailyChallengesCount, cancelledChallengesCount, completedChallengesCount } = this.props;

    return (
      <section className="challenge-filter__container">
        <div className="challenge-filter__dropdown">
          <ChallengeDropdown
            activeDropdownType={activeDropdownFilter}
            callback={this.dropdownCallback}
            filters={dropdownFilters}
            dailyChallengesCount={dailyChallengesCount}
            cancelledChallengesCount={cancelledChallengesCount}
            completedChallengesCount={completedChallengesCount}
          />
        </div>
        <div className="challenge-filter__type">
          <ChallengeTypes activeFilterTypes={activeFilterTypes} filterCallback={this.filterCallback} filtersTitle={filtersTitle} />
        </div>
      </section>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    updateDropdownFilter: (type) => {
      dispatch(updateDropdownFilter(type));
    },
    addTypeFilter: (type) => {
      dispatch(addTypeFilter(type));
    },
    removeTypeFilter: (type) => {
      dispatch(removeTypeFilter(type));
    },
    removeAllFilters: () => {
      dispatch(removeAllFilters());
    },
    displayResetChallenges: () => {
      dispatch(displayResetChallenges());
    },
  };
}

const mapStateToProps = (state, ownProps) => {
  const { filter } = ownProps;
  return {
    siteText: getSiteTextDataByGroup(state, 'challenges'),
    filtersTitle: getSiteTextDataByGroup(state, 'filter_titles'),
    activeFilterTypes: getChallengesTypeFilters(state),
    dropdownFilters: getChallengesDropdownFilters(state),
    activeDropdownFilter: getChallengesActiveDropdownFilter(state),
    challengeFilter: getChallengesTypeByUrl(state, filter),
    dailyChallengesCount: getDailyChallengesCount(state),
    cancelledChallengesCount: cancelledChallenges(state).length,
    completedChallengesCount: completedChallenges(state).length,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeFilterBar);

import { combineReducers } from 'redux';

import app from 'store/reducers/App';
import navigation from 'store/reducers/Navigation';
import homepage from 'store/reducers/Homepage';
import videopage from 'store/reducers/Videopage';
import quizpage from 'store/reducers/Quizpage';
import custompages from 'store/reducers/CustomPages';
import challenges from 'store/reducers/Challenges';
import ratings from 'store/reducers/Ratings';
import likes from 'store/reducers/Likes';
import emoji from 'store/reducers/Emoji';
// import experience from 'store/reducers/Experience';
// import userExperience from 'store/reducers/UserExperience';
import ads from 'store/reducers/Ads';
import ensightenTracking from 'store/reducers/EnsightenTracking';
import comingsoonpage from 'store/reducers/ComingSoonpage';
import legalpopups from 'store/reducers/LegalPopups';
import onetrust from 'store/reducers/OneTrust';
import showcasemap from 'store/reducers/ShowcaseMap';
import videoplaylist from 'store/reducers/VideoPlaylist';
import login from 'store/reducers/Login';
import user from 'store/reducers/User';
import userLevels from 'store/reducers/UserLevels';
import editorials from 'store/reducers/Editorials';
import userAuth from 'store/reducers/UserAuth';
import onboarding from 'store/reducers/Onboarding';
// import schoolspage from 'store/reducers/Schoolspage';

const rootReducer = combineReducers({
  app,
  navigation,
  homepage,
  videopage,
  quizpage,
  custompages,
  ratings,
  likes,
  emoji,
  ads,
  ensightenTracking,
  comingsoonpage,
  legalpopups,
  onetrust,
  showcasemap,
  challenges,
  videoplaylist,
  login,
  user,
  userLevels,
  editorials,
  userAuth,
  onboarding,
  // schoolspage,
});

export default rootReducer;

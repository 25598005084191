import React from 'react';
import { Link } from 'react-router-dom';
import { getContentLink } from 'constants/app/AppUrls';
import { connect } from 'react-redux';
import { getSiteTextDataByGroupedKey } from 'store/selectors/App.selectors';
import AppExitSiteLink from 'containers/base/AppExitSiteLink';
// SVG
import ExternalIcon from 'components/inline-svgs/content-items/ExternalIcon';
import GenericArrowDown from 'components/inline-svgs/generic/GenericArrowDown';

const MarqueCta = (props) => {
  const {
    marque_link_type,
    content_type,
    external_url,
    external_title,
    external_title_top = '',
    content_url,
    existing_title_top = '',
    existing_title,
    labelText = '',
    onMarqueCtaClick,
    show_leaving_message = true,
  } = props;

  if (marque_link_type === 'existing') {
    return (
      <div className="marque-cta__container container">
        <Link to={getContentLink(content_type, content_url)} className="flex flex-wrap justify-content-center marque-cta" aria-label={existing_title} onClick={onMarqueCtaClick}>
          <div className="marque-cta__icon">
            <GenericArrowDown />
          </div>
          <div className="marque-cta__text">
            <span>{existing_title_top === '' ? labelText : existing_title_top}</span>
            {existing_title}
          </div>
        </Link>
      </div>
    );
  }
  if (show_leaving_message !== 'false') {
    return (
      <div className="marque-cta__container container">
        <div className="marque-cta" aria-label={external_title}>
          <AppExitSiteLink link={external_url}>
            <div className="flex flex-wrap justify-content-center">
              <div className="marque-cta__icon">
                <GenericArrowDown />
              </div>
              <div className="marque-cta__text marque-cta__text--external">
                <span>{external_title_top === '' ? labelText : external_title_top}</span>
                {external_title}
              </div>
            </div>
          </AppExitSiteLink>
        </div>
      </div>
    );
  }
  return (
    <div className="marque-cta__container container">
      <a href={external_url} target="_blank" rel="noreferrer" className="marque-cta" aria-label={external_title}>
        <div className="flex flex-wrap justify-content-center">
          <div className="marque-cta__icon">
            <GenericArrowDown />
          </div>
          <div className="marque-cta__text marque-cta__text--external">
            <span>{external_title_top === '' ? labelText : external_title_top}</span>
            {external_title}
          </div>
        </div>
      </a>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { content_type = '' } = ownProps;
  let contentType = content_type != '' ? content_type : 'external';
  return {
    labelText: getSiteTextDataByGroupedKey(state, 'marque_cta_text', contentType),
  };
};

export default connect(mapStateToProps)(MarqueCta);

import { PAGES } from 'constants/content-library/ContentLibraryConstants';
import { triggerInteraction } from 'store/actions/EnsightenTracking.actions';
import { INTERACTION } from 'constants/ensighten-tracking/EnsightenTrackingConstants';
import { PAGE } from 'constants/app/AppConstants';
import { getVideoMarqueData } from 'store/selectors/Videopage.selectors';
import { getContentIDByFriendlyURL, getHideCookieBar } from 'store/selectors/App.selectors';

export const BOOTSTRAP_INIT = '[APP] BOOTSTRAP_INIT';
export const BOOTSTRAP_COMPLETE = '[APP] BOOTSTRAP_COMPLETE';
export const ACTIVE_MOBILE_NAV = '[APP] ACTIVE_MOBILE_NAV';
export const ACTIVE_EXIT_SITE_MODAL = '[APP] ACTIVE_EXIT_SITE_MODAL';
export const ACTIVE_AUTH_MODAL = '[APP] ACTIVE_AUTH_MODAL';
export const UDPATE_ACTIVE_PAGE = '[APP] UDPATE_ACTIVE_PAGE';
export const SET_ONLINE_STATUS = '[APP] SET_ONLINE_STATUS';
export const ACTIVE_OFFLINE_MODAL = '[APP] ACTIVE_OFFLINE_MODAL';
export const HIDE_COOKIE_BAR = '[APP] HIDE_COOKIE_BAR';

export const startBootstrap = (JsonData) => ({
  type: BOOTSTRAP_INIT,
  payload: JsonData,
});

export const completeBootstrap = () => ({
  type: BOOTSTRAP_COMPLETE,
});

export const updateActivePage = ({ perviousPage, perviousPageSubpage, page, subpage }) => ({
  type: UDPATE_ACTIVE_PAGE,
  payload: { perviousPage, perviousPageSubpage, page, subpage },
});

export const activeMobileNav = () => ({
  type: ACTIVE_MOBILE_NAV,
});

export const activeExitSiteModal = (link) => ({
  type: ACTIVE_EXIT_SITE_MODAL,
  payload: link,
});

export const activeOfflineModal = (link) => ({
  type: ACTIVE_OFFLINE_MODAL,
  payload: link,
});

export const activeAuthModal = () => ({
  type: ACTIVE_AUTH_MODAL,
});

export const setOnlineStatus = (status) => ({
  type: SET_ONLINE_STATUS,
  payload: status,
});

export const hideCookieBar = (status) => ({
  type: HIDE_COOKIE_BAR,
  payload: status,
});

export const toggleCookieBar = () => {
  return (dispatch, getState) => {
    if (!getHideCookieBar(getState())) {
      setCookie('cn_tos', true, { expires: 1825, path: '/', secure: true });
      dispatch(hideCookieBar(true));
    } else {
      dispatch(hideCookieBar(false));
    }
  };
};

export const checkCookieBar = () => {
  return (dispatch, getState) => {
    const cookiePresent = getCookie('cn_tos') || false;

    dispatch(hideCookieBar(cookiePresent));
  };
};

export const clickedMarqueCTA = ({ pageContentType }) => {
  return (dispatch, getState) => {
    const state = getState();
    let data;
    const opts = {
      pageType: PAGE.LANDING,
      pageContentType,
    };
    switch (pageContentType) {
      case PAGES.VIDEOS:
        data = getVideoMarqueData(state);
        opts.interactionType = INTERACTION.FEATURE_VIDEO;
        break;
    }
    opts.contentType = data.marque_cta.content_type;
    opts.id = getContentIDByFriendlyURL(state, opts.contentType, data.marque_cta.content_url);
    dispatch(triggerInteraction(opts));
  };
};

const converterRead = (value) => {
  if (value[0] === '"') {
    value = value.slice(1, -1);
  }
  return value.replace(/(%[\dA-F]{2})+/gi, decodeURIComponent);
};

const converterWrite = (value) => {
  return encodeURIComponent(value).replace(/%(2[346BF]|3[AC-F]|40|5[BDE]|60|7[BCD])/g, decodeURIComponent);
};

const setCookie = (name, value, attributes) => {
  if (typeof document === 'undefined') {
    return;
  }

  if (typeof attributes.expires === 'number') {
    attributes.expires = new Date(Date.now() + attributes.expires * 864e5);
  }
  if (attributes.expires) {
    attributes.expires = attributes.expires.toUTCString();
  }

  name = encodeURIComponent(name)
    .replace(/%(2[346B]|5E|60|7C)/g, decodeURIComponent)
    .replace(/[()]/g, escape);

  let stringifiedAttributes = '';
  for (let attributeName in attributes) {
    if (!attributes[attributeName]) {
      continue;
    }

    stringifiedAttributes += '; ' + attributeName;

    if (attributes[attributeName] === true) {
      continue;
    }

    // Considers RFC 6265 section 5.2:
    // ...
    // 3.  If the remaining unparsed-attributes contains a %x3B (";")
    //     character:
    // Consume the characters of the unparsed-attributes up to,
    // not including, the first %x3B (";") character.
    // ...
    stringifiedAttributes += '=' + attributes[attributeName].split(';')[0];
  }

  return (document.cookie = name + '=' + converterWrite(value, name) + stringifiedAttributes);
};
const getCookie = (name) => {
  if (typeof document === 'undefined' || (arguments.length && !name)) {
    return;
  }

  // To prevent the for loop in the first place assign an empty array
  // in case there are no cookies at all.
  let cookies = document.cookie ? document.cookie.split('; ') : [];
  let jar = {};
  for (let i = 0; i < cookies.length; i++) {
    let parts = cookies[i].split('=');
    let value = parts.slice(1).join('=');

    try {
      let found = decodeURIComponent(parts[0]);
      jar[found] = converterRead(value, found);

      if (name === found) {
        break;
      }
    } catch (e) {
      console.log('err', e);
    }
  }

  return name ? jar[name] : jar;
};

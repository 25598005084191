//magic things
import React, { Component } from 'react';
import { connect } from 'react-redux';

// import constants
import { PAGES } from 'constants/content-library/ContentLibraryConstants';
import { PAGE } from 'constants/app/AppConstants';

// import components
import MarqueArea from 'containers/content/MarqueArea';
import SiteMetaData from 'components/generic/SiteMetaData';
import Challenges from 'components/challenges/Challenges';
import ChallengeFilterBar from 'components/challenges/ChallengeFilterBar';
import StatsCTA from 'components/stats-cta/StatsCTA';
import ChallengesOfflineMessage from 'components/challenges/ChallengesOfflineMessage';

//import selectors
import { getSiteMetaData, getOnlineStatus } from 'store/selectors/App.selectors';

// import actions
import { triggerPageView } from 'store/actions/EnsightenTracking.actions';
import { activeOfflineModal } from 'store/actions/App.actions';

class PageChallenges extends Component {
  constructor(props) {
    super(props);

    this.toggleOfflineMessage = this.toggleOfflineMessage.bind(this);
  }
  componentDidMount() {
    // Setup ensighten tracking stuff
    scrollTo(0, 0);
    this.props.trackPageView({ contentType: PAGES.CHALLENGES, pageType: PAGE.LANDING });
  }

  toggleOfflineMessage() {
    this.props.activeOfflineModal();
  }

  render() {
    const { match } = this.props;
    const { params } = match;
    const { urlFragment = '' } = params;

    return (
      <section className={`main-content`}>
        <SiteMetaData {...this.props.metadata} />
        <div className="challengespage container">
          <StatsCTA currentPage={'challenges'} />
          {this.props.isOnline ? <ChallengeFilterBar filter={urlFragment} /> : <ChallengesOfflineMessage />}
          <Challenges isOnline={this.props.isOnline} toggleOfflineMessage={this.toggleOfflineMessage} />
        </div>
      </section>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    trackPageView: (opts) => {
      dispatch(triggerPageView(opts));
    },
    activeOfflineModal: () => {
      dispatch(activeOfflineModal());
    },
  };
}

const mapStateToProps = (state) => {
  return {
    metadata: getSiteMetaData(state, PAGES.CHALLENGES),
    isOnline: getOnlineStatus(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageChallenges);

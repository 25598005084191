import { BOOTSTRAP_PAGE_CONTENT } from 'store/actions/Bootstrap.actions';

const initState = {
  allIDs: [],
  byID: {},
  markerRefs: {},
};

const Editorials = (state = initState, action) => {
  let nextState = {};
  switch (action.type) {
    case BOOTSTRAP_PAGE_CONTENT: {
      const { data } = action.payload.editorials;
      const { allIDs, byID, markerRefs } = data;
      nextState.allIDs = allIDs;
      nextState.byID = byID;
      nextState.markerRefs = markerRefs;
      return { ...state, ...nextState };
    }
  }
  return state;
};

export default Editorials;
